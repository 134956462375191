import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { validateInputField } from "../../../containers/UpdateModulePage/utils";
import useWatchFormInputs from "../../../hooks/useWatchFormInputs";
import { selectSettings } from "../../../store/selectors";
import translation from "../../../utils/translation";
import InputCheckbox from "../../ui/Inputs/InputCheckbox";
import InputField from "../../ui/Inputs/InputField";
import InputSelect from "../../ui/Inputs/InputSelect";
import Modal from "../../ui/Modal";

const AddClientAddress = ({
  onAddNewAddress,
  onCloseModal,
  citiesOptions,
  citiesDependats,
  regionsOptions,
  defaultAddressValues,
}: {
  onAddNewAddress: CallableFunction;
  onCloseModal: CallableFunction;
  citiesOptions: any[];
  citiesDependats: any[];
  regionsOptions: any[];
  defaultAddressValues: any;
}) => {
  const { siteLang } = useSelector(selectSettings);

  const { handleSubmit, formState, register, setValue, watch } = useForm({
    defaultValues: defaultAddressValues,
  });

  const watchedInputs = useWatchFormInputs(defaultAddressValues, true, watch);

  return (
    <Modal
      as="form"
      onSubmit={handleSubmit((data) => onAddNewAddress(data))}
      maxWidth="500px"
      showHeader={true}
      headerTitle={[
        translation[siteLang].add,
        translation[siteLang].address.toLowerCase(),
      ].join(" ")}
      showFooter={true}
      footerButtons={[
        {
          label: translation[siteLang].cancel,
          buttonType: "button",
          onClick: () => onCloseModal(),
          styleType: "secondary",
          size: "lg",
        },
        {
          label: translation[siteLang].apply,
          buttonType: "submit",
          styleType: "primary",
          size: "lg",
        },
      ]}
    >
      <div className="modal_section">
        <InputField
          type="text"
          label={translation[siteLang].name}
          props={register(`name`, validateInputField(siteLang, true))}
          error={formState.errors?.name?.message}
        />
      </div>
      <div className="modal_section">
        <InputField
          type="text"
          label={translation[siteLang].street}
          props={register(`street`, validateInputField(siteLang, true))}
          error={formState.errors?.street?.message}
        />
      </div>
      <div className="modal_section">
        <InputSelect
          label={translation[siteLang].city}
          options={[
            {
              options: citiesOptions,
            },
          ]}
          props={{
            ...register(`city`, validateInputField(siteLang, true, true)),
            value: watchedInputs.city,
          }}
          error={formState.errors?.city?.message}
          allInputsValues={watchedInputs}
          dependantConditions={citiesDependats}
          setDependantValues={(name: any, value: string) =>
            setValue(name, value)
          }
        />
      </div>
      <div className="modal_section">
        <InputSelect
          label={translation[siteLang].region}
          options={[
            {
              options: regionsOptions,
            },
          ]}
          allInputsValues={watchedInputs}
          props={{
            ...register(`region_id`, validateInputField(siteLang, true, true)),
            value: watchedInputs.region_id,
          }}
          error={formState.errors?.region_id?.message}
        />
      </div>
      <div className="modal_section">
        <label className="field_label">{translation[siteLang].location}</label>
        <div className="modal_row">
          <div className="modal_col">
            <InputField
              type="text"
              props={register(`lat`, validateInputField(siteLang, true))}
              error={formState.errors?.lat?.message}
            />
          </div>
          <div className="modal_col">
            <InputField
              type="text"
              props={register(`long`, validateInputField(siteLang, true))}
              error={formState.errors?.long?.message}
            />
          </div>
        </div>
      </div>
      <div className="modal_section">
        <InputCheckbox
          checkboxStyle="switcher"
          topLabel={translation[siteLang].home}
          props={register(`home`)}
        />
      </div>
      {watchedInputs.home ? (
        <>
          <div className="modal_section">
            <InputField
              type="text"
              label={translation[siteLang].building}
              props={register(`building`, validateInputField(siteLang, true))}
              error={formState.errors?.building?.message}
            />
          </div>
          <div className="modal_section">
            <InputField
              type="text"
              label={translation[siteLang].floor}
              props={register(`floor`, validateInputField(siteLang, true))}
              error={formState.errors?.floor?.message}
            />
          </div>
          <div className="modal_section">
            <InputField
              type="text"
              label={translation[siteLang].appartment}
              props={register(`appartment`, validateInputField(siteLang, true))}
              error={formState.errors?.appartment?.message}
            />
          </div>
        </>
      ) : null}
    </Modal>
  );
};

export default AddClientAddress;
