import { useCallback } from "react";
import { useForm } from "react-hook-form";
import useWatchFormInputs from "./useWatchFormInputs";

const useFilterListing = (defaultValues: any) => {
  const {
    handleSubmit,
    watch,
    register,
    reset: resetFields,
    setValue,
  } = useForm({
    defaultValues: { ...defaultValues },
  });

  const filterFieldsValues = useWatchFormInputs(
    { ...defaultValues },
    true,
    watch
  );

  const reset = useCallback(() => {
    resetFields({
      ...Object.keys(defaultValues).reduce(
        (acc, cur) =>
          Object.assign(acc, {
            [cur]:
              cur.includes("time") || cur.includes("date")
                ? defaultValues[cur]
                : "__default",
          }),
        {}
      ),
    });
  }, [resetFields, defaultValues]);

  return {
    handleSubmit,
    register,
    setValue,
    reset,
    filterFieldsValues,
  };
};

export default useFilterListing;
