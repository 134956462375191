import { useCallback, useRef, useState } from "react";
import Button from "../Button";
import { DropdownType } from "./utils";
import Wrapper, { Overlay, Widget } from "./styles";

const Dropdown = ({
  button,
  children,
  isFitContent = false,
  noAutoCollapse = false,
  openDirection = "start",
  widgetStyle = {},
}: DropdownType) => {
  const [isWidgetExpanded, setIsWidgetExpanded] = useState<{
    expanded: boolean;
    isTop: boolean;
  }>({ expanded: false, isTop: false });
  const widgetContainer = useRef<HTMLDivElement>(null);

  const onOpenDropdownWidgetHandler = useCallback(() => {
    let isTop = false;
    if (
      widgetContainer.current &&
      widgetContainer.current.getBoundingClientRect().bottom >
        document.documentElement.offsetHeight
    )
      isTop = true;
    setIsWidgetExpanded({ expanded: true, isTop });
  }, []);

  return (
    <Wrapper>
      {button && (
        <Button
          buttonType="button"
          {...button}
          onClick={onOpenDropdownWidgetHandler}
        />
      )}
      <Widget
        className="dropdown_widget"
        expanded={isWidgetExpanded.expanded}
        isTop={isWidgetExpanded.isTop}
        openDirection={openDirection}
        {...(noAutoCollapse
          ? {}
          : {
              onClick: () =>
                setIsWidgetExpanded({ expanded: false, isTop: false }),
            })}
        ref={widgetContainer}
        isFitContent={isFitContent}
        style={{ ...widgetStyle }}
      >
        {children}
      </Widget>
      {isWidgetExpanded.expanded && (
        <Overlay
          onClick={() => setIsWidgetExpanded({ expanded: false, isTop: false })}
        ></Overlay>
      )}
    </Wrapper>
  );
};

export default Dropdown;
