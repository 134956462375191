import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import {
  BoxWrapper,
  SectionsWrapper,
} from "../../components/updatingPages/components";
import MainContent from "../../containers/MainContent";
import useUpdatingData from "../../hooks/useUpdatingData";
import { selectSettings } from "../../store/selectors";
import { Arrow } from "../../utils/icons";
import translation from "../../utils/translation";
import { useMemo } from "react";
import UpdateModulePage from "../../containers/UpdateModulePage";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { useParams } from "react-router-dom";
import InputSelect from "../../components/ui/Inputs/InputSelect";
import { ClientDetailsRow } from "../../components/clients/ClientDetails/styles";
import { formatDate } from "../../utils/formatter";

const moduleBaseName = "/settings/tickets";

const UpdateSupportTickets = ({ type }: { type: "create" | "edit" }) => {
  const { siteLang } = useSelector(selectSettings);
  const { ticketID } = useParams();

  const defaultValues = useMemo(
    () => ({
      status: "",
    }),
    []
  );

  const {
    isFetching,
    extraFetchedData,
    register,
    watchedInputs,
    setValue,
    moduleDefaultData,
    ...updatingModule
  } = useUpdatingData({
    moduleType: "admin",
    moduleState: type,
    moduleID: +(ticketID || 0),
    moduleName: "support_forms",
    modulePostEndpoint:
      type === "edit"
        ? `support-form/update/${ticketID}`
        : `support-form/update`,
    modulePostType: "formData",
    watchInputFields: true,
    defaultValues,
    queries: "with=user,support_reason",
    navigateAfterSubmissionTo: moduleBaseName,
  });

  return (
    <MainContent onlyInPage={true} hasFixedWidthHeader={true}>
      <UpdateModulePage moduleBaseName={moduleBaseName} {...updatingModule}>
        <PageTitle
          firstSection={
            <div className="page_header_title_update">
              <Button
                buttonType="button"
                size="md"
                styleType="icon"
                onClick={() => updatingModule.onNavigateBack(moduleBaseName)}
                disabled={updatingModule.submissionState.isSubmitting}
                icon={
                  <Arrow
                    height="27"
                    width="27"
                    color="#101928"
                    extraStyle={{
                      transform: `rotate(${
                        siteLang === "ar" ? "180" : "0"
                      }deg)`,
                    }}
                  />
                }
              />
              <h1>
                {type === "edit"
                  ? [
                      translation[siteLang].edit,
                      " ",
                      translation[siteLang].ticket.toLowerCase(),
                    ]
                  : [
                      translation[siteLang].add,
                      " ",
                      translation[siteLang][
                        siteLang === "ar" ? "ticket" : "new"
                      ].toLowerCase(),
                      " ",
                      translation[siteLang][
                        siteLang === "ar" ? "new" : "ticket"
                      ].toLowerCase(),
                    ]}
              </h1>
            </div>
          }
          secondSection={
            <Button
              label={translation[siteLang].save}
              buttonType="submit"
              size="lg"
              styleType="primary"
              disabled={
                isFetching || updatingModule.submissionState.isSubmitting
              }
              isLoading={updatingModule.submissionState.isSubmitting}
            />
          }
        />
        <SectionsWrapper isSeparated={true} fixedWidth={true}>
          {isFetching ? (
            <div style={{ width: "fit-content", margin: "100px auto 0" }}>
              <LoadingSpinner type="circle" />
            </div>
          ) : (
            <div className="section_wrapper_row">
              <div style={{ width: "70%" }}>
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].ticket}</h3>
                  </div>
                  <div className="box_content">
                    <div className="box_section">
                      <ClientDetailsRow>
                        <span>{translation[siteLang].reason}</span>
                        <span>{moduleDefaultData?.support_reason?.reason}</span>
                      </ClientDetailsRow>
                      <ClientDetailsRow>
                        <span>{translation[siteLang].message}</span>
                        <span>{moduleDefaultData.message}</span>
                      </ClientDetailsRow>
                      <ClientDetailsRow>
                        <span>{translation[siteLang].user_id}</span>
                        <span>{moduleDefaultData?.user_id}</span>
                      </ClientDetailsRow>
                      <ClientDetailsRow>
                        <span>{translation[siteLang].user}</span>
                        <span>{moduleDefaultData?.user?.name}</span>
                      </ClientDetailsRow>
                      <ClientDetailsRow>
                        <span>{translation[siteLang].email}</span>
                        <span>{moduleDefaultData?.user?.email}</span>
                      </ClientDetailsRow>
                      <ClientDetailsRow>
                        <span>{translation[siteLang].phone}</span>
                        <span>{moduleDefaultData?.user?.phone}</span>
                      </ClientDetailsRow>
                      <ClientDetailsRow>
                        <span>{translation[siteLang].time}</span>
                        <span>
                          {formatDate(moduleDefaultData.created_at, siteLang)}
                        </span>
                      </ClientDetailsRow>
                    </div>
                  </div>
                </BoxWrapper>
              </div>
              <div style={{ width: "30%" }}>
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].status}</h3>
                  </div>
                  <div className="box_content">
                    <div className="box_section">
                      <InputSelect
                        options={[
                          {
                            options: [
                              {
                                label: translation[siteLang].open,
                                value: "open",
                              },
                              {
                                label: translation[siteLang].closed,
                                value: "closed",
                              },
                              {
                                label: translation[siteLang].spam,
                                value: "spam",
                              },
                            ],
                          },
                        ]}
                        props={{
                          ...register("status"),
                          value: watchedInputs.status,
                        }}
                      />
                    </div>
                  </div>
                </BoxWrapper>
              </div>
            </div>
          )}
        </SectionsWrapper>
      </UpdateModulePage>
    </MainContent>
  );
};

export default UpdateSupportTickets;
