import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import InputField from "../../components/ui/Inputs/InputField";
import {
  BoxWrapper,
  SectionsWrapper,
} from "../../components/updatingPages/components";
import MainContent from "../../containers/MainContent";
import useUpdatingData from "../../hooks/useUpdatingData";
import { selectSettings, selectUser } from "../../store/selectors";
import { Arrow } from "../../utils/icons";
import translation from "../../utils/translation";
import { useMemo } from "react";
import UpdateModulePage from "../../containers/UpdateModulePage";
import { validateInputField } from "../../containers/UpdateModulePage/utils";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { useParams } from "react-router-dom";
import InputSelect from "../../components/ui/Inputs/InputSelect";
import Calendar from "../../components/ui/Calendar";
import { getModuleData, getSingleModuleData } from "../../utils/fetchers";
import { format as dateFormat, nextDay } from "date-fns";
import { work_days } from "./utils";

const UpdateDaysoff = ({ type }: { type: "create" | "edit" }) => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { branch_id: userBranchID },
  } = useSelector(selectUser);
  const { workerName, workerID, dayoffID } = useParams();

  const moduleBaseName = useMemo(
    () => `/workers/daysoff/${workerName}/${workerID}`,
    [workerName, workerID]
  );

  const extraFetchers = useMemo(
    () => [
      {
        function: (signal: AbortSignal) =>
          getModuleData(
            userBranchID ? "branch" : "admin",
            "day_offs",
            `where=worker_id=${workerID}`,
            signal
          ),
        returnName: "day_offs",
        isModule: true,
      },
      {
        function: (signal: AbortSignal) =>
          getSingleModuleData(
            userBranchID ? "branch" : "admin",
            "workers",
            +(workerID || 0),
            "with=work_days",
            signal
          ),
        returnName: "data",
      },
    ],
    [userBranchID, workerID]
  );

  const defaultValues = useMemo(
    () => ({
      reason: "",
      day: new Date(),
      status: "pending",
      ...(type === "create" ? { worker_id: workerID } : {}),
    }),
    [workerID, type]
  );

  const resetModuleDefaults = useMemo(
    () => (clonedData: any, extraFetchedData: any) => {
      /* eslint-disable no-loop-func */
      let dayDate = clonedData?.day ? new Date(clonedData.day) : new Date();
      if (type === "create") {
        let isValidDate = false;
        while (isValidDate === false) {
          if (
            (extraFetchedData?.day_offs || []).findIndex((el: any) =>
              el.status === "accepted"
                ? dateFormat(new Date(el.day), "yyyy-MM-dd") ===
                  dateFormat(dayDate, "yyyy-MM-dd")
                : false
            ) === -1 &&
            (extraFetchedData?.data?.work_days || []).findIndex(
              (el: any) =>
                dayDate.getDay() ===
                  work_days.findIndex((weekday) => weekday === el.day) &&
                el.on === 0
            ) === -1
          ) {
            isValidDate = true;
          } else {
            dayDate = nextDay(
              dayDate,
              (dayDate.getDay() === 6 ? 0 : dayDate.getDay() + 1) as Day
            );
          }
        }
      }
      return { day: dayDate };
    },
    [type]
  );

  const {
    isFetching,
    extraFetchedData,
    register,
    watchedInputs,
    setValue,
    moduleDefaultData,
    ...updatingModule
  } = useUpdatingData({
    moduleType: userBranchID ? "branch" : "admin",
    moduleState: type,
    moduleID: +(dayoffID || 0),
    moduleName: "day_offs",
    modulePatch: type === "edit" ? true : false,
    modulePostEndpoint:
      type === "edit" ? `dayoff/update/${dayoffID}` : "dayoff/create",
    modulePostType: "object",
    watchInputFields: true,
    defaultValues,
    navigateAfterSubmissionTo: moduleBaseName,
    extraFetchers,
    resetModuleDefaults,
  });

  const formState = updatingModule.formState;

  const updatedDataToSubmit = useMemo(() => {
    return (data: any) => ({
      day: `${data.day.getFullYear()}-${
        (`${data.day.getMonth() + 1}`.length === 1 ? "0" : "") +
        (data.day.getMonth() + 1)
      }-${
        (`${data.day.getDate()}`.length === 1 ? "0" : "") + data.day.getDate()
      }`,
    });
  }, []);

  return (
    <MainContent onlyInPage={true} hasFixedWidthHeader={true}>
      <UpdateModulePage
        moduleBaseName={moduleBaseName}
        updatedDataToSubmit={updatedDataToSubmit}
        {...updatingModule}
      >
        <PageTitle
          firstSection={
            <div className="page_header_title_update">
              <Button
                buttonType="button"
                size="md"
                styleType="icon"
                onClick={() => updatingModule.onNavigateBack(moduleBaseName)}
                disabled={updatingModule.submissionState.isSubmitting}
                icon={
                  <Arrow
                    height="27"
                    width="27"
                    color="#101928"
                    extraStyle={{
                      transform: `rotate(${
                        siteLang === "ar" ? "180" : "0"
                      }deg)`,
                    }}
                  />
                }
              />
              <h1>
                {type === "edit"
                  ? [
                      translation[siteLang].edit,
                      " ",
                      translation[siteLang].dayoff.toLowerCase(),
                    ]
                  : [
                      translation[siteLang].add,
                      " ",
                      translation[siteLang][
                        siteLang === "ar" ? "dayoff" : "new"
                      ].toLowerCase(),
                      " ",
                      translation[siteLang][
                        siteLang === "ar" ? "new" : "dayoff"
                      ].toLowerCase(),
                    ]}
              </h1>
            </div>
          }
          secondSection={
            <Button
              label={translation[siteLang].save}
              buttonType="submit"
              size="lg"
              styleType="primary"
              disabled={
                isFetching || updatingModule.submissionState.isSubmitting
              }
              isLoading={updatingModule.submissionState.isSubmitting}
            />
          }
        />
        <SectionsWrapper isSeparated={true} fixedWidth={true}>
          {isFetching ? (
            <div style={{ width: "fit-content", margin: "100px auto 0" }}>
              <LoadingSpinner type="circle" />
            </div>
          ) : (
            <div className="section_wrapper_row">
              <div style={{ width: "60%" }}>
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].basic_info}</h3>
                  </div>
                  <div className="box_content">
                    <div className="box_row">
                      <div>
                        <InputField
                          label={translation[siteLang].reason}
                          type="text"
                          props={register(
                            "reason",
                            validateInputField(siteLang, true)
                          )}
                          error={formState.errors?.reason?.message}
                        />
                      </div>
                      <div>
                        <InputSelect
                          label={translation[siteLang].status}
                          options={[
                            {
                              options: [
                                {
                                  label: translation[siteLang].pending,
                                  value: "pending",
                                },
                                {
                                  label: translation[siteLang].accepted,
                                  value: "accepted",
                                },
                                {
                                  label: translation[siteLang].rejected,
                                  value: "rejected",
                                },
                              ],
                            },
                          ]}
                          props={{
                            ...register(
                              "status",
                              validateInputField(siteLang, true, true)
                            ),
                            value: watchedInputs.status,
                          }}
                          error={formState.errors?.status?.message}
                        />
                      </div>
                    </div>
                  </div>
                </BoxWrapper>
              </div>
              <div style={{ width: "40%" }}>
                <BoxWrapper hasBorder={true}>
                  <div className="box_content" style={{ padding: "2px" }}>
                    <Calendar
                      defaultValue={watchedInputs.day}
                      value={watchedInputs.day}
                      onChange={(date) => setValue("day", date)}
                      tileDisabled={({ date }) =>
                        (extraFetchedData?.day_offs || []).findIndex(
                          (el: any) =>
                            el.status === "accepted"
                              ? dateFormat(new Date(el.day), "yyyy-MM-dd") ===
                                dateFormat(date, "yyyy-MM-dd")
                              : false
                        ) !== -1 ||
                        (extraFetchedData?.data?.work_days || []).findIndex(
                          (el: any) =>
                            new Date(date).getDay() ===
                              work_days.findIndex(
                                (weekday) => weekday === el.day
                              ) && el.on === 0
                        ) !== -1
                      }
                    />
                  </div>
                </BoxWrapper>
              </div>
            </div>
          )}
        </SectionsWrapper>
      </UpdateModulePage>
    </MainContent>
  );
};

export default UpdateDaysoff;
