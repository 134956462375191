import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { selectUser } from "../../store/selectors";
import ListCities from "./ListCities";
import ListRegions from "./ListRegions";
import UpdateCities from "./UpdateCities";
import UpdateRegions from "./UpdateRegions";

const Cities = () => {
  const {
    user: { permissions: userPermissions },
  } = useSelector(selectUser);

  return (
    <Routes>
      {(userPermissions?.regions?.create ||
        userPermissions?.regions?.view ||
        userPermissions?.regions?.update) && (
        <Route path="regions/:cityName/:cityID">
          {userPermissions?.regions?.update && (
            <Route
              path="edit/:regionID"
              element={<UpdateRegions type="edit" />}
            />
          )}
          {userPermissions?.regions?.create && (
            <Route path="new" element={<UpdateRegions type="create" />} />
          )}
          {userPermissions?.regions?.view && (
            <Route index element={<ListRegions />} />
          )}
        </Route>
      )}

      {userPermissions?.cities?.update && (
        <Route path="edit/:cityID" element={<UpdateCities type="edit" />} />
      )}
      {userPermissions?.cities?.create && (
        <Route path="new" element={<UpdateCities type="create" />} />
      )}
      {userPermissions?.cities?.view && (
        <Route index element={<ListCities />} />
      )}
    </Routes>
  );
};

export default Cities;
