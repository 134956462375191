import Dropdown from "..";
import { DropdownCalendarType } from "../utils";
import Calendar from "../../Calendar";

const DropdownCalendar = ({
  button,
  openDirection,
  widgetStyle,
  noAutoCollapse = true,
  ...rest
}: DropdownCalendarType) => {
  return (
    <Dropdown
      button={button}
      isFitContent={true}
      noAutoCollapse={noAutoCollapse}
      openDirection={openDirection}
      widgetStyle={widgetStyle}
    >
      <Calendar {...rest} />
    </Dropdown>
  );
};

export default DropdownCalendar;
