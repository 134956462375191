import { Route, Routes } from "react-router-dom";
import UpdateStocks from "./UpdateStocks";
import ListStocks from "./ListStocks";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/selectors";

const Stocks = () => {
  const {
    user: { permissions: userPermissions },
  } = useSelector(selectUser);

  return (
    <Routes>
      {userPermissions?.stocks?.update && (
        <Route path="edit/:stockID" element={<UpdateStocks type="edit" />} />
      )}
      {userPermissions?.stocks?.create && (
        <Route path="new" element={<UpdateStocks type="create" />} />
      )}
      {userPermissions?.stocks?.view && (
        <Route index element={<ListStocks />} />
      )}
    </Routes>
  );
};

export default Stocks;
