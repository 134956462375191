import { Route, Routes } from "react-router-dom";
import UpdateCategories from "../Services/UpdateCategories";
import UpdateProducts from "./UpdateProducts";
import ListProducts from "./ListProducts";
import ListBrands from "./ListBrands";
import UpdateBrands from "./UpdateBrands";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/selectors";

const Products = () => {
  const {
    user: { permissions: userPermissions },
  } = useSelector(selectUser);

  return (
    <Routes>
      {(userPermissions?.brands?.create ||
        userPermissions?.brands?.view ||
        userPermissions?.brands?.update) && (
        <Route path="brands">
          {userPermissions?.brands?.update && (
            <Route
              path="edit/:brandID"
              element={<UpdateBrands type="edit" />}
            />
          )}
          {userPermissions?.brands?.create && (
            <Route path="new" element={<UpdateBrands type="create" />} />
          )}
          {userPermissions?.brands?.view && (
            <Route index element={<ListBrands />} />
          )}
        </Route>
      )}

      {(userPermissions?.categories?.create ||
        userPermissions?.categories?.update) && (
        <Route path="categories">
          {userPermissions?.categories?.update && (
            <Route
              path="edit/:categoryID"
              element={<UpdateCategories type="edit" topBaseName="/products" />}
            />
          )}
          {userPermissions?.categories?.create && (
            <Route
              path="new"
              element={
                <UpdateCategories type="create" topBaseName="/products" />
              }
            />
          )}
        </Route>
      )}

      {userPermissions?.product?.update && (
        <Route
          path="edit/:productID"
          element={<UpdateProducts type="edit" />}
        />
      )}
      {userPermissions?.product?.create && (
        <Route
          path="new/:categoryID"
          element={<UpdateProducts type="create" />}
        />
      )}
      {userPermissions?.product?.view && (
        <Route index element={<ListProducts />} />
      )}
    </Routes>
  );
};

export default Products;
