import { ReactNode } from "react";
import { Wrapper } from "./styles";
import Logo from "../../../utils/logo.png";

const LoginBox = ({
  children,
  error,
}: {
  children: ReactNode;
  error?: string;
}) => {
  return (
    <Wrapper>
      <div>
        <img className="login_box_logo" src={Logo} alt="" />
        {error && <p className="login_box_error">{error}</p>}
        <div className="login_box">{children}</div>
      </div>
    </Wrapper>
  );
};

export default LoginBox;
