import styled from "styled-components";

const Wrapper = styled.div`
  .sidebar_widget {
    position: fixed;
    z-index: 999;
    background-color: var(--white);
    box-shadow: 0 10px 20px 10px rgb(16 25 40 / 25%);
    width: fit-content;
    width: 350px;
    ${(props) => (props.theme.lang === "ar" ? "left" : "right")}: 0;
    transform: translateX(${(props) =>
      props.theme.lang === "ar" ? "-100%" : "100%"});
    top: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .sidebar_widget_header {
    border-bottom: 1px solid var(--lightGrey3);
    padding: 15px 20px;
    display: flex;
    align-items: flex-start;
    height: fit-content;
    p {
      margin: 0;
      font-size: 20px;
      font-weight: 700;
      color: var(--darkBlue);
      padding-${(props) =>
        props.theme.lang === "ar" ? "left" : "right"}: 10px;
      flex: 1;
    }
  }
  .sidebar_widget_footer {
    padding: 15px 20px 30px;
    border-top: 1px solid var(--lightGrey3);
  }
  .sidebar_widget_footer__buttons {
    margin: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      padding: 5px;
      width: 50%;
    }
    button {
      width: 100%;
    }
  }
  .sidebar_widget_body {
    flex: 1;
    overflow-y: auto;
    padding: 15px 20px;
  }
  .overlay {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

export default Wrapper;
