import { ReactNode } from "react";
import { FormState, UseFormHandleSubmit } from "react-hook-form";
import translation from "../../utils/translation";

export type SubmissionStateType = {
  isSubmitting: boolean;
  submitSuccessful: boolean;
  submitError: string;
};

export type UpdatedDataToSubmitType = (submittedData: any) => any;

export type UpdateModulePageType = {
  children: ReactNode;
  moduleBaseName: string;
  formState: FormState<any>;
  navigationWarning: boolean;
  onNavigateBack: (path: string, discard?: boolean) => void;
  onCloseNavigationWarningHandler: () => void;
  handleSubmit: UseFormHandleSubmit<any>;
  onSubmitModuleHandler: (
    submittedData: any,
    callback: UpdatedDataToSubmitType | undefined,
    urlParamToReplace: string | undefined
  ) => void;
  submissionState: SubmissionStateType;
  onResetSubmissionStateHandler: (loading?: boolean) => void;
  updatedDataToSubmit?: UpdatedDataToSubmitType;
  urlParamToReplace?: string;
};

export const validateInputField = (
  siteLang: "ar" | "en",
  required: boolean = false,
  isSelect: boolean = false,
  extraValidation: any = {}
) => {
  return {
    validate: {
      ...(required
        ? {
            required: (val: any) =>
              (isSelect ? val !== "no_option" : val?.trim?.() !== "") ||
              translation[siteLang].required_field,
          }
        : {}),
      ...extraValidation,
    },
  };
};
