import {
  InputCheckboxType,
  InputGeneralType,
  onChangeInputHandler,
} from "../utils";
import InputContainer from "../InputContainer";
import Wrapper from "./styles";

const InputCheckbox = ({
  label,
  props,
  topLabel,
  dependantConditions,
  setDependantValues,
  checkboxStyle,
}: InputGeneralType & InputCheckboxType) => {
  return (
    <InputContainer label={topLabel || ""}>
      <Wrapper
        className="checkbox_option"
        isSwitch={checkboxStyle === "switcher"}
      >
        <label style={props?.disabled ? { cursor: "not-allowed" } : {}}>
          <div
            {...(checkboxStyle === "switcher"
              ? { className: "checkbox_switcher" }
              : { style: { height: "22px" } })}
          >
            <input
              type="checkbox"
              {...props}
              onChange={(e) =>
                onChangeInputHandler(
                  e,
                  props?.onChange,
                  dependantConditions,
                  setDependantValues
                )
              }
            />
            <span></span>
          </div>
          <span>{label}</span>
        </label>
      </Wrapper>
    </InputContainer>
  );
};

export default InputCheckbox;
