import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import InputCheckbox from "../../components/ui/Inputs/InputCheckbox";
import Table from "../../components/ui/Table";
import MainContent from "../../containers/MainContent";
import ViewListingPage from "../../containers/ViewListingPage";
import useListingData from "../../hooks/useListingData";
import { selectSettings, selectUser } from "../../store/selectors";
import { Delete } from "../../utils/icons";
import translation from "../../utils/translation";
import MainHeader from "../../containers/MainHeader";
import MainSidebar from "../../containers/MainSidebar";
import { useParams } from "react-router-dom";

const ListRegions = () => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { permissions: userPermissions },
  } = useSelector(selectUser);

  const { cityName, cityID } = useParams();

  const {
    data,
    extraFetchedData,
    onRequestDeleteHandler,
    onSelectTableRowHandler,
    isAllRowsSelected,
    showMultipleDelete,
    ...rest
  } = useListingData({
    moduleName: "regions",
    moduleType: "admin",
    queries: `where=city_id=${cityID}`,
  });

  return (
    <>
      <MainHeader />
      <MainSidebar />
      <MainContent>
        <PageTitle
          firstSection={
            <>
              <span style={{ color: "#878c93", fontWeight: "500" }}>
                {cityName}
              </span>
              <h1>
                {[
                  translation[siteLang][siteLang === "ar" ? "list" : "regions"],
                  " ",
                  translation[siteLang][
                    siteLang === "ar" ? "regions" : "list"
                  ].toLowerCase(),
                ]}
              </h1>
            </>
          }
          secondSection={
            <>
              <Button
                styleType="secondary"
                size="lg"
                label={[
                  translation[siteLang].back_to,
                  translation[siteLang].cities.toLowerCase(),
                ].join(" ")}
                buttonType="link"
                url={`/cities`}
              />
              {userPermissions?.regions?.create && (
                <Button
                  styleType="primary"
                  size="lg"
                  label={[
                    translation[siteLang][siteLang === "ar" ? "region" : "new"],
                    translation[siteLang][
                      siteLang === "ar" ? "new" : "region"
                    ].toLowerCase(),
                  ].join(" ")}
                  buttonType="link"
                  url={`/cities/regions/${cityName}/${cityID}/new`}
                />
              )}
            </>
          }
        />
        <ViewListingPage
          {...rest}
          hasNoResults={data.length === 0}
          tableFilter={{
            showMultipleDelete,
            onRequestDeleteHandler,
            hasSearch: true,
            search: {
              ...rest.search,
              placeholder:
                translation[siteLang].search_all +
                " " +
                translation[siteLang].regions.toLowerCase(),
            },
          }}
        >
          <Table
            isHeaderFixed={true}
            headings={[
              ...(data.length > 0 && userPermissions?.regions?.delete
                ? [
                    {
                      label: (
                        <InputCheckbox
                          checkboxStyle="checkbox"
                          props={{
                            onChange: () => onSelectTableRowHandler(0, true),
                            checked: isAllRowsSelected,
                            style: {
                              marginTop: "7px",
                              width: "15px",
                              height: "15px",
                            },
                          }}
                        />
                      ),
                      style: {
                        width: "50px",
                      },
                    },
                  ]
                : []),
              {
                label: translation[siteLang].region,
              },
              ...(userPermissions?.regions?.delete
                ? [
                    {
                      label: translation[siteLang].actions,
                      style: {
                        width: "85px",
                      },
                    },
                  ]
                : []),
            ]}
            rows={data.map((el) => ({
              link: userPermissions?.regions?.update
                ? `/cities/regions/${cityName}/${cityID}/edit/${el.id}`
                : "",
              columns: [
                ...(userPermissions?.regions?.delete
                  ? [
                      {
                        link_not_applied: true,
                        value: (
                          <InputCheckbox
                            checkboxStyle="checkbox"
                            props={{
                              onChange: () => onSelectTableRowHandler(el.id),
                              checked: el.table_selected,
                              style: {
                                marginTop: "7px",
                                width: "15px",
                                height: "15px",
                              },
                            }}
                          />
                        ),
                      },
                    ]
                  : []),
                {
                  value: el.name,
                },
                ...(userPermissions?.regions?.delete
                  ? [
                      {
                        link_not_applied: true,
                        value: (
                          <div className="page_listing_table_actions">
                            <Button
                              icon={
                                <Delete
                                  width="15"
                                  height="15"
                                  color="#101928"
                                />
                              }
                              buttonType="button"
                              styleType="secondary"
                              onClick={() => onRequestDeleteHandler([el.id])}
                              size="md"
                              className="delete"
                            />
                          </div>
                        ),
                      },
                    ]
                  : []),
              ],
            }))}
          />
        </ViewListingPage>
      </MainContent>
    </>
  );
};

export default ListRegions;
