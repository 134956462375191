import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectSettings, selectUser } from "../../../store/selectors";
import {
  createMilestone,
  deleteMilestone,
  getSingleModuleData,
} from "../../../utils/fetchers";
import { formatDate } from "../../../utils/formatter";
import { Delete, Page, Plus } from "../../../utils/icons";
import translation from "../../../utils/translation";
import { RefundStateType } from "../../orders/RefundOrderModal";
import Button from "../../ui/Button";
import LoadingSpinner from "../../ui/LoadingSpinner";
import Table from "../../ui/Table";
import { BoxWrapper } from "../../updatingPages/components";
import CreateMilestoneModal, {
  CreateMilestoneStatusType,
} from "./CreateMilestoneModal";
import DeleteMilestoneModal from "./DeleteMilestoneModal";

const ClientMilestones = () => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { branch_id: userBranchID, permissions: userPermissions },
  } = useSelector(selectUser);

  const { clientID } = useParams();

  const [clientDetails, setClientDetails] = useState<{
    isFetching: boolean;
    data: any;
  }>({ isFetching: true, data: {} });

  const [deleteState, setDeleteState] = useState<RefundStateType>({
    isSetToSubmit: -1,
    isSubmitting: false,
    success: false,
    error: "",
  });

  const [createState, setCreateState] = useState<CreateMilestoneStatusType>({
    isOpen: false,
    isSubmitting: false,
    success: false,
    error: "",
  });

  useEffect(() => {
    const fetchController = new AbortController();
    const fetchControllerSignal = fetchController.signal;
    getSingleModuleData(
      "admin",
      "users",
      +(clientID as string),
      "with=milestones",
      fetchControllerSignal
    ).then((res) => {
      setClientDetails({ isFetching: false, data: res.data.data });
    });
  }, [clientID, userBranchID]);

  const onSetDeleteToSubmit = useCallback((id: number) => {
    setDeleteState({
      isSetToSubmit: id,
      isSubmitting: false,
      success: false,
      error: "",
    });
  }, []);

  const onResetDeleteToSubmit = useCallback(() => {
    setDeleteState({
      isSetToSubmit: -1,
      isSubmitting: false,
      success: false,
      error: "",
    });
  }, []);

  const onDeleteMilestone = useCallback(async () => {
    setDeleteState((current) => ({
      ...current,
      isSubmitting: true,
      success: false,
      error: "",
    }));
    let success = false,
      error = "";
    await deleteMilestone(deleteState.isSetToSubmit)
      .then(() => {
        success = true;
      })
      .catch(() => {
        error = translation[siteLang].submission_error_msg;
      });
    if (success)
      setClientDetails((current) => ({
        ...current,
        data: {
          ...current.data,
          milestones: current.data.milestones.filter(
            (el: any) => el.id !== deleteState.isSetToSubmit
          ),
        },
      }));
    setDeleteState((current) => ({
      isSetToSubmit: success ? -1 : current.isSetToSubmit,
      isSubmitting: false,
      success,
      error,
    }));
  }, [deleteState.isSetToSubmit, siteLang, setClientDetails]);

  const onSetCreateState = useCallback((type: "open" | "reset") => {
    setCreateState({
      isOpen: type === "open" ? true : false,
      isSubmitting: false,
      success: false,
      error: "",
    });
  }, []);

  const onCreateMilestone = useCallback(
    async (data: {
      reason_en: string;
      reason_ar: string;
      points: string | number;
    }) => {
      setCreateState((current) => ({
        ...current,
        isSubmitting: true,
        success: false,
        error: "",
      }));
      let success = false,
        error = "";
      await createMilestone({ ...data, user_id: clientDetails.data.id })
        .then(() => {
          success = true;
        })
        .catch((err: any) => {
          error =
            err?.response?.data?.message ||
            translation[siteLang].submission_error_msg;
        });
      if (success)
        setClientDetails((current) => ({
          ...current,
          data: {
            ...current.data,
            milestones: [
              {
                reason: siteLang === "ar" ? data.reason_ar : data.reason_en,
                created_at: new Date().toISOString(),
                points: +data.points,
              },
              ...current.data.milestones,
            ],
          },
        }));
      setCreateState((current) => ({
        isOpen: !success,
        isSubmitting: false,
        success,
        error,
      }));
    },
    [siteLang, setClientDetails, clientDetails.data]
  );

  return clientDetails.isFetching ? (
    <div style={{ width: "fit-content", margin: "100px auto 0" }}>
      <LoadingSpinner type="circle" />
    </div>
  ) : (
    <>
      {deleteState.isSetToSubmit !== -1 && (
        <DeleteMilestoneModal
          onDeleteMilestone={onDeleteMilestone}
          onResetDeleteToSubmit={onResetDeleteToSubmit}
          deleteState={deleteState}
        />
      )}
      {createState.isOpen && (
        <CreateMilestoneModal
          onCreateMilestone={onCreateMilestone}
          onResetCreate={() => onSetCreateState("reset")}
          createState={createState}
        />
      )}
      <BoxWrapper hasBorder={true} style={{ backgroundColor: "#fff" }}>
        <div
          className="box_header"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h3>{translation[siteLang].milestones}</h3>
          {userPermissions?.milestones?.update && (
            <Button
              buttonType="button"
              onClick={() => onSetCreateState("open")}
              size="md"
              styleType="secondary"
              icon={<Plus width="20px" height="20px" color="#101928" />}
            />
          )}
        </div>
        <div
          className="box_content"
          style={clientDetails.data.milestones.length > 0 ? { padding: 0 } : {}}
        >
          {clientDetails.data.milestones.length === 0 ? (
            <div className="page_listing_no_results" style={{ marginTop: 0 }}>
              <Page width="70" height="70" color="#878c93" />
              <p>{translation[siteLang].no_milestones}</p>
            </div>
          ) : (
            <Table
              isHeaderFixed={true}
              headings={[
                {
                  label: translation[siteLang].reason,
                  style: {
                    width: "40%",
                  },
                },
                {
                  label: translation[siteLang].time,
                },
                {
                  label: translation[siteLang].points,
                  style: {
                    width: "15%",
                  },
                },
                ...(userPermissions?.milestones?.delete
                  ? [
                      {
                        label: translation[siteLang].actions,
                        style: {
                          width: "105px",
                        },
                      },
                    ]
                  : []),
              ]}
              rows={clientDetails.data.milestones.map((el: any) => ({
                columns: [
                  {
                    value: el.reason,
                  },
                  {
                    value: formatDate(el.created_at, siteLang, true),
                  },
                  {
                    value: el.points,
                  },
                  ...(userPermissions?.milestones?.delete
                    ? [
                        {
                          value: (
                            <div className="page_listing_table_actions">
                              <Button
                                icon={
                                  <Delete
                                    width="20"
                                    height="20"
                                    color="#101928"
                                  />
                                }
                                buttonType="button"
                                styleType="secondary"
                                onClick={() => onSetDeleteToSubmit(el.id)}
                                size="md"
                                className="delete"
                              />
                            </div>
                          ),
                        },
                      ]
                    : []),
                ],
              }))}
            />
          )}
        </div>
      </BoxWrapper>
    </>
  );
};

export default ClientMilestones;
