import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import Table from "../../components/ui/Table";
import MainContent from "../../containers/MainContent";
import ViewListingPage from "../../containers/ViewListingPage";
import useListingData from "../../hooks/useListingData";
import { selectSettings, selectUser } from "../../store/selectors";
import translation from "../../utils/translation";
import MainHeader from "../../containers/MainHeader";
import MainSidebar from "../../containers/MainSidebar";
import { useParams } from "react-router-dom";
import { formatDate } from "../../utils/formatter";

const ListWorkRates = () => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { branch_id: userBranchID },
  } = useSelector(selectUser);
  const { workerName, workerID } = useParams();

  const { data, extraFetchedData, isAllRowsSelected, ...rest } = useListingData(
    {
      moduleName: "workers",
      moduleType: userBranchID ? "branch" : "admin",
      queries: `with=worker_rates&where=id=${workerID}`,
    }
  );

  return (
    <>
      <MainHeader />
      <MainSidebar />
      <MainContent>
        <PageTitle
          firstSection={
            <>
              <span style={{ color: "#878c93", fontWeight: "500" }}>
                {workerName}
              </span>
              <h1>
                {[
                  translation[siteLang][
                    siteLang === "ar" ? "list" : "work_rates"
                  ],
                  " ",
                  translation[siteLang][
                    siteLang === "ar" ? "work_rates" : "list"
                  ].toLowerCase(),
                ]}
              </h1>
            </>
          }
          secondSection={
            <>
              <Button
                styleType="secondary"
                size="lg"
                label={[
                  translation[siteLang].back_to,
                  translation[siteLang].workers.toLowerCase(),
                ].join(" ")}
                buttonType="link"
                url={`/workers`}
              />
            </>
          }
        />
        <ViewListingPage
          {...rest}
          hasNoResults={(data?.[0]?.worker_rates || []).length === 0}
        >
          <Table
            isHeaderFixed={true}
            headings={[
              {
                label: translation[siteLang].rate,
              },
              {
                label: translation[siteLang].order_id,
              },
              {
                label: translation[siteLang].date,
              },
            ]}
            rows={(data?.[0]?.worker_rates || [])?.map((el: any) => ({
              columns: [
                {
                  value: el.rate,
                },
                {
                  value: el.order_id,
                },
                {
                  value: formatDate(el.created_at, siteLang, true),
                },
              ],
            }))}
          />
        </ViewListingPage>
      </MainContent>
    </>
  );
};

export default ListWorkRates;
