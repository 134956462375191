import { useMemo } from "react";
import { useSelector } from "react-redux";
import { TablePaginationType } from "../../containers/ViewListingPage/utils";
import { selectSettings } from "../../store/selectors";
import translation from "../../utils/translation";

const TablePagination = ({
  currentPage,
  lastPage,
  onChangePageHandler,
}: TablePaginationType) => {
  const { siteLang } = useSelector(selectSettings);

  const totalPages = useMemo(() => {
    return new Array(lastPage).fill("1").map((_, index) => index + 1);
  }, [lastPage]);

  return (
    <div className="page_listing_pagination">
      {totalPages.length === 1 ? (
        <p>
          {translation[siteLang].page} 1{" "}
          {translation[siteLang].of.toLowerCase()} 1
        </p>
      ) : (
        <ul>
          {totalPages.length > 5 && currentPage - 3 > 1 && (
            <>
              <li onClick={() => onChangePageHandler(1)}>1</li>
              {currentPage - 4 > 1 && (
                <span style={{ margin: "-7px 5px 0" }}>...</span>
              )}
            </>
          )}
          {totalPages.map(
            (page, index) =>
              (totalPages.length > 5
                ? index > currentPage - 5 && index < currentPage + 3
                : true) && (
                <li
                  key={page}
                  {...(page === currentPage ? { className: "active" } : {})}
                  {...(page !== currentPage
                    ? { onClick: () => onChangePageHandler(page) }
                    : {})}
                >
                  {page}
                </li>
              )
          )}
          {totalPages.length > 5 && currentPage + 3 < totalPages.length && (
            <>
              {currentPage + 4 < totalPages.length && (
                <span style={{ margin: "-7px 5px 0" }}>...</span>
              )}
              <li onClick={() => onChangePageHandler(totalPages.length)}>
                {totalPages.length}
              </li>
            </>
          )}
        </ul>
      )}
    </div>
  );
};

export default TablePagination;
