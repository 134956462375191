import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectSettings, selectUser } from "../../../store/selectors";
import { getSingleModuleData } from "../../../utils/fetchers";
import { formatDate, formatMoney } from "../../../utils/formatter";
import translation from "../../../utils/translation";
import LoadingSpinner from "../../ui/LoadingSpinner";
import { BoxWrapper } from "../../updatingPages/components";
import { ClientOrder, ClientOrderDate } from "./styles";

const ClientBookings = () => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { branch_id: userBranchID },
  } = useSelector(selectUser);
  const { clientID } = useParams();

  const [clientDetails, setClientDetails] = useState<{
    isFetching: boolean;
    data: any;
  }>({ isFetching: true, data: {} });

  useEffect(() => {
    const fetchController = new AbortController();
    const fetchControllerSignal = fetchController.signal;
    getSingleModuleData(
      "admin",
      "users",
      +(clientID as string),
      "with=orders,orders.products,orders.services,orders.order_workers.service,orders.order_workers.worker,orders.order_products",
      fetchControllerSignal
    ).then((res) => {
      setClientDetails({ isFetching: false, data: res.data.data });
    });
  }, [clientID, userBranchID]);

  return clientDetails.isFetching ? (
    <div style={{ width: "fit-content", margin: "100px auto 0" }}>
      <LoadingSpinner type="circle" />
    </div>
  ) : (
    <>
      <BoxWrapper hasBorder={true} style={{ backgroundColor: "#fff" }}>
        <div className="box_header">
          <h3>{translation[siteLang].future}</h3>
        </div>
        <div className="box_content">
          {clientDetails.data.orders
            .filter(
              (order: any) => order.reservation_time > new Date().toISOString()
            )
            .map((order: any) => (
              <div key={order.id} className="box_section">
                <ClientOrder
                  to={
                    order.status === "canceled"
                      ? `/orders/edit/${order.id}`
                      : `/orders/view/${order.id}`
                  }
                >
                  <ClientOrderDate>
                    <span>
                      {formatDate(
                        order.reservation_time,
                        siteLang,
                        false,
                        { day: "numeric" },
                        false
                      )}
                    </span>
                    <span>
                      {formatDate(
                        order.reservation_time,
                        siteLang,
                        false,
                        { month: "short" },
                        false
                      )}
                    </span>
                    <span>
                      {formatDate(
                        order.reservation_time,
                        siteLang,
                        false,
                        { year: "numeric" },
                        false
                      )}
                    </span>
                  </ClientOrderDate>
                  <div className="order_body">
                    <p className="title">
                      {order.order_workers?.[0]?.service?.name}
                    </p>
                    {order.order_workers?.[0]?.worker?.name && (
                      <p className="time">
                        {translation[siteLang].with}{" "}
                        {order.order_workers?.[0]?.worker?.name}
                      </p>
                    )}
                    <p className="price">
                      {formatMoney(order.total_amount, siteLang)}
                    </p>
                    <p className="status">
                      {translation[siteLang][order.status]}
                    </p>
                  </div>
                </ClientOrder>
              </div>
            ))}
        </div>
      </BoxWrapper>
      <BoxWrapper hasBorder={true} style={{ backgroundColor: "#fff" }}>
        <div className="box_header">
          <h3>{translation[siteLang].past}</h3>
        </div>
        <div className="box_content">
          {clientDetails.data.orders
            .filter(
              (order: any) => order.reservation_time < new Date().toISOString()
            )
            .map((order: any) => (
              <div key={order.id} className="box_section">
                <ClientOrder
                  to={
                    order.status === "canceled"
                      ? `/orders/edit/${order.id}`
                      : `/orders/view/${order.id}`
                  }
                >
                  <ClientOrderDate>
                    <span>
                      {formatDate(
                        order.reservation_time,
                        siteLang,
                        false,
                        { day: "numeric" },
                        false
                      )}
                    </span>
                    <span>
                      {formatDate(
                        order.reservation_time,
                        siteLang,
                        false,
                        { month: "short" },
                        false
                      )}
                    </span>
                    <span>
                      {formatDate(
                        order.reservation_time,
                        siteLang,
                        false,
                        { year: "numeric" },
                        false
                      )}
                    </span>
                  </ClientOrderDate>
                  <div className="order_body">
                    <p className="title">
                      {order.order_workers?.[0]?.service?.name}
                    </p>
                    {order.order_workers?.[0]?.worker?.name && (
                      <p className="time">
                        {translation[siteLang].with}{" "}
                        {order.order_workers?.[0]?.worker?.name}
                      </p>
                    )}
                    <p className="price">
                      {formatMoney(order.total_amount, siteLang)}
                    </p>
                    <p className="status">
                      {translation[siteLang][order.status]}
                    </p>
                  </div>
                </ClientOrder>
              </div>
            ))}
        </div>
      </BoxWrapper>
    </>
  );
};

export default ClientBookings;
