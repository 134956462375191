import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectSettings, selectUser } from "../../../store/selectors";
import { getSingleModuleData } from "../../../utils/fetchers";
import {
  formatDate,
  formatMoney,
  formatNumber,
} from "../../../utils/formatter";
import { Page } from "../../../utils/icons";
import translation from "../../../utils/translation";
import LoadingSpinner from "../../ui/LoadingSpinner";
import { BoxWrapper } from "../../updatingPages/components";
import { ClientDetailsRow } from "./styles";

const ClientDetails = () => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { branch_id: userBranchID },
  } = useSelector(selectUser);
  const { clientID } = useParams();

  const [clientDetails, setClientDetails] = useState<{
    isFetching: boolean;
    data: any;
  }>({ isFetching: true, data: {} });

  useEffect(() => {
    const fetchController = new AbortController();
    const fetchControllerSignal = fetchController.signal;
    getSingleModuleData(
      "admin",
      "users",
      +(clientID as string),
      "with=addresses",
      fetchControllerSignal
    ).then((res) => {
      setClientDetails({ isFetching: false, data: res.data.data });
    });
  }, [clientID, userBranchID]);

  return clientDetails.isFetching ? (
    <div style={{ width: "fit-content", margin: "100px auto 0" }}>
      <LoadingSpinner type="circle" />
    </div>
  ) : (
    <>
      <BoxWrapper hasBorder={true} style={{ backgroundColor: "#fff" }}>
        <div className="box_header">
          <h3>{translation[siteLang].basic_info}</h3>
        </div>
        <div className="box_content">
          <ClientDetailsRow>
            <span>{translation[siteLang].phone}</span>
            <span>{clientDetails.data.phone}</span>
          </ClientDetailsRow>
          <ClientDetailsRow>
            <span>{translation[siteLang].birth_date}</span>
            <span>
              {formatDate(
                clientDetails.data.birth_date,
                siteLang,
                false,
                { year: "numeric", month: "long", day: "numeric" },
                false
              )}
            </span>
          </ClientDetailsRow>
          <ClientDetailsRow>
            <span>{translation[siteLang].points}</span>
            <span>{formatNumber(clientDetails.data.points, siteLang)}</span>
          </ClientDetailsRow>
          <ClientDetailsRow>
            <span>{translation[siteLang].wallet}</span>
            <span>{formatMoney(clientDetails.data.wallet || 0, siteLang)}</span>
          </ClientDetailsRow>
          <ClientDetailsRow>
            <span>{translation[siteLang].source}</span>
            <span>{clientDetails.data.source}</span>
          </ClientDetailsRow>
          <ClientDetailsRow>
            <span>{translation[siteLang].status}</span>
            <span>{translation[siteLang][clientDetails.data.status]}</span>
          </ClientDetailsRow>
          <ClientDetailsRow>
            <span>{translation[siteLang].registration_date}</span>
            <span>{formatDate(clientDetails.data.created_at, siteLang)}</span>
          </ClientDetailsRow>
          <ClientDetailsRow>
            <span>{translation[siteLang].membership}</span>
            <span>
              {clientDetails.data.membership &&
                translation[siteLang][
                  clientDetails.data.membership.toLowerCase()
                ]}
            </span>
          </ClientDetailsRow>
        </div>
      </BoxWrapper>
      <BoxWrapper hasBorder={true} style={{ backgroundColor: "#fff" }}>
        <div className="box_header">
          <h3>{translation[siteLang].addresses}</h3>
        </div>
        <div className="box_content">
          {clientDetails.data.addresses.length === 0 && (
            <div className="page_listing_no_results" style={{ marginTop: 0 }}>
              <Page width="70" height="70" color="#878c93" />
              <p>{translation[siteLang].no_addresses}</p>
            </div>
          )}
          {clientDetails.data.addresses.map((el: any) => (
            <ClientDetailsRow key={el.id}>
              <span>{el.name}</span>
            </ClientDetailsRow>
          ))}
        </div>
      </BoxWrapper>
    </>
  );
};

export default ClientDetails;
