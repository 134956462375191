import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import InputField from "../components/ui/Inputs/InputField";
import Modal from "../components/ui/Modal";
import { validateInputField } from "../containers/UpdateModulePage/utils";
import { selectSettings } from "../store/selectors";
import appAxios from "../utils/setupAxios";
import translation from "../utils/translation";

const defaultStatus = {
  setToSubmit: -1,
  currentQuantity: 0,
  isSubmitting: false,
  success: false,
  error: "",
  extraItemInfo: {},
};

const useRefillItem = ({
  type,
  branchID,
  setListingData,
  isMyCurrent = false,
}: {
  type: "stock" | "product";
  branchID: number | string;
  setListingData: (
    value: React.SetStateAction<{
      isFetching: boolean;
      data: any[];
      errorFetching: string;
      currentPage: number;
      lastPage: number;
      queryTotalResults: number;
      extraFetchedData: any;
    }>
  ) => void;
  isMyCurrent?: boolean;
}) => {
  const { siteLang } = useSelector(selectSettings);

  const [refillStatus, setRefillStatus] = useState<{
    setToSubmit: number;
    currentQuantity: number;
    isSubmitting: boolean;
    success: boolean;
    error: string;
    extraItemInfo: any;
  }>({ ...defaultStatus });

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: { quantity: "" } });

  const onSetRefillToSubmit = useCallback(
    (id: number, currentQuantity: number, extraItemInfo?: any) => {
      setRefillStatus({
        ...defaultStatus,
        setToSubmit: id,
        currentQuantity,
        extraItemInfo: extraItemInfo || {},
      });
    },
    []
  );

  const onCancelRefillFromSubmit = useCallback(() => {
    setRefillStatus({ ...defaultStatus });
    reset();
  }, [reset]);

  const onSubmitRefill = useCallback(
    async (data: any) => {
      setRefillStatus((current) => ({
        ...defaultStatus,
        isSubmitting: true,
        setToSubmit: current.setToSubmit,
        currentQuantity: current.currentQuantity,
        extraItemInfo: current.extraItemInfo,
      }));
      let error = "",
        success = false;
      let submitEndpoint = "";
      if (type === "stock") {
        submitEndpoint = `stock/update/${refillStatus.setToSubmit}`;
      } else if (type === "product") {
        submitEndpoint = "product/assign/products";
      }
      await appAxios
        .post(submitEndpoint, {
          ...(type === "product"
            ? {
                branch_id: branchID,
                products: [
                  {
                    id: refillStatus.setToSubmit,
                    quantity: +refillStatus.currentQuantity + +data.quantity,
                    ...refillStatus.extraItemInfo,
                  },
                ],
              }
            : {
                quantity: +refillStatus.currentQuantity + +data.quantity,
              }),
        })
        .then((res) => {
          success = true;
          if (type === "stock") {
            setListingData((current) => ({
              ...current,
              data: current.data.map((el: any) => ({
                ...el,
                quantity:
                  +el.id === +refillStatus.setToSubmit
                    ? +el.quantity + +data.quantity
                    : el.quantity,
                table_selected: false,
              })),
            }));
          } else {
            setListingData((current) => ({
              ...current,
              data: current.data.map((el) => ({
                ...el,
                ...(isMyCurrent
                  ? {
                      quantity:
                        +el.product.id === +refillStatus.setToSubmit
                          ? +el.quantity + +data.quantity
                          : el.quantity,
                      table_selected: false,
                    }
                  : {
                      branch_products: el?.branch_products?.map((el: any) => ({
                        ...el,
                        quantity:
                          +el.product.id === +refillStatus.setToSubmit
                            ? +el.quantity + +data.quantity
                            : el.quantity,
                        table_selected: false,
                      })),
                    }),
              })),
            }));
          }
          reset();
        })
        .catch(() => {
          error = translation[siteLang].submission_error_msg;
        });
      setRefillStatus((current) => ({
        isSubmitting: false,
        error,
        success,
        setToSubmit: error ? current.setToSubmit : defaultStatus.setToSubmit,
        currentQuantity: error
          ? current.currentQuantity
          : defaultStatus.currentQuantity,
        extraItemInfo: error ? { ...current.extraItemInfo } : {},
      }));
    },
    [siteLang, type, refillStatus, setListingData, reset, branchID, isMyCurrent]
  );

  return {
    isActiveToRefill: refillStatus.setToSubmit,
    isSubmittingRefill: refillStatus.isSubmitting,
    submittingRefillError: refillStatus.error,
    submittingRefillSuccess: refillStatus.success,
    onSetRefillToSubmit,
    onCancelRefillFromSubmit,
    onSubmitRefill: (data: any) => handleSubmit(onSubmitRefill)(data),
    register,
    errors,
  };
};

export default useRefillItem;

export const RefillModal = ({ refillStocks }: { refillStocks: any }) => {
  const { siteLang } = useSelector(selectSettings);

  return (
    <Modal
      maxWidth="400px"
      showFooter={true}
      showCloseIcon={true}
      showHeader={true}
      headerTitle={translation[siteLang].refill}
      onCloseHandler={() => refillStocks.onCancelRefillFromSubmit()}
      footerButtonsDirection="center"
      as="form"
      onSubmit={refillStocks.onSubmitRefill}
      footerButtons={[
        {
          label: translation[siteLang].cancel,
          styleType: "secondary",
          size: "lg",
          buttonType: "button",
          onClick: () => refillStocks.onCancelRefillFromSubmit(),
          disabled: refillStocks.isSubmittingRefill,
        },
        {
          label: translation[siteLang].refill,
          styleType: "primary",
          size: "lg",
          buttonType: "submit",
          disabled: refillStocks.isSubmittingRefill,
          isLoading: refillStocks.isSubmittingRefill,
        },
      ]}
    >
      <InputField
        label={translation[siteLang].amount}
        type="number"
        props={refillStocks.register(
          "quantity",
          validateInputField(siteLang, true)
        )}
        error={refillStocks.errors?.quantity?.message}
      />
    </Modal>
  );
};
