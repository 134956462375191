import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { selectUser } from "../../store/selectors";
import ListAdmins from "./ListAdmins";
import ListRoles from "./ListRoles";
import UpdateAdmins from "./UpdateAdmins";
import UpdateRoles from "./UpdateRoles";

const Admins = () => {
  const {
    user: { permissions: userPermissions },
  } = useSelector(selectUser);

  return (
    <Routes>
      {(userPermissions?.roles?.create ||
        userPermissions?.roles?.view ||
        userPermissions?.roles?.update) && (
        <Route path="roles">
          {userPermissions?.roles?.update && (
            <Route path="edit/:roleID" element={<UpdateRoles type="edit" />} />
          )}
          {userPermissions?.roles?.create && (
            <Route path="new" element={<UpdateRoles type="create" />} />
          )}
          {userPermissions?.roles?.view && (
            <Route index element={<ListRoles />} />
          )}
        </Route>
      )}

      {userPermissions?.admins?.update && (
        <Route path="edit/:adminID" element={<UpdateAdmins type="edit" />} />
      )}
      {userPermissions?.admins?.create && (
        <Route path="new" element={<UpdateAdmins type="create" />} />
      )}
      {userPermissions?.admins?.view && (
        <Route index element={<ListAdmins />} />
      )}
    </Routes>
  );
};

export default Admins;
