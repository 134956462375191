import styled from "styled-components";

export const Wrapper = styled.div`
  border-radius: 3px;
  background-color: var(--white);
  border: 1px solid var(--lightGrey3);
  padding: ${(props) =>
    props.theme.lang === "ar" ? "15px 50px 15px 15px" : "15px 15px 15px 50px"};
  position: relative;
  color: var(--darkBlue);
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  .widget_quantity {
    position: absolute;
    top: 15px;
    ${(props) => (props.theme.lang === "ar" ? "right" : "left")}: 15px;
    font-weight: 700;
    font-size: 17px;
  }
  .widget_price {
    position: absolute;
    top: 15px;
    ${(props) => (props.theme.lang === "ar" ? "left" : "right")}: 15px;
    display: flex;
    align-items: center;
    font-size: 15px;
    span {
      font-weight: 700;
      margin-${(props) => (props.theme.lang === "ar" ? "left" : "right")}: 10px;
    }
    button {
      padding: 0;
      border: none;
      outline: none;
      background: none;
      display: flex;
      align-items: center;
    }
  }
  .widget_body {
    .widget_title {
      margin: 0 0 15px;
      span {
        color: var(--lightGrey6);
        font-size: 15px;
        font-weight: 500;
      }
    }
    h3 {
      margin:0;
      font-size: 17px;
      padding-${(props) =>
        props.theme.lang === "ar" ? "left" : "right"}: 130px;
    }
    .widget_row {
      margin: -10px;
      display: flex;
      flex-wrap: wrap;
    }
    .widget_col {
      padding: 10px;
      width: 50%;
    }
  }
`;
