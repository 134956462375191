import styled from "styled-components";

const Wrapper = styled.div<{
  isHeaderFixed: boolean;
  stickyTopOffsetValue: string;
}>`
  position: relative;
  padding: 0;
  table {
    background: none;
    border-collapse: separate;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    color: var(--darkBlue);
    cursor: default;
    border: none;
    border-spacing: 0;
    td, th {
      padding-top: 15px;
      padding-bottom: 15px;
      &:first-child {
        padding-${(props) =>
          props.theme.lang === "ar" ? "right" : "left"}: 20px;
      }
      &:last-child {
        padding-${(props) =>
          props.theme.lang === "ar" ? "left" : "right"}: 20px;
      }
      &:not(:first-child):not(:last-child) {
        padding-right: 10px;
        padding-left: 10px;
      }
    }
  }
  .header_section {
    ${(props) =>
      props.isHeaderFixed
        ? `top: ${
            props.stickyTopOffsetValue ? props.stickyTopOffsetValue : "-40px"
          };
      position: sticky;`
        : ""}
    table {
      border-bottom: 1px solid var(--lightGrey3);
      ${(props) =>
        props.isHeaderFixed ? "background-color: var(--white);" : ""}
    }
    th {
      font-size: 12px;
      font-weight: 700;
    }
  }
  .main_table {
    font-size: 13px;
    font-weight: 500;
    tr {
      transition: background-color .1s ease-in-out;
      &:hover {
        background-color: var(--lightGrey);
      }
      td {
        border-bottom: 1px solid var(--lightGrey3);
        word-break: break-word;
      }
    }
    img {
      width: 86px;
      height: 86px;
      object-fit: contain;
      border: 1px solid var(--lightGrey3);
      border-radius: 3px;
      background-color: var(--white);
    }
  }
`;

export default Wrapper;
