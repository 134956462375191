import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  min-height: 80px;
  .page_listing_pagination {
    width: fit-content;
    margin: 30px auto 0;
    p {
      text-align: center;
      color: var(--lightGrey6);
    }
    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: -7px;
      font-weight: 600;
      font-size: 15px;
    }
    li {
      width: 35px;
      height: 35px;
      text-align: center;
      line-height: 35px;
      border-radius: 20px;
      transition: background-color .1s ease-in-out, color .1s ease-in-out;
      cursor: default;
      margin: 7px;
      &:not(.separator) {
        background-color: var(--lightGrey3);
        &:hover, &.active {
          color: var(--white);
          background-color: var(--darkGrey);
        }
        &:not(.active) {
          cursor: pointer;
        }
      }
    }
  }
  .loading_spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 2;
  }
  .page_listing_no_results {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    p {
      margin: 10px 0 0;
      font-weight: 600;
      color: var(--lightGrey6);
    }
  }
  .page_listing_table_actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    > *:not(:last-child) {
      margin-${(props) => (props.theme.lang === "ar" ? "left" : "right")}: 7px;
    }
    svg {
      transition: fill .1s ease-in-out;
      width: 18px;
      height: 18px;
    }
    .delete {
      &:hover, &:focus {
        border-color: var(--red);
        background-color: var(--red);
        svg {
          fill: var(--white);
        }
      }
    }
  }
`;

export default Wrapper;
