import styled from "styled-components";

const Wrapper = styled.section<{
  hasContentSidebar: boolean;
  expanded: boolean;
  onlyInPage: boolean;
  hasFixedWidthHeader: boolean;
}>`
  position: relative;
  padding: ${(props) => (props.onlyInPage ? "0 40px 80px" : "40px 40px 80px")};
  overflow-y: auto;
  height: 100%;
  ${(props) =>
    !props.onlyInPage
      ? `
    ${
      props.hasContentSidebar
        ? `
      ${props.theme.lang === "ar" ? "right" : "left"}: var(--${
            props.expanded
              ? "contentSidebarCollapsedWidth"
              : "contentSidebarWidth"
          });
      width: calc(
        100% -
          var(
            --${
              props.expanded
                ? "contentSidebarCollapsedWidth"
                : "contentSidebarWidth"
            }
          )
      );
      `
        : ""
    }

  `
      : ""}
  .page_header_content, .page_listing_filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .page_header_content {
    ${(props) =>
      props.hasFixedWidthHeader
        ? `
      max-width: 1100px;
      margin: auto;
    `
        : ""}
  }
  .page_header {
    &:not(.page_header--sticky) {
      margin-bottom: ${(props) => (props.onlyInPage ? "30px" : "20px")};
      ${(props) => (props.onlyInPage ? "padding-top: 20px;" : "")}
    }
  }
  .page_header--sticky {
    position: sticky;
    top: ${(props) => (props.onlyInPage ? "0" : "-40px")};
    padding: 10px 40px;
    margin: ${(props) =>
      props.onlyInPage ? "10px -40px 30px" : "0 -40px 20px"};
    transition: border-color .1s ease-in-out, box-shadow .1s ease-in-out;
    background-color: var(--white);
    border-bottom: 1px solid transparent;
    z-index: 990;
  }
  .page_header--sticky_applied {
    border-color: var(--lightGrey3);
    box-shadow: 0 5px 15px rgba(0,0,0,.08);
    margin-top: 0;
    h1 {
      font-size: 23px !important;
    }
  }
  .page_header_sec {
    h1 {
      color: var(--black);
      margin: 0;
      font-weight: 800;
      font-size: 28px;
      transition: font-size .2s ease-in-out;
    }
    p {
      margin: 0;
      font-weight: 600;
      font-size: 15px;
      color: var(--darkGrey);
    }
    &:page_header_sec:last-child:not(:last-child) {
      > a,
      p > a {
        color: var(--blue);
      }
    }
  }
  .page_listing_filter_sec, .page_header_sec:last-child:not(:first-child) {
    display: flex;
    align-items: center;
    > *:not(:last-child) {
      margin-${(props) => (props.theme.lang === "ar" ? "left" : "right")}: 15px;
    }
  }
  .page_header_title_update {
    display: flex;
    align-items: center;
    button, a {
      margin-${(props) => (props.theme.lang === "ar" ? "left" : "right")}: 10px;
    }
  }
  .page_listing_filter {
    margin-bottom: 20px;
  }
  .page_listing_filter {
    background-color: var(--lightGrey);
    border-radius: 5px;
    padding: 15px;
    border: 1px solid var(--lightGrey2)
  }
  .page_listing_filter_sec {
    p {
      margin-bottom: 0;
      color: var(--lightGrey6);
      font-size: 15px;
    }
  }
`;

export default Wrapper;
