import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import {
  BoxWrapper,
  SectionsWrapper,
} from "../../components/updatingPages/components";
import MainContent from "../../containers/MainContent";
import useUpdatingData from "../../hooks/useUpdatingData";
import { selectSettings, selectUser } from "../../store/selectors";
import { Arrow, CalendarIcon, Close, Plus } from "../../utils/icons";
import translation from "../../utils/translation";
import { useEffect, useMemo, useState } from "react";
import UpdateModulePage from "../../containers/UpdateModulePage";
import { validateInputField } from "../../containers/UpdateModulePage/utils";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { useParams } from "react-router-dom";
import InputSelect from "../../components/ui/Inputs/InputSelect";
import InputRadio from "../../components/ui/Inputs/InputRadio";
import DropdownCalendar from "../../components/ui/Dropdown/DropdownCalendar";
import { formatDate } from "../../utils/formatter";
import {
  branchesWorkersDependatConditions,
  orderServicesWithCategorized,
  timeWorkersDependatConditions,
  workersSelectOptions,
} from "./utils";
import { useFieldArray } from "react-hook-form";
import { getModuleData, getSingleModuleData } from "../../utils/fetchers";
import InputField from "../../components/ui/Inputs/InputField";
import { work_days } from "../Workers/utils";
import SelectClientModal from "./SelectClientModal";

const defaultServices = {
  service: "no_option",
  worker: "no_option",
};

const moduleBaseName = "/orders";

const currentDate = new Date();
const currentTime = () => {
  let time = new Date().toTimeString().split(" ")[0];
  time = time.substring(0, time.lastIndexOf(":"));
  return time;
};

const CreateOrders = () => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { branch_id: userBranchID },
  } = useSelector(selectUser);
  const { orderID } = useParams();

  const [orderAddresses, setOrderAddresses] = useState<any[]>([]);
  const [showSelectClient, setShowSelectClient] = useState(false);
  const [selectedClientName, setSelectedClientName] = useState("");

  const defaultValues = useMemo(
    () => ({
      branch_id: userBranchID ? userBranchID : "no_option",
      type: "indoor",
      address_id: "",
      date: currentDate,
      time: currentTime(),
      services: [defaultServices],
      user_id: "",
    }),
    [userBranchID]
  );

  const extraFetchers = useMemo(
    () => [
      ...(userBranchID
        ? []
        : [
            {
              function: (signal: AbortSignal) =>
                getModuleData("admin", "branches", "with=work_days", signal),
              returnName: "branches",
              isModule: true,
            },
          ]),
      {
        function: (signal: AbortSignal) =>
          getModuleData(
            userBranchID ? "branch" : "admin",
            "services",
            "sort=-sort_order&with=category,",
            signal
          ),
        returnName: "services",
        isModule: true,
      },
      {
        function: (signal: AbortSignal) =>
          getModuleData(
            userBranchID ? "branch" : "admin",
            "workers",
            "with=services",
            signal
          ),
        returnName: "workers",
        isModule: true,
      },
    ],
    [userBranchID]
  );

  const {
    isFetching,
    extraFetchedData,
    register,
    watchedInputs,
    setValue,
    moduleDefaultData,
    control,
    ...updatingModule
  } = useUpdatingData({
    moduleType: userBranchID ? "branch" : "admin",
    moduleState: "create",
    moduleID: +(orderID || 0),
    moduleName: "orders",
    modulePostEndpoint: "order/branch/%PARAM%/create",
    modulePostType: "formData",
    watchInputFields: true,
    defaultValues,
    navigateAfterSubmissionTo: moduleBaseName,
    extraFetchers,
    onSubmit: (data) => {
      if (!data.user_id) {
        throw new Error(translation[siteLang].select_client);
      }
    },
  });

  const formState = updatingModule.formState;

  const {
    fields: servicesFields,
    append: appendServiceField,
    remove: removeServiceField,
  } = useFieldArray({
    control,
    name: "services",
  });

  const moduleSelectOptions = useMemo(() => {
    const servicesCategories: any = orderServicesWithCategorized({
      fetchedServices: extraFetchedData?.services || [],
      siteLang,
      includePrice: true,
    });
    return {
      branches:
        extraFetchedData?.branches
          ?.filter(
            (el: any) =>
              el.work_days.findIndex(
                (day: any) =>
                  day.day === work_days[watchedInputs.date.getDay()] &&
                  day.on === 1 &&
                  watchedInputs.time >= day.from &&
                  (day.to !== "00:00:00" && day.to !== "00:00"
                    ? watchedInputs.time <= day.to
                    : true)
              ) !== -1
          )
          ?.map((el: any) => ({
            label: el.name,
            value: el.id.toString(),
          })) || [],
      services: servicesCategories || [],
      servicesDependantConditions: extraFetchedData?.services?.reduce(
        (acc: any, cur: any) =>
          Object.assign(acc, {
            [cur.id]: "no_option",
          }),
        {}
      ),
    };
  }, [extraFetchedData, siteLang, watchedInputs.date, watchedInputs.time]);

  const updatedDataToSubmit = useMemo(() => {
    return (data: any) => ({
      services: data.services.map((serv: any) => serv.service),
      workers: data.services.map((serv: any) => serv.worker),
      reservation_time: `${data.date.getFullYear()}-${
        (`${data.date.getMonth() + 1}`.length === 1 ? "0" : "") +
        (data.date.getMonth() + 1)
      }-${
        (`${data.date.getDate()}`.length === 1 ? "0" : "") + data.date.getDate()
      } ${data.time}:00`,
      date: "",
      time: "",
    });
  }, []);

  const branchIDParam = useMemo(() => {
    return watchedInputs.branch_id;
  }, [watchedInputs.branch_id]);

  useEffect(() => {
    if (
      watchedInputs.type !== "indoor" &&
      watchedInputs.user_id !== "no_option"
    ) {
      getSingleModuleData(
        "admin",
        "users",
        watchedInputs.user_id,
        `with=addresses`
      ).then((res) => {
        setOrderAddresses(res.data.data.addresses);
      });
    }
  }, [watchedInputs.type, watchedInputs.user_id, userBranchID]);

  return (
    <>
      {showSelectClient && (
        <SelectClientModal
          onCloseModal={() => setShowSelectClient(false)}
          onSelectClient={(id, name) => {
            setValue("user_id", id);
            setSelectedClientName(name);
          }}
        />
      )}
      <MainContent onlyInPage={true} hasFixedWidthHeader={true}>
        <UpdateModulePage
          moduleBaseName={moduleBaseName}
          updatedDataToSubmit={updatedDataToSubmit}
          urlParamToReplace={branchIDParam}
          {...updatingModule}
        >
          <PageTitle
            isSticky={true}
            firstSection={
              <div className="page_header_title_update">
                <Button
                  buttonType="button"
                  size="md"
                  styleType="icon"
                  onClick={() => updatingModule.onNavigateBack(moduleBaseName)}
                  disabled={
                    updatingModule.submissionState.isSubmitting || isFetching
                  }
                  icon={
                    <Arrow
                      height="27"
                      width="27"
                      color="#101928"
                      extraStyle={{
                        transform: `rotate(${
                          siteLang === "ar" ? "180" : "0"
                        }deg)`,
                      }}
                    />
                  }
                />
                <h1>
                  {[
                    translation[siteLang].add,
                    " ",
                    translation[siteLang][
                      siteLang === "ar" ? "order" : "new"
                    ].toLowerCase(),
                    " ",
                    translation[siteLang][
                      siteLang === "ar" ? "new" : "order"
                    ].toLowerCase(),
                  ]}
                </h1>
              </div>
            }
            secondSection={
              <Button
                label={translation[siteLang].save}
                buttonType="submit"
                size="lg"
                styleType="primary"
                disabled={
                  isFetching || updatingModule.submissionState.isSubmitting
                }
                isLoading={updatingModule.submissionState.isSubmitting}
              />
            }
          />
          <SectionsWrapper isSeparated={true} fixedWidth={true}>
            {isFetching ? (
              <div style={{ width: "fit-content", margin: "100px auto 0" }}>
                <LoadingSpinner type="circle" />
              </div>
            ) : (
              <>
                <BoxWrapper>
                  <div className="box_section">
                    <div className="box_row">
                      <div style={{ width: "auto" }}>
                        <DropdownCalendar
                          button={{
                            styleType: "secondary",
                            size: "lg",
                            icon: (
                              <CalendarIcon
                                width="15"
                                height="15"
                                color="#878c93"
                                extraStyle={{ width: "18px", height: "18px" }}
                              />
                            ),
                            iconPosition: "start",
                            label: formatDate(
                              watchedInputs.date.toISOString(),
                              siteLang,
                              false,
                              { weekday: "long" }
                            ),
                          }}
                          defaultValue={watchedInputs.date}
                          onChange={(date) => {
                            setValue("branch_id", "no_option");
                            watchedInputs.services.forEach(
                              (el: any, index: number) => {
                                setValue(
                                  `services.${index}.worker`,
                                  "no_option"
                                );
                              }
                            );
                            setValue("date", date);
                          }}
                        />
                      </div>
                      <div style={{ width: "200px" }}>
                        <InputField
                          type="time"
                          props={{
                            ...register(
                              "time",
                              validateInputField(siteLang, true)
                            ),
                          }}
                          dependantConditions={[
                            {
                              name: "branch_id",
                              value: {
                                any: "no_option",
                              },
                            },
                            ...timeWorkersDependatConditions(
                              servicesFields.length
                            ),
                          ]}
                          setDependantValues={setValue}
                          error={formState.errors?.time?.message}
                        />
                      </div>
                    </div>
                  </div>
                </BoxWrapper>
                <div className="section_wrapper_row">
                  <div style={{ width: "60%" }}>
                    <BoxWrapper hasBorder={true}>
                      <div
                        className="box_header"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h3>{translation[siteLang].services}</h3>
                        <Button
                          buttonType="button"
                          onClick={() => appendServiceField(defaultServices)}
                          size="md"
                          styleType="secondary"
                          icon={
                            <Plus width="20px" height="20px" color="#101928" />
                          }
                        />
                      </div>
                      <div className="box_content">
                        {servicesFields.map((field, filedIndex) => (
                          <BoxWrapper key={field.id} hasBorder={true}>
                            <div className="box_content">
                              {servicesFields.length > 1 && (
                                <button
                                  className="order_service_remove"
                                  type="button"
                                  onClick={() => removeServiceField(filedIndex)}
                                >
                                  <Close
                                    width="18"
                                    height="18"
                                    color="#f23636"
                                  />
                                </button>
                              )}
                              <div className="box_section">
                                <InputSelect
                                  label={translation[siteLang].service}
                                  options={moduleSelectOptions.services}
                                  dependantConditions={[
                                    {
                                      name: `services.${filedIndex}.worker`,
                                      value:
                                        moduleSelectOptions.servicesDependantConditions,
                                    },
                                  ]}
                                  setDependantValues={setValue}
                                  props={{
                                    ...register(
                                      `services.${filedIndex}.service`,
                                      validateInputField(siteLang, true, true)
                                    ),
                                    value:
                                      watchedInputs?.services?.[filedIndex]
                                        ?.service,
                                  }}
                                  error={
                                    formState.errors?.services?.[filedIndex]
                                      ?.service?.message
                                  }
                                />
                              </div>
                              <div className="box_section">
                                <InputSelect
                                  label={translation[siteLang].worker}
                                  allInputsValues={watchedInputs}
                                  options={[
                                    {
                                      options: workersSelectOptions(
                                        extraFetchedData?.workers || [],
                                        filedIndex
                                      ),
                                    },
                                  ]}
                                  props={{
                                    ...register(
                                      `services.${filedIndex}.worker`,
                                      validateInputField(siteLang, true, true)
                                    ),
                                    value:
                                      watchedInputs?.services?.[filedIndex]
                                        ?.worker,
                                  }}
                                  error={
                                    formState.errors?.services?.[filedIndex]
                                      ?.worker?.message
                                  }
                                />
                              </div>
                            </div>
                          </BoxWrapper>
                        ))}
                      </div>
                    </BoxWrapper>
                  </div>
                  <div style={{ width: "40%" }}>
                    {!userBranchID && (
                      <BoxWrapper hasBorder={true}>
                        <div className="box_content">
                          <InputSelect
                            label={translation[siteLang].branch}
                            options={
                              watchedInputs.date && watchedInputs.time
                                ? [
                                    {
                                      options: moduleSelectOptions.branches,
                                    },
                                  ]
                                : []
                            }
                            dependantConditions={branchesWorkersDependatConditions(
                              extraFetchedData?.branches || [],
                              servicesFields.length
                            )}
                            setDependantValues={setValue}
                            props={{
                              ...register(
                                "branch_id",
                                validateInputField(siteLang, true, true)
                              ),
                              placeholder:
                                watchedInputs.date && watchedInputs.time
                                  ? ""
                                  : translation[siteLang].select_timing,
                              value: watchedInputs.branch_id,
                            }}
                            error={formState.errors?.branch_id?.message}
                          />
                        </div>
                      </BoxWrapper>
                    )}
                    <BoxWrapper hasBorder={true}>
                      <div
                        className="box_header"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h3>{translation[siteLang].client}</h3>
                        <Button
                          buttonType="button"
                          onClick={() => setShowSelectClient(true)}
                          size="md"
                          styleType="secondary"
                          icon={
                            <Plus width="20px" height="20px" color="#101928" />
                          }
                        />
                      </div>
                      <div className="box_content">
                        <p
                          style={{
                            margin: 0,
                            fontWeight: watchedInputs.user_id ? 700 : 500,
                            color: watchedInputs.user_id
                              ? "var(--darkBlue)"
                              : "var(--darkGrey)",
                          }}
                        >
                          {watchedInputs.user_id && selectedClientName
                            ? selectedClientName
                            : translation[siteLang].select_client}
                        </p>
                        {formState.errors?.user_id?.message && (
                          <p
                            style={{
                              color: "var(--red)",
                              margin: 0,
                              fontSize: "15px",
                            }}
                          >
                            {formState.errors?.user_id?.message}
                          </p>
                        )}
                      </div>
                    </BoxWrapper>
                    <BoxWrapper hasBorder={true}>
                      <div className="box_header">
                        <h3>{translation[siteLang].basic_info}</h3>
                      </div>
                      <div className="box_content">
                        <div className="box_section">
                          <InputRadio
                            label={translation[siteLang].type}
                            shape="with_icon"
                            currentValue="indoor"
                            options={[
                              {
                                label: translation[siteLang].indoor,
                                value: "indoor",
                              },
                              {
                                label: translation[siteLang].outdoor,
                                value: "outdoor",
                              },
                              {
                                label: translation[siteLang].home,
                                value: "home",
                              },
                            ]}
                            props={{
                              ...register("type"),
                            }}
                          />
                        </div>
                        {watchedInputs.type !== "indoor" && (
                          <div className="box_section">
                            <InputSelect
                              label={translation[siteLang].address}
                              options={[
                                {
                                  options: orderAddresses.map((el) => ({
                                    label: el.name,
                                    value: el.id,
                                  })),
                                },
                              ]}
                              props={{
                                ...register(
                                  "address_id",
                                  validateInputField(siteLang, true, true)
                                ),
                                value: watchedInputs.address_id,
                              }}
                              error={formState.errors?.address_id?.message}
                            />
                          </div>
                        )}
                      </div>
                    </BoxWrapper>
                  </div>
                </div>
              </>
            )}
          </SectionsWrapper>
        </UpdateModulePage>
      </MainContent>
    </>
  );
};

export default CreateOrders;
