import { useSelector } from "react-redux";
import { selectSettings } from "../../store/selectors";
import ContentSidebar from "../ContentSidebar";
import PageWrapper from "../PageWrapper";
import Wrapper from "./styles";
import { MainContentType } from "./utils";

const MainContent = ({
  children,
  hasContentSidebar = false,
  contentSidebarSections,
  onlyInPage = false,
  hasFixedWidthHeader = false,
}: MainContentType) => {
  const { isMainSidebarCollapsed } = useSelector(selectSettings);

  return (
    <Wrapper expanded={isMainSidebarCollapsed} onlyInPage={onlyInPage}>
      {hasContentSidebar && contentSidebarSections && (
        <ContentSidebar sections={contentSidebarSections} />
      )}
      <PageWrapper
        hasContentSidebar={hasContentSidebar}
        onlyInPage={onlyInPage}
        hasFixedWidthHeader={hasFixedWidthHeader}
      >
        {children}
      </PageWrapper>
    </Wrapper>
  );
};

export default MainContent;
