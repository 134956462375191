import { useSelector } from "react-redux";
import { selectSettings } from "../../../store/selectors";
import translation from "../../../utils/translation";
import { RefundStateType } from "../../orders/RefundOrderModal";
import Modal from "../../ui/Modal";

type ComponentPropsType = {
  deleteState: RefundStateType;
  onResetDeleteToSubmit: () => void;
  onDeleteMilestone: () => Promise<void>;
};

const DeleteMilestoneModal = ({
  deleteState,
  onDeleteMilestone,
  onResetDeleteToSubmit,
}: ComponentPropsType) => {
  const { siteLang } = useSelector(selectSettings);

  return (
    <Modal
      maxWidth="400px"
      showFooter={true}
      showCloseIcon={true}
      onCloseHandler={onResetDeleteToSubmit}
      footerButtonsDirection="center"
      footerButtons={[
        {
          label: translation[siteLang].cancel,
          styleType: "secondary",
          size: "lg",
          buttonType: "button",
          onClick: onResetDeleteToSubmit,
          disabled: deleteState.isSubmitting,
        },
        {
          label: translation[siteLang].delete,
          styleType: "danger",
          size: "lg",
          buttonType: "button",
          onClick: onDeleteMilestone,
          disabled: deleteState.isSubmitting,
          isLoading: deleteState.isSubmitting,
        },
      ]}
    >
      {deleteState.error && (
        <p
          style={{
            margin: "20px 0 0",
            textAlign: "center",
            fontSize: "15px",
            color: "#f23636",
            fontWeight: 500,
          }}
        >
          {deleteState.error}
        </p>
      )}
      <p
        style={{
          margin: "20px 0",
          textAlign: "center",
          fontSize: "18px",
          fontWeight: 700,
        }}
      >
        {translation[siteLang].sure_to_delete}
      </p>
    </Modal>
  );
};

export default DeleteMilestoneModal;
