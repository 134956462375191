import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import InputField from "../../components/ui/Inputs/InputField";
import {
  BoxWrapper,
  SectionsWrapper,
  SplittedPage,
  SplittedPageSidebar,
  SplittedPageSidebarButton,
} from "../../components/updatingPages/components";
import MainContent from "../../containers/MainContent";
import useUpdatingData from "../../hooks/useUpdatingData";
import { selectSettings, selectUser } from "../../store/selectors";
import { Arrow, CalendarIcon, Plus } from "../../utils/icons";
import translation from "../../utils/translation";
import { useEffect, useMemo, useState } from "react";
import UpdateModulePage from "../../containers/UpdateModulePage";
import { validateInputField } from "../../containers/UpdateModulePage/utils";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { useParams } from "react-router-dom";
import InputSelect from "../../components/ui/Inputs/InputSelect";
import DropdownCalendar from "../../components/ui/Dropdown/DropdownCalendar";
import { formatDate, formatMoney } from "../../utils/formatter";
import {
  orderProductsWithCategorized,
  orderServicesWithCategorized,
  orderStatusOptions,
  workersSelectOptions,
} from "./utils";
import { useFieldArray } from "react-hook-form";
import {
  assignProductsToOrder,
  assignServicesToOrder,
  assignWorkersToOrder,
  getModuleData,
  getSingleModuleData,
  payOrder,
  removeProductsFromOrder,
  removeServicesFromOrder,
  removeWorkersFromOrder,
} from "../../utils/fetchers";
import OrderServiceWidget from "../../components/updatingPages/OrderServiceWidget";
import InputRadio from "../../components/ui/Inputs/InputRadio";
import { ClientDetailsRow } from "../../components/clients/ClientDetails/styles";
import InputCheckbox from "../../components/ui/Inputs/InputCheckbox";

const defaultServices = {
  service: "no_option",
  worker: "no_option",
};

const defaultProducts = {
  quantity: 1,
  product: "no_option",
};

const moduleBaseName = "/orders";

const UpdateOrders = () => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { branch_id: userBranchID },
  } = useSelector(selectUser);
  const { orderID } = useParams();

  const [branchProducts, setBranchProducts] = useState<any[]>([]);

  const [isCheckedout, setIsCheckedout] = useState<boolean>(false);

  const defaultValues = useMemo(
    () => ({
      address_id: "",
      date: new Date(),
      time: new Date().toTimeString().split(" ")[0].substring(0, 5),
      payment_method: ["cash"],
      services: [defaultServices],
      products: [],
      status: "pending",
      discounted_amount: "",
      discounted_amount_type: "fixed",
      use_coupon: false,
      coupon_code: "no_option",
      start_at_date: "",
      start_at_time: "",
      end_at_date: "",
      end_at_time: "",
      branch_id: userBranchID ? userBranchID : "no_option",
      use_wallet: false,
    }),
    [userBranchID]
  );

  const extraFetchers = useMemo(
    () => [
      {
        function: (signal: AbortSignal) =>
          getModuleData(
            userBranchID ? "branch" : "admin",
            "services",
            "sort=-sort_order&with=category",
            signal
          ),
        returnName: "services",
        isModule: true,
      },
      {
        function: (signal: AbortSignal) =>
          getModuleData(
            userBranchID ? "branch" : "admin",
            "workers",
            "with=services",
            signal
          ),
        returnName: "workers",
        isModule: true,
      },
      {
        function: (signal: AbortSignal) =>
          getModuleData("admin", "app_settings", "where=name~tax", signal),
        returnName: "settings",
        isModule: true,
      },
      {
        function: (signal: AbortSignal) =>
          getModuleData("admin", "coupons", "where=active=1", signal),
        returnName: "coupons",
        isModule: true,
      },
    ],
    [userBranchID]
  );

  const extraSubmissions = useMemo(
    () => [
      {
        function: (id: number, refinedData: any) => {
          return assignProductsToOrder(
            id,
            refinedData.products.map((el: any) => ({
              id: el.product,
              quantity: el.quantity,
            }))
          );
        },
        responseOfID: "order",
      },
      {
        function: (id: number, refinedData: any) => {
          return removeProductsFromOrder(
            id,
            refinedData.deletedProducts.map((el: any) => el.id)
          );
        },
        responseOfID: "order",
      },
      {
        function: (id: number, refinedData: any) => {
          return removeServicesFromOrder(
            id,
            refinedData.deletedServices.map((el: any) => ({
              id: el.service,
            }))
          );
        },
        responseOfID: "order",
      },
      {
        function: (id: number, refinedData: any) => {
          return removeWorkersFromOrder(
            id,
            refinedData.removedWorkersFromDefaultServices.map((el: any) => ({
              id: el.worker,
              service: el.service,
            }))
          );
        },
        responseOfID: "order",
      },
      {
        function: (id: number, refinedData: any) => {
          return assignServicesToOrder(
            id,
            refinedData.newServices.map((el: any) => ({
              id: el.service,
              worker: el.worker,
            }))
          );
        },
        responseOfID: "order",
      },
      {
        function: (id: number, refinedData: any) => {
          return assignWorkersToOrder(
            id,
            refinedData.newServices.map((el: any) => ({
              id: el.worker,
              service: el.service,
            }))
          );
        },
        responseOfID: "order",
        submitAlone: true,
      },
      {
        function: (id: number, refinedData: any) => {
          return assignWorkersToOrder(
            id,
            refinedData.newAssignedWorkersToDefaultServices.map((el: any) => ({
              id: el.worker,
              service: el.service,
            }))
          );
        },
        responseOfID: "order",
      },
      {
        function: (id: number, refinedData: any) => {
          return payOrder(
            id,
            refinedData.isCheckedOut,
            refinedData.payment_methods || []
          );
        },
        responseOfID: "order",
        submitAlone: true,
      },
    ],
    []
  );

  const resetModuleDefaults = useMemo(
    () => (clonedDefaults: any, extraFetchedData: any) => ({
      branch_id: clonedDefaults?.branch_id?.toString() || "no_option",
      user_id: clonedDefaults?.user_id?.toString() || "no_option",
      services: clonedDefaults?.services?.map((el: any) => ({
        service: el.id.toString() || "no_option",
        worker:
          clonedDefaults?.order_workers
            ?.find((worker: any) => worker.service_id === el.id)
            ?.worker_id?.toString() || "no_option",
      })),
      products:
        clonedDefaults?.order_products?.map((el: any) => ({
          product: el.product_id?.toString() || "no_option",
          quantity: el.quantity || 1,
        })) || [],
      date: clonedDefaults?.reservation_time
        ? new Date(clonedDefaults.reservation_time)
        : new Date(),
      time:
        clonedDefaults?.reservation_time?.split(" ")?.[1]?.substring(0, 5) ||
        "10:00",
      ...(clonedDefaults?.started_at
        ? {
            start_at_date: new Date(clonedDefaults.started_at),
            start_at_time: clonedDefaults?.started_at
              ?.split(" ")?.[1]
              ?.substring(0, 5),
          }
        : {}),
      ...(clonedDefaults?.ended_at
        ? {
            end_at_date: new Date(clonedDefaults.ended_at),
            end_at_time: clonedDefaults?.ended_at
              ?.split(" ")?.[1]
              ?.substring(0, 5),
          }
        : {}),
      use_coupon: clonedDefaults?.coupon_code ? true : false,
      coupon_code: clonedDefaults?.coupon
        ? clonedDefaults.coupon.code
        : "no_option",
    }),
    []
  );

  const {
    isFetching,
    extraFetchedData,
    register,
    watchedInputs,
    setValue,
    moduleDefaultData,
    control,
    ...updatingModule
  } = useUpdatingData({
    moduleType: userBranchID ? "branch" : "admin",
    moduleState: "edit",
    moduleID: +(orderID || 0),
    moduleName: "orders",
    modulePostEndpoint: `order/branch/update/${orderID}`,
    modulePostType: "formData",
    watchInputFields: true,
    defaultValues,
    navigateAfterSubmissionTo: isCheckedout
      ? `/orders/view/${orderID}`
      : moduleBaseName,
    disallowDefaultNavigateBackOnSuccess: isCheckedout,
    extraFetchers,
    resetModuleDefaults,
    queries:
      "with=services,order_workers,products,order_products,user,payment_methods",
    extraSubmissions,
  });

  const formState = updatingModule.formState;

  const {
    fields: servicesFields,
    append: appendServiceField,
    remove: removeServiceField,
  } = useFieldArray({
    control,
    name: "services",
  });

  const {
    fields: productsFields,
    append: appendProductField,
    remove: removeProductField,
  } = useFieldArray({
    control,
    name: "products",
  });

  const moduleSelectOptions = useMemo(() => {
    const servicesCategories: any = orderServicesWithCategorized({
      fetchedServices: extraFetchedData?.services,
      siteLang,
      includePrice: true,
    });
    const productsCategories: any = orderProductsWithCategorized({
      fetchedProducts: branchProducts,
    });
    return {
      services: servicesCategories || [],
      servicesDependantConditions: extraFetchedData?.services?.reduce(
        (acc: any, cur: any) =>
          Object.assign(acc, {
            [cur.id]: "no_option",
          }),
        {}
      ),
      products: productsCategories || [],
      coupons:
        extraFetchedData?.coupons?.map((el: any) => ({
          label:
            el.code +
            ` (${
              el.type === "percentage"
                ? `${el.value}%`
                : siteLang === "ar"
                ? `${el.value} جنيه`
                : `EGP ${el.value}`
            })`,
          value: el.code,
        })) || [],
    };
  }, [extraFetchedData, branchProducts, siteLang]);

  const subtotalOrderAmount = useMemo(() => {
    return (
      (watchedInputs?.services?.reduce(
        (acc: any, cur: any) =>
          acc +
          (extraFetchedData?.services?.find(
            (el: any) => el.id.toString() === cur.service
          )?.price || 0),
        0
      ) || 0) +
      (watchedInputs?.products?.reduce(
        (acc: any, cur: any) =>
          acc +
          (branchProducts?.find((el: any) => el.id.toString() === cur.product)
            ?.price || 0) *
            +cur.quantity,
        0
      ) || 0)
    );
  }, [watchedInputs, branchProducts, extraFetchedData]);

  const orderCouponDiscount = useMemo(() => {
    let couponDiscount = 0;
    if (watchedInputs.use_coupon && watchedInputs.coupon_code !== "no_option") {
      let coupon = extraFetchedData?.coupons?.find(
        (el: any) => el.code === watchedInputs.coupon_code
      );
      if (coupon) {
        couponDiscount =
          coupon.type === "percentage"
            ? (coupon.value / 100) * subtotalOrderAmount
            : coupon.value;
      }
    }
    return couponDiscount;
  }, [
    subtotalOrderAmount,
    extraFetchedData?.coupons,
    watchedInputs.use_coupon,
    watchedInputs.coupon_code,
  ]);

  const orderManualDiscount = useMemo(() => {
    let manualDiscount = 0;
    if (+watchedInputs.discounted_amount) {
      manualDiscount =
        watchedInputs.discounted_amount_type === "percentage"
          ? (+watchedInputs.discounted_amount * subtotalOrderAmount) / 100
          : +watchedInputs.discounted_amount;
    }
    return manualDiscount;
  }, [
    subtotalOrderAmount,
    watchedInputs.discounted_amount_type,
    watchedInputs.discounted_amount,
  ]);

  const totalOrderAmount = useMemo(() => {
    return Math.max(
      0,
      subtotalOrderAmount - orderManualDiscount - orderCouponDiscount
    );
  }, [subtotalOrderAmount, orderManualDiscount, orderCouponDiscount]);

  const totalOrderTaxes = useMemo(() => {
    return (
      ((+extraFetchedData?.settings?.[0]?.value || 0) / 100) *
        totalOrderAmount || 0
    );
  }, [totalOrderAmount, extraFetchedData]);

  const updatedDataToSubmit = useMemo(() => {
    return (data: any) => ({
      reservation_time: `${data.date.getFullYear()}-${
        (`${data.date.getMonth() + 1}`.length === 1 ? "0" : "") +
        (data.date.getMonth() + 1)
      }-${
        (`${data.date.getDate()}`.length === 1 ? "0" : "") + data.date.getDate()
      } ${data.time}:00`,
      date: "",
      time: "",
      ...(data.start_at_date && data.start_at_time
        ? {
            started_at: `${data.start_at_date.getFullYear()}-${
              (`${data.start_at_date.getMonth() + 1}`.length === 1 ? "0" : "") +
              (data.start_at_date.getMonth() + 1)
            }-${
              (`${data.start_at_date.getDate()}`.length === 1 ? "0" : "") +
              data.start_at_date.getDate()
            } ${data.start_at_time}:00`,
          }
        : {}),
      start_at_date: "",
      start_at_time: "",
      ...(data.end_at_date && data.end_at_time
        ? {
            ended_at: `${data.end_at_date.getFullYear()}-${
              (`${data.end_at_date.getMonth() + 1}`.length === 1 ? "0" : "") +
              (data.end_at_date.getMonth() + 1)
            }-${
              (`${data.end_at_date.getDate()}`.length === 1 ? "0" : "") +
              data.end_at_date.getDate()
            } ${data.end_at_time}:00`,
          }
        : {}),
      end_at_date: "",
      end_at_time: "",
      deletedServices: moduleDefaultData.services
        .filter(
          (serv: any) =>
            data.services.findIndex(
              (el: any) => el.service === serv.id.toString()
            ) === -1
        )
        .map((serv: any) => ({
          service: serv.id,
          worker: moduleDefaultData.order_workers
            ?.find((worker: any) => worker.service_id === serv.id)
            ?.worker_id?.toString(),
        })),
      newServices: data.services.filter(
        (serv: any) =>
          moduleDefaultData.services.findIndex(
            (el: any) => el.id.toString() === serv.service
          ) === -1
      ),
      removedWorkersFromDefaultServices: moduleDefaultData.order_workers
        .filter(
          (el: any) =>
            data.services.findIndex(
              (serv: any) => serv.service === el.service_id.toString()
            ) !== -1 &&
            data.services.find(
              (serv: any) => serv.service === el.service_id.toString()
            )?.worker !== el.worker_id.toString()
        )
        .map((el: any) => ({ service: el.service_id, worker: el.worker_id })),
      newAssignedWorkersToDefaultServices: data.services.filter(
        (serv: any) =>
          moduleDefaultData.services.findIndex(
            (el: any) => serv.service === el.id.toString()
          ) !== -1 &&
          (moduleDefaultData.order_workers
            .find((el: any) => serv.service === el.service_id.toString())
            ?.worker_id?.toString() !== serv.worker ||
            moduleDefaultData.order_workers.find(
              (el: any) => serv.service === el.service_id.toString()
            ) === undefined)
      ),
      deletedProducts: moduleDefaultData.products.filter(
        (prod: any) =>
          data.products.findIndex(
            (el: any) => el.product === prod.id.toString()
          ) === -1
      ),
      products: data.products.filter(
        (prod: any) =>
          moduleDefaultData.products.findIndex(
            (el: any) => prod.product === el.id.toString()
          ) === -1
      ),
      services: "",
      discounted_amount: orderManualDiscount,
      ...(isCheckedout
        ? {
            status: "completed",
            ended_at: `${new Date().getFullYear()}-${
              (`${new Date().getMonth() + 1}`.length === 1 ? "0" : "") +
              (new Date().getMonth() + 1)
            }-${
              (`${new Date().getDate()}`.length === 1 ? "0" : "") +
              new Date().getDate()
            } ${new Date().toTimeString().split(" ")[0].substring(0, 5)}:00`,
            // payment_methods:
            //   data.use_wallet &&
            //   (+moduleDefaultData?.user?.wallet || 0) >=
            //     totalOrderAmount + totalOrderTaxes
            //     ? ["wallet"]
            //     : [data.payment_method, ...(data.use_wallet ? ["wallet"] : [])],
            payment_methods: [
              ...(Array.isArray(data.payment_method)
                ? data.payment_method
                : [data.payment_method]),
              ...(data.use_wallet ? ["wallet"] : []),
            ],
            coupon_code: data.use_coupon ? data.coupon_code : "",
          }
        : {}),
      isCheckedout,
    });
  }, [
    moduleDefaultData,
    isCheckedout,
    // totalOrderAmount,
    // totalOrderTaxes,
    orderManualDiscount,
  ]);

  useEffect(() => {
    if (moduleDefaultData?.branch_id) {
      getSingleModuleData(
        userBranchID ? "branch" : "admin",
        "branches",
        moduleDefaultData.branch_id,
        "with=products,products.category"
      ).then((res) => {
        setBranchProducts(
          res.data.data.products.map((el: any) => ({
            id: el.id,
            name: el.name,
            price: el.price,
            quantity: el.quantity,
            category_id: el.category_id,
            category: el.category,
          }))
        );
      });
    }
  }, [moduleDefaultData?.branch_id, userBranchID]);

  return (
    <MainContent onlyInPage={true}>
      <PageTitle
        extraStyle={{
          margin: "0 -40px",
          padding: "15px 40px",
          borderBottom: "1px solid #dee3e7",
        }}
        firstSection={
          <div className="page_header_title_update">
            <Button
              buttonType="button"
              size="md"
              styleType="icon"
              onClick={() => updatingModule.onNavigateBack(moduleBaseName)}
              disabled={updatingModule.submissionState.isSubmitting}
              icon={
                <Arrow
                  height="27"
                  width="27"
                  color="#101928"
                  extraStyle={{
                    transform: `rotate(${siteLang === "ar" ? "180" : "0"}deg)`,
                  }}
                />
              }
            />
            <h1>
              {[
                translation[siteLang].edit,
                " ",
                translation[siteLang].order.toLowerCase(),
              ]}
            </h1>
          </div>
        }
      />
      <UpdateModulePage
        moduleBaseName={moduleBaseName}
        updatedDataToSubmit={updatedDataToSubmit}
        {...updatingModule}
      >
        <SplittedPage
          pixelsToMinusFromWidth={500}
          style={{ backgroundColor: "#f8f8fb" }}
        >
          <SectionsWrapper>
            {isFetching ? (
              <div style={{ width: "fit-content", margin: "100px auto 0" }}>
                <LoadingSpinner type="circle" />
              </div>
            ) : (
              <>
                <BoxWrapper>
                  <div className="box_section">
                    <div className="box_row">
                      <div style={{ width: "auto" }}>
                        <DropdownCalendar
                          button={{
                            styleType: "secondary",
                            size: "lg",
                            icon: (
                              <CalendarIcon
                                width="15"
                                height="15"
                                color="#878c93"
                                extraStyle={{ width: "18px", height: "18px" }}
                              />
                            ),
                            iconPosition: "start",
                            label: formatDate(
                              watchedInputs.date.toISOString(),
                              siteLang,
                              false,
                              { weekday: "long" }
                            ),
                          }}
                          defaultValue={watchedInputs.date}
                          onChange={(date) => setValue("date", date)}
                        />
                      </div>
                      <div style={{ width: "200px" }}>
                        <InputField
                          type="time"
                          props={{
                            ...register(
                              "time",
                              validateInputField(siteLang, true)
                            ),
                          }}
                          error={formState.errors?.time?.message}
                        />
                      </div>
                      <div style={{ width: "200px" }}>
                        <InputSelect
                          options={[
                            {
                              options: orderStatusOptions(siteLang),
                            },
                          ]}
                          props={{
                            ...register("status"),
                            value: watchedInputs.status,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </BoxWrapper>
                <BoxWrapper>
                  <div
                    className="box_header"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottom: 0,
                      paddingBottom: 0,
                    }}
                  >
                    <h3>{translation[siteLang].services}</h3>
                    <Button
                      buttonType="button"
                      onClick={() => appendServiceField(defaultServices)}
                      size="md"
                      styleType="secondary"
                      icon={<Plus width="20px" height="20px" color="#101928" />}
                    />
                  </div>
                  <div className="box_content">
                    <div className="box_row">
                      {servicesFields.map((field, fieldIndex) => (
                        <div key={field.id}>
                          <OrderServiceWidget
                            onRemoveHandler={
                              servicesFields.length > 1
                                ? () => removeServiceField(fieldIndex)
                                : null
                            }
                            title={
                              extraFetchedData?.services?.find(
                                (el: any) =>
                                  el.id.toString() ===
                                  watchedInputs?.services?.[fieldIndex]?.service
                              )?.name || ""
                            }
                            info={
                              extraFetchedData?.services?.find(
                                (el: any) =>
                                  el.id.toString() ===
                                  watchedInputs?.services?.[fieldIndex]?.service
                              )?.category?.name || ""
                            }
                            price={
                              extraFetchedData?.services?.find(
                                (el: any) =>
                                  el.id.toString() ===
                                  watchedInputs?.services?.[fieldIndex]?.service
                              )?.price || ""
                            }
                            quantity={1}
                          >
                            <div className="widget_row">
                              <div className="widget_col">
                                <InputSelect
                                  label={translation[siteLang].service}
                                  options={moduleSelectOptions.services}
                                  dependantConditions={[
                                    {
                                      name: `services.${fieldIndex}.worker`,
                                      value:
                                        moduleSelectOptions.servicesDependantConditions,
                                    },
                                  ]}
                                  setDependantValues={setValue}
                                  props={{
                                    ...register(
                                      `services.${fieldIndex}.service`,
                                      validateInputField(siteLang, true, true)
                                    ),
                                    value:
                                      watchedInputs?.services?.[fieldIndex]
                                        ?.service,
                                  }}
                                  error={
                                    formState.errors?.services?.[fieldIndex]
                                      ?.service?.message
                                  }
                                />
                              </div>
                              <div className="widget_col">
                                <InputSelect
                                  label={translation[siteLang].worker}
                                  allInputsValues={watchedInputs}
                                  options={[
                                    {
                                      options: workersSelectOptions(
                                        extraFetchedData?.workers || [],
                                        fieldIndex
                                      ),
                                    },
                                  ]}
                                  props={{
                                    ...register(
                                      `services.${fieldIndex}.worker`,
                                      validateInputField(siteLang, true, true)
                                    ),
                                    value:
                                      watchedInputs?.services?.[fieldIndex]
                                        ?.worker,
                                  }}
                                  error={
                                    formState.errors?.services?.[fieldIndex]
                                      ?.worker?.message
                                  }
                                />
                              </div>
                            </div>
                          </OrderServiceWidget>
                        </div>
                      ))}
                    </div>
                  </div>
                </BoxWrapper>
                <BoxWrapper>
                  <div
                    className="box_header"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottom: 0,
                      paddingBottom: 0,
                    }}
                  >
                    <h3>{translation[siteLang].products}</h3>
                    <Button
                      buttonType="button"
                      onClick={() => appendProductField(defaultProducts)}
                      size="md"
                      styleType="secondary"
                      icon={<Plus width="20px" height="20px" color="#101928" />}
                    />
                  </div>
                  <div className="box_content">
                    <div className="box_row">
                      {productsFields.map((field, fieldIndex) => (
                        <div key={field.id}>
                          <OrderServiceWidget
                            onRemoveHandler={() =>
                              removeProductField(fieldIndex)
                            }
                            title={
                              branchProducts?.find(
                                (el: any) =>
                                  el.id.toString() ===
                                  watchedInputs?.products?.[fieldIndex]?.product
                              )?.name || ""
                            }
                            info={
                              branchProducts?.find(
                                (el: any) =>
                                  el.id.toString() ===
                                  watchedInputs?.products?.[fieldIndex]?.product
                              )?.category?.name || ""
                            }
                            price={
                              (branchProducts?.find(
                                (el: any) =>
                                  el.id.toString() ===
                                  watchedInputs?.products?.[fieldIndex]?.product
                              )?.price || 0) *
                              (watchedInputs?.products?.[fieldIndex]
                                ?.quantity || 1)
                            }
                            quantity={
                              watchedInputs?.products?.[fieldIndex]?.quantity
                            }
                          >
                            <div className="widget_row">
                              <div
                                className="widget_col"
                                style={{ width: "20%" }}
                              >
                                <InputField
                                  label={translation[siteLang].quantity}
                                  type="number"
                                  props={{
                                    ...register(
                                      `products.${fieldIndex}.quantity`
                                    ),
                                    min: 1,
                                  }}
                                />
                              </div>
                              <div
                                className="widget_col"
                                style={{ width: "80%" }}
                              >
                                <InputSelect
                                  label={translation[siteLang].product}
                                  options={moduleSelectOptions.products}
                                  props={{
                                    ...register(
                                      `products.${fieldIndex}.product`,
                                      validateInputField(siteLang, true, true)
                                    ),
                                    value:
                                      watchedInputs?.products?.[fieldIndex]
                                        ?.product,
                                  }}
                                  error={
                                    formState.errors?.products?.[fieldIndex]
                                      ?.product?.message
                                  }
                                />
                              </div>
                            </div>
                          </OrderServiceWidget>
                        </div>
                      ))}
                    </div>
                  </div>
                </BoxWrapper>
              </>
            )}
          </SectionsWrapper>
        </SplittedPage>
        <SplittedPageSidebar width={500} style={{ padding: "30px" }}>
          <SectionsWrapper>
            {isFetching ? (
              <></>
            ) : (
              <>
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].start_time}</h3>
                  </div>
                  <div className="box_content">
                    <div className="box_row">
                      <div style={{ width: "60%" }}>
                        <div className="box_section">
                          <DropdownCalendar
                            button={{
                              styleType: "secondary",
                              size: "lg",
                              icon: (
                                <CalendarIcon
                                  width="15"
                                  height="15"
                                  color="#878c93"
                                  extraStyle={{ width: "18px", height: "18px" }}
                                />
                              ),
                              iconPosition: "start",
                              label: watchedInputs.start_at_date
                                ? formatDate(
                                    watchedInputs.start_at_date.toISOString(),
                                    siteLang,
                                    false
                                  )
                                : translation[siteLang].not_yet,
                              extraStyle: { width: "100%" },
                            }}
                            defaultValue={
                              watchedInputs.start_at_date || new Date()
                            }
                            onChange={(date) => setValue("start_at_date", date)}
                            widgetStyle={{
                              [siteLang === "ar" ? "right" : "left"]: "-40px",
                            }}
                          />
                        </div>
                      </div>
                      <div style={{ width: "40%" }}>
                        <div className="box_section">
                          <InputField
                            type="time"
                            props={{
                              ...register(
                                "start_at_time",
                                watchedInputs.start_at_date
                                  ? validateInputField(siteLang, true)
                                  : {}
                              ),
                            }}
                            error={formState.errors?.start_at_time?.message}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </BoxWrapper>
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].end_time}</h3>
                  </div>
                  <div className="box_content">
                    <div className="box_row">
                      <div style={{ width: "60%" }}>
                        <div className="box_section">
                          <DropdownCalendar
                            button={{
                              styleType: "secondary",
                              size: "lg",
                              icon: (
                                <CalendarIcon
                                  width="15"
                                  height="15"
                                  color="#878c93"
                                  extraStyle={{ width: "18px", height: "18px" }}
                                />
                              ),
                              iconPosition: "start",
                              label: watchedInputs.end_at_date
                                ? formatDate(
                                    watchedInputs.end_at_date.toISOString(),
                                    siteLang,
                                    false
                                  )
                                : translation[siteLang].not_yet,
                              extraStyle: { width: "100%" },
                            }}
                            defaultValue={
                              watchedInputs.end_at_date || new Date()
                            }
                            onChange={(date) => setValue("end_at_date", date)}
                            widgetStyle={{
                              [siteLang === "ar" ? "right" : "left"]: "-40px",
                            }}
                          />
                        </div>
                      </div>
                      <div style={{ width: "40%" }}>
                        <div className="box_section">
                          <InputField
                            type="time"
                            props={{
                              ...register(
                                "end_at_time",
                                watchedInputs.end_at_date
                                  ? validateInputField(siteLang, true)
                                  : {}
                              ),
                            }}
                            error={formState.errors?.end_at_time?.message}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </BoxWrapper>
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].client}</h3>
                  </div>
                  <div className="box_content">
                    <div className="box_section">
                      <ClientDetailsRow>
                        <span>{translation[siteLang].name}</span>
                        <span>{moduleDefaultData.user.name}</span>
                      </ClientDetailsRow>
                      <ClientDetailsRow>
                        <span>{translation[siteLang].shads}</span>
                        <span>
                          {
                            translation[siteLang][
                              moduleDefaultData.user.shads ? "yes" : "no"
                            ]
                          }
                        </span>
                      </ClientDetailsRow>
                      <ClientDetailsRow>
                        <span>{translation[siteLang].phone}</span>
                        <span>{moduleDefaultData.user.phone}</span>
                      </ClientDetailsRow>
                      <ClientDetailsRow>
                        <span>{translation[siteLang].email}</span>
                        <span>{moduleDefaultData.user.email}</span>
                      </ClientDetailsRow>
                      <ClientDetailsRow>
                        <span>{translation[siteLang].membership}</span>
                        <span>
                          {moduleDefaultData.user.membership &&
                            translation[siteLang][
                              moduleDefaultData.user.membership.toLowerCase()
                            ]}
                        </span>
                      </ClientDetailsRow>
                      <ClientDetailsRow>
                        <span>{translation[siteLang].wallet}</span>
                        <span>
                          {formatMoney(moduleDefaultData.user.wallet, siteLang)}
                        </span>
                      </ClientDetailsRow>
                    </div>
                  </div>
                </BoxWrapper>
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].payment_method}</h3>
                  </div>
                  <div className="box_content">
                    <div className="box_section">
                      <InputRadio
                        shape="with_icon"
                        asCheckbox={true}
                        currentValue="cash"
                        options={[
                          {
                            label: translation[siteLang].cash,
                            value: "cash",
                          },
                          {
                            label: translation[siteLang].card,
                            value: "card",
                          },
                          {
                            label: translation[siteLang].lucky,
                            value: "lucky",
                          },
                          {
                            label: translation[siteLang].waffarha,
                            value: "waffarha",
                          },
                        ]}
                        props={{
                          ...register("payment_method", {
                            validate: (value) =>
                              watchedInputs.use_wallet
                                ? true
                                : value.length > 0 ||
                                  translation[siteLang].min_payment_methods,
                          }),
                        }}
                        error={formState.errors?.payment_method?.message}
                      />
                    </div>
                    <div className="box_section">
                      <InputCheckbox
                        topLabel={translation[siteLang].use_wallet}
                        label={
                          <>
                            {translation[siteLang].use_wallet_money}{" "}
                            {
                              <b>
                                (
                                {formatMoney(
                                  moduleDefaultData.user.wallet,
                                  siteLang
                                )}
                                )
                              </b>
                            }
                          </>
                        }
                        checkboxStyle="switcher"
                        props={{
                          ...register("use_wallet"),
                          disabled: moduleDefaultData.user.wallet === 0,
                        }}
                      />
                    </div>
                  </div>
                </BoxWrapper>
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].discount}</h3>
                  </div>
                  <div className="box_content">
                    {/* <div className="box_section">
                      <div className="box_row">
                        <div style={{ width: "50%" }}>
                          <InputSelect
                            label={translation[siteLang].type}
                            options={[
                              {
                                options: [
                                  {
                                    label: translation[siteLang].fixed,
                                    value: "fixed",
                                  },
                                  {
                                    label: translation[siteLang].percentage,
                                    value: "percentage",
                                  },
                                ],
                              },
                            ]}
                            props={{
                              ...register("discounted_amount_type"),
                              value: watchedInputs.discounted_amount_type,
                            }}
                          />
                        </div>
                        <div style={{ width: "50%" }}>
                          <InputField
                            label={translation[siteLang].discount}
                            type="number"
                            icon={
                              <>
                                {watchedInputs.discounted_amount_type ===
                                "percentage"
                                  ? "%"
                                  : siteLang === "ar"
                                  ? "جنيه"
                                  : "EGP"}
                              </>
                            }
                            iconBorder={true}
                            props={{
                              ...register("discounted_amount", {
                                min: {
                                  value: 0,
                                  message:
                                    translation[siteLang].minimum + " = 0",
                                },
                                max: {
                                  value:
                                    watchedInputs.discounted_amount_type ===
                                    "fixed"
                                      ? subtotalOrderAmount
                                      : 100,
                                  message:
                                    translation[siteLang].maximum +
                                    ` = ${
                                      watchedInputs.discounted_amount_type ===
                                      "fixed"
                                        ? subtotalOrderAmount
                                        : 100
                                    }`,
                                },
                              }),
                            }}
                            error={formState.errors?.discounted_amount?.message}
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="box_section">
                      <InputCheckbox
                        topLabel={translation[siteLang].use_coupon}
                        label={translation[siteLang].applied_on_checkout_only}
                        checkboxStyle="switcher"
                        props={{
                          ...register("use_coupon"),
                        }}
                      />
                    </div>
                    {watchedInputs.use_coupon && (
                      <div className="box_section">
                        <InputSelect
                          label={translation[siteLang].coupon}
                          options={[{ options: moduleSelectOptions.coupons }]}
                          props={{
                            ...register(
                              "coupon_code",
                              validateInputField(siteLang, true, true)
                            ),
                            value: watchedInputs?.coupon_code,
                          }}
                          error={formState.errors?.coupon_code?.message}
                        />
                      </div>
                    )}
                  </div>
                </BoxWrapper>
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].invoice}</h3>
                  </div>
                  <div className="box_content">
                    <div className="box_section">
                      <div className="invoice_row">
                        <span>{translation[siteLang].subtotal}</span>
                        <span>
                          {formatMoney(subtotalOrderAmount, siteLang)}
                        </span>
                      </div>
                      <div className="invoice_row">
                        <span>{translation[siteLang].discount}</span>
                        <span>
                          {formatMoney(orderManualDiscount, siteLang)}
                        </span>
                      </div>
                      {watchedInputs.use_coupon &&
                        watchedInputs.coupon_code !== "no_option" && (
                          <div className="invoice_row">
                            <span>{translation[siteLang].coupon}</span>
                            <span>
                              {formatMoney(orderCouponDiscount, siteLang)}
                            </span>
                          </div>
                        )}
                      <div className="invoice_row">
                        <span>
                          {translation[siteLang].taxes} (
                          {+extraFetchedData?.settings?.[0]?.value || "0"}%)
                        </span>
                        <span>{formatMoney(totalOrderTaxes, siteLang)}</span>
                      </div>
                      <div className="invoice_row invoice_balance">
                        <span>{translation[siteLang].total}</span>
                        <span>
                          {formatMoney(
                            totalOrderAmount + totalOrderTaxes,
                            siteLang
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </BoxWrapper>
              </>
            )}
          </SectionsWrapper>
        </SplittedPageSidebar>
        <SplittedPageSidebarButton width={500}>
          <Button
            label={translation[siteLang].save}
            buttonType="submit"
            size="xl"
            styleType="secondary"
            disabled={isFetching || updatingModule.submissionState.isSubmitting}
            isLoading={updatingModule.submissionState.isSubmitting}
          />
          <Button
            label={translation[siteLang].checkout}
            buttonType="submit"
            onClick={() => {
              setIsCheckedout(true);
            }}
            size="xl"
            styleType="primary"
            disabled={isFetching || updatingModule.submissionState.isSubmitting}
            isLoading={updatingModule.submissionState.isSubmitting}
          />
        </SplittedPageSidebarButton>
      </UpdateModulePage>
    </MainContent>
  );
};

export default UpdateOrders;
