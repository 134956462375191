import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: var(--white);
  padding: 40px 30px;
  border: 1px solid var(--lightGrey3);
  width: 450px;
  max-height: 100%;
  overflow-y: auto;
  .invoice_header {
    text-align: center;
    h2 {
      font-weight: 500;
      font-size: 20px;
      color: var(--darkBlue);
      margin: 0;
    }
    span {
      color: var(--lightGrey6);
    }
  }
  .invoice_row {
    margin: 20px -10px 0;
    padding-top: 20px;
    border-top: 1px solid var(--lightGrey3);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .invoice_col {
    padding: 0 10px;
    &:last-child:not(:first-child) {
      white-space: nowrap;
    }
    p {
      margin: 0;
      font-weight: 700;
    }
    span {
      color: var(--lightGrey6);
      font-size: 15px;
    }
  }
`;
