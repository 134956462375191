import { Route, Routes } from "react-router-dom";
import ListBanners from "./ListBanners";
import ListCoupons from "./ListCoupons";
import ListNotifications from "./ListNotifications";
import ListReasons from "./ListReasons";
import ListReferralCodes from "./ListReferralCodes";
import ListSettings from "./ListSettings";
import ListSupportTickets from "./ListSupportTickets";
import UpdateBanners from "./UpdateBanners";
import UpdateCoupons from "./UpdateCoupons";
import UpdateNotifications from "./UpdateNotifications";
import UpdateReasons from "./UpdateReasons";
import UpdateSettings from "./UpdateSettings";
import UpdateSupportTickets from "./UpdateSupportTickets";

const Settings = () => {
  return (
    <Routes>
      <Route path="tickets">
        <Route
          path="edit/:ticketID"
          element={<UpdateSupportTickets type="edit" />}
        />
        <Route index element={<ListSupportTickets />} />
      </Route>
      <Route path="notifications">
        <Route path="new" element={<UpdateNotifications type="create" />} />
        <Route index element={<ListNotifications />} />
      </Route>
      <Route path="referrals">
        <Route index element={<ListReferralCodes />} />
      </Route>
      <Route path="coupons">
        <Route path="edit/:couponID" element={<UpdateCoupons type="edit" />} />
        <Route path="new" element={<UpdateCoupons type="create" />} />
        <Route index element={<ListCoupons />} />
      </Route>
      <Route path="banners">
        <Route path="edit/:bannerID" element={<UpdateBanners type="edit" />} />
        <Route path="new" element={<UpdateBanners type="create" />} />
        <Route index element={<ListBanners />} />
      </Route>
      <Route path="support-reasons">
        <Route
          path="edit/:reasonID"
          element={
            <UpdateReasons
              type="edit"
              moduleName="support_reasons"
              endpoint="support-reason"
            />
          }
        />
        <Route
          path="new"
          element={
            <UpdateReasons
              type="create"
              moduleName="support_reasons"
              endpoint="support-reason"
            />
          }
        />
        <Route
          index
          element={
            <ListReasons
              moduleName="support_reasons"
              endpoint="support-reason"
            />
          }
        />
      </Route>
      <Route path="cancel-reasons">
        <Route
          path="edit/:reasonID"
          element={
            <UpdateReasons
              type="edit"
              moduleName="cancellation_reasons"
              endpoint="cancel-reason"
            />
          }
        />
        <Route
          path="new"
          element={
            <UpdateReasons
              type="create"
              moduleName="cancellation_reasons"
              endpoint="cancel-reason"
            />
          }
        />
        <Route
          index
          element={
            <ListReasons
              moduleName="cancellation_reasons"
              endpoint="cancel-reason"
            />
          }
        />
      </Route>
      <Route path="edit/:settingID" element={<UpdateSettings type="edit" />} />
      <Route path="new" element={<UpdateSettings type="create" />} />
      <Route index element={<ListSettings />} />
    </Routes>
  );
};

export default Settings;
