import { Route, Routes } from "react-router-dom";
import HomeContent from "./HomeContent";

const Home = () => {
  return (
    <Routes>
      <Route index element={<HomeContent />} />
    </Routes>
  );
};

export default Home;
