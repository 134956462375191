import styled from "styled-components";
import { Widget } from "../../components/ui/Dropdown/styles";

export const UserWidget = styled(Widget)`
  min-width: 270px;
  max-width: none;
  max-height: none;
  top: calc(100% + 12px);
  .header {
    padding: 20px;
    border-bottom: 1px solid var(--lightGrey3);
    display: flex;
    align-items: center;
  }
  .header__img {
    width: 78px;
    height: 78px;
    background-color: var(--lightBlue2);
    color: var(--blue);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    font-weight: 600;
    box-shadow: 0 0 0 2px var(--white), 0 0 0 3px var(--lightBlue2);
    margin-${(props) => (props.theme.lang === "ar" ? "left" : "right")}: 15px;
    cursor: default;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .header__name {
    p {
      font-size: 18px;
      font-weight: 600;
      margin: 0;
    }
    a {
      color: var(--blue);
      font-size: 13px;
      font-weight: 500;
    }
  }
  .links {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    a, button {
      padding: 0;
      border: 0;
      background: none;
      color: var(--darkBlue);
      transition: color 0.1s;
      outline: none;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      &:hover {
        color: var(--blue);
        text-decoration: none;
      }
    }
  }
`;

export const UserIcon = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--lightBlue2);
  border-radius: 50%;
  cursor: pointer;
  color: var(--blue);
  font-size: 17px;
  font-weight: 500;
`;

const Wrapper = styled.header`
  width: 100%;
  height: var(--headerHeight);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (props.theme.lang === "ar" ? "0 24px" : "0 24px")};
  border-bottom: 1px solid var(--lightGrey3);
  .logo {
    img {
      height: 40px;
    }
  }
  .controllers {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

export default Wrapper;
