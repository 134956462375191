import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import InputRadio from "../../components/ui/Inputs/InputRadio";
import InputSearch from "../../components/ui/Inputs/InputSearch";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import Modal from "../../components/ui/Modal";
import { selectSettings } from "../../store/selectors";
import { getModuleData } from "../../utils/fetchers";
import translation from "../../utils/translation";

const SelectClientModal = ({
  onCloseModal,
  onSelectClient,
}: {
  onCloseModal: () => void;
  onSelectClient: (id: string, name: string) => void;
}) => {
  const { siteLang } = useSelector(selectSettings);

  const [search, setSearch] = useState<string | null>(null);

  const [searchValue, setSearchValue] = useState<string>("");

  const [clients, setClients] = useState<{ loading: boolean; data: any[] }>({
    loading: false,
    data: [],
  });

  const { register, reset, handleSubmit, watch } = useForm({
    defaultValues: { id: "" },
  });

  const onChangeSearchHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    },
    []
  );

  const onCancelSearchHandler = useCallback(() => {
    setSearch(null);
    setSearchValue("");
    setClients({ loading: false, data: [] });
    reset();
  }, [reset]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (search !== null) {
        setSearchValue(search.trim());
      }
    }, 1000);
    return () => clearTimeout(timeOut);
  }, [search]);

  useEffect(() => {
    const fetchController = new AbortController();
    const fetchControllerSignal = fetchController.signal;

    if (searchValue) {
      (async () => {
        try {
          setClients((current) => ({ ...current, loading: true }));
          const clients = (
            await getModuleData(
              "admin",
              "users",
              `search=${searchValue}`,
              fetchControllerSignal
            )
          ).data.module.data;
          setClients({ loading: false, data: clients });
        } catch {}
      })();
    }

    return () => {
      if (fetchController) fetchController.abort();
    };
  }, [searchValue]);

  const onSubmitHandler = useCallback(
    ({ id }: { id: string }) => {
      if (!id) return;
      const client = clients.data.find((el) => el.id === +id);
      onSelectClient(id, `${client.name} (${client.phone})`);
      onCloseModal();
    },
    [clients, onSelectClient, onCloseModal]
  );

  return (
    <Modal
      as="form"
      onSubmit={handleSubmit(onSubmitHandler)}
      maxWidth="500px"
      showHeader={true}
      headerTitle={[
        translation[siteLang].add,
        translation[siteLang].client.toLowerCase(),
      ].join(" ")}
      showFooter={true}
      footerButtons={[
        {
          label: translation[siteLang].cancel,
          buttonType: "button",
          onClick: () => onCloseModal(),
          styleType: "secondary",
          size: "lg",
        },
        {
          label: translation[siteLang].add,
          buttonType: "submit",
          styleType: "primary",
          size: "lg",
          disabled: !watch("id"),
        },
      ]}
    >
      <div className="modal_section">
        <InputSearch
          onClearSearch={onCancelSearchHandler}
          props={{
            placeholder:
              translation[siteLang].search_all +
              " " +
              translation[siteLang].clients.toLowerCase(),
            onChange: onChangeSearchHandler,
            value: search ?? "",
          }}
        />
      </div>
      <div className="modal_section">
        {searchValue && clients.data.length && !clients.loading ? (
          <InputRadio
            options={clients.data.map((client) => ({
              label: `${client.name} (${client.phone})`,
              value: client.id.toString(),
            }))}
            props={{
              ...register("id"),
            }}
          />
        ) : clients.loading ? (
          <LoadingSpinner type="circle" />
        ) : (
          translation[siteLang].no_clients_search
        )}
      </div>
    </Modal>
  );
};

export default SelectClientModal;
