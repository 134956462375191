import { useMemo } from "react";
import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import Table from "../../components/ui/Table";
import MainContent from "../../containers/MainContent";
import MainHeader from "../../containers/MainHeader";
import MainSidebar from "../../containers/MainSidebar";
import ViewListingPage from "../../containers/ViewListingPage";
import useListingData from "../../hooks/useListingData";
import { selectSettings, selectUser } from "../../store/selectors";
import { getAllCategories } from "../../utils/fetchers";
import { Delete, DotsIcon, Edit, Page } from "../../utils/icons";
import translation from "../../utils/translation";
import { BoxWrapper } from "../../components/updatingPages/components";
import DropdownOptions from "../../components/ui/Dropdown/DropdownOptions";
import { Product } from "../../components/listingPages/ListingComponents";
import { formatMoney } from "../../utils/formatter";
import {
  ButtonGeneralType,
  ButtonSortType,
} from "../../components/ui/Button/utils";

const ListProducts = () => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { branch_id: userBranchID, permissions: userPermissions },
  } = useSelector(selectUser);

  const extraFetchers = useMemo(
    () => [
      {
        function: getAllCategories,
        returnName: "categories",
        afterDeletion: true,
      },
    ],
    []
  );

  const {
    data,
    extraFetchedData,
    onRequestDeleteHandler,
    onSelectTableRowHandler,
    isAllRowsSelected,
    showMultipleDelete,
    ...rest
  } = useListingData({
    moduleName: userBranchID ? "branch_products" : "product",
    moduleType: userBranchID ? "branch" : "admin",
    extraFetchers,
    number: "all",
    queries: "with=brand",
  });

  const refinedListingData = useMemo(() => {
    const refinedProducts: {
      id: number;
      name: string;
      products: any[];
    }[] =
      extraFetchedData?.categories?.map((cat: any) => ({
        id: cat.id,
        name: cat.name,
        products: [],
      })) || [];
    data.forEach((product) => {
      const categoryIndex = refinedProducts.findIndex(
        (el) => el?.id === product?.category_id
      );
      if (categoryIndex !== -1) {
        refinedProducts[categoryIndex].products.push({ ...product });
      }
    });
    return refinedProducts;
  }, [data, extraFetchedData]);

  return (
    <>
      <MainHeader />
      <MainSidebar />
      <MainContent
        {...(userPermissions?.brands?.view
          ? {
              hasContentSidebar: true,
              contentSidebarSections: [
                {
                  title: translation[siteLang].products,
                  links: [
                    {
                      label: translation[siteLang].products,
                      url: "/products",
                    },
                    {
                      label: translation[siteLang].brands,
                      url: "/products/brands",
                    },
                  ],
                },
              ],
            }
          : {
              hasContentSidebar: false,
            })}
      >
        <PageTitle
          firstSection={
            <h1>
              {[
                translation[siteLang][siteLang === "ar" ? "list" : "products"],
                " ",
                translation[siteLang][
                  siteLang === "ar" ? "products" : "list"
                ].toLocaleLowerCase(),
              ]}
            </h1>
          }
          {...(userPermissions?.categories?.create
            ? {
                secondSection: (
                  <Button
                    styleType="primary"
                    size="lg"
                    label={[
                      translation[siteLang][
                        siteLang === "ar" ? "category" : "new"
                      ],
                      translation[siteLang][
                        siteLang === "ar" ? "new" : "category"
                      ].toLowerCase(),
                    ].join(" ")}
                    buttonType="link"
                    url="/products/categories/new"
                  />
                ),
              }
            : {})}
        />
        <ViewListingPage
          {...rest}
          hasNoResults={refinedListingData.length === 0}
          hasNoPagination={true}
          tableFilter={{
            hasSearch: true,
            search: {
              ...rest.search,
              placeholder:
                translation[siteLang].search_all +
                " " +
                translation[siteLang].products.toLowerCase(),
            },
            hasSorting: true,
            sorting: {
              ...rest.sort,
              options: [
                {
                  label: `${translation[siteLang].default}`,
                  value: "id__desc",
                },
                {
                  label: `${translation[siteLang].name} ↑`,
                  value: "name__asc",
                },
                {
                  label: `${translation[siteLang].name} ↓`,
                  value: "name__desc",
                },
              ],
            },
          }}
        >
          {refinedListingData.map((category, index) => (
            <BoxWrapper key={category.id} hasBorder={true}>
              <div
                className="box_header"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h3>{category.name}</h3>
                {(userPermissions?.product?.create ||
                  userPermissions?.categories?.update ||
                  userPermissions?.categories?.delete) && (
                  <DropdownOptions
                    button={{
                      size: "md",
                      styleType: "secondary",
                      icon: (
                        <DotsIcon width="20px" height="20px" color="#101928" />
                      ),
                    }}
                    openDirection="end"
                    options={[
                      {
                        buttons: [
                          ...(userPermissions?.product?.create
                            ? ([
                                {
                                  buttonType: "link",
                                  url: `/products/new/${category.id}`,
                                  label: [
                                    translation[siteLang][
                                      siteLang === "ar" ? "product" : "new"
                                    ],
                                    translation[siteLang][
                                      siteLang === "ar" ? "new" : "product"
                                    ].toLowerCase() +
                                      (siteLang === "ar" ? "ة" : ""),
                                  ].join(" "),
                                },
                              ] as (ButtonGeneralType & ButtonSortType)[])
                            : []),
                          ...(userPermissions?.categories?.update
                            ? ([
                                {
                                  buttonType: "link",
                                  url: `/products/categories/edit/${category.id}`,
                                  label: [
                                    translation[siteLang].edit,
                                    translation[
                                      siteLang
                                    ].category.toLowerCase(),
                                  ].join(" "),
                                },
                              ] as (ButtonGeneralType & ButtonSortType)[])
                            : []),
                          ...(userPermissions?.categories?.delete
                            ? ([
                                {
                                  buttonType: "button",
                                  onClick: () =>
                                    onRequestDeleteHandler(
                                      [category.id],
                                      false,
                                      "categories"
                                    ),
                                  label: [
                                    translation[siteLang].delete,
                                    (siteLang === "ar" ? "ال" : "") +
                                      translation[
                                        siteLang
                                      ].category.toLowerCase(),
                                  ].join(" "),
                                },
                              ] as (ButtonGeneralType & ButtonSortType)[])
                            : []),
                        ],
                      },
                    ]}
                    widgetStyle={{ minWidth: "150px" }}
                  />
                )}
              </div>
              <div
                className="box_content"
                style={category.products.length > 0 ? { padding: 0 } : {}}
              >
                {category.products.length === 0 && !rest.isFetching ? (
                  <div
                    className="page_listing_no_results"
                    style={{ marginTop: 0 }}
                  >
                    <Page width="70" height="70" color="#878c93" />
                    <p>{translation[siteLang].category_no_products}</p>
                  </div>
                ) : (
                  <Table
                    headings={[
                      {
                        label: translation[siteLang].product,
                        style: {
                          width: "40%",
                        },
                      },
                      {
                        label: translation[siteLang].quantity,
                      },
                      {
                        label: translation[siteLang].featured,
                      },
                      {
                        label: translation[siteLang].commission,
                      },
                      {
                        label: translation[siteLang].brand,
                        style: {
                          width: "17%",
                        },
                      },
                      ...(userPermissions?.product?.update ||
                      userPermissions?.product?.delete
                        ? [
                            {
                              label: translation[siteLang].actions,
                              style: {
                                width: "105px",
                              },
                            },
                          ]
                        : []),
                    ]}
                    rows={category.products.map((el) => ({
                      columns: [
                        {
                          value: (
                            <Product
                              image={el.image}
                              name={el.name}
                              info={
                                el.info
                                  ? el.info?.substring(0, 15) +
                                    (el.info?.length > 15 ? "..." : "")
                                  : ""
                              }
                              price={
                                el.price ? formatMoney(+el.price, siteLang) : ""
                              }
                            />
                          ),
                        },
                        {
                          value: el.quantity,
                        },
                        {
                          value: el.featured,
                        },
                        {
                          value: el.commission,
                        },
                        {
                          value: el?.brand?.name,
                        },
                        ...(userPermissions?.product?.update ||
                        userPermissions?.product?.delete
                          ? [
                              {
                                value: (
                                  <div className="page_listing_table_actions">
                                    {userPermissions?.product?.update && (
                                      <Button
                                        icon={
                                          <Edit
                                            width="20"
                                            height="20"
                                            color="#101928"
                                          />
                                        }
                                        buttonType="link"
                                        styleType="secondary"
                                        url={`/products/edit/${el.id}`}
                                        size="md"
                                      />
                                    )}
                                    {userPermissions?.product?.delete && (
                                      <Button
                                        icon={
                                          <Delete
                                            width="20"
                                            height="20"
                                            color="#101928"
                                          />
                                        }
                                        buttonType="button"
                                        styleType="secondary"
                                        onClick={() =>
                                          onRequestDeleteHandler(
                                            [el.id],
                                            false,
                                            "product"
                                          )
                                        }
                                        size="md"
                                        className="delete"
                                      />
                                    )}
                                  </div>
                                ),
                              },
                            ]
                          : []),
                      ],
                    }))}
                  />
                )}
              </div>
            </BoxWrapper>
          ))}
        </ViewListingPage>
      </MainContent>
    </>
  );
};

export default ListProducts;
