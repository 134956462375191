export const userPermissions = (
  role:
    | {
        permissions: { name: string; id: number }[];
      }
    | undefined
) => {
  if (role) {
    const permissions: { [name: string]: { [name: string]: boolean } } = {};
    role.permissions.forEach((perm) => {
      const permName = perm.name.split(" ");
      if (permissions[permName[1]]) {
        permissions[permName[1]][permName[0]] = true;
      } else {
        permissions[permName[1]] = {
          create: false,
          update: false,
          view: false,
          delete: false,
        };
        permissions[permName[1]][permName[0]] = true;
      }
    });
    return permissions;
  } else {
    return {
      // users: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
      // admins: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
      // workers: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
      // categories: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
      // services: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
      // product: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
      // branch_products: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
      // branches: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
      // cities: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
      // regions: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
      // paycuts: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
      // additives: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
      // addresses: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
      // work_days: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
      // coupons: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
      // day_offs: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
      // orders: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
      // cancellation_reasons: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
      // stocks: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
      // expense_categories: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
      // expenses: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
      // banners: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
      // app_settings: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
      // support_reasons: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
      // support_forms: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
      // roles: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
      // permissions: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
      // notifications: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
      // brands: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
      // support_tickets: {
      //   create: true,
      //   update: true,
      //   view: true,
      //   delete: true,
      // },
    };
  }
};
