import { Route, Routes } from "react-router-dom";
import ListExpensesCategories from "./ListExpensesCategories";
import ListExpenses from "./ListExpenses";
import UpdateExpensesCategories from "./UpdateExpensesCategories";
import UpdateExpenses from "./UpdateExpenses";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/selectors";

const Expenses = () => {
  const {
    user: { permissions: userPermissions },
  } = useSelector(selectUser);

  return (
    <Routes>
      {(userPermissions?.expense_categories?.create ||
        userPermissions?.expense_categories?.view ||
        userPermissions?.expense_categories?.update) && (
        <Route path="list/:categoryName/:categoryID">
          {userPermissions?.expense_categories?.update && (
            <Route
              path="edit/:expenseID"
              element={<UpdateExpenses type="edit" />}
            />
          )}
          {userPermissions?.expense_categories?.create && (
            <Route path="new" element={<UpdateExpenses type="create" />} />
          )}
          {userPermissions?.expense_categories?.view && (
            <Route index element={<ListExpenses />} />
          )}
        </Route>
      )}

      {userPermissions?.expenses?.update && (
        <Route
          path="edit/:categoryID"
          element={<UpdateExpensesCategories type="edit" />}
        />
      )}
      {userPermissions?.expenses?.create && (
        <Route
          path="new"
          element={<UpdateExpensesCategories type="create" />}
        />
      )}
      {userPermissions?.expenses?.view && (
        <Route index element={<ListExpensesCategories />} />
      )}
    </Routes>
  );
};

export default Expenses;
