import styled from "styled-components";

export const SectionTitle = styled.div`
  margin-bottom: 20px;
  h3 {
    font-size: 25px;
    font-weight: 800;
    color: var(--black);
    margin: 0;
  }
`;

export const ClientDetailsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--darkBlue);
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  span:first-child {
    font-weight: 700;
  }
  span:last-child {
    font-weight: 600;
  }
`;
