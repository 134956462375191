import { useNavigate } from "react-router-dom";
import Wrapper from "./styles";
import { TableType } from "./utils";

const Table = ({
  headings,
  rows,
  tableHeaderChildren,
  isHeaderFixed = false,
  stickyTopOffsetValue = "",
}: TableType) => {
  const navigate = useNavigate();

  return (
    <Wrapper
      isHeaderFixed={isHeaderFixed}
      stickyTopOffsetValue={stickyTopOffsetValue}
    >
      <div className="header_section">
        {tableHeaderChildren && tableHeaderChildren}
        <table>
          <colgroup>
            {headings.map((heading, index) => (
              <col key={index} style={heading.style} />
            ))}
          </colgroup>
          <thead>
            <tr>
              {headings.map((heading, index) => (
                <th key={index}>{heading.label}</th>
              ))}
            </tr>
          </thead>
        </table>
      </div>
      <table className="main_table">
        <colgroup>
          {headings.map((heading, index) => (
            <col key={index} style={heading.style} />
          ))}
        </colgroup>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              {row.columns.map((col, index) => (
                <td
                  key={index}
                  {...(row.link && !col.link_not_applied
                    ? {
                        onClick: () =>
                          row.linkOutside
                            ? window.open(row.link, "_blank")
                            : navigate(row.link as string),
                        style: { cursor: "pointer" },
                      }
                    : {})}
                >
                  {col.value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Wrapper>
  );
};

export default Table;
