import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import InputField from "../../components/ui/Inputs/InputField";
import {
  BoxWrapper,
  SectionsWrapper,
} from "../../components/updatingPages/components";
import MainContent from "../../containers/MainContent";
import useUpdatingData from "../../hooks/useUpdatingData";
import { selectSettings } from "../../store/selectors";
import { Arrow } from "../../utils/icons";
import translation from "../../utils/translation";
import { useMemo } from "react";
import UpdateModulePage from "../../containers/UpdateModulePage";
import { validateInputField } from "../../containers/UpdateModulePage/utils";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { useParams, useSearchParams } from "react-router-dom";

const moduleBaseName = "/settings/notifications";

const UpdateNotifications = ({ type }: { type: "create" | "edit" }) => {
  const { siteLang } = useSelector(selectSettings);
  const { notificationID } = useParams();
  const [searchParams] = useSearchParams();
  const duplicateID = searchParams.get("duplicate");

  const defaultValues = useMemo(
    () => ({
      title_en: "",
      title_ar: "",
      body_en: "",
      body_ar: "",
      url: "",
      ids: {},
      new_notification: !duplicateID,
      notification_message_id: duplicateID,
    }),
    [duplicateID]
  );

  const {
    isFetching,
    extraFetchedData,
    register,
    watchedInputs,
    setValue,
    moduleDefaultData,
    ...updatingModule
  } = useUpdatingData({
    moduleType: "admin",
    moduleState: type,
    moduleID: +(notificationID || 0),
    moduleName: "notifications",
    modulePostEndpoint:
      type === "edit"
        ? `notification/update/${notificationID}`
        : "notification/send/user",
    modulePostType: "formData",
    watchInputFields: true,
    defaultValues,
    navigateAfterSubmissionTo: moduleBaseName,
  });

  const formState = updatingModule.formState;

  const updatedDataToSubmit = useMemo(() => {
    return (data: any) => {
      return { all: true, ids: undefined };
    };
  }, []);

  return (
    <MainContent onlyInPage={true} hasFixedWidthHeader={true}>
      <UpdateModulePage
        moduleBaseName={moduleBaseName}
        updatedDataToSubmit={updatedDataToSubmit}
        {...updatingModule}
      >
        <PageTitle
          isSticky={true}
          firstSection={
            <div className="page_header_title_update">
              <Button
                buttonType="button"
                size="md"
                styleType="icon"
                onClick={() => updatingModule.onNavigateBack(moduleBaseName)}
                disabled={updatingModule.submissionState.isSubmitting}
                icon={
                  <Arrow
                    height="27"
                    width="27"
                    color="#101928"
                    extraStyle={{
                      transform: `rotate(${
                        siteLang === "ar" ? "180" : "0"
                      }deg)`,
                    }}
                  />
                }
              />
              <div>
                <h1>
                  {type === "edit"
                    ? [
                        translation[siteLang].edit,
                        " ",
                        translation[siteLang].notification.toLowerCase(),
                      ]
                    : [
                        translation[siteLang].add,
                        " ",
                        translation[siteLang][
                          siteLang === "ar" ? "notification" : "new"
                        ].toLowerCase(),
                        " ",
                        translation[siteLang][
                          siteLang === "ar" ? "new" : "notification"
                        ].toLowerCase(),
                      ]}
                </h1>
              </div>
            </div>
          }
          secondSection={
            <Button
              label={translation[siteLang].save}
              buttonType="submit"
              size="lg"
              styleType="primary"
              disabled={
                isFetching || updatingModule.submissionState.isSubmitting
              }
              isLoading={updatingModule.submissionState.isSubmitting}
            />
          }
        />
        <SectionsWrapper isSeparated={true} fixedWidth={true}>
          {isFetching ? (
            <div style={{ width: "fit-content", margin: "100px auto 0" }}>
              <LoadingSpinner type="circle" />
            </div>
          ) : (
            <>
              {duplicateID ? (
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].duplicate}</h3>
                  </div>
                  <div className="box_content">
                    <p style={{ margin: 0 }}>
                      {translation[siteLang].duplicate_notification}{" "}
                      {duplicateID}
                    </p>
                  </div>
                </BoxWrapper>
              ) : (
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].basic_info}</h3>
                  </div>
                  <div className="box_content">
                    <div className="box_row">
                      <div>
                        <div className="box_section">
                          <InputField
                            label={`${translation[siteLang].name} ${translation[siteLang].en}`}
                            type="text"
                            props={register(
                              "title_en",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.title_en?.message}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={`${translation[siteLang].name} ${translation[siteLang].ar}`}
                            type="text"
                            props={register(
                              "title_ar",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.title_ar?.message}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={`${translation[siteLang].description} ${translation[siteLang].en}`}
                            type="text"
                            props={register(
                              "body_en",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.body_en?.message}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={`${translation[siteLang].description} ${translation[siteLang].ar}`}
                            type="text"
                            props={register(
                              "body_ar",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.body_ar?.message}
                          />
                        </div>
                      </div>
                      <div style={{ width: "100%" }}>
                        <div className="box_section">
                          <InputField
                            label={translation[siteLang].link}
                            type="text"
                            props={register(
                              "url",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.url?.message}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </BoxWrapper>
              )}
            </>
          )}
        </SectionsWrapper>
      </UpdateModulePage>
    </MainContent>
  );
};

export default UpdateNotifications;
