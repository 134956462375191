import styled from "styled-components";

const Wrapper = styled.div<{ shape: "normal" | "with_icon" }>`
  ${(props) => (props.shape === "with_icon" ? `margin: -10px;` : "")}
  label {
    margin: 0;    
    ${(props) =>
      props.shape === "with_icon"
        ? `padding: 10px;`
        : `
    margin-${props.theme.lang === "ar" ? "left" : "right"}: 20px;`}
  }
  .option_container {
    width: 100%;
    line-height: 22px;
    display: inline-flex;
    position: relative;
    align-items: center;
    cursor: pointer;
    input {
      margin: 0 0 -1px;
      width: 22px;
      height: 22px;
      cursor: pointer;
      ${(props) => (props.shape === "with_icon" ? "display: none;" : "")}
      &:checked + .radio_choice {
        background-color: var(--lightBlue);
        border-color: var(--blue);
        box-shadow: 0 0px 0px 1px var(--blue);
        .check_icon {
          opacity: 1;
        }
      }
    }
    .radio_choice {
      width: 110px;
      height: 110px;
      border: 1px solid var(--lightGrey3);
      box-shadow: 0 4px 8px 0 rgb(16 25 40 / 10%);
      border-radius: 8px;
      background-color: var(--white);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
      > svg {
        margin-bottom: 7px;
      }
      .check_icon {
        position: absolute;
        top: 0;
        ${(props) => (props.theme.lang === "ar" ? "left" : "right")}: 0;
        transform: translate(${(props) =>
          props.theme.lang === "ar" ? "-" : ""}50%, -50%);
        background-color: var(--blue);
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      span:last-child {
        font-size: 13px;
        font-weight: 700;
        text-align: center;
      }
    }
    p {
      margin: 0;
      color: var(--darkBlue);
      margin-${(props) => (props.theme.lang === "ar" ? "right" : "left")}: 5px;
      flex: 1;
    }
  }
`;

export default Wrapper;
