import { useSelector } from "react-redux";
import TableFilter from "../../components/listingPages/TableFilter";
import TablePagination from "../../components/listingPages/TablePagination";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import Modal from "../../components/ui/Modal";
import { selectSettings } from "../../store/selectors";
import { Page } from "../../utils/icons";
import translation from "../../utils/translation";
import Wrapper from "./styles";
import { ViewListingPageType } from "./utils";

const ViewListingPage = ({
  children,
  isFetching,
  tableFilter,
  tablePagination,
  hasNoResults,
  deletingState,
  onDeleteRowsHandler,
  onCancelRequestDeleteHandler,
  hasNoPagination = false,
}: ViewListingPageType) => {
  const { siteLang } = useSelector(selectSettings);

  return (
    <>
      {(tableFilter?.hasFilter ||
        tableFilter?.hasSearch ||
        tableFilter?.hasSorting) && <TableFilter {...tableFilter} />}
      {deletingState.ids.length > 0 && (
        <Modal
          maxWidth="400px"
          showFooter={true}
          showCloseIcon={true}
          onCloseHandler={onCancelRequestDeleteHandler}
          footerButtonsDirection="center"
          footerButtons={[
            {
              label: translation[siteLang].cancel,
              styleType: "secondary",
              size: "lg",
              buttonType: "button",
              onClick: onCancelRequestDeleteHandler,
              disabled: isFetching,
            },
            {
              label: translation[siteLang].delete,
              styleType: "danger",
              size: "lg",
              buttonType: "button",
              onClick: onDeleteRowsHandler,
              disabled: isFetching,
              isLoading: isFetching,
            },
          ]}
        >
          <p
            style={{
              margin: "20px 0",
              textAlign: "center",
              fontSize: "18px",
              fontWeight: 700,
            }}
          >
            {translation[siteLang].sure_to_delete}
          </p>
        </Modal>
      )}
      <Wrapper>
        {isFetching && (
          <LoadingSpinner className="loading_spinner" type="dots" />
        )}
        {children}
        {!isFetching && hasNoResults && (
          <div className="page_listing_no_results">
            <Page width="70" height="70" color="#878c93" />
            <p>{translation[siteLang].listing_no_results}</p>
          </div>
        )}
        {!isFetching && !hasNoResults && !hasNoPagination && (
          <TablePagination {...tablePagination} />
        )}
      </Wrapper>
    </>
  );
};

export default ViewListingPage;
