import { useCallback, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { Arrow, Close } from "../../../utils/icons";
import Button from "../Button";
import { Overlay } from "../Dropdown/styles";
import Wrapper from "./styles";
import { ModalType } from "./utils";

const defaultAnimationState = {
  transform: "translate(-50%, calc(-50% + 100px))",
  opacity: 0,
};

const endAnimationState = {
  transform: "translate(-50%, -50%)",
  opacity: 1,
};

const Modal = ({
  children,
  showHeader = false,
  headerTitle,
  showCloseIcon = false,
  onCloseHandler,
  showBackHeaderButton,
  onBackHandler,
  showFooter = false,
  footerButtons,
  footerButtonsDirection = "end",
  maxWidth,
  as = "div",
  onSubmit,
}: ModalType) => {
  const modalWidget = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modalWidget.current) {
      modalWidget.current.animate([defaultAnimationState, endAnimationState], {
        duration: 500,
        easing: "cubic-bezier(.23,1,.32,1)",
        fill: "forwards",
      });
    }
  }, []);

  const onAnimateCloseHandler = useCallback(
    (e) => {
      if (modalWidget.current && (onCloseHandler || onBackHandler)) {
        modalWidget.current.animate(
          [endAnimationState, defaultAnimationState],
          {
            duration: 200,
            easing: "cubic-bezier(.23,1,.32,1)",
            fill: "forwards",
          }
        );
        setTimeout(() => {
          if (onBackHandler) onBackHandler(e);
          else if (onCloseHandler) onCloseHandler(e);
        }, 200);
      }
    },
    [onCloseHandler, onBackHandler]
  );

  return createPortal(
    <Wrapper
      footerButtonsDirection={footerButtonsDirection}
      as={as}
      onSubmit={onSubmit}
    >
      <div className="modal" ref={modalWidget} style={{ maxWidth }}>
        {showHeader && (
          <div className="modal_header">
            <div className="modal_header__title">
              {showBackHeaderButton && (
                <Button
                  buttonType="button"
                  size="lg"
                  styleType="icon"
                  onClick={onBackHandler ? onAnimateCloseHandler : () => {}}
                  icon={<Arrow height="27" width="27" color="#101928" />}
                />
              )}
              {headerTitle && <p>{headerTitle}</p>}
            </div>
            {showCloseIcon && (
              <Button
                buttonType="button"
                size="lg"
                styleType="icon"
                onClick={onCloseHandler ? onAnimateCloseHandler : () => {}}
                icon={
                  <Close
                    height="27"
                    width="27"
                    color="#101928"
                    extraStyle={{ strokeWidth: 2 }}
                  />
                }
              />
            )}
          </div>
        )}
        <div className="modal_body">{children}</div>
        {showFooter && (
          <div className="modal_footer">
            {footerButtons?.map((button, index) => (
              <Button key={index} {...button} />
            ))}
          </div>
        )}
      </div>
      <Overlay className="overlay" />
    </Wrapper>,
    document.getElementById("portalRoot") as HTMLElement
  );
};

export default Modal;
