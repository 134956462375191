import { HTMLAttributes } from "react";
import Wrapper from "./styles";

const LoadingSpinner = ({
  type,
  ...props
}: { type: "dots" | "circle" } & HTMLAttributes<HTMLDivElement>) => {
  return (
    <Wrapper {...props}>
      {type === "dots" && (
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
      {type === "circle" && (
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
    </Wrapper>
  );
};

export default LoadingSpinner;
