import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import InputCheckbox from "../../components/ui/Inputs/InputCheckbox";
import Table from "../../components/ui/Table";
import MainContent from "../../containers/MainContent";
import ViewListingPage from "../../containers/ViewListingPage";
import useListingData from "../../hooks/useListingData";
import { selectSettings, selectUser } from "../../store/selectors";
import { CalendarIcon, Close, Delete } from "../../utils/icons";
import translation from "../../utils/translation";
import MainHeader from "../../containers/MainHeader";
import MainSidebar from "../../containers/MainSidebar";
import { useParams } from "react-router-dom";
import { formatDate, formatMoney } from "../../utils/formatter";
import DropdownCalendar from "../../components/ui/Dropdown/DropdownCalendar";
import * as dateFns from "date-fns";

const ListAdditives = () => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { branch_id: userBranchID, permissions: userPermissions },
  } = useSelector(selectUser);
  const { workerName, workerID } = useParams();

  const {
    data,
    extraFetchedData,
    onRequestDeleteHandler,
    onSelectTableRowHandler,
    isAllRowsSelected,
    showMultipleDelete,
    ...rest
  } = useListingData({
    moduleName: "additives",
    moduleType: userBranchID ? "branch" : "admin",
    queries: `where=worker_id=${workerID}`,
  });

  return (
    <>
      <MainHeader />
      <MainSidebar />
      <MainContent>
        <PageTitle
          firstSection={
            <>
              <span style={{ color: "#878c93", fontWeight: "500" }}>
                {workerName}
              </span>
              <h1>
                {[
                  translation[siteLang][
                    siteLang === "ar" ? "list" : "additives"
                  ],
                  " ",
                  translation[siteLang][
                    siteLang === "ar" ? "additives" : "list"
                  ].toLowerCase(),
                ]}
              </h1>
            </>
          }
          secondSection={
            <>
              <Button
                styleType="secondary"
                size="lg"
                label={[
                  translation[siteLang].back_to,
                  translation[siteLang].workers.toLowerCase(),
                ].join(" ")}
                buttonType="link"
                url={`/workers`}
              />
              {userPermissions?.additives?.create && (
                <Button
                  styleType="primary"
                  size="lg"
                  label={[
                    translation[siteLang][
                      siteLang === "ar" ? "additive" : "new"
                    ],
                    translation[siteLang][
                      siteLang === "ar" ? "new" : "additive"
                    ].toLowerCase(),
                  ].join(" ")}
                  buttonType="link"
                  url={`/workers/additives/${workerName}/${workerID}/new`}
                />
              )}
            </>
          }
        />
        <ViewListingPage
          {...rest}
          hasNoResults={data.length === 0}
          tableFilter={{
            showMultipleDelete,
            onRequestDeleteHandler,
            hasSearch: true,
            search: {
              ...rest.search,
              placeholder:
                translation[siteLang].search_all +
                " " +
                translation[siteLang].additives.toLowerCase(),
            },
            extraFilters: (
              <div style={{ minWidth: "220px", display: "flex" }}>
                <DropdownCalendar
                  button={{
                    size: "lg",
                    styleType: "secondary",
                    label:
                      rest.filter.filterData["created_at~"] !== "__default" &&
                      rest.filter.filterData["created_at~"] !== undefined
                        ? formatDate(
                            rest.filter.filterData["created_at~"],
                            siteLang
                          )
                        : translation[siteLang].date,
                    icon: (
                      <CalendarIcon width="20" height="20" color="#a2a6ab" />
                    ),
                    iconPosition: "start",
                    extraStyle: {
                      borderRadius: "0 3px 3px 0",
                    },
                  }}
                  onChange={(value) => {
                    rest.filter.onChangeFilterHandler({
                      "created_at~": dateFns.format(
                        value as Date,
                        "yyyy-MM-dd"
                      ),
                    });
                  }}
                  defaultValue={new Date()}
                  openDirection="end"
                  noAutoCollapse={true}
                />
                {rest.filter.filterData["created_at~"] !== "__default" &&
                  rest.filter.filterData["created_at~"] !== undefined && (
                    <Button
                      size="lg"
                      styleType="secondary"
                      icon={
                        <Close
                          width="20"
                          height="20"
                          color="#101928"
                          extraStyle={{ width: "20" }}
                        />
                      }
                      buttonType="button"
                      onClick={() =>
                        rest.filter.onChangeFilterHandler({
                          "created_at~": "__default",
                        })
                      }
                      extraStyle={{
                        minWidth: "45px",
                        height: "46px",
                        borderRadius: "3px 0 0 3px",
                        marginRight: "-1px",
                      }}
                    />
                  )}
              </div>
            ),
          }}
        >
          <Table
            isHeaderFixed={true}
            headings={[
              ...(data.length > 0 && userPermissions?.additives?.delete
                ? [
                    {
                      label: (
                        <InputCheckbox
                          checkboxStyle="checkbox"
                          props={{
                            onChange: () => onSelectTableRowHandler(0, true),
                            checked: isAllRowsSelected,
                            style: {
                              marginTop: "7px",
                              width: "15px",
                              height: "15px",
                            },
                          }}
                        />
                      ),
                      style: {
                        width: "50px",
                      },
                    },
                  ]
                : []),
              {
                label: translation[siteLang].name,
              },
              {
                label: translation[siteLang].order_id,
              },
              {
                label: translation[siteLang].value,
              },
              {
                label: translation[siteLang].time,
              },
              ...(userPermissions?.additives?.delete
                ? [
                    {
                      label: translation[siteLang].actions,
                      style: {
                        width: "80px",
                      },
                    },
                  ]
                : []),
            ]}
            rows={data.map((el) => ({
              link: userPermissions?.additives?.update
                ? `/workers/additives/${workerName}/${workerID}/edit/${el.id}`
                : "",
              columns: [
                ...(userPermissions?.additives?.delete
                  ? [
                      {
                        link_not_applied: true,
                        value: (
                          <InputCheckbox
                            checkboxStyle="checkbox"
                            props={{
                              onChange: () => onSelectTableRowHandler(el.id),
                              checked: el.table_selected,
                              style: {
                                marginTop: "7px",
                                width: "15px",
                                height: "15px",
                              },
                            }}
                          />
                        ),
                      },
                    ]
                  : []),
                {
                  value: el.note,
                },
                {
                  value: el.order_id,
                },
                {
                  value: formatMoney(el.value, siteLang),
                },
                {
                  value: formatDate(el.created_at, siteLang, true),
                },
                ...(userPermissions?.additives?.delete
                  ? [
                      {
                        link_not_applied: true,
                        value: (
                          <div className="page_listing_table_actions">
                            <Button
                              icon={
                                <Delete
                                  width="15"
                                  height="15"
                                  color="#101928"
                                />
                              }
                              buttonType="button"
                              styleType="secondary"
                              onClick={() => onRequestDeleteHandler([el.id])}
                              size="md"
                              className="delete"
                            />
                          </div>
                        ),
                      },
                    ]
                  : []),
              ],
            }))}
          />
        </ViewListingPage>
      </MainContent>
    </>
  );
};

export default ListAdditives;
