import { Route, Routes } from "react-router-dom";
import ListOrders from "./ListOrders";
import CreateOrders from "./CreateOrders";
import UpdateOrders from "./UpdateOrders";
import ViewOrder from "./ViewOrder";
import PrintOrder from "./PrintOrder";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/selectors";

const Orders = () => {
  const {
    user: { permissions: userPermissions },
  } = useSelector(selectUser);

  return (
    <Routes>
      {userPermissions?.orders?.view && (
        <Route path="print/:orderID" element={<PrintOrder />} />
      )}
      {userPermissions?.orders?.view && (
        <Route path="view/:orderID" element={<ViewOrder />} />
      )}
      {userPermissions?.orders?.update && (
        <Route path="edit/:orderID" element={<UpdateOrders />} />
      )}
      {userPermissions?.orders?.create && (
        <Route path="new" element={<CreateOrders />} />
      )}
      {userPermissions?.orders?.view && (
        <Route index element={<ListOrders />} />
      )}
    </Routes>
  );
};

export default Orders;
