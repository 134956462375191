import { useSelector } from "react-redux";
import {
  FilterWidgetSection,
  Product,
} from "../../components/listingPages/ListingComponents";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import InputCheckbox from "../../components/ui/Inputs/InputCheckbox";
import InputSelect from "../../components/ui/Inputs/InputSelect";
import Table from "../../components/ui/Table";
import MainContent from "../../containers/MainContent";
import ViewListingPage from "../../containers/ViewListingPage";
import useListingData from "../../hooks/useListingData";
import { selectSettings, selectUser } from "../../store/selectors";
import { getAllBranches, refill } from "../../utils/fetchers";
import { formatMoney } from "../../utils/formatter";
import { Delete, Edit } from "../../utils/icons";
import translation from "../../utils/translation";
import MainHeader from "../../containers/MainHeader";
import MainSidebar from "../../containers/MainSidebar";
import { useCallback, useMemo } from "react";
import useFilterListing from "../../hooks/useFilterListing";
import { useParams } from "react-router-dom";
import useRefillItem, { RefillModal } from "../../hooks/useRefillItem";

const filterDefaultValues: any = {
  "branch_id=": "__default",
  "stock_availability=": "__default",
};

const ListStocks = ({ type = "listing" }: { type?: "refill" | "listing" }) => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { branch_id: userBranchID, permissions: userPermissions },
  } = useSelector(selectUser);

  let { branchName, branchID } = useParams();
  if (userBranchID) branchID = userBranchID;

  const extraFetchers = useMemo(
    () => [
      ...(!userBranchID && type === "listing"
        ? [
            {
              function: getAllBranches,
              returnName: "branches",
            },
          ]
        : []),
    ],
    [userBranchID, type]
  );

  const {
    data,
    extraFetchedData,
    onRequestDeleteHandler,
    onSelectTableRowHandler,
    isAllRowsSelected,
    showMultipleDelete,
    setListingData,
    ...rest
  } = useListingData({
    moduleName: "stocks",
    moduleType: userBranchID ? "branch" : "admin",
    queries: userBranchID
      ? ""
      : `with=branch${type === "refill" ? `&where=branch_id=${branchID}` : ""}`,
    extraFetchers,
    number: "all",
  });

  const { handleSubmit, register, reset, filterFieldsValues } =
    useFilterListing(filterDefaultValues);

  const onRefillSelected = useCallback(async () => {
    setListingData((current) => ({ ...current, isFetching: true }));
    await refill("stock", {
      stocks:
        data
          ?.filter(
            (el: any) => el.table_selected && +el.quantity < +el.max_quantity
          )
          .map((el: any) => el.id) || [],
    })
      .then(() => {
        setListingData((current) => ({
          ...current,
          data: current.data.map((el) => ({
            ...el,
            quantity: el.table_selected ? el.max_quantity : el.quantity,
            table_selected: false,
          })),
          isFetching: false,
        }));
      })
      .catch(() => {});
  }, [data, setListingData]);

  const refillStocks = useRefillItem({
    type: "stock",
    setListingData,
    branchID: branchID || userBranchID,
  });

  return (
    <>
      {refillStocks.isActiveToRefill !== -1 && (
        <RefillModal refillStocks={refillStocks} />
      )}
      <MainHeader />
      <MainSidebar />
      <MainContent
        {...(userBranchID && userPermissions?.stocks?.update
          ? {
              hasContentSidebar: true,
              contentSidebarSections: [
                {
                  title: translation[siteLang].stocks,
                  links: [
                    {
                      label: translation[siteLang].stocks,
                      url: "/stocks",
                    },
                    {
                      label: translation[siteLang].refill,
                      url: "/branches/refill-stocks",
                    },
                  ],
                },
              ],
            }
          : { hasContentSidebar: false })}
      >
        <PageTitle
          firstSection={
            <>
              {type === "refill" && (
                <span style={{ color: "#878c93", fontWeight: "500" }}>
                  {branchName}
                </span>
              )}
              <h1>
                {[
                  translation[siteLang][siteLang === "ar" ? "list" : "stocks"],
                  ...(type === "refill"
                    ? [" ", translation[siteLang].refill.toLocaleLowerCase()]
                    : []),
                  " ",
                  translation[siteLang][
                    siteLang === "ar" ? "stocks" : "list"
                  ].toLowerCase(),
                ]}
              </h1>
            </>
          }
          secondSection={
            type === "refill" ? (
              userBranchID ? null : (
                <Button
                  styleType="secondary"
                  size="lg"
                  label={[
                    translation[siteLang].back_to,
                    translation[siteLang].branches.toLowerCase(),
                  ].join(" ")}
                  buttonType="link"
                  url={`/branches`}
                />
              )
            ) : userPermissions?.stocks?.create ? (
              <Button
                styleType="primary"
                size="lg"
                label={[
                  translation[siteLang][siteLang === "ar" ? "stock" : "new"],
                  translation[siteLang][
                    siteLang === "ar" ? "new" : "stock"
                  ].toLowerCase(),
                ].join(" ")}
                buttonType="link"
                url="/stocks/new"
              />
            ) : null
          }
        />
        <ViewListingPage
          {...rest}
          hasNoResults={data.length === 0}
          tableFilter={{
            ...(type === "listing"
              ? {
                  showMultipleDelete,
                  onRequestDeleteHandler,
                  hasSearch: true,
                  search: {
                    ...rest.search,
                    placeholder:
                      translation[siteLang].search_all +
                      " " +
                      translation[siteLang].stocks.toLowerCase(),
                  },
                  hasSorting: true,
                  sorting: {
                    ...rest.sort,
                    options: [
                      {
                        label: `${translation[siteLang].default}`,
                        value: "id__desc",
                      },
                      {
                        label: `${translation[siteLang].quantity} ↑`,
                        value: "quantity__asc",
                      },
                      {
                        label: `${translation[siteLang].quantity} ↓`,
                        value: "quantity__desc",
                      },
                      {
                        label: `${translation[siteLang].left_over} ↑`,
                        value: "left_over__asc",
                      },
                      {
                        label: `${translation[siteLang].left_over} ↓`,
                        value: "left_over__desc",
                      },
                      {
                        label: `${translation[siteLang].usage} ↑`,
                        value: "usage__asc",
                      },
                      {
                        label: `${translation[siteLang].usage} ↓`,
                        value: "usage__desc",
                      },
                    ],
                  },
                  filter: {
                    ...rest.filter,
                    formSubmission: handleSubmit,
                    reset,
                    filterDefaultValues,
                    filterContent: (
                      <div>
                        {!userBranchID && (
                          <FilterWidgetSection>
                            <InputSelect
                              label={translation[siteLang].branch}
                              options={[
                                {
                                  options: [
                                    {
                                      label: translation[siteLang].all_branches,
                                      value: "__default",
                                    },
                                    ...(extraFetchedData?.branches?.map(
                                      (el: any) => ({
                                        label: el.name,
                                        value: el.id.toString(),
                                      })
                                    ) || []),
                                  ],
                                },
                              ]}
                              props={{
                                ...register("branch_id="),
                                value: filterFieldsValues["branch_id="],
                              }}
                            />
                          </FilterWidgetSection>
                        )}
                        <FilterWidgetSection>
                          <InputSelect
                            label={translation[siteLang].available}
                            options={[
                              {
                                options: [
                                  {
                                    label: translation[siteLang].default,
                                    value: "__default",
                                  },
                                  {
                                    label: translation[siteLang].available,
                                    value: "1",
                                  },
                                  {
                                    label: translation[siteLang].not_available,
                                    value: "0",
                                  },
                                ],
                              },
                            ]}
                            props={{
                              ...register("stock_availability="),
                              value: filterFieldsValues["stock_availability="],
                            }}
                          />
                        </FilterWidgetSection>
                      </div>
                    ),
                  },
                }
              : {
                  extraFiltersFullWidth: true,
                  extraFilters: (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ margin: 0, color: "#101928" }}>
                        {translation[siteLang].refill_note}
                      </p>
                      <Button
                        styleType="primary"
                        size="md"
                        label={translation[siteLang].refill_selected}
                        buttonType="button"
                        onClick={onRefillSelected}
                        extraStyle={{
                          height: "45px",
                          visibility: showMultipleDelete ? "visible" : "hidden",
                        }}
                      />
                    </div>
                  ),
                }),
            hasFilter: true,
          }}
        >
          <Table
            isHeaderFixed={true}
            headings={[
              ...(data.length > 0 &&
              userPermissions?.stocks?.[type === "refill" ? "update" : "delete"]
                ? [
                    {
                      label: (
                        <InputCheckbox
                          checkboxStyle="checkbox"
                          props={{
                            onChange: () => onSelectTableRowHandler(0, true),
                            checked: isAllRowsSelected,
                            style: {
                              marginTop: "7px",
                              width: "15px",
                              height: "15px",
                            },
                          }}
                        />
                      ),
                      style: {
                        width: "50px",
                      },
                    },
                  ]
                : []),
              {
                label: translation[siteLang].stock,
                style: {
                  width: type === "refill" ? "40%" : "30%",
                },
              },
              ...(type === "refill"
                ? [
                    {
                      label:
                        translation[siteLang].quantity +
                        " (" +
                        translation[siteLang].available +
                        ")",
                    },
                    {
                      label:
                        translation[siteLang].quantity +
                        " (" +
                        translation[siteLang].minimum +
                        ")",
                    },
                    {
                      label:
                        translation[siteLang].quantity +
                        " (" +
                        translation[siteLang].maximum +
                        ")",
                    },
                    ...(userPermissions?.branches?.update
                      ? [
                          {
                            label: translation[siteLang].refill,
                            style: {
                              width: "80px",
                            },
                          },
                        ]
                      : []),
                  ]
                : [
                    {
                      label: translation[siteLang].quantity,
                    },
                    {
                      label: translation[siteLang].available,
                    },
                    {
                      label: translation[siteLang].multi_use,
                    },
                    {
                      label: translation[siteLang].usage,
                    },
                    {
                      label: translation[siteLang].left_over,
                    },
                    ...(!userBranchID
                      ? [
                          {
                            label: translation[siteLang].branch,
                            style: {
                              width: "20%",
                            },
                          },
                        ]
                      : []),
                    ...(userPermissions?.stocks?.delete
                      ? [
                          {
                            label: translation[siteLang].actions,
                            style: {
                              width: "80px",
                            },
                          },
                        ]
                      : []),
                  ]),
            ]}
            rows={data.map((el) => ({
              link:
                userPermissions?.stocks?.update && type === "listing"
                  ? `/stocks/edit/${el.id}`
                  : "",
              columns: [
                ...(userPermissions?.stocks?.[
                  type === "refill" ? "update" : "delete"
                ]
                  ? [
                      {
                        link_not_applied: true,
                        value: (
                          <InputCheckbox
                            checkboxStyle="checkbox"
                            props={{
                              onChange: () => onSelectTableRowHandler(el.id),
                              checked: el.table_selected,
                              style: {
                                marginTop: "7px",
                                width: "15px",
                                height: "15px",
                              },
                            }}
                          />
                        ),
                      },
                    ]
                  : []),
                {
                  value: (
                    <Product
                      image={el.image}
                      name={el.name}
                      info={
                        el.description
                          ? el.description?.substring(0, 15) +
                            (el.description?.length > 15 ? "..." : "")
                          : ""
                      }
                      price={el.price ? formatMoney(+el.price, siteLang) : ""}
                    />
                  ),
                },
                ...(type === "refill"
                  ? [
                      {
                        value: el.quantity,
                      },
                      {
                        value: el.min_quantity,
                      },
                      {
                        value: el.max_quantity,
                      },
                      ...(userPermissions?.branches?.update
                        ? [
                            {
                              link_not_applied: true,
                              value: (
                                <div className="page_listing_table_actions">
                                  <Button
                                    icon={
                                      <Edit
                                        width="15"
                                        height="15"
                                        color="#101928"
                                      />
                                    }
                                    buttonType="button"
                                    styleType="secondary"
                                    onClick={() =>
                                      refillStocks.onSetRefillToSubmit(
                                        el.id,
                                        el.quantity
                                      )
                                    }
                                    size="md"
                                  />
                                </div>
                              ),
                            },
                          ]
                        : []),
                    ]
                  : [
                      {
                        value: el.quantity,
                      },
                      {
                        value: el.stock_availability,
                      },
                      {
                        value: el.multi_use,
                      },
                      {
                        value: el.usage,
                      },
                      {
                        value: el.left_over,
                      },
                      ...(!userBranchID
                        ? [
                            {
                              value: el.branch.name,
                            },
                          ]
                        : []),
                      ...(userPermissions?.stocks?.delete
                        ? [
                            {
                              link_not_applied: true,
                              value: (
                                <div className="page_listing_table_actions">
                                  <Button
                                    icon={
                                      <Delete
                                        width="15"
                                        height="15"
                                        color="#101928"
                                      />
                                    }
                                    buttonType="button"
                                    styleType="secondary"
                                    onClick={() =>
                                      onRequestDeleteHandler([el.id])
                                    }
                                    size="md"
                                    className="delete"
                                  />
                                </div>
                              ),
                            },
                          ]
                        : []),
                    ]),
              ],
            }))}
          />
        </ViewListingPage>
      </MainContent>
    </>
  );
};

export default ListStocks;
