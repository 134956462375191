import styled from "styled-components";

export const Wrapper = styled.div`
  overflow-y: auto;
  padding: 10px 10px 20px;
  .invoice_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 -10px;
    border-bottom: 1px solid var(--black);
    color: var(--black);
    padding-bottom: 10px;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  .invoice_col {
    padding: 0 10px;
    font-weight: 500;
    &:last-child:not(:first-child) {
      white-space: nowrap;
    }
    &.full {
      width: 100%;
      text-align: center;
      font-weight: 700;
    }
    p {
      margin: 0;
    }
  }
`;
