import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Table from "../../components/ui/Table";
import MainContent from "../../containers/MainContent";
import ViewListingPage from "../../containers/ViewListingPage";
import useListingData from "../../hooks/useListingData";
import { selectSettings, selectUser } from "../../store/selectors";
import translation from "../../utils/translation";
import MainHeader from "../../containers/MainHeader";
import MainSidebar from "../../containers/MainSidebar";
import Button from "../../components/ui/Button";

const ListNotifications = () => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { permissions: userPermissions },
  } = useSelector(selectUser);

  const { data, extraFetchedData, ...rest } = useListingData({
    moduleName: "notification_messages",
    moduleType: "admin",
    queries: "where=internal=0",
  });

  return (
    <>
      <MainHeader />
      <MainSidebar />
      <MainContent
        {...(userPermissions?.app_settings?.view ||
        userPermissions?.support_forms?.view ||
        userPermissions?.coupons?.view ||
        userPermissions?.banners?.view ||
        userPermissions?.support_reasons?.view ||
        userPermissions?.referal_codes?.view ||
        userPermissions?.cancellation_reasons?.view
          ? {
              hasContentSidebar: true,
              contentSidebarSections: [
                {
                  title: translation[siteLang].settings,
                  links: [
                    ...(userPermissions?.app_settings?.view
                      ? [
                          {
                            label: translation[siteLang].app_settings,
                            url: "/settings",
                          },
                        ]
                      : []),
                    ...(userPermissions?.support_forms?.view
                      ? [
                          {
                            label: translation[siteLang].support_tickets,
                            url: "/settings/tickets",
                          },
                        ]
                      : []),
                    {
                      label: translation[siteLang].notifications,
                      url: "/settings/notifications",
                    },
                    ...(userPermissions?.coupons?.view
                      ? [
                          {
                            label: translation[siteLang].coupons,
                            url: "/settings/coupons",
                          },
                        ]
                      : []),
                    ...(userPermissions?.banners?.view
                      ? [
                          {
                            label: translation[siteLang].banners,
                            url: "/settings/banners",
                          },
                        ]
                      : []),
                    ...(userPermissions?.referal_codes?.view
                      ? [
                          {
                            label: translation[siteLang].referrals,
                            url: "/settings/referrals",
                          },
                        ]
                      : []),
                    ...(userPermissions?.support_reasons?.view
                      ? [
                          {
                            label: translation[siteLang].support_reasons,
                            url: "/settings/support-reasons",
                          },
                        ]
                      : []),
                    ...(userPermissions?.cancellation_reasons?.view
                      ? [
                          {
                            label: translation[siteLang].cancel_reasons,
                            url: "/settings/cancel-reasons",
                          },
                        ]
                      : []),
                  ],
                },
              ],
            }
          : {
              hasContentSidebar: false,
            })}
      >
        <PageTitle
          firstSection={
            <h1>
              {[
                translation[siteLang][
                  siteLang === "ar" ? "list" : "notifications"
                ],
                " ",
                translation[siteLang][
                  siteLang === "ar" ? "notifications" : "list"
                ].toLowerCase(),
              ]}
            </h1>
          }
          secondSection={
            <Button
              styleType="primary"
              size="lg"
              label={[
                translation[siteLang][
                  siteLang === "ar" ? "notification" : "new"
                ],
                translation[siteLang][
                  siteLang === "ar" ? "new" : "notification"
                ].toLowerCase(),
              ].join(" ")}
              buttonType="link"
              url="/settings/notifications/new"
            />
          }
        />
        <ViewListingPage
          {...rest}
          hasNoResults={data.length === 0}
          tableFilter={{
            hasSearch: true,
            search: {
              ...rest.search,
              placeholder:
                translation[siteLang].search_all +
                " " +
                translation[siteLang].notifications.toLowerCase(),
            },
          }}
        >
          <Table
            isHeaderFixed={true}
            headings={[
              {
                label: translation[siteLang].name,
              },
              {
                label: translation[siteLang].description,
              },
              {
                label: translation[siteLang].link,
              },
              {
                label: translation[siteLang].actions,
              },
            ]}
            rows={data.map((el) => ({
              columns: [
                {
                  value: el.title,
                },
                {
                  value: el.body,
                },
                {
                  value: el.url && (
                    <a href={el.url}>{translation[siteLang].link}</a>
                  ),
                },
                {
                  value: (
                    <div className="page_listing_table_actions">
                      <Button
                        label={translation[siteLang].duplicate}
                        buttonType="link"
                        url={`/settings/notifications/new?duplicate=${el.id}`}
                        styleType="secondary"
                        size="md"
                        extraStyle={{
                          padding: "0 10px",
                          minWidth: "auto",
                        }}
                      />
                    </div>
                  ),
                },
              ],
            }))}
          />
        </ViewListingPage>
      </MainContent>
    </>
  );
};

export default ListNotifications;
