import { CSSProperties } from "react";

type IconType = {
  width: string;
  height: string;
  color: string;
  extraStyle?: CSSProperties;
};

export const Dashboard = ({
  width,
  height,
  color,
  extraStyle = {},
}: IconType) => {
  return (
    <svg
      viewBox="0 0 48 48"
      width={width}
      height={height}
      fill="none"
      stroke={color}
      strokeWidth="2"
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <path d="M43 46H33a3 3 0 0 1-3-3V28c0-.6-.4-1-1-1H19c-.6 0-1 .4-1 1v15a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V24.8c0-.8.3-1.5.9-2.1l19-19c1.2-1.2 3-1.2 4.2 0l19 19c.6.6.9 1.3.9 2.1V43a3 3 0 0 1-3 3zM19 25h10a3 3 0 0 1 3 3v15c0 .6.4 1 1 1h10c.6 0 1-.4 1-1V24.8a1 1 0 0 0-.3-.7l-19-19a1 1 0 0 0-1.4 0l-19 19a1 1 0 0 0-.3.7V43c0 .6.4 1 1 1h10c.6 0 1-.4 1-1V28a3 3 0 0 1 3-3z" />
    </svg>
  );
};

export const Chevron = ({ width, height, color, extraStyle }: IconType) => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <path d="m9 18 6-6-6-6" />
    </svg>
  );
};

export const Check = ({ width, height, color, extraStyle }: IconType) => {
  return (
    <svg
      viewBox="0 0 48 48"
      width={width}
      height={height}
      fill="none"
      stroke={color}
      strokeWidth="3"
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <path d="M16 41.1 1.3 26.4l1.4-1.5L16 38.2 45.3 8.9l1.4 1.5z" />
    </svg>
  );
};

export const Search = ({ width, height, color, extraStyle }: IconType) => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <circle cx="10.5" cy="10.5" r="7.5" />
      <path d="m21 21-5.2-5.2" />
    </svg>
  );
};

export const Close = ({ width, height, color, extraStyle }: IconType) => {
  return (
    <svg
      viewBox="0 0 32 32"
      width={width}
      height={height}
      fill="none"
      stroke={color}
      strokeWidth="3"
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <path d="m7 7 18 18M7 25 25 7" />
    </svg>
  );
};

export const Arrow = ({ width, height, color, extraStyle }: IconType) => {
  return (
    <svg
      viewBox="0 0 32 32"
      width={width}
      height={height}
      fill={color}
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <path d="M13 26a1 1 0 0 1-.7-.3l-9-9a1 1 0 0 1 0-1.4l9-9a1 1 0 1 1 1.4 1.4L5.4 16l8.3 8.3a1 1 0 0 1 0 1.4 1 1 0 0 1-.7.3Z" />
      <path d="M28 17H4a1 1 0 0 1 0-2h24a1 1 0 0 1 0 2Z" />
    </svg>
  );
};

export const Filter = ({ width, height, color, extraStyle }: IconType) => {
  return (
    <svg
      viewBox="0 0 32 32"
      width={width}
      height={height}
      fill={color}
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <g>
        <path d="M28 9H11a1 1 0 0 1 0-2h17a1 1 0 0 1 0 2ZM7 9H4a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2ZM21 17H4a1 1 0 0 1 0-2h17a1 1 0 0 1 0 2ZM11 25H4a1 1 0 0 1 0-2h7a1 1 0 0 1 0 2Z" />
        <path d="M9 11a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1ZM23 19a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1ZM13 27a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1Z" />
        <path d="M28 17h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2ZM28 25H15a1 1 0 0 1 0-2h13a1 1 0 0 1 0 2Z" />
      </g>
    </svg>
  );
};

export const CalendarIcon = ({
  width,
  height,
  color,
  extraStyle,
}: IconType) => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill="none"
      stroke={color}
      strokeWidth={2}
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <path d="M23.3 8.2v13.2c0 1-.8 1.9-1.9 1.9H2.6c-1 0-1.9-.8-1.9-1.9V8.2h22.6zM23.3 4.7v3.5H.8V4.7c0-1 .8-1.9 1.9-1.9h18.8c.9.1 1.8.9 1.8 1.9zM6.6 4.4V.8M17.4.8v3.6" />
    </svg>
  );
};

export const Page = ({ width, height, color, extraStyle }: IconType) => {
  return (
    <svg
      viewBox="0 0 35313 29795"
      width={width}
      height={height}
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <path
        fillRule="nonzero"
        d="M552 0h34209c304 0 552 248 552 552v28690c0 304-248 552-552 552H552c-304 0-552-248-552-552V552C0 248 248 0 552 0zm17105 15171c527 0 1006 214 1353 562l3 3c347 347 562 826 562 1353s-215 1007-563 1354l1 1a1913 1913 0 0 1-1355 562c-529 0-1009-216-1355-562a1913 1913 0 0 1-562-1355c0-530 216-1009 562-1355l38-35c344-326 809-527 1318-527zm575 1340c-145-145-349-236-575-236-214 0-409 81-552 214l-23 25c-147 147-239 351-239 575s92 428 239 575 351 239 575 239 428-92 575-239l1 1 1-1c145-145 236-349 236-575 0-225-91-429-236-575l-3-3zm-575-3638c1161 0 2216 472 2978 1234l3 3c762 762 1234 1817 1234 2978 0 1163-472 2218-1234 2980s-1818 1235-2981 1235c-1160 0-2214-472-2978-1235l-3-3c-763-764-1235-1818-1235-2978 0-1163 472-2218 1235-2981 762-762 1817-1234 2980-1234zm2201 2014c-563-562-1342-910-2201-910s-1637 349-2199 911c-563 563-912 1341-912 2200 0 860 348 1639 909 2199l3 3c560 561 1339 909 2199 909 859 0 1637-349 2200-912 562-562 911-1341 911-2199 0-860-348-1638-910-2201zM7532 16732c3313-3835 6674-5714 10077-5694 3404 20 6799 1941 10179 5700 190 210 185 529 0 736l-5 5-8 8c-3325 3696-6688 5615-10087 5650-3406 35-6796-1821-10167-5670-188-214-179-533 11-737zm10073-4595c-2966-18-5947 1622-8936 4967 3037 3350 6042 4966 9010 4936 2964-31 5950-1705 8953-4933-3050-3278-6061-4952-9027-4970zm7225 15451c-304 0-552-248-552-552s248-552 552-552h5474c304 0 552 248 552 552s-248 552-552 552h-5474zm2207-2759c-304 0-552-248-552-552s248-552 552-552h5474c304 0 552 248 552 552s-248 552-552 552h-5474zM2760 7725c-304 0-552-248-552-552s248-552 552-552h5474c304 0 552 248 552 552s-248 552-552 552H2760zm2207 2759c-304 0-552-248-552-552s248-552 552-552h5474c304 0 552 248 552 552s-248 552-552 552H4967zm3311-8277h1103v1104H8278V2207zm-2759 0h1103v1104H5519V2207zm-2759 0h1103v1104H2760V2207zm31450 3311H1105v23174h33105V5518zM1105 4414h33105V1104H1105v3310z"
      />
    </svg>
  );
};

export const Edit = ({ width, height, color, extraStyle }: IconType) => {
  return (
    <svg
      viewBox="0 0 25 25"
      width={width}
      height={height}
      fill={color}
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <path d="M15.898 4.47a.75.75 0 01.977-.073l.084.073L21.53 9.04c.293.293-.066.467-.358.76-.267.266-.325.591-.618.373l-.084-.072-4.572-4.572a.75.75 0 010-1.06z"></path>
      <path d="M19.055 1.038L2.164 17.929a.739.739 0 00-.202.378l-1.126 5.63a.739.739 0 00.87.87l5.63-1.126a.739.739 0 00.377-.203l16.892-16.89a.739.739 0 000-1.046L20.1 1.038a.739.739 0 00-1.045 0zm.523 1.567l3.459 3.46-16.211 16.21-4.324.864.864-4.323 16.212-16.21z"></path>
    </svg>
  );
};

export const Delete = ({ width, height, color, extraStyle }: IconType) => {
  return (
    <svg
      viewBox="0 0 16 16"
      width={width}
      height={height}
      fill={color}
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <path d="M13.035 5.37a.5.5 0 01.462.444l.002.09-.542 7.806c-.061.88-.742 1.578-1.594 1.653l-.152.007H4.79c-.87 0-1.597-.65-1.729-1.507l-.017-.153-.542-7.806a.5.5 0 01.984-.158l.014.089.542 7.806c.026.379.305.675.652.722l.096.007h6.422a.76.76 0 00.735-.628l.013-.101.542-7.806a.5.5 0 01.534-.464zM1 4.5a.5.5 0 01-.09-.992L1 3.5h3.5V1.75c0-.647.492-1.18 1.122-1.244L5.75.5h4.5c.647 0 1.18.492 1.244 1.122l.006.128V3.5H15a.5.5 0 01.09.992L15 4.5H1zm9.25-3h-4.5a.25.25 0 00-.243.193L5.5 1.75V3.5h5V1.75a.25.25 0 00-.193-.243L10.25 1.5z"></path>
    </svg>
  );
};

export const ProductIcon = ({ width, height, color, extraStyle }: IconType) => {
  return (
    <svg
      viewBox="0 0 100 100"
      width={width}
      height={height}
      fill={color}
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <path d="M58.3 20.3v-3.7a1.5 1.5 0 0 0-1.5-1.5h-.6V6.7a1.5 1.5 0 0 0-1.5-1.5h-9.1A1.5 1.5 0 0 0 44 6.7v8.4h-.7a1.5 1.5 0 0 0-1.5 1.5v3.7a10.5 10.5 0 0 0-9.4 10.4V91a1.5 1.5 0 0 0 1.5 1.5h32.3a1.5 1.5 0 0 0 1.5-1.5V30.7a10.5 10.5 0 0 0-9.5-10.4ZM50.1 7.9a3.4 3.4 0 1 1-3.4 3.4 3.4 3.4 0 0 1 3.4-3.4ZM45 18h10.4v2.1H45Zm19.9 71.4H35.5V30.7a7.5 7.5 0 0 1 7.5-7.4h14.3a7.5 7.5 0 0 1 7.5 7.4Z" />
      <path d="M40.2 37.3a1.5 1.5 0 0 0-1.5 1.5v32.4a1.5 1.5 0 0 0 3 0V38.8a1.5 1.5 0 0 0-1.5-1.5Z" />
    </svg>
  );
};

export const Camera = ({ width, height, color, extraStyle }: IconType) => {
  return (
    <svg
      viewBox="0 0 25 23"
      width={width}
      height={height}
      fill={color}
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <path
        d="M21.072 16.002a.75.75 0 01.75.75v1.842h1.842a.75.75 0 01.743.648l.007.102a.75.75 0 01-.75.75h-1.842v1.842a.75.75 0 01-.648.743l-.102.007a.75.75 0 01-.75-.75v-1.842H18.48a.75.75 0 01-.743-.648l-.007-.102a.75.75 0 01.75-.75h1.842v-1.842a.75.75 0 01.648-.743zM14.102.45a.75.75 0 01.624.334l1.621 2.43h3.285a2.593 2.593 0 012.593 2.594v7.494a.75.75 0 11-1.5 0V5.808c0-.604-.49-1.093-1.093-1.093h-3.686a.75.75 0 01-.624-.334L13.7 1.95H8.974l-1.62 2.43a.75.75 0 01-.624.335H3.043c-.604 0-1.093.49-1.093 1.093v11.98c0 .605.49 1.094 1.093 1.094h11.691a.75.75 0 110 1.5H3.044A2.593 2.593 0 01.45 17.789V5.808a2.593 2.593 0 012.593-2.593h3.285L7.948.784A.75.75 0 018.574.45zm-2.764 5.53a5.358 5.358 0 110 10.716 5.358 5.358 0 010-10.716zm0 1.5a3.858 3.858 0 100 7.716 3.858 3.858 0 000-7.716zM4.08 5.808a1.037 1.037 0 110 2.074 1.037 1.037 0 010-2.074z"
        fillRule="evenodd"
      ></path>
    </svg>
  );
};

export const PDF = ({ width, height, color, extraStyle }: IconType) => {
  return (
    <svg
      viewBox="0 0 32 32"
      width={width}
      height={height}
      fill={color}
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <path
        fillRule="evenodd"
        d="M21 13v-3l-6-7H4a2 2 0 0 0-2 2v23c0 1.1.9 2 2 2h15a2 2 0 0 0 2-2v-2h8a3 3 0 0 0 3-3v-7a3 3 0 0 0-3-3h-8Zm-1 13v2c0 .6-.4 1-1 1H4a1 1 0 0 1-1-1V5c0-.5.4-1 1-1h10v5c0 1.1.9 2 2 2h4v2h-8a3 3 0 0 0-3 3v7a3 3 0 0 0 3 3h8ZM15 4.5V9c0 .5.5 1 1 1h3.7L15 4.5ZM12 14a2 2 0 0 0-2 2v7c0 1.1.9 2 2 2h17a2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2H12Zm13 5v-2h4v-1h-5v7h1v-3h3v-1h-3Zm-13-1v5h1v-3h2a2 2 0 1 0 0-4h-3v2Zm1-1v2h2c.6 0 1-.4 1-1s-.4-1-1-1h-2Zm5-1v7h3a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2h-3Zm1 1v5h2c.6 0 1-.4 1-1v-3c0-.6-.4-1-1-1h-2Z"
      />
    </svg>
  );
};

export const LinkIcon = ({ width, height, color, extraStyle }: IconType) => {
  return (
    <svg
      viewBox="0 0 128 128"
      width={width}
      height={height}
      fill={color}
      stroke={color}
      strokeWidth={2}
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <path d="M112.8 63c1.2.5 1.3.7 1.4 2v28.2a22.6 22.6 0 0 1-21.9 21.7c-18.8 0-37.6.2-56.4 0a22.6 22.6 0 0 1-21.7-21.7c-.2-18.9-.2-37.7 0-56.6C14.4 25.4 24.2 15 36 15h28.2s1.3.2 1.8 1.1c.6 1.2-.4 2.8-1.8 3-9.4 0-18.8-.2-28.2 0-9.3 0-17.7 8-17.8 17.8 0 18.8-.2 37.6 0 56.3.1 9.3 8 17.7 17.9 17.8 18.7 0 37.5.2 56.3 0 9.2-.1 17.7-8.1 17.8-17.9V65s.5-2.6 2.6-2Zm-.9-48h.4c1 0 1.8.9 1.9 2v32c-.1 2-4 2.8-4 0V21.6L65.6 66.3c-1.7 1.6-2.7.4-3.1-.3a2 2 0 0 1 .3-2.5l44.5-44.6H80c-1.5-.2-1.4-.6-1.7-1.2-.5-1.2.5-2.7 1.9-2.8h31.7Z" />
    </svg>
  );
};

export const Warning = ({ width, height, color, extraStyle }: IconType) => {
  return (
    <svg
      viewBox="0 0 32 32"
      width={width}
      height={height}
      fill={color}
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <path
        fillRule="evenodd"
        d="M14.3 6c.9-1.4 2.3-1.3 3.2 0l10.7 17.5c1.2 1.9.4 3.4-1.8 3.4h-21c-2.2 0-3-1.5-1.9-3.4L14.3 6.1Zm1.6 6a1 1 0 0 0-1 .9v6c0 .6.4 1 1 1 .5 0 1-.4 1-1v-6c0-.5-.5-1-1-1Zm0 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      />
    </svg>
  );
};

export const Success = ({ width, height, color, extraStyle }: IconType) => {
  return (
    <svg
      viewBox="0 0 512 512"
      width={width}
      height={height}
      fill={color}
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <path d="M256 7a249 249 0 1 0 0 498 249 249 0 0 0 0-498zm0 478A229.3 229.3 0 0 1 27 256C27 129.7 129.6 27 256 27s229 102.7 229 229-102.7 229-229 229z" />
      <path d="M384.2 158.2 216.9 325.5l-89-89-14.2 14.1 103.2 103.2 181.4-181.5z" />
    </svg>
  );
};

export const StockIcon = ({ width, height, color, extraStyle }: IconType) => {
  return (
    <svg
      viewBox="0 0 50 50"
      width={width}
      height={height}
      fill="none"
      stroke={color}
      strokeWidth={4}
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <path d="M46.3 13.9H3.7c-.3 0-.5-.2-.5-.5V6.6c0-.6.4-1 1-1h41.7c.6 0 1 .4 1 1v6.8c-.1.3-.3.5-.6.5zM42.5 44.4h-35c-.6 0-1-.4-1-1V13.9h37.1v29.5c-.1.5-.5 1-1.1 1z" />
    </svg>
  );
};

export const DotsIcon = ({ width, height, color, extraStyle }: IconType) => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={color}
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 14a2 2 0 110-4 2 2 0 010 4zm-6 0a2 2 0 110-4 2 2 0 010 4zm-8-2a2 2 0 104 0 2 2 0 00-4 0z"
      ></path>
    </svg>
  );
};

export const ServiceIcon = ({ width, height, color, extraStyle }: IconType) => {
  return (
    <svg
      viewBox="0 0 512 512"
      width={width}
      height={height}
      fill={color}
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <path d="m373.9 24-1.2-8-209.8 375.7a24 24 0 0 1-32.1 9.5l-.5-.3a47.5 47.5 0 0 0-44.7 83.6 47.3 47.3 0 0 0 64.5-18.3l55.8-100a11.7 11.7 0 0 1 16-4.5h0a13.3 13.3 0 0 0 18.2-5.2l108.4-194.2A217 217 0 0 0 373.9 24zM127.1 453.3a21 21 0 1 1-36.7-20.5 21 21 0 0 1 36.7 20.5zm132.3-169.1c-6.6 0-11.9-5.3-11.9-11.9s5.3-11.9 11.9-11.9a12 12 0 0 1 0 23.8zM444.6 425.4a47.5 47.5 0 0 0-62-19.5l-.5.3a24 24 0 0 1-31.9-10.1l-53.8-102.8-29.2 52.3 6.4 13.6c3.5 6.5 11.6 9 18.1 5.6h0c5.7-3 12.9-.9 15.9 4.9l53.7 101.2a47.4 47.4 0 1 0 83.3-45.5zm-31.5 41.8a21 21 0 0 1-19.7-37.1 21 21 0 0 1 28.4 8.7 21.1 21.1 0 0 1-8.7 28.4z" />
      <path d="m216.8 255.2 29.2-52.4L148.5 16l-1.4 8c-8.1 47.5-.3 96.3 22.3 138.8l47.4 92.4z" />
    </svg>
  );
};

export const Shampoo = ({ width, height, color, extraStyle }: IconType) => {
  return (
    <svg
      viewBox="0 0 48 48"
      width={width}
      height={height}
      fill={color}
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <path d="M36.6 18.3v-.6c0-5.7-3.7-10.5-8.8-12v-2h1.3V0H19v3.8h1.2v1.8c-4.9 1.6-8.5 6-8.8 11.4V18.9l5 29.1h15.2l5-29v-.7zM24 7.6a10.1 10.1 0 0 1 10 11.8l-.4 2H14.4L14 19v-1.2C14 12 18.3 7.6 24 7.6z" />
    </svg>
  );
};

export const Wokrer = ({ width, height, color, extraStyle }: IconType) => {
  return (
    <svg
      viewBox="0 0 32 32"
      width={width}
      height={height}
      fill={color}
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <path d="m22.8 20.6-.6-.2c-1-.3-1.8-.5-2-1.8 3-3 2.9-5.7 2.8-8.5V9a7.1 7.1 0 0 0-7-7 7.1 7.1 0 0 0-7 7v1.1c0 2.9-.1 5.5 2.7 8.5 0 1.3-.8 1.5-1.9 1.8l-.6.2C5.6 21.7 2 25 2 29a1 1 0 0 0 2 0c0-3 3-5.6 5.8-6.5l.5-.2a4.1 4.1 0 0 0 3.1-2.3 5.4 5.4 0 0 0 5.1 0 4.1 4.1 0 0 0 3.2 2.4l.5.1C25 23.4 28 26 28 29a1 1 0 0 0 2 0c0-4-3.6-7.3-7.2-8.4Zm-9.3-3c-2.6-2.6-2.6-4.7-2.5-7.4V9a5 5 0 0 1 10 0v1.2c0 2.7 0 4.8-2.5 7.3a3.6 3.6 0 0 1-5 0Z" />
    </svg>
  );
};

export const Client = ({ width, height, color, extraStyle }: IconType) => {
  return (
    <svg
      viewBox="0 0 32 32"
      width={width}
      height={height}
      fill={color}
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <g>
        <circle cx="16" cy="13" r="5" />
        <path d="M23 28a7 7 0 0 0-14 0ZM24 14a5 5 0 1 0-4-8M25 24h6a7 7 0 0 0-7-7M12 6a5 5 0 1 0-4 8M8 17a7 7 0 0 0-7 7h6" />
      </g>
    </svg>
  );
};

export const Branch = ({ width, height, color, extraStyle }: IconType) => {
  return (
    <svg
      viewBox="0 0 32 32"
      width={width}
      height={height}
      fill={color}
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <path d="m31.4 11.2-3-4a3 3 0 0 0-.4-.4V2a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v4.8a3 3 0 0 0-.4.4l-3 4A3 3 0 0 0 0 13v1a3 3 0 0 0 3 3v13c0 1.1.9 2 2 2h22a2 2 0 0 0 2-2V17a3 3 0 0 0 3-3v-1a3 3 0 0 0-.6-1.8zM26 2v4H6V2h20zM10.2 15H6l4-7h2.2l-2 7zm3-7h2.3v7h-4.3l2-7zm3.3 0h2.3l2 7h-4.3V8zm3.3 0H22l4 7h-4.2l-2-7zM2 14v-1c0-.2 0-.4.2-.6l3-4c.2-.3.5-.4.8-.4h2.9l-4 7H3a1 1 0 0 1-1-1zm18 16h-7.5V20H20v10zm7 0h-6V20c0-.6-.4-1-1-1h-7.5a1 1 0 0 0-1 1v10H5V17h22v13zm3-16c0 .6-.4 1-1 1h-1.9l-4-7H26c.3 0 .6.1.8.4l3 4 .2.6v1z" />
    </svg>
  );
};

export const Location = ({ width, height, color, extraStyle }: IconType) => {
  return (
    <svg
      viewBox="0 0 32 32"
      width={width}
      height={height}
      fill={color}
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <g>
        <path d="M16 31a3 3 0 0 1-2.2-1C10 26 4 18.4 4 13.4A12.2 12.2 0 0 1 16 1a12.2 12.2 0 0 1 12 12.4c0 5-6.1 12.5-9.8 16.6a3 3 0 0 1-2.2 1Zm0-28A10.2 10.2 0 0 0 6 13.4c0 3.1 3.5 8.8 9.3 15.3a1 1 0 0 0 1.4 0c5.8-6.5 9.3-12.2 9.3-15.3A10.2 10.2 0 0 0 16 3Z" />
        <path d="M16 19a6 6 0 1 1 6-6 6 6 0 0 1-6 6Zm0-10a4 4 0 1 0 4 4 4 4 0 0 0-4-4Z" />
      </g>
    </svg>
  );
};

export const Invoice = ({ width, height, color, extraStyle }: IconType) => {
  return (
    <svg
      viewBox="0 0 32 32"
      width={width}
      height={height}
      fill={color}
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <g>
        <path d="M23.9 30.3H7a3 3 0 0 1-3-3V4.1a3 3 0 0 1 3-3h11.6a3.6 3.6 0 0 1 2.8 1.3l4.4 5.4a4.6 4.6 0 0 1 1 2.9v16.6a3 3 0 0 1-3 3ZM7 3.1a1 1 0 0 0-1 1v23.2a1 1 0 0 0 1 1h16.9a1 1 0 0 0 1-1V10.7a2.6 2.6 0 0 0-.6-1.6l-4.5-5.5a1.6 1.6 0 0 0-1.2-.5Z" />
        <path d="M14.7 12.5h-4.1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2Zm-4.1-5v3h4v-3ZM21.3 17.9H9.6a1 1 0 1 1 0-2h11.7a1 1 0 1 1 0 2ZM21.3 21.2H9.6a1 1 0 0 1 0-2h11.7a1 1 0 0 1 0 2ZM16 24.5H9.6a1 1 0 0 1 0-2H16a1 1 0 0 1 0 2Z" />
      </g>
    </svg>
  );
};

export const Admin = ({ width, height, color, extraStyle }: IconType) => {
  return (
    <svg
      viewBox="0 0 32 32"
      width={width}
      height={height}
      fill={color}
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <path d="m22.8 20.6-.6-.2a7.3 7.3 0 0 1-1-.3 1.5 1.5 0 0 1-1-1.5c3-3 2.9-5.7 2.8-8.5V9a7.1 7.1 0 0 0-7-7 7.1 7.1 0 0 0-7 7v1.1c0 2.9-.1 5.5 2.7 8.5a1.5 1.5 0 0 1-1 1.5l-.9.3-.6.2C5.6 21.7 2 25 2 29a1 1 0 0 0 2 0c0-3 3-5.6 5.8-6.5l.5-.2a9.6 9.6 0 0 0 1.1-.3 3.4 3.4 0 0 0 2-2 6 6 0 0 0 .8.3l-1 1a1 1 0 0 0-.1 1.2l.9 1.6-1 4.7a1 1 0 0 0 2 .4l1-5a1.4 1.4 0 0 0 0-.2 1.4 1.4 0 0 0 0 .2l1 5a1 1 0 0 0 1 .8h.2a1 1 0 0 0 .8-1.2l-1-4.7 1-1.7a1 1 0 0 0-.3-1.1l-1-1a5.1 5.1 0 0 0 .9-.4 3.4 3.4 0 0 0 2 2 9.6 9.6 0 0 0 1.1.4l.5.2C25 23.4 28 26 28 29a1 1 0 0 0 2 0c0-4-3.6-7.3-7.2-8.4ZM11 10.2V9a5 5 0 0 1 10 0v1.2c0 2.7 0 4.8-2.5 7.3a3.6 3.6 0 0 1-5 0C10.9 15 10.9 13 11 10.2Zm5.1 13.3a1 1 0 0 0-.1.5 1 1 0 0 0-.1-.5l-.7-1.3.8-.8.8.8Z" />
    </svg>
  );
};

export const CogIcon = ({ width, height, color, extraStyle }: IconType) => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={color}
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <path d="M22.3 9.1 20.6 9c0-.3-.1-.7-.3-1l1.2-1.5c.6-.7.6-1.8 0-2.5l-1-1c-.8-.7-1.8-.7-2.5-.1L16.5 4l-1-.5-.2-1.6c-.1-1-1-1.4-1.9-1.4H12c-1 0-1.8.5-1.9 1.4L10 3.7 9 4 7.4 2.8c-.7-.6-1.8-.6-2.5.1L4 4c-.7.7-.7 1.8-.1 2.5L5 8l-.5 1-2 .2c-1 .1-2 .9-2 1.9v1.4c0 1 1 1.8 2 1.9l2 .1c0 .4.2.8.4 1.1L3.7 17c-.6.7-.5 1.8.2 2.4l1 1c.7.7 1.8.8 2.5.2L9 19.4l1 .4.2 2c.1 1 .9 1.7 1.9 1.7h1.4c1 0 1.8-.8 1.9-1.7l.1-2c.4 0 .8-.2 1.1-.4l1.5 1.2c.7.6 1.8.5 2.4-.1l1-1c.7-.7.8-1.8.2-2.5l-1.2-1.5.4-1 1.5-.2c1-.1 1.2-1 1.2-1.9V11c0-1-.3-1.8-1.2-1.9zm.2 3.3c0 .3 0 .6-.2.6l-2.1.3c-.2 0-.3 0-.4.3v.1l-.7 1.6v.7l1.5 1.7c.2.3.2.6 0 .9l-1 1c-.3.2-.6.2-.9 0L17 18.2a.6.6 0 0 0-.7 0l-1.6.6c-.2 0-.4.3-.4.5l-.3 2.5c0 .3-.3.7-.6.7H12c-.4 0-.6-.4-.7-.7l-.2-2.4c0-.2-.1-.4-.3-.5l-.2-.1-1.5-.6a.6.6 0 0 0-.7 0l-1.8 1.4c-.2.2-.6.2-.8 0l-1-1a.6.6 0 0 1 0-.9L6.1 16a.6.6 0 0 0 0-.7c-.3-.6-.5-1-.6-1.6 0-.2-.3-.4-.5-.4L2.4 13c-.3 0-.9-.3-.9-.6V11c0-.4.6-.6 1-.7l2.4-.2c.2 0 .4-.1.5-.3l.2-.2.6-1.5v-.7L4.7 5.6a.6.6 0 0 1 0-.8l1.1-1c.2-.3.6-.3.8 0l1.8 1.4a.6.6 0 0 0 .7 0c.5-.3 1-.5 1.6-.6.2 0 .4-.3.4-.5l.2-2.2c0-.3.3-.4.7-.4h1.4c.3 0 .6.1.6.4l.3 2.2c0 .2 0 .4.3.5h.1l1.6.6c.2.2.5.1.7 0l1.7-1.5c.3-.2.6-.1.9 0l1 1.1c.2.2.2.6 0 .8l-1.4 1.8a.6.6 0 0 0 0 .7l.6 1.6c0 .2.3.4.5.4l2 .2c.3 0 .2.3.2.7v1.4z" />
      <path d="M12.7 7.4a4.3 4.3 0 1 0 0 8.6 4.3 4.3 0 0 0 0-8.6zm0 7.4a3 3 0 1 1 0-6.2 3 3 0 0 1 0 6.2z" />
    </svg>
  );
};

export const Plus = ({ width, height, color, extraStyle }: IconType) => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <path d="M12 5v14M5 12h14" />
    </svg>
  );
};

export const Notification = ({
  width,
  height,
  color,
  extraStyle,
}: IconType) => {
  return (
    <svg
      viewBox="0 0 8.5 8.5"
      width={width}
      height={height}
      fill={color}
      {...(extraStyle ? { style: extraStyle } : {})}
    >
      <path
        d="m3.4 289.6.1.4a2.6 2.6 0 0 1 1.4 0l.1-.4a.8.8 0 0 0-1.6 0zM4.2 290.1c-1.3 0-2.3 1-2.3 2.4 0 .7 0 1.5-.4 2.4H7c-.3-.9-.3-1.7-.3-2.4 0-1.3-1-2.4-2.4-2.4zM1.6 295.1c-.4 0-.8.4-.8.8 0 .5.4.8.8.8h5.3a.8.8 0 0 0 0-1.6z"
        transform="translate(0 -288.5)"
      />
    </svg>
  );
};
