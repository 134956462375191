import { ProductIcon } from "../../utils/icons";
import styled from "styled-components";

const ProductWrapper = styled.div`
  display: flex;
  // align-items: center;
  .product_image {
    width: 75px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--lightGrey4);
    border-radius: 3px;
    background-color: var(--white);
    margin-${(props) => (props.theme.lang === "ar" ? "left" : "right")}: 10px;
    img {
      width: 75px;
      height: 70px;
      object-fit: contain;
    }
  }
  .product_info {
    display: flex;
    flex-direction: column;
    h3 {
      margin: 0;
      font-size: 16px;
      font-weight: 700;
      color: var(--darkBlue);
    }
    span:first-child:not(:last-child) {
      color: var(--darkGrey);
    }
    span:last-child {
      color: var(--lightGrey6);
    }
  }
`;

export const Product = ({
  image,
  name,
  info,
  price = "",
}: {
  image: string;
  name: string;
  info: string;
  price?: string;
}) => {
  return (
    <ProductWrapper>
      <div
        className="product_image"
        {...(image ? { style: { border: "none" } } : {})}
      >
        {image ? (
          <img src={image} alt="" />
        ) : (
          <ProductIcon width="50px" height="50px" color="#878c93" />
        )}
      </div>
      <div className="product_info">
        <h3>{name}</h3>
        {price && <span>{price}</span>}
        <span>{info}</span>
      </div>
    </ProductWrapper>
  );
};

export const FilterWidgetSection = styled.div`
  &:not(last-child) {
    margin-bottom: 15px;
  }
`;
