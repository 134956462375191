import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import InputCheckbox from "../../components/ui/Inputs/InputCheckbox";
import Table from "../../components/ui/Table";
import MainContent from "../../containers/MainContent";
import ViewListingPage from "../../containers/ViewListingPage";
import useListingData from "../../hooks/useListingData";
import { selectSettings, selectUser } from "../../store/selectors";
import { Delete } from "../../utils/icons";
import translation from "../../utils/translation";
import MainHeader from "../../containers/MainHeader";
import MainSidebar from "../../containers/MainSidebar";

const ListBrands = () => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { permissions: userPermissions },
  } = useSelector(selectUser);

  const {
    data,
    extraFetchedData,
    onRequestDeleteHandler,
    onSelectTableRowHandler,
    isAllRowsSelected,
    showMultipleDelete,
    ...rest
  } = useListingData({
    moduleName: "brands",
    moduleType: "admin",
  });

  return (
    <>
      <MainHeader />
      <MainSidebar />
      <MainContent
        {...(userPermissions?.product?.view
          ? {
              hasContentSidebar: true,
              contentSidebarSections: [
                {
                  title: translation[siteLang].products,
                  links: [
                    {
                      label: translation[siteLang].products,
                      url: "/products",
                    },
                    {
                      label: translation[siteLang].brands,
                      url: "/products/brands",
                    },
                  ],
                },
              ],
            }
          : {
              hasContentSidebar: false,
            })}
      >
        <PageTitle
          firstSection={
            <h1>
              {[
                translation[siteLang][siteLang === "ar" ? "list" : "brands"],
                " ",
                translation[siteLang][
                  siteLang === "ar" ? "brands" : "list"
                ].toLowerCase(),
              ]}
            </h1>
          }
          {...(userPermissions?.brands?.create
            ? {
                secondSection: (
                  <Button
                    styleType="primary"
                    size="lg"
                    label={[
                      translation[siteLang][
                        siteLang === "ar" ? "brand" : "new"
                      ],
                      translation[siteLang][
                        siteLang === "ar" ? "new" : "brand"
                      ].toLowerCase(),
                    ].join(" ")}
                    buttonType="link"
                    url="/products/brands/new"
                  />
                ),
              }
            : {})}
        />
        <ViewListingPage
          {...rest}
          hasNoResults={data.length === 0}
          tableFilter={{
            showMultipleDelete,
            onRequestDeleteHandler,
            hasSearch: true,
            search: {
              ...rest.search,
              placeholder:
                translation[siteLang].search_all +
                " " +
                translation[siteLang].brands.toLowerCase(),
            },
          }}
        >
          <Table
            isHeaderFixed={true}
            headings={[
              ...(data.length > 0 && userPermissions?.brands?.delete
                ? [
                    {
                      label: (
                        <InputCheckbox
                          checkboxStyle="checkbox"
                          props={{
                            onChange: () => onSelectTableRowHandler(0, true),
                            checked: isAllRowsSelected,
                            style: {
                              marginTop: "7px",
                              width: "15px",
                              height: "15px",
                            },
                          }}
                        />
                      ),
                      style: {
                        width: "50px",
                      },
                    },
                  ]
                : []),
              {
                label: translation[siteLang].brand,
                style: {
                  width: "70%",
                },
              },
              ...(userPermissions?.brands?.delete
                ? [
                    {
                      label: translation[siteLang].actions,
                      style: {
                        width: "80px",
                      },
                    },
                  ]
                : []),
            ]}
            rows={data.map((el) => ({
              link: userPermissions?.brands?.update
                ? `/products/brands/edit/${el.id}`
                : "",
              columns: [
                ...(userPermissions?.brands?.delete
                  ? [
                      {
                        link_not_applied: true,
                        value: (
                          <InputCheckbox
                            checkboxStyle="checkbox"
                            props={{
                              onChange: () => onSelectTableRowHandler(el.id),
                              checked: el.table_selected,
                              style: {
                                marginTop: "7px",
                                width: "15px",
                                height: "15px",
                              },
                            }}
                          />
                        ),
                      },
                    ]
                  : []),
                {
                  value: el.name,
                },
                ...(userPermissions?.brands?.delete
                  ? [
                      {
                        link_not_applied: true,
                        value: (
                          <div className="page_listing_table_actions">
                            <Button
                              icon={
                                <Delete
                                  width="15"
                                  height="15"
                                  color="#101928"
                                />
                              }
                              buttonType="button"
                              styleType="secondary"
                              onClick={() => onRequestDeleteHandler([el.id])}
                              size="md"
                              className="delete"
                            />
                          </div>
                        ),
                      },
                    ]
                  : []),
              ],
            }))}
          />
        </ViewListingPage>
      </MainContent>
    </>
  );
};

export default ListBrands;
