import { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
import { Overlay } from "../../components/ui/Dropdown/styles";
import { selectSettings, selectUser } from "../../store/selectors";
import translation from "../../utils/translation";
import Logo from "../../utils/logo.png";
import Wrapper, { UserIcon, UserWidget } from "./styles";
import { logUserOut } from "../../store/userSlice";
import Button from "../../components/ui/Button";
import { Notification } from "../../utils/icons";

const MainHeader = () => {
  const { siteLang } = useSelector(selectSettings);
  const { user } = useSelector(selectUser);

  const dispatch = useDispatch();

  const [isWidgetExpanded, setIsWidgetExpanded] = useState<boolean>(false);

  const onCloseWidgetHandler = useCallback((e) => {
    if (e.target.closest("a") || e.target.closest("button"))
      setIsWidgetExpanded(false);
  }, []);

  const onChangeLanguage = useCallback(() => {
    localStorage.setItem("shads_lang", siteLang === "en" ? "ar" : "en");
    window.location.reload();
  }, [siteLang]);

  return (
    <Wrapper>
      <div className="logo">
        <img src={Logo} alt="" />
      </div>
      <div className="controllers">
        <Button
          icon={<Notification width="15" height="15" color="#101928" />}
          buttonType="link"
          label={translation[siteLang].notifications}
          url="/notifications"
          iconPosition="start"
          size="md"
          styleType="secondary"
        />
        <UserIcon onClick={() => setIsWidgetExpanded(true)}>
          {user?.name?.substring(0, 1)}
        </UserIcon>
        <UserWidget
          expanded={isWidgetExpanded}
          openDirection="end"
          onClick={onCloseWidgetHandler}
        >
          <div className="header">
            <div className="header__img">{user?.name?.substring(0, 1)}</div>
            <div className="header__name">
              <p>{user?.name}</p>
              {/* <Link to="/">{translation[siteLang].my_profile}</Link> */}
            </div>
          </div>
          <div className="links">
            {/* <Link to="/">{translation[siteLang].notifications}</Link> */}
            <button onClick={onChangeLanguage}>
              {siteLang === "en" ? "عربى" : "English"}
            </button>
            <button onClick={() => dispatch(logUserOut())}>
              {translation[siteLang].logout}
            </button>
          </div>
        </UserWidget>
        {isWidgetExpanded && (
          <Overlay onClick={() => setIsWidgetExpanded(false)}></Overlay>
        )}
      </div>
    </Wrapper>
  );
};

export default memo(MainHeader);
