import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import InputField from "../../components/ui/Inputs/InputField";
import {
  BoxWrapper,
  SectionsWrapper,
} from "../../components/updatingPages/components";
import MainContent from "../../containers/MainContent";
import useUpdatingData from "../../hooks/useUpdatingData";
import { selectSettings, selectUser } from "../../store/selectors";
import { Arrow } from "../../utils/icons";
import translation from "../../utils/translation";
import { useMemo } from "react";
import UpdateModulePage from "../../containers/UpdateModulePage";
import { validateInputField } from "../../containers/UpdateModulePage/utils";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { useParams } from "react-router-dom";
import Calendar from "../../components/ui/Calendar";
import { getSingleModuleData } from "../../utils/fetchers";
import * as dateFns from "date-fns";

const UpdateSalary = ({ type }: { type: "create" | "edit" }) => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { branch_id: userBranchID },
  } = useSelector(selectUser);
  const { workerName, workerID, salaryID } = useParams();

  const moduleBaseName = useMemo(
    () => `/workers/salaries/${workerName}/${workerID}`,
    [workerName, workerID]
  );

  const defaultValues = useMemo(
    () => ({
      salary_date: new Date(),
      expected_salary: "",
      notes_en: "",
      notes_ar: "",
      worker_id: workerID,
    }),
    [workerID]
  );

  const extraFetchers = useMemo(
    () => [
      {
        function: (signal: AbortSignal) =>
          getSingleModuleData(
            userBranchID ? "branch" : "admin",
            "workers",
            +(workerID || 0),
            "with=additives,paycuts",
            signal
          ),
        returnName: "data",
      },
    ],
    [workerID, userBranchID]
  );

  const {
    isFetching,
    extraFetchedData,
    register,
    watchedInputs,
    setValue,
    moduleDefaultData,
    ...updatingModule
  } = useUpdatingData({
    moduleType: userBranchID ? "branch" : "admin",
    moduleState: type,
    moduleID: +(salaryID || 0),
    moduleName: "worker_salaries",
    modulePatch: type === "edit" ? true : false,
    modulePostEndpoint:
      type === "edit"
        ? `worker-salary/update/${salaryID}`
        : "worker-salary/create",
    modulePostType: "formData",
    watchInputFields: true,
    defaultValues,
    navigateAfterSubmissionTo: moduleBaseName,
    extraFetchers,
  });

  const formState = updatingModule.formState;

  const total_additives_paycuts = useMemo(() => {
    const [additives, paycuts] = ["additives", "paycuts"].map(
      (module) =>
        extraFetchedData?.data?.[module]?.reduce((acc: any, cur: any) => {
          let total = acc;
          let currentDateFirstMonth = dateFns.sub(
            dateFns.endOfDay(watchedInputs.salary_date),
            {
              months: 1,
            }
          );
          if (
            dateFns.compareAsc(
              dateFns.endOfDay(watchedInputs.salary_date),
              new Date(cur.created_at)
            ) === 1 &&
            dateFns.compareAsc(
              new Date(cur.created_at),
              currentDateFirstMonth
            ) === 1
          ) {
            total += +cur.value;
          }
          return total;
        }, 0) || 0
    );
    return { additives, paycuts };
  }, [extraFetchedData?.data, watchedInputs.salary_date]);

  const actual_salary = useMemo(() => {
    return (
      +(extraFetchedData?.data?.monthly_salary || 0) -
      total_additives_paycuts.paycuts +
      total_additives_paycuts.additives
    );
  }, [extraFetchedData?.data, total_additives_paycuts]);

  const updatedDataToSubmit = useMemo(() => {
    return (data: any) => {
      return {
        salary_date: dateFns.format(data.salary_date, "yyyy-MM-dd"),
        actual_salary: actual_salary,
        total_additives: total_additives_paycuts.additives,
        total_paycuts: total_additives_paycuts.paycuts,
      };
    };
  }, [actual_salary, total_additives_paycuts]);

  return (
    <MainContent onlyInPage={true} hasFixedWidthHeader={true}>
      <UpdateModulePage
        moduleBaseName={moduleBaseName}
        updatedDataToSubmit={updatedDataToSubmit}
        {...updatingModule}
      >
        <PageTitle
          firstSection={
            <div className="page_header_title_update">
              <Button
                buttonType="button"
                size="md"
                styleType="icon"
                onClick={() => updatingModule.onNavigateBack(moduleBaseName)}
                disabled={updatingModule.submissionState.isSubmitting}
                icon={
                  <Arrow
                    height="27"
                    width="27"
                    color="#101928"
                    extraStyle={{
                      transform: `rotate(${
                        siteLang === "ar" ? "180" : "0"
                      }deg)`,
                    }}
                  />
                }
              />
              <h1>
                {type === "edit"
                  ? [
                      translation[siteLang].edit,
                      " ",
                      translation[siteLang].monthly_salary.toLowerCase(),
                    ]
                  : [
                      translation[siteLang].add,
                      " ",
                      translation[siteLang][
                        siteLang === "ar" ? "monthly_salary" : "new"
                      ].toLowerCase(),
                      " ",
                      translation[siteLang][
                        siteLang === "ar" ? "new" : "monthly_salary"
                      ].toLowerCase(),
                    ]}
              </h1>
            </div>
          }
          secondSection={
            <Button
              label={translation[siteLang].save}
              buttonType="submit"
              size="lg"
              styleType="primary"
              disabled={
                isFetching || updatingModule.submissionState.isSubmitting
              }
              isLoading={updatingModule.submissionState.isSubmitting}
            />
          }
        />
        <SectionsWrapper isSeparated={true} fixedWidth={true}>
          {isFetching ? (
            <div style={{ width: "fit-content", margin: "100px auto 0" }}>
              <LoadingSpinner type="circle" />
            </div>
          ) : (
            <div className="section_wrapper_row">
              <div style={{ width: "60%" }}>
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].basic_info}</h3>
                  </div>
                  <div className="box_content">
                    <div className="box_row">
                      <div>
                        <div className="box_section">
                          <InputField
                            label={`${translation[siteLang].note} ${translation[siteLang].en}`}
                            type="text"
                            props={register("notes_en")}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={`${translation[siteLang].note} ${translation[siteLang].ar}`}
                            type="text"
                            props={register("notes_ar")}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={translation[siteLang].expected_salary}
                            type="number"
                            icon={<>{siteLang === "ar" ? "جنيه" : "EGP"}</>}
                            iconBorder={true}
                            props={register(
                              "expected_salary",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.expected_salary?.message}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={translation[siteLang].actual_salary}
                            type="number"
                            icon={<>{siteLang === "ar" ? "جنيه" : "EGP"}</>}
                            iconBorder={true}
                            disabled={true}
                            props={{
                              value: actual_salary,
                              onChange: () => {},
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={translation[siteLang].total_additives}
                            type="number"
                            icon={<>{siteLang === "ar" ? "جنيه" : "EGP"}</>}
                            iconBorder={true}
                            disabled={true}
                            props={{
                              value: total_additives_paycuts.additives,
                              onChange: () => {},
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={translation[siteLang].total_paycuts}
                            type="number"
                            icon={<>{siteLang === "ar" ? "جنيه" : "EGP"}</>}
                            iconBorder={true}
                            disabled={true}
                            props={{
                              value: total_additives_paycuts.paycuts,
                              onChange: () => {},
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </BoxWrapper>
              </div>
              <div style={{ width: "40%" }}>
                <BoxWrapper hasBorder={true}>
                  <div className="box_content" style={{ padding: "2px" }}>
                    <Calendar
                      defaultValue={watchedInputs.salary_date}
                      value={watchedInputs.salary_date}
                      onChange={(date) => setValue("salary_date", date)}
                    />
                  </div>
                </BoxWrapper>
              </div>
            </div>
          )}
        </SectionsWrapper>
      </UpdateModulePage>
    </MainContent>
  );
};

export default UpdateSalary;
