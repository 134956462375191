import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import InputCheckbox from "../../components/ui/Inputs/InputCheckbox";
import Table from "../../components/ui/Table";
import MainContent from "../../containers/MainContent";
import ViewListingPage from "../../containers/ViewListingPage";
import useListingData from "../../hooks/useListingData";
import { selectSettings, selectUser } from "../../store/selectors";
import { Delete, Edit } from "../../utils/icons";
import translation from "../../utils/translation";
import MainHeader from "../../containers/MainHeader";
import MainSidebar from "../../containers/MainSidebar";

const ListBanners = () => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { permissions: userPermissions },
  } = useSelector(selectUser);

  const {
    data,
    extraFetchedData,
    onRequestDeleteHandler,
    onSelectTableRowHandler,
    isAllRowsSelected,
    showMultipleDelete,
    ...rest
  } = useListingData({
    moduleName: "banners",
    moduleType: "admin",
  });

  return (
    <>
      <MainHeader />
      <MainSidebar />
      <MainContent
        {...(userPermissions?.app_settings?.view ||
        userPermissions?.support_forms?.view ||
        userPermissions?.notifications?.view ||
        userPermissions?.coupons?.view ||
        userPermissions?.support_reasons?.view ||
        userPermissions?.referal_codes?.view ||
        userPermissions?.cancellation_reasons?.view
          ? {
              hasContentSidebar: true,
              contentSidebarSections: [
                {
                  title: translation[siteLang].settings,
                  links: [
                    ...(userPermissions?.app_settings?.view
                      ? [
                          {
                            label: translation[siteLang].app_settings,
                            url: "/settings",
                          },
                        ]
                      : []),
                    ...(userPermissions?.support_forms?.view
                      ? [
                          {
                            label: translation[siteLang].support_tickets,
                            url: "/settings/tickets",
                          },
                        ]
                      : []),
                    ...(userPermissions?.notifications?.view
                      ? [
                          {
                            label: translation[siteLang].notifications,
                            url: "/settings/notifications",
                          },
                        ]
                      : []),
                    ...(userPermissions?.coupons?.view
                      ? [
                          {
                            label: translation[siteLang].coupons,
                            url: "/settings/coupons",
                          },
                        ]
                      : []),
                    {
                      label: translation[siteLang].banners,
                      url: "/settings/banners",
                    },
                    ...(userPermissions?.referal_codes?.view
                      ? [
                          {
                            label: translation[siteLang].referrals,
                            url: "/settings/referrals",
                          },
                        ]
                      : []),
                    ...(userPermissions?.support_reasons?.view
                      ? [
                          {
                            label: translation[siteLang].support_reasons,
                            url: "/settings/support-reasons",
                          },
                        ]
                      : []),
                    ...(userPermissions?.cancellation_reasons?.view
                      ? [
                          {
                            label: translation[siteLang].cancel_reasons,
                            url: "/settings/cancel-reasons",
                          },
                        ]
                      : []),
                  ],
                },
              ],
            }
          : {
              hasContentSidebar: false,
            })}
      >
        <PageTitle
          firstSection={
            <h1>
              {[
                translation[siteLang][siteLang === "ar" ? "list" : "banners"],
                " ",
                translation[siteLang][
                  siteLang === "ar" ? "banners" : "list"
                ].toLowerCase(),
              ]}
            </h1>
          }
          secondSection={
            <Button
              styleType="primary"
              size="lg"
              label={[
                translation[siteLang][siteLang === "ar" ? "banner" : "new"],
                translation[siteLang][
                  siteLang === "ar" ? "new" : "banner"
                ].toLowerCase(),
              ].join(" ")}
              buttonType="link"
              url="/settings/banners/new"
            />
          }
        />
        <ViewListingPage
          {...rest}
          hasNoResults={data.length === 0}
          tableFilter={{
            showMultipleDelete,
            onRequestDeleteHandler,
            hasSearch: true,
            search: {
              ...rest.search,
              placeholder:
                translation[siteLang].search_all +
                " " +
                translation[siteLang].banners.toLowerCase(),
            },
          }}
        >
          <Table
            isHeaderFixed={true}
            headings={[
              ...(data.length > 0
                ? [
                    {
                      label: (
                        <InputCheckbox
                          checkboxStyle="checkbox"
                          props={{
                            onChange: () => onSelectTableRowHandler(0, true),
                            checked: isAllRowsSelected,
                            style: {
                              marginTop: "7px",
                              width: "15px",
                              height: "15px",
                            },
                          }}
                        />
                      ),
                      style: {
                        width: "50px",
                      },
                    },
                  ]
                : []),
              {
                label: translation[siteLang].link,
              },
              {
                label: translation[siteLang].actions,
                style: {
                  width: "110px",
                },
              },
            ]}
            rows={data.map((el) => ({
              link: el.image,
              linkOutside: true,
              columns: [
                {
                  link_not_applied: true,
                  value: (
                    <InputCheckbox
                      checkboxStyle="checkbox"
                      props={{
                        onChange: () => onSelectTableRowHandler(el.id),
                        checked: el.table_selected,
                        style: {
                          marginTop: "7px",
                          width: "15px",
                          height: "15px",
                        },
                      }}
                    />
                  ),
                },
                {
                  value: el.link,
                },
                {
                  link_not_applied: true,
                  value: (
                    <div className="page_listing_table_actions">
                      <Button
                        icon={<Edit width="20" height="20" color="#101928" />}
                        buttonType="link"
                        styleType="secondary"
                        url={`/settings/banners/edit/${el.id}`}
                        size="md"
                      />
                      <Button
                        icon={<Delete width="15" height="15" color="#101928" />}
                        buttonType="button"
                        styleType="secondary"
                        onClick={() => onRequestDeleteHandler([el.id])}
                        size="md"
                        className="delete"
                      />
                    </div>
                  ),
                },
              ],
            }))}
          />
        </ViewListingPage>
      </MainContent>
    </>
  );
};

export default ListBanners;
