import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectSettings } from "../../../store/selectors";
import translation from "../../../utils/translation";
import InputCheckbox from "../../ui/Inputs/InputCheckbox";
import InputField from "../../ui/Inputs/InputField";
import Modal from "../../ui/Modal";

export type RefundStateType = {
  isSetToSubmit: number;
  isSubmitting: boolean;
  success: boolean;
  error: string;
};

type CompoonentPropsType = {
  refundState: RefundStateType;
  onResetRefundOrderToSubmit: () => void;
  onRefundOrder: (data: {
    refund_reason_en: string;
    refund_reason_ar: string;
    return_to_wallet: boolean;
  }) => Promise<void>;
};

const RefundOrderModal = ({
  refundState,
  onRefundOrder,
  onResetRefundOrderToSubmit,
}: CompoonentPropsType) => {
  const { siteLang } = useSelector(selectSettings);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      refund_reason_en: "",
      refund_reason_ar: "",
      return_to_wallet: false,
    },
  });

  return (
    <Modal
      as="form"
      onSubmit={handleSubmit(onRefundOrder)}
      showHeader={true}
      headerTitle={translation[siteLang].refund_order}
      maxWidth="400px"
      showFooter={true}
      showCloseIcon={true}
      onCloseHandler={onResetRefundOrderToSubmit}
      footerButtonsDirection="center"
      footerButtons={[
        {
          label: translation[siteLang].cancel,
          styleType: "secondary",
          size: "lg",
          buttonType: "button",
          onClick: onResetRefundOrderToSubmit,
          disabled: refundState.isSubmitting,
        },
        {
          label: translation[siteLang].refund,
          styleType: "danger",
          size: "lg",
          buttonType: "submit",
          disabled: refundState.isSubmitting,
          isLoading: refundState.isSubmitting,
        },
      ]}
    >
      {refundState.error && (
        <p
          style={{
            margin: "0 0 20px",
            textAlign: "center",
            fontSize: "15px",
            color: "#f23636",
            fontWeight: 500,
          }}
        >
          {refundState.error}
        </p>
      )}
      <div style={{ marginBottom: "15px" }}>
        <InputField
          type="text"
          label={`${translation[siteLang].reason} ${translation[siteLang].en}`}
          props={register("refund_reason_en", {
            required: translation[siteLang].required_field,
          })}
          error={errors?.refund_reason_en?.message}
        />
      </div>
      <div style={{ marginBottom: "15px" }}>
        <InputField
          type="text"
          label={`${translation[siteLang].reason} ${translation[siteLang].ar}`}
          props={register("refund_reason_ar", {
            required: translation[siteLang].required_field,
          })}
          error={errors?.refund_reason_ar?.message}
        />
      </div>
      <InputCheckbox
        checkboxStyle="switcher"
        topLabel={translation[siteLang].return_to_wallet}
        props={register("return_to_wallet")}
      />
    </Modal>
  );
};

export default RefundOrderModal;
