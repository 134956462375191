import { createSlice } from "@reduxjs/toolkit";

const initialState: {
  isMainSidebarCollapsed: boolean;
  isContentSidebarCollapsed: boolean;
  siteLang: "ar" | "en";
} = {
  isMainSidebarCollapsed: false,
  isContentSidebarCollapsed: false,
  siteLang: (localStorage.getItem("shads_lang") as "en" | "ar") || "en",
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setIsMainSidebarCollapsed: (state, action) => {
      state.isMainSidebarCollapsed = action.payload;
    },
    setIsContentSidebarCollapsed: (state, action) => {
      state.isContentSidebarCollapsed = action.payload;
    },
  },
});

export const { setIsMainSidebarCollapsed, setIsContentSidebarCollapsed } =
  settingsSlice.actions;

export default settingsSlice.reducer;
