import { useEffect, useState } from "react";
import { PageTitleType } from "../../containers/ViewListingPage/utils";

const PageTitle = ({
  firstSection,
  secondSection,
  isSticky = false,
  extraStyle,
}: PageTitleType) => {
  const [headeryIsSticky, setHeaderyIsSticky] = useState<boolean>(false);

  useEffect(() => {
    let scrollListener = (e: Event) => {
      if (
        (e.target as HTMLElement).scrollTop >= 40 &&
        !document
          .getElementById("page_header_top")
          ?.classList?.contains("page_header--sticky_applied")
      )
        setHeaderyIsSticky(true);
      if (
        (e.target as HTMLElement).scrollTop < 40 &&
        document
          .getElementById("page_header_top")
          ?.classList?.contains("page_header--sticky_applied")
      )
        setHeaderyIsSticky(false);
    };
    if (isSticky) {
      document
        .getElementById("page_content_wrapper")
        ?.addEventListener("scroll", scrollListener);
    }
    return () => {
      if (scrollListener)
        document
          .getElementById("page_content_wrapper")
          ?.removeEventListener("scroll", scrollListener);
    };
  }, [isSticky]);

  return (
    <div
      className={`page_header${isSticky ? " page_header--sticky" : ""}${
        headeryIsSticky ? " page_header--sticky_applied" : ""
      }`}
      id="page_header_top"
      style={extraStyle || {}}
    >
      <div className="page_header_content">
        <div className="page_header_sec">{firstSection}</div>
        {secondSection && (
          <div className="page_header_sec">{secondSection}</div>
        )}
      </div>
    </div>
  );
};

export default PageTitle;
