import { Wrapper } from "./style";

const Card = ({
  count,
  divisions,
}: {
  count: number;
  divisions: { label: string; value: string }[];
}) => {
  return (
    <Wrapper>
      <p className="count">{count}</p>
      <div className="divisions">
        {divisions.map((el, index) => (
          <div key={index}>
            <span>{el.label}</span>
            <span>{el.value}</span>
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

export default Card;
