import styled from "styled-components";

export const Option = styled.div`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
  .icon {
    width: 50px;
    height: 50px;
    background-color: var(--lightBlue2);
    margin-${(props) => (props.theme.lang === "ar" ? "left" : "right")}: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  p {
    margin: 0;
    font-weight: 600;
  }
`;
