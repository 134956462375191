import { useCallback, useState } from "react";
import { InputFileType, InputGeneralType } from "../utils";
import InputContainer from "../InputContainer";
import { Camera, Delete, LinkIcon, PDF } from "../../../../utils/icons";
import Wrapper from "./styles";
import { FileType, readImageAsDataURL } from "./utils";
import LoadingSpinner from "../../LoadingSpinner";
import translation from "../../../../utils/translation";
import { useSelector } from "react-redux";
import { selectSettings } from "../../../../store/selectors";

const InputFile = ({
  label,
  props,
  onChangeFiles,
  defaultFiles,
  watchedFiles,
  onAddToDeletedFiles,
  error,
  labelNote,
  note,
}: InputGeneralType & InputFileType) => {
  const { siteLang } = useSelector(selectSettings);

  const [currentFiles, setCurrentFiles] = useState<FileType[]>(
    defaultFiles.map((file) => ({ ...file, type: "link" }))
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onDeleteFileHandler = useCallback(
    (fileIndex: number) => {
      if (onAddToDeletedFiles && currentFiles[fileIndex].type === "link") {
        onAddToDeletedFiles(currentFiles[fileIndex].id as number);
      } else {
        onChangeFiles([
          ...watchedFiles.filter(
            (file) => file.id !== currentFiles[fileIndex].id
          ),
        ]);
      }
      setCurrentFiles((current) =>
        current.filter((_, index) => fileIndex !== index)
      );
    },
    [currentFiles, onAddToDeletedFiles, onChangeFiles, watchedFiles]
  );

  const onUploadFileHandler = useCallback(
    async (e) => {
      if (e.target.files && e.target.files.length > 0) {
        let timeStamp = Date.now();
        const files: FileType[] = [];
        if (props?.accept === "pdf") {
          files.push(
            ...Array.from(e.target.files as FileList).map((file, index) => ({
              id: timeStamp + index,
              type: "file" as "file",
              src: "",
              name:
                file.name.length > 40
                  ? file.name.substring(0, 40) + "..."
                  : file.name,
            }))
          );
        } else {
          setIsLoading(true);
          const readFiles = Array.from(e.target.files as FileList).map((file) =>
            readImageAsDataURL(file)
          );
          await Promise.all(readFiles)
            .then((values) => {
              files.push(
                ...values.map((val, index) => ({
                  id: timeStamp + index,
                  type: "file" as "file",
                  src: val as string,
                }))
              );
            })
            .catch(() => {});
          setIsLoading(false);
        }
        setCurrentFiles((current) => [
          ...(props?.multiple ? current : []),
          ...files,
        ]);
        onChangeFiles([
          ...watchedFiles,
          ...Array.from(e.target.files as FileList).map((file, index) => ({
            id: timeStamp + index,
            file,
          })),
        ]);
      }
    },
    [props, onChangeFiles, watchedFiles]
  );

  return (
    <InputContainer
      label={label}
      labelNote={labelNote}
      error={error}
      note={note}
    >
      <Wrapper
        hasFiles={currentFiles.length > 0}
        fileType={(props?.accept as "image" | "pdf") || "image"}
      >
        {currentFiles.length > 0 && (
          <div className="uploaded_file uploaded_file--main">
            {props?.accept === "pdf" ? (
              <>
                <PDF width="25px" height="25px" color="#037aff" />
                <span className="upload_label">{currentFiles[0].name}</span>
              </>
            ) : (
              <img src={currentFiles[0].src} alt="" />
            )}
            <div className="uploaded_file_control">
              {props?.accept === "pdf" && currentFiles[0].type === "link" && (
                <button
                  type="button"
                  onClick={() => window.open(currentFiles[0].src, "_blank")}
                >
                  <LinkIcon width="25px" height="25px" color="#fff" />
                </button>
              )}
              <button type="button" onClick={() => onDeleteFileHandler(0)}>
                <Delete width="25px" height="25px" color="#fff" />
              </button>
            </div>
          </div>
        )}
        {(props?.multiple ? true : currentFiles.length === 0) && (
          <div className="files_sections">
            <div className="files_sections_single">
              <label>
                <input
                  {...props}
                  type="file"
                  title=""
                  accept={
                    props?.accept === "pdf"
                      ? "application/pdf"
                      : "image/png,image/jpg,image/jpeg"
                  }
                  onChange={onUploadFileHandler}
                />
                {isLoading ? (
                  <LoadingSpinner type="dots" />
                ) : (
                  <>
                    {props?.accept === "pdf" ? (
                      <PDF width="25px" height="25px" color="#037aff" />
                    ) : (
                      <Camera width="25px" height="25px" color="#037aff" />
                    )}
                    <span className="upload_label">
                      {
                        translation[siteLang][
                          `add_${props?.accept === "pdf" ? "pdf" : "photo"}`
                        ]
                      }
                    </span>
                  </>
                )}
              </label>
            </div>
            {currentFiles.map(
              (file, index) =>
                index !== 0 && (
                  <div key={index} className="files_sections_single">
                    <div className="uploaded_file">
                      {props?.accept === "pdf" ? (
                        <>
                          <PDF width="22px" height="22px" color="#037aff" />
                          <span className="upload_label">{file.name}</span>
                        </>
                      ) : (
                        <img src={file.src} alt="" />
                      )}
                      <div className="uploaded_file_control">
                        {props?.accept === "pdf" && file.type === "link" && (
                          <button
                            type="button"
                            onClick={() => window.open(file.src, "_blank")}
                          >
                            <LinkIcon width="22px" height="22px" color="#fff" />
                          </button>
                        )}
                        <button
                          type="button"
                          onClick={() => onDeleteFileHandler(index)}
                        >
                          <Delete width="22px" height="22px" color="#fff" />
                        </button>
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>
        )}
      </Wrapper>
    </InputContainer>
  );
};

export default InputFile;
