import { Link } from "react-router-dom";
import Dropdown from "..";
import { DropdownOptionsType } from "../utils";
import { WidgetButton } from "../styles";
import { Fragment } from "react";

const DropdownOptions = ({
  options,
  button,
  openDirection,
  isFitContent = false,
  widgetStyle,
}: DropdownOptionsType) => {
  return (
    <Dropdown
      button={button}
      openDirection={openDirection}
      isFitContent={isFitContent}
      widgetStyle={widgetStyle}
    >
      {options.map((option, index) => (
        <Fragment key={index}>
          {option.label && <h4>{option.label}</h4>}
          {option.buttons.map((btn, index) => (
            <WidgetButton
              key={index}
              {...(btn.buttonType === "link" || btn.buttonType === "outsideLink"
                ? {
                    as: btn.buttonType === "outsideLink" ? "a" : Link,
                    [btn.buttonType === "outsideLink" ? "href" : "to"]: btn.url,
                  }
                : { type: btn.buttonType, onClick: btn.onClick })}
              {...(btn.buttonType === "outsideLink"
                ? { target: btn.target, rel: "noreferrer" }
                : {})}
              className="option_container"
              $hasLabelIcon={btn.label && btn.icon ? "both" : "single"}
              $iconOnly={btn.icon && !btn.label ? true : false}
            >
              {btn.icon}
              {btn.label && <i>{btn.label}</i>}
            </WidgetButton>
          ))}
        </Fragment>
      ))}
    </Dropdown>
  );
};

export default DropdownOptions;
