import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import Table from "../../components/ui/Table";
import MainContent from "../../containers/MainContent";
import ViewListingPage from "../../containers/ViewListingPage";
import useListingData from "../../hooks/useListingData";
import { selectSettings, selectUser } from "../../store/selectors";
import { Edit } from "../../utils/icons";
import translation from "../../utils/translation";
import MainHeader from "../../containers/MainHeader";
import MainSidebar from "../../containers/MainSidebar";
import { useParams } from "react-router-dom";
import { formatDate } from "../../utils/formatter";

const ListDaysoff = () => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { branch_id: userBranchID, permissions: userPermissions },
  } = useSelector(selectUser);
  const { workerName, workerID } = useParams();

  const { data, extraFetchedData, isAllRowsSelected, ...rest } = useListingData(
    {
      moduleName: "day_offs",
      moduleType: userBranchID ? "branch" : "admin",
      queries: `where=worker_id=${workerID}`,
    }
  );

  return (
    <>
      <MainHeader />
      <MainSidebar />
      <MainContent>
        <PageTitle
          firstSection={
            <>
              <span style={{ color: "#878c93", fontWeight: "500" }}>
                {workerName}
              </span>
              <h1>
                {[
                  translation[siteLang][siteLang === "ar" ? "list" : "daysoff"],
                  " ",
                  translation[siteLang][
                    siteLang === "ar" ? "daysoff" : "list"
                  ].toLowerCase(),
                ]}
              </h1>
            </>
          }
          secondSection={
            <>
              <Button
                styleType="secondary"
                size="lg"
                label={[
                  translation[siteLang].back_to,
                  translation[siteLang].workers.toLowerCase(),
                ].join(" ")}
                buttonType="link"
                url={`/workers`}
              />
              {userPermissions?.day_offs?.create && (
                <Button
                  styleType="primary"
                  size="lg"
                  label={[
                    translation[siteLang][siteLang === "ar" ? "dayoff" : "new"],
                    translation[siteLang][
                      siteLang === "ar" ? "new" : "dayoff"
                    ].toLowerCase(),
                  ].join(" ")}
                  buttonType="link"
                  url={`/workers/daysoff/${workerName}/${workerID}/new`}
                />
              )}
            </>
          }
        />
        <ViewListingPage
          {...rest}
          hasNoResults={data.length === 0}
          tableFilter={{
            hasSearch: true,
            search: {
              ...rest.search,
              placeholder:
                translation[siteLang].search_all +
                " " +
                translation[siteLang].daysoff.toLowerCase(),
            },
          }}
        >
          <Table
            isHeaderFixed={true}
            headings={[
              {
                label: translation[siteLang].reason,
              },
              {
                label: translation[siteLang].status,
              },
              {
                label: translation[siteLang].day,
              },
              ...(userPermissions?.day_offs?.update
                ? [
                    {
                      label: translation[siteLang].actions,
                      style: {
                        width: "80px",
                      },
                    },
                  ]
                : []),
            ]}
            rows={data.map((el) => ({
              columns: [
                {
                  value: el.reason,
                },
                {
                  value: translation[siteLang][el.status],
                },
                {
                  value: formatDate(el.day, siteLang),
                },
                ...(userPermissions?.day_offs?.update
                  ? [
                      {
                        link_not_applied: true,
                        value: (
                          <div className="page_listing_table_actions">
                            <Button
                              icon={
                                <Edit width="20" height="20" color="#101928" />
                              }
                              buttonType="link"
                              styleType="secondary"
                              url={`/workers/daysoff/${workerName}/${workerID}/edit/${el.id}`}
                              size="md"
                            />
                          </div>
                        ),
                      },
                    ]
                  : []),
              ],
            }))}
          />
        </ViewListingPage>
      </MainContent>
    </>
  );
};

export default ListDaysoff;
