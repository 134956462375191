export type FileType = {
  type: "link" | "file";
  src: string;
  id: number;
  name?: string;
};

export const readImageAsDataURL = (
  file: File
): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      reject(reader);
    };
    reader.readAsDataURL(file);
  });
};
