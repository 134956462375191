import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import InputCheckbox from "../../components/ui/Inputs/InputCheckbox";
import InputField from "../../components/ui/Inputs/InputField";
import {
  BoxWrapper,
  SectionsWrapper,
} from "../../components/updatingPages/components";
import MainContent from "../../containers/MainContent";
import useUpdatingData from "../../hooks/useUpdatingData";
import { selectSettings, selectUser } from "../../store/selectors";
import { Arrow, Page } from "../../utils/icons";
import translation from "../../utils/translation";
import { useMemo } from "react";
import UpdateModulePage from "../../containers/UpdateModulePage";
import { validateInputField } from "../../containers/UpdateModulePage/utils";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { useParams } from "react-router-dom";
import {
  getAllCities,
  getModuleData,
  assignBranchToRegion,
  removeBranchFromRegion,
  assignProductsToBranch,
  removeProductsFromBranch,
  assignWorkdays,
  updateWorkdays,
} from "../../utils/fetchers";
import Table from "../../components/ui/Table";
import { formatMoney } from "../../utils/formatter";
import InputSelect from "../../components/ui/Inputs/InputSelect";
import { Product } from "../../components/listingPages/ListingComponents";
import { work_days } from "../Workers/utils";

const UpdateBranches = ({
  type,
  isMyCurrent = false,
}: {
  type: "create" | "edit";
  isMyCurrent?: boolean;
}) => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { branch_id: userBranchID },
  } = useSelector(selectUser);

  let { branchID } = useParams();
  if (isMyCurrent) branchID = userBranchID;

  const moduleBaseName = useMemo(() => {
    return isMyCurrent ? "/" : "/branches";
  }, [isMyCurrent]);

  const defaultValues = useMemo(
    () => ({
      name_en: "",
      name_ar: "",
      address_en: "",
      address_ar: "",
      info_en: "",
      info_ar: "",
      lat: "",
      long: "",
      is_van: false,
      city: "no_option",
      regions: [],
      products: [],
      working_days: work_days.reduce(
        (acc, day) =>
          Object.assign(acc, {
            [day]: { on: false, from: "09:00", to: "17:00", id: null },
          }),
        {}
      ),
    }),
    []
  );

  const extraFetchers = useMemo(
    () => [
      {
        function: (signal: AbortSignal) =>
          getModuleData("admin", "product", "", signal),
        returnName: "products",
        isModule: true,
      },
      {
        function: getAllCities,
        returnName: "cities",
      },
    ],
    []
  );

  const extraSubmissions = useMemo(
    () => [
      {
        function: (id: number, refinedData: any) => {
          return assignProductsToBranch(id, refinedData.newProducts);
        },
        responseOfID: "branch",
      },
      {
        function: (id: number, refinedData: any) =>
          removeProductsFromBranch(refinedData.deletedProducts),
        responseOfID: "branch",
      },
      {
        function: (id: number, refinedData: any) => {
          return assignBranchToRegion(id, refinedData.newRegions);
        },
        responseOfID: "branch",
      },
      {
        function: (id: number, refinedData: any) =>
          removeBranchFromRegion(id, refinedData.deletedRegions),
        responseOfID: "branch",
      },
      {
        function: (id: number, refinedData: any) => {
          return assignWorkdays("branch", id, refinedData.newWorkingDays);
        },
        responseOfID: "branch",
      },
      {
        function: (id: number, refinedData: any) => {
          return updateWorkdays(refinedData.updatedWorkingDays);
        },
        responseOfID: "branch",
      },
    ],
    []
  );

  const resetModuleDefaults = useMemo(
    () => (clonedDefaults: any, extraFetchedData: any) => ({
      city: clonedDefaults?.regions?.[0]?.city?.id?.toString() || "no_option",
      regions:
        clonedDefaults?.regions?.map((el: any) => el.id.toString()) || [],
      products:
        extraFetchedData?.products?.map((el: any) => ({
          ...el,
          quantity:
            clonedDefaults?.branch_products?.find(
              (product: any) => product.product_id === el.id
            )?.quantity || "",
          min_quantity:
            clonedDefaults?.branch_products?.find(
              (product: any) => product.product_id === el.id
            )?.min_quantity || "",
          max_quantity:
            clonedDefaults?.branch_products?.find(
              (product: any) => product.product_id === el.id
            )?.max_quantity || "",
          commission:
            clonedDefaults?.branch_products?.find(
              (product: any) => product.product_id === el.id
            )?.commission || "",
          commission_type: "percentage",
          isSelected: clonedDefaults?.branch_products?.find(
            (product: any) => product.product_id === el.id
          )
            ? true
            : false,
          isDefault: clonedDefaults?.branch_products?.find(
            (product: any) => product.product_id === el.id
          )
            ? true
            : false,
          branchProductID:
            clonedDefaults?.branch_products?.find(
              (product: any) => product.product_id === el.id
            )?.id || "",
        })) || [],
      ...(clonedDefaults?.work_days && clonedDefaults.work_days.length > 0
        ? {
            working_days: clonedDefaults.work_days.reduce(
              (acc: any, cur: any) =>
                Object.assign(acc, {
                  [cur.day]: {
                    on: cur.on === 1 ? true : false,
                    from: cur?.from?.substring(0, 5) || "09:00",
                    to: cur?.to?.substring(0, 5) || "17:00",
                    id: cur.id || null,
                  },
                }),
              {}
            ),
          }
        : {}),
    }),
    []
  );

  const {
    isFetching,
    extraFetchedData,
    register,
    watchedInputs,
    setValue,
    moduleDefaultData,
    ...updatingModule
  } = useUpdatingData({
    moduleType: "admin",
    moduleState: type,
    moduleID: +(branchID || 0),
    moduleName: "branches",
    modulePostEndpoint:
      type === "edit" ? `branch/update/${branchID}` : "branch/create",
    modulePostType: "object",
    modulePatch: type === "edit" ? true : false,
    watchInputFields: true,
    defaultValues,
    navigateAfterSubmissionTo: moduleBaseName,
    extraFetchers,
    extraSubmissions,
    resetModuleDefaults,
    queries: "with=branch_products,regions.city,work_days",
  });

  const formState = updatingModule.formState;

  const updatedDataToSubmit = useMemo(() => {
    return (data: any) => ({
      newRegions: data.regions.filter((el: any) =>
        moduleDefaultData?.regions?.length > 0 &&
        moduleDefaultData?.regions?.findIndex(
          (region: any) => region.id.toString() === el
        ) !== -1
          ? false
          : true
      ),
      deletedRegions:
        moduleDefaultData?.regions
          ?.filter(
            (el: any) =>
              data.regions.findIndex(
                (region: any) => region === el.id.toString()
              ) === -1
          )
          ?.map((el: any) => el.id) || [],
      newProducts: data.products
        .filter((el: any) => el.isSelected)
        .map((el: any) => ({
          ...el,
          commission:
            el.commission_type === "fixed"
              ? +((+el.commission / +el.price) * 100).toFixed(2)
              : el.commission,
        })),
      deletedProducts: data.products
        .filter((el: any) => el.isDefault && !el.isSelected)
        .map((el: any) => el.branchProductID),
      newWorkingDays: Object.keys(data.working_days)
        .filter((day) => data.working_days[day].id === null)
        .reduce(
          (acc, cur) =>
            Object.assign(acc, { [cur]: { ...data.working_days[cur] } }),
          {}
        ),
      updatedWorkingDays: Object.keys(data.working_days)
        .filter((day) => {
          const defaultDay = moduleDefaultData?.work_days?.find(
            (el: any) => el.day === day
          );
          let needsUpdate = false;
          if (
            defaultDay &&
            ((defaultDay.on === 0 ? false : true) !==
              data.working_days[day].on ||
              defaultDay.from !== data.working_days[day].from ||
              defaultDay.to !== data.working_days[day].to)
          ) {
            needsUpdate = true;
          }
          return needsUpdate;
        })
        .map((day) => ({
          ...data.working_days[day],
        })),
    });
  }, [moduleDefaultData]);

  const moduleSelectOptions = useMemo(() => {
    const cities: any[] = [],
      regions: any[] = [];
    let citiesRegionsDependats: any = {
      name: `region`,
      value: {
        no_option: "no_option",
      },
    };
    extraFetchedData?.cities?.forEach((city: any) => {
      cities.push({ label: city.name, value: city.id.toString() });
      citiesRegionsDependats.value = Object.assign(citiesRegionsDependats, {
        [city.id]: "no_option",
      });
      city.regions.forEach((region: any) => {
        regions.push({
          label: region.name,
          value: region.id.toString(),
          dependsOn: {
            or: [
              {
                name: `city`,
                value: city.id.toString(),
              },
            ],
          },
        });
      });
    });
    return {
      cities,
      regions,
      citiesDependats: [citiesRegionsDependats],
    };
  }, [extraFetchedData]);

  return (
    <MainContent onlyInPage={true} hasFixedWidthHeader={true}>
      <UpdateModulePage
        moduleBaseName={moduleBaseName}
        updatedDataToSubmit={updatedDataToSubmit}
        {...updatingModule}
      >
        <PageTitle
          isSticky={true}
          firstSection={
            <div className="page_header_title_update">
              <Button
                buttonType="button"
                size="md"
                styleType="icon"
                onClick={() => updatingModule.onNavigateBack(moduleBaseName)}
                disabled={updatingModule.submissionState.isSubmitting}
                icon={
                  <Arrow
                    height="27"
                    width="27"
                    color="#101928"
                    extraStyle={{
                      transform: `rotate(${
                        siteLang === "ar" ? "180" : "0"
                      }deg)`,
                    }}
                  />
                }
              />
              <h1>
                {type === "edit"
                  ? [
                      translation[siteLang].edit,
                      " ",
                      translation[siteLang].branch.toLowerCase(),
                    ]
                  : [
                      translation[siteLang].add,
                      " ",
                      translation[siteLang][
                        siteLang === "ar" ? "branch" : "new"
                      ].toLowerCase(),
                      " ",
                      translation[siteLang][
                        siteLang === "ar" ? "new" : "branch"
                      ].toLowerCase(),
                    ]}
              </h1>
            </div>
          }
          secondSection={
            <Button
              label={translation[siteLang].save}
              buttonType="submit"
              size="lg"
              styleType="primary"
              disabled={
                isFetching || updatingModule.submissionState.isSubmitting
              }
              isLoading={updatingModule.submissionState.isSubmitting}
            />
          }
        />
        <SectionsWrapper isSeparated={true} fixedWidth={true}>
          {isFetching ? (
            <div style={{ width: "fit-content", margin: "100px auto 0" }}>
              <LoadingSpinner type="circle" />
            </div>
          ) : (
            <div className="section_wrapper_row">
              <div style={{ width: "55%" }}>
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].basic_info}</h3>
                  </div>
                  <div className="box_content">
                    <div className="box_section">
                      <div className="box_row">
                        <div>
                          <InputField
                            label={`${translation[siteLang].name} ${translation[siteLang].en}`}
                            type="text"
                            props={register(
                              "name_en",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.name_en?.message}
                          />
                        </div>
                        <div>
                          <InputField
                            label={`${translation[siteLang].name} ${translation[siteLang].ar}`}
                            type="text"
                            props={register(
                              "name_ar",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.name_ar?.message}
                          />
                        </div>
                        <div>
                          <InputField
                            label={`${translation[siteLang].description} ${translation[siteLang].en}`}
                            type="textarea"
                            props={register(
                              "info_en",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.info_en?.message}
                          />
                        </div>
                        <div>
                          <InputField
                            label={`${translation[siteLang].description} ${translation[siteLang].ar}`}
                            type="textarea"
                            props={register(
                              "info_ar",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.info_ar?.message}
                          />
                        </div>
                        <div>
                          <InputCheckbox
                            topLabel={translation[siteLang].van}
                            checkboxStyle="switcher"
                            props={register("is_van")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </BoxWrapper>
              </div>
              <div style={{ width: "45%" }}>
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].region}</h3>
                  </div>
                  <div className="box_content">
                    <div className="box_section">
                      <div>
                        <InputSelect
                          label={translation[siteLang].city}
                          options={[
                            {
                              options: moduleSelectOptions.cities,
                            },
                          ]}
                          props={{
                            ...register(
                              `city`,
                              validateInputField(siteLang, true, true)
                            ),
                            value: watchedInputs.city,
                          }}
                          error={formState.errors?.city?.message}
                          allInputsValues={watchedInputs}
                          dependantConditions={
                            moduleSelectOptions.citiesDependats
                          }
                          setDependantValues={(name: any, value: string) =>
                            setValue(name, value)
                          }
                        />
                      </div>
                    </div>
                    <div className="box_section">
                      <div>
                        <InputSelect
                          label={translation[siteLang].region}
                          options={[
                            {
                              options: moduleSelectOptions.regions,
                            },
                          ]}
                          isMulti={true}
                          allInputsValues={watchedInputs}
                          props={{
                            ...register(
                              `regions`,
                              validateInputField(siteLang, true, true, {
                                required: (val: any) =>
                                  val?.length > 0 ||
                                  translation[siteLang].required_field,
                              })
                            ),
                            value: watchedInputs.regions,
                          }}
                          error={formState.errors?.regions?.message}
                        />
                      </div>
                    </div>
                  </div>
                </BoxWrapper>
              </div>
              <div style={{ width: "100%" }}>
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].location}</h3>
                  </div>
                  <div className="box_content">
                    <div className="box_section">
                      <div className="box_row">
                        <div style={{ width: "100%" }}>
                          <InputField
                            label={`${translation[siteLang].address} ${translation[siteLang].en}`}
                            type="text"
                            props={register(
                              "address_en",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.address_en?.message}
                          />
                        </div>
                        <div style={{ width: "100%" }}>
                          <InputField
                            label={`${translation[siteLang].address} ${translation[siteLang].ar}`}
                            type="text"
                            props={register(
                              "address_ar",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.address_ar?.message}
                          />
                        </div>
                        <div>
                          <InputField
                            label={translation[siteLang].location}
                            labelNote="(LAT)"
                            type="text"
                            props={register(
                              "lat",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.lat?.message}
                          />
                        </div>
                        <div>
                          <InputField
                            label={translation[siteLang].location}
                            labelNote="(LONG)"
                            type="text"
                            props={register(
                              "long",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.long?.message}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </BoxWrapper>
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].working_days}</h3>
                  </div>
                  <div className="box_content" style={{ padding: 0 }}>
                    <div className="box_section">
                      <Table
                        headings={[
                          {
                            label: translation[siteLang].on_off,
                            style: {
                              width: "110px",
                            },
                          },
                          {
                            label: translation[siteLang].day,
                            style: {
                              width: "30%",
                            },
                          },
                          {
                            label: translation[siteLang].from,
                          },
                          {
                            label: translation[siteLang].to,
                          },
                        ]}
                        rows={work_days.map((el: any, index: number) => ({
                          columns: [
                            {
                              value: (
                                <InputCheckbox
                                  checkboxStyle="switcher"
                                  props={{
                                    ...register(`working_days.${el}.on`),
                                    style: {
                                      marginTop: "5px",
                                      width: "15px",
                                      height: "15px",
                                    },
                                  }}
                                />
                              ),
                            },
                            {
                              value: translation[siteLang][el],
                            },
                            {
                              value: (
                                <InputField
                                  type="time"
                                  props={register(`working_days.${el}.from`)}
                                  disabled={!watchedInputs.working_days[el].on}
                                />
                              ),
                            },
                            {
                              value: (
                                <InputField
                                  type="time"
                                  props={register(`working_days.${el}.to`)}
                                  disabled={!watchedInputs.working_days[el].on}
                                />
                              ),
                            },
                          ],
                        }))}
                      />
                    </div>
                  </div>
                </BoxWrapper>
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].branch_products}</h3>
                  </div>
                  <div
                    className="box_content"
                    style={
                      watchedInputs.products.length > 0 ? { padding: 0 } : {}
                    }
                  >
                    <div className="box_section">
                      {watchedInputs.products.length === 0 ? (
                        <div className="page_listing_no_results">
                          <Page width="70" height="70" color="#878c93" />
                          <p>{translation[siteLang].no_products}</p>
                        </div>
                      ) : (
                        <Table
                          headings={[
                            {
                              label: "",
                              style: {
                                width: "50px",
                              },
                            },
                            {
                              label: translation[siteLang].product,
                            },
                            {
                              label: `${translation[siteLang].quantity} (${translation[siteLang].available})`,
                              style: { width: "12%" },
                            },
                            {
                              label: `${translation[siteLang].quantity} (${translation[siteLang].minimum})`,
                              style: { width: "12%" },
                            },
                            {
                              label: `${translation[siteLang].quantity} (${translation[siteLang].maximum})`,
                              style: { width: "12%" },
                            },
                            {
                              label: translation[siteLang].commission_type,
                              style: { width: "14%" },
                            },
                            {
                              label: translation[siteLang].commission,
                              style: { width: "14%" },
                            },
                          ]}
                          rows={watchedInputs.products.map(
                            (el: any, index: number) => ({
                              columns: [
                                {
                                  value: (
                                    <InputCheckbox
                                      checkboxStyle="checkbox"
                                      props={{
                                        ...register(
                                          `products.${index}.isSelected`
                                        ),
                                        style: {
                                          marginTop: "5px",
                                          width: "15px",
                                          height: "15px",
                                        },
                                      }}
                                    />
                                  ),
                                },
                                {
                                  value: (
                                    <Product
                                      image={el.image}
                                      name={el.name}
                                      info={
                                        el.info
                                          ? el.info?.substring(0, 15) +
                                            (el.info?.length > 15 ? "..." : "")
                                          : ""
                                      }
                                      price={
                                        el.price
                                          ? formatMoney(+el.price, siteLang)
                                          : ""
                                      }
                                    />
                                  ),
                                },
                                {
                                  value: el.isSelected && (
                                    <InputField
                                      type="number"
                                      props={register(
                                        `products.${index}.quantity`,
                                        validateInputField(siteLang, true)
                                      )}
                                      error={
                                        formState.errors?.products?.[index]
                                          ?.quantity?.message
                                      }
                                    />
                                  ),
                                },
                                {
                                  value: el.isSelected && (
                                    <InputField
                                      type="number"
                                      props={register(
                                        `products.${index}.min_quantity`,
                                        validateInputField(siteLang, true)
                                      )}
                                      error={
                                        formState.errors?.products?.[index]
                                          ?.min_quantity?.message
                                      }
                                    />
                                  ),
                                },
                                {
                                  value: el.isSelected && (
                                    <InputField
                                      type="number"
                                      props={register(
                                        `products.${index}.max_quantity`,
                                        validateInputField(siteLang, true)
                                      )}
                                      error={
                                        formState.errors?.products?.[index]
                                          ?.max_quantity?.message
                                      }
                                    />
                                  ),
                                },
                                {
                                  value: el.isSelected && (
                                    <InputSelect
                                      options={[
                                        {
                                          options: [
                                            {
                                              label:
                                                translation[siteLang].fixed,
                                              value: "fixed",
                                            },
                                            {
                                              label:
                                                translation[siteLang]
                                                  .percentage,
                                              value: "percentage",
                                            },
                                          ],
                                        },
                                      ]}
                                      props={{
                                        ...register(
                                          `products.${index}.commission_type`
                                        ),
                                        value:
                                          watchedInputs.products?.[index]
                                            ?.commission_type,
                                      }}
                                    />
                                  ),
                                },
                                {
                                  value: el.isSelected && (
                                    <InputField
                                      type="number"
                                      icon={
                                        <>
                                          {watchedInputs?.products?.[index]
                                            ?.commission_type === "percentage"
                                            ? "%"
                                            : siteLang === "ar"
                                            ? "جنيه"
                                            : "EGP"}
                                        </>
                                      }
                                      iconBorder={true}
                                      props={{
                                        ...register(
                                          `products.${index}.commission`,
                                          validateInputField(siteLang, true)
                                        ),
                                        step: "any",
                                      }}
                                      error={
                                        formState.errors?.products?.[index]
                                          ?.commission?.message
                                      }
                                    />
                                  ),
                                },
                              ],
                            })
                          )}
                        />
                      )}
                    </div>
                  </div>
                </BoxWrapper>
              </div>
            </div>
          )}
        </SectionsWrapper>
      </UpdateModulePage>
    </MainContent>
  );
};

export default UpdateBranches;
