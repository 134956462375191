import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoginBox from "../../components/auth/LoginBox";
import Button from "../../components/ui/Button";
import InputField from "../../components/ui/Inputs/InputField";
import { validateInputField } from "../../containers/UpdateModulePage/utils";
import { selectSettings, selectUser } from "../../store/selectors";
import { logUser } from "../../store/userSlice";
import translation from "../../utils/translation";

const Login = () => {
  const { siteLang } = useSelector(selectSettings);
  const { isSubmitting, errorSubmitting, user, isValidating } =
    useSelector(selectUser);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isValidating && user) navigate("/");
  }, [user, isValidating, navigate]);

  const dispatch = useDispatch();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      phone: "",
      password: "",
    },
  });

  const onLoginHandler = useCallback(
    (data: any) => {
      dispatch(logUser(data));
    },
    [dispatch]
  );

  return (
    <LoginBox error={errorSubmitting}>
      <form onSubmit={handleSubmit(onLoginHandler)}>
        <div className="box_section">
          <InputField
            type="number"
            icon={<>+20</>}
            iconBorder={true}
            label={translation[siteLang].phone}
            props={register("phone", validateInputField(siteLang, true))}
            error={errors.phone?.message}
          />
        </div>
        <div className="box_section">
          <InputField
            type="password"
            label={translation[siteLang].password}
            props={register("password", validateInputField(siteLang, true))}
            error={errors.password?.message}
          />
        </div>
        <div
          className="box_section"
          style={{ width: "fit-content", margin: "auto" }}
        >
          <Button
            size="lg"
            buttonType="submit"
            label={translation[siteLang].login}
            styleType="primary"
            isLoading={isSubmitting}
            disabled={isSubmitting}
          />
        </div>
      </form>
    </LoginBox>
  );
};

export default Login;
