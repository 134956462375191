import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { selectSettings } from "../../store/selectors";
import { setIsContentSidebarCollapsed } from "../../store/settingsSlice";
import { Chevron } from "../../utils/icons";
import Button from "../../components/ui/Button";
import { ContentSidebarType } from "./utils";
import Wrapper from "./styles";

const ContentSidebar = ({ sections }: ContentSidebarType) => {
  const { isMainSidebarCollapsed, isContentSidebarCollapsed } =
    useSelector(selectSettings);

  const dispatch = useDispatch();

  return (
    <Wrapper
      isMainSidebarCollapsed={isMainSidebarCollapsed}
      collapsed={isContentSidebarCollapsed}
    >
      <div className="content">
        {sections.map((sec, index) => (
          <div key={index} className="content_section">
            {sec.title && <h3>{sec.title}</h3>}
            {sec.links.map((link, index) => (
              <NavLink key={index} to={link.url} end>
                {link.label}
              </NavLink>
            ))}
          </div>
        ))}
      </div>
      <div className="collapse">
        <Button
          buttonType="button"
          styleType="secondary"
          size="md"
          fullRadius={true}
          icon={<Chevron width="20" height="20" color="#101928" />}
          onClick={() =>
            dispatch(setIsContentSidebarCollapsed(!isContentSidebarCollapsed))
          }
        />
      </div>
    </Wrapper>
  );
};

export default ContentSidebar;
