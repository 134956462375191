import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import InputField from "../../components/ui/Inputs/InputField";
import {
  BoxWrapper,
  SectionsWrapper,
} from "../../components/updatingPages/components";
import MainContent from "../../containers/MainContent";
import useUpdatingData from "../../hooks/useUpdatingData";
import { selectSettings, selectUser } from "../../store/selectors";
import { Arrow } from "../../utils/icons";
import translation from "../../utils/translation";
import { useMemo } from "react";
import UpdateModulePage from "../../containers/UpdateModulePage";
import { validateInputField } from "../../containers/UpdateModulePage/utils";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { useParams } from "react-router-dom";
import { getAllBranches } from "../../utils/fetchers";
import InputSelect from "../../components/ui/Inputs/InputSelect";
import Calendar from "../../components/ui/Calendar";

const UpdateExpenses = ({ type }: { type: "create" | "edit" }) => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { branch_id: userBranchID },
  } = useSelector(selectUser);

  const { categoryID, categoryName, expenseID } = useParams();

  const moduleBaseName = useMemo(
    () => `/expenses/list/${categoryName}/${categoryID}`,
    [categoryID, categoryName]
  );

  const extraFetchers = useMemo(
    () => [
      ...(userBranchID
        ? []
        : [
            {
              function: getAllBranches,
              returnName: "branches",
            },
          ]),
    ],
    [userBranchID]
  );

  const defaultValues = useMemo(
    () => ({
      name_en: "",
      name_ar: "",
      amount: "",
      note_en: "",
      note_ar: "",
      expense_date: new Date(),
      branch_id: userBranchID ? userBranchID : "no_option",
      expense_category_id: categoryID,
    }),
    [categoryID, userBranchID]
  );

  const resetModuleDefaults = useMemo(
    () => (clonedDefaults: any) => ({
      expense_date: clonedDefaults?.expense_date
        ? new Date(clonedDefaults.expense_date)
        : new Date(),
      branch_id: clonedDefaults?.branch_id?.toString() || "no_option",
    }),
    []
  );

  const {
    isFetching,
    extraFetchedData,
    register,
    watchedInputs,
    setValue,
    moduleDefaultData,
    ...updatingModule
  } = useUpdatingData({
    moduleType: userBranchID ? "branch" : "admin",
    moduleState: type,
    ...(type === "edit" ? { resetModuleDefaults } : {}),
    moduleID: +(expenseID || 0),
    moduleName: "expenses",
    modulePostEndpoint:
      type === "edit" ? `expense/update/${expenseID}` : "expense/create",
    ...(type === "edit" ? { modulePatch: true } : {}),
    modulePostType: "formData",
    watchInputFields: true,
    defaultValues,
    extraFetchers,
    navigateAfterSubmissionTo: moduleBaseName,
  });

  const formState = updatingModule.formState;

  const updatedDataToSubmit = useMemo(() => {
    return (data: any) => ({
      expense_date: `${data.expense_date.getFullYear()}-${
        (`${data.expense_date.getMonth() + 1}`.length === 1 ? "0" : "") +
        (data.expense_date.getMonth() + 1)
      }-${
        (`${data.expense_date.getDate()}`.length === 1 ? "0" : "") +
        data.expense_date.getDate()
      }`,
    });
  }, []);

  return (
    <MainContent onlyInPage={true} hasFixedWidthHeader={true}>
      <UpdateModulePage
        moduleBaseName={moduleBaseName}
        updatedDataToSubmit={updatedDataToSubmit}
        {...updatingModule}
      >
        <PageTitle
          firstSection={
            <div className="page_header_title_update">
              <Button
                buttonType="button"
                size="md"
                styleType="icon"
                onClick={() => updatingModule.onNavigateBack(moduleBaseName)}
                disabled={updatingModule.submissionState.isSubmitting}
                icon={
                  <Arrow
                    height="27"
                    width="27"
                    color="#101928"
                    extraStyle={{
                      transform: `rotate(${
                        siteLang === "ar" ? "180" : "0"
                      }deg)`,
                    }}
                  />
                }
              />
              <h1>
                {type === "edit"
                  ? [
                      translation[siteLang].edit,
                      " ",
                      translation[siteLang].expense.toLowerCase(),
                    ]
                  : [
                      translation[siteLang].add,
                      " ",
                      translation[siteLang][
                        siteLang === "ar" ? "expense" : "new"
                      ].toLowerCase(),
                      " ",
                      translation[siteLang][
                        siteLang === "ar" ? "new" : "expense"
                      ].toLowerCase(),
                    ]}
              </h1>
            </div>
          }
          secondSection={
            <Button
              label={translation[siteLang].save}
              buttonType="submit"
              size="lg"
              styleType="primary"
              disabled={
                isFetching || updatingModule.submissionState.isSubmitting
              }
              isLoading={updatingModule.submissionState.isSubmitting}
            />
          }
        />
        <SectionsWrapper isSeparated={true} fixedWidth={true}>
          {isFetching ? (
            <div style={{ width: "fit-content", margin: "100px auto 0" }}>
              <LoadingSpinner type="circle" />
            </div>
          ) : (
            <div className="section_wrapper_row">
              <div style={{ width: "60%" }}>
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].basic_info}</h3>
                  </div>
                  <div className="box_content">
                    <div className="box_row">
                      <div>
                        <div className="box_section">
                          <InputField
                            label={`${translation[siteLang].name} ${translation[siteLang].en}`}
                            type="text"
                            props={register(
                              "name_en",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.name_en?.message}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={`${translation[siteLang].name} ${translation[siteLang].ar}`}
                            type="text"
                            props={register(
                              "name_ar",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.name_ar?.message}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={`${translation[siteLang].description} ${translation[siteLang].en}`}
                            type="textarea"
                            props={register("note_en")}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={`${translation[siteLang].description} ${translation[siteLang].ar}`}
                            type="textarea"
                            props={register("note_ar")}
                          />
                        </div>
                      </div>
                      <div>
                        <InputField
                          label={translation[siteLang].value}
                          type="number"
                          icon={<>{siteLang === "ar" ? "جنيه" : "EGP"}</>}
                          iconBorder={true}
                          props={register(
                            "amount",
                            validateInputField(siteLang, true)
                          )}
                          error={formState.errors?.amount?.message}
                        />
                      </div>
                      <div>
                        <InputSelect
                          label={translation[siteLang].branch}
                          options={[
                            {
                              options:
                                extraFetchedData?.branches?.map((el: any) => ({
                                  label: el.name,
                                  value: el.id.toString(),
                                })) || [],
                            },
                          ]}
                          props={{
                            ...register(
                              "branch_id",
                              validateInputField(siteLang, true, true)
                            ),
                            value: watchedInputs.branch_id,
                          }}
                          error={formState.errors?.branch_id?.message}
                        />
                      </div>
                    </div>
                  </div>
                </BoxWrapper>
              </div>
              <div style={{ width: "40%" }}>
                <BoxWrapper hasBorder={true}>
                  <div className="box_content" style={{ padding: "2px" }}>
                    <Calendar
                      defaultValue={watchedInputs.expense_date}
                      value={watchedInputs.expense_date}
                      onChange={(date) => setValue("expense_date", date)}
                    />
                  </div>
                </BoxWrapper>
              </div>
            </div>
          )}
        </SectionsWrapper>
      </UpdateModulePage>
    </MainContent>
  );
};

export default UpdateExpenses;
