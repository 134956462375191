import Dropdown from "..";
import { DropdownRadioType } from "../utils";
import InputRadio from "../../Inputs/InputRadio";

const DropdownInputRadio = ({
  options,
  button,
  props,
  currentValue,
  openDirection,
}: DropdownRadioType) => {
  return (
    <Dropdown button={button} openDirection={openDirection}>
      <InputRadio
        options={options}
        props={props}
        currentValue={currentValue}
        shape="normal"
      />
    </Dropdown>
  );
};

export default DropdownInputRadio;
