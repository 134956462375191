import { useSelector } from "react-redux";
import { Product } from "../../components/listingPages/ListingComponents";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import InputCheckbox from "../../components/ui/Inputs/InputCheckbox";
import Table from "../../components/ui/Table";
import MainContent from "../../containers/MainContent";
import ViewListingPage from "../../containers/ViewListingPage";
import useListingData from "../../hooks/useListingData";
import { selectSettings, selectUser } from "../../store/selectors";
import { refill } from "../../utils/fetchers";
import { formatMoney } from "../../utils/formatter";
import translation from "../../utils/translation";
import MainHeader from "../../containers/MainHeader";
import MainSidebar from "../../containers/MainSidebar";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import useRefillItem, { RefillModal } from "../../hooks/useRefillItem";
import { Edit } from "../../utils/icons";

const ListProductsRefill = () => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { branch_id: userBranchID, permissions: userPermissions },
  } = useSelector(selectUser);

  let { branchName, branchID } = useParams();

  if (userBranchID) branchID = userBranchID;

  const {
    data,
    extraFetchedData,
    onRequestDeleteHandler,
    onSelectTableRowHandler,
    isAllRowsSelected,
    showMultipleDelete,
    setListingData,
    ...rest
  } = useListingData({
    moduleName: userBranchID ? "branch_products" : "branches",
    moduleType: userBranchID ? "branch" : "admin",
    queries: !userBranchID
      ? `with=branch_products,branch_products.product,branch_products.product.brand&where=branch_id=${branchID}`
      : "with=product,product.brand",
    isSubModuleListing: userBranchID ? false : true,
    subModuleName: userBranchID ? "" : "branch_products",
  });

  const onRefillSelected = useCallback(async () => {
    setListingData((current) => ({ ...current, isFetching: true }));
    await refill("product", {
      branch_products:
        (userBranchID ? data : data?.[0]?.branch_products)
          ?.filter(
            (el: any) => el.table_selected && +el.quantity < +el.max_quantity
          )
          .map((el: any) => el.id) || [],
    })
      .then(() => {
        setListingData((current) => ({
          ...current,
          data: current.data.map((el) => ({
            ...el,
            ...(userBranchID
              ? {
                  quantity: el.table_selected ? el.max_quantity : el.quantity,
                  table_selected: false,
                }
              : {
                  branch_products: el?.branch_products?.map((el: any) => ({
                    ...el,
                    quantity: el.table_selected ? el.max_quantity : el.quantity,
                    table_selected: false,
                  })),
                }),
          })),
          isFetching: false,
        }));
      })
      .catch(() => {});
  }, [data, setListingData, userBranchID]);

  const refillStocks = useRefillItem({
    type: "product",
    setListingData,
    branchID: branchID || userBranchID,
    isMyCurrent: userBranchID,
  });

  return (
    <>
      {refillStocks.isActiveToRefill !== -1 && (
        <RefillModal refillStocks={refillStocks} />
      )}

      <MainHeader />
      <MainSidebar />
      <MainContent>
        <PageTitle
          firstSection={
            <>
              <span style={{ color: "#878c93", fontWeight: "500" }}>
                {branchName}
              </span>
              <h1>
                {[
                  translation[siteLang][
                    siteLang === "ar" ? "list" : "products"
                  ],
                  " ",
                  ...(!userBranchID
                    ? [translation[siteLang].refill.toLocaleLowerCase(), " "]
                    : []),
                  translation[siteLang][
                    siteLang === "ar" ? "products" : "list"
                  ].toLowerCase(),
                ]}
              </h1>
            </>
          }
          {...(userBranchID
            ? {}
            : {
                secondSection: (
                  <>
                    <Button
                      styleType="secondary"
                      size="lg"
                      label={[
                        translation[siteLang].back_to,
                        translation[siteLang].branches.toLowerCase(),
                      ].join(" ")}
                      buttonType="link"
                      url={`/branches`}
                    />
                  </>
                ),
              })}
        />
        <ViewListingPage
          {...rest}
          hasNoResults={
            userBranchID
              ? data.length === 0
              : !data?.[0]?.branch_products || data?.[0]?.branch_products === 0
          }
          tableFilter={{
            // hasSearch: true,
            // search: {
            //   ...rest.search,
            //   placeholder:
            //     translation[siteLang].search_all +
            //     " " +
            //     translation[siteLang].products.toLowerCase(),
            // },
            hasFilter: true,
            extraFiltersFullWidth: true,
            extraFilters: (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p style={{ margin: 0, color: "#101928" }}>
                  {translation[siteLang].refill_note}
                </p>
                <Button
                  styleType="primary"
                  size="md"
                  label={translation[siteLang].refill_selected}
                  buttonType="button"
                  onClick={onRefillSelected}
                  extraStyle={{
                    height: "45px",
                    visibility: showMultipleDelete ? "visible" : "hidden",
                  }}
                />
              </div>
            ),
          }}
        >
          <Table
            isHeaderFixed={true}
            headings={[
              ...(data.length > 0 &&
              (!userBranchID ? data[0]?.branch_products?.length > 0 : true) &&
              userPermissions?.branches?.update
                ? [
                    {
                      label: (
                        <InputCheckbox
                          checkboxStyle="checkbox"
                          props={{
                            onChange: () => onSelectTableRowHandler(0, true),
                            checked: isAllRowsSelected,
                            style: {
                              marginTop: "7px",
                              width: "15px",
                              height: "15px",
                            },
                          }}
                        />
                      ),
                      style: {
                        width: "50px",
                      },
                    },
                  ]
                : []),
              {
                label: translation[siteLang].product,
                style: {
                  width: "30%",
                },
              },
              {
                label:
                  translation[siteLang].quantity +
                  " (" +
                  translation[siteLang].available +
                  ")",
              },
              {
                label:
                  translation[siteLang].quantity +
                  " (" +
                  translation[siteLang].minimum +
                  ")",
              },
              {
                label:
                  translation[siteLang].quantity +
                  " (" +
                  translation[siteLang].maximum +
                  ")",
              },
              {
                label: translation[siteLang].commission,
              },
              {
                label: translation[siteLang].brand,
                style: {
                  width: "15%",
                },
              },
              ...(userPermissions?.branches?.update
                ? [
                    {
                      label: translation[siteLang].refill,
                      style: {
                        width: "80px",
                      },
                    },
                  ]
                : []),
            ]}
            rows={(userBranchID ? data : data?.[0]?.branch_products || []).map(
              (el: any) => ({
                columns: [
                  ...(userPermissions?.branches?.update
                    ? [
                        {
                          link_not_applied: true,
                          value: (
                            <InputCheckbox
                              checkboxStyle="checkbox"
                              props={{
                                onChange: () => onSelectTableRowHandler(el.id),
                                checked: el.table_selected,
                                style: {
                                  marginTop: "7px",
                                  width: "15px",
                                  height: "15px",
                                },
                              }}
                            />
                          ),
                        },
                      ]
                    : []),
                  {
                    value: (
                      <Product
                        image={el.product.image}
                        name={el.product.name}
                        info={
                          el.product.info
                            ? el.product.info?.substring(0, 15) +
                              (el.product.info?.length > 15 ? "..." : "")
                            : ""
                        }
                        price={
                          el.product.price
                            ? formatMoney(+el.product.price, siteLang)
                            : ""
                        }
                      />
                    ),
                  },
                  {
                    value: el.quantity,
                  },
                  {
                    value: el.min_quantity,
                  },
                  {
                    value: el.max_quantity,
                  },
                  {
                    value: el.commission,
                  },
                  {
                    value: el?.brand?.name,
                  },
                  ...(userPermissions?.branches?.update
                    ? [
                        {
                          link_not_applied: true,
                          value: (
                            <div className="page_listing_table_actions">
                              <Button
                                icon={
                                  <Edit
                                    width="15"
                                    height="15"
                                    color="#101928"
                                  />
                                }
                                buttonType="button"
                                styleType="secondary"
                                onClick={() =>
                                  refillStocks.onSetRefillToSubmit(
                                    el.product.id,
                                    el.quantity,
                                    { commission: el.commission }
                                  )
                                }
                                size="md"
                              />
                            </div>
                          ),
                        },
                      ]
                    : []),
                ],
              })
            )}
          />
        </ViewListingPage>
      </MainContent>
    </>
  );
};

export default ListProductsRefill;
