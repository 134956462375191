import { useEffect, useState } from "react";
import { UseFormWatch } from "react-hook-form";
import { Subscription } from "react-hook-form/dist/utils/createSubject";

const useWatchFormInputs = (
  defaultValues: any,
  watchInputFields: boolean,
  watch: UseFormWatch<any>
) => {
  const [watchedInputs, setWatchedInputs] = useState<any>(defaultValues);

  useEffect(() => {
    let subscription: Subscription | null = null;
    if (watchInputFields)
      subscription = watch((value) => setWatchedInputs(value));
    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, [watchInputFields, watch]);

  return watchedInputs;
};

export default useWatchFormInputs;
