import { useSelector } from "react-redux";
import { selectSettings } from "../../../store/selectors";
import { formatDate, formatMoney } from "../../../utils/formatter";
import { Wrapper } from "./styles";

const Invoice = ({
  title,
  date,
  products,
  extraFields,
}: {
  title: string;
  date: string;
  products: { quantity?: string; name: string; info?: string; price: number }[];
  extraFields?: { name: string; amount: number; isTotal?: boolean }[];
}) => {
  const { siteLang } = useSelector(selectSettings);

  return (
    <Wrapper>
      <div className="invoice_header">
        <h2>{title}</h2>
        <span>{formatDate(date, siteLang, false, { weekday: "long" })}</span>
      </div>
      {products.map((el, index) => (
        <div key={index} className="invoice_row">
          <div className="invoice_col">
            {el.quantity && <span>{el.quantity}</span>}
            <p>{el.name}</p>
            {el.info && <span>{el.info}</span>}
          </div>
          <div className="invoice_col">
            <p>{formatMoney(el.price, siteLang)}</p>
          </div>
        </div>
      ))}
      {extraFields?.map((field, index) => (
        <div className="invoice_row" key={index}>
          <div className="invoice_col">
            <p style={field.isTotal ? { fontWeight: 800 } : {}}>{field.name}</p>
          </div>
          <div className="invoice_col">
            <p style={field.isTotal ? { fontWeight: 800 } : {}}>
              {formatMoney(field.amount, siteLang)}
            </p>
          </div>
        </div>
      ))}
    </Wrapper>
  );
};

export default Invoice;
