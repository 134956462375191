import { useSelector } from "react-redux";
import { ButtonType } from "../../components/ui/Button/utils";
import Modal from "../../components/ui/Modal";
import { selectSettings } from "../../store/selectors";
import { Success, Warning } from "../../utils/icons";
import translation from "../../utils/translation";
import { UpdateModulePageType } from "./utils";

const UpdateModulePage = ({
  children,
  moduleBaseName,
  navigationWarning,
  onCloseNavigationWarningHandler,
  onNavigateBack,
  handleSubmit,
  onSubmitModuleHandler,
  updatedDataToSubmit,
  submissionState,
  onResetSubmissionStateHandler,
  urlParamToReplace,
}: UpdateModulePageType) => {
  const { siteLang } = useSelector(selectSettings);

  return (
    <>
      {(submissionState.submitSuccessful ||
        submissionState.submitError ||
        navigationWarning) && (
        <Modal
          maxWidth="400px"
          showHeader={true}
          headerTitle={
            translation[siteLang][
              submissionState.submitError
                ? "submission_error"
                : submissionState.submitSuccessful
                ? "submission_successful"
                : "warning"
            ]
          }
          showFooter={true}
          footerButtons={[
            {
              label: translation[siteLang].cancel,
              styleType: "secondary",
              size: "lg",
              buttonType: "button",
              onClick:
                submissionState.submitSuccessful || submissionState.submitError
                  ? () => onResetSubmissionStateHandler()
                  : onCloseNavigationWarningHandler,
            },
            ...(navigationWarning
              ? [
                  {
                    label: translation[siteLang].discard,
                    styleType: "danger",
                    size: "lg",
                    buttonType: "button",
                    onClick: () => onNavigateBack(moduleBaseName, true),
                  } as ButtonType,
                ]
              : []),
          ]}
          footerButtonsDirection="center"
        >
          <p
            style={{
              margin: "10px 0",
              textAlign: "center",
              fontWeight: 600,
            }}
          >
            {submissionState.submitSuccessful && (
              <>
                <Success
                  width="50px"
                  height="50px"
                  color="#00a36d"
                  extraStyle={{ marginBottom: "10px" }}
                />
                <br />
              </>
            )}
            {submissionState.submitError && (
              <>
                <Warning
                  width="60px"
                  height="60px"
                  color="#f23636"
                  extraStyle={{ marginBottom: "10px" }}
                />
                <br />
              </>
            )}
            {submissionState.submitError &&
            submissionState.submitError !== "__ERROR"
              ? submissionState.submitError
              : translation[siteLang][
                  submissionState.submitError
                    ? "submission_error_msg"
                    : submissionState.submitSuccessful
                    ? "submission_successful_msg"
                    : "sure_cancel_changes"
                ]}
          </p>
        </Modal>
      )}
      <form
        onSubmit={handleSubmit((submittedData) =>
          onSubmitModuleHandler(
            submittedData,
            updatedDataToSubmit,
            urlParamToReplace
          )
        )}
        noValidate={true}
      >
        {children}
      </form>
    </>
  );
};

export default UpdateModulePage;
