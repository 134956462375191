import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { useMemo } from "react";
import { Line } from "react-chartjs-2";
import * as dateFns from "date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom" as const,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

const Chart = ({
  chartDataSet,
  timeFrame,
}: {
  chartDataSet: {
    title: string;
    data: { daily_count: number; date_day: string }[];
  }[];
  timeFrame: "all" | number;
}) => {
  const chartData = useMemo(() => {
    const labelsSet: Set<any> = new Set(
      chartDataSet.map((el) => el.data.map((el) => el.date_day)).flat()
    );
    let labels: string[] = Array.from(labelsSet);

    if (timeFrame !== "all") {
      const minDayToInclude = dateFns.format(
        dateFns.sub(new Date(), { days: +timeFrame }),
        "yyyy-MM-dd"
      );
      labels = labels.filter((label) => label > minDayToInclude);
    }
    labels.sort();

    let datasets = chartDataSet.map((el, index) => ({
      label: el.title,
      data: labels.map(
        (day) => el.data.find((el) => el.date_day === day)?.daily_count || 0
      ),
      ...(index === 0
        ? {
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          }
        : {
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          }),
    }));

    return {
      labels,
      datasets,
    };
  }, [timeFrame, chartDataSet]);

  return <Line options={options} data={chartData} />;
};

export default Chart;
