import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import InputCheckbox from "../../components/ui/Inputs/InputCheckbox";
import Table from "../../components/ui/Table";
import MainContent from "../../containers/MainContent";
import ViewListingPage from "../../containers/ViewListingPage";
import useListingData from "../../hooks/useListingData";
import useFilterListing from "../../hooks/useFilterListing";
import { selectSettings, selectUser } from "../../store/selectors";
import { Delete, Edit } from "../../utils/icons";
import translation from "../../utils/translation";
import MainHeader from "../../containers/MainHeader";
import MainSidebar from "../../containers/MainSidebar";
import InputSelect from "../../components/ui/Inputs/InputSelect";
import { FilterWidgetSection } from "../../components/listingPages/ListingComponents";
import { formatDate } from "../../utils/formatter";

const filterDefaultValues: any = {
  "membership=": "__default",
  "shads=": "__default",
};

const ListClients = () => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { permissions: userPermissions },
  } = useSelector(selectUser);

  const {
    data,
    extraFetchedData,
    onRequestDeleteHandler,
    onSelectTableRowHandler,
    isAllRowsSelected,
    showMultipleDelete,
    ...rest
  } = useListingData({
    moduleName: "users",
    moduleType: "admin",
    number: "500",
    queries: "with=orders",
    filterDefaultValues,
  });

  const { handleSubmit, register, reset, filterFieldsValues } =
    useFilterListing(filterDefaultValues);

  const tableSelectedCount = data.filter((el) => el.table_selected).length;

  return (
    <>
      <MainHeader />
      <MainSidebar />
      <MainContent>
        <PageTitle
          firstSection={
            <>
              <h1>
                {[
                  translation[siteLang][siteLang === "ar" ? "list" : "clients"],
                  " ",
                  translation[siteLang][
                    siteLang === "ar" ? "clients" : "list"
                  ].toLowerCase(),
                ]}
              </h1>
              <span
                style={{
                  [siteLang === "ar" ? "marginLeft" : "marginRight"]: "10px",
                }}
              >
                {translation[siteLang].selected_count}: {tableSelectedCount}
              </span>
            </>
          }
          {...(userPermissions?.users?.create
            ? {
                secondSection: (
                  <Button
                    styleType="primary"
                    size="lg"
                    label={[
                      translation[siteLang][
                        siteLang === "ar" ? "client" : "new"
                      ],
                      translation[siteLang][
                        siteLang === "ar" ? "new" : "client"
                      ].toLowerCase(),
                    ].join(" ")}
                    buttonType="link"
                    url="/clients/new"
                  />
                ),
              }
            : {})}
        />
        <ViewListingPage
          {...rest}
          hasNoResults={data.length === 0}
          tableFilter={{
            showMultipleDelete,
            onRequestDeleteHandler,
            hasSearch: true,
            search: {
              ...rest.search,
              placeholder:
                translation[siteLang].search_all +
                " " +
                translation[siteLang].clients.toLowerCase(),
            },
            hasFilter: true,
            filter: {
              ...rest.filter,
              formSubmission: handleSubmit,
              reset,
              filterDefaultValues,
              filterContent: (
                <div>
                  <FilterWidgetSection>
                    <InputSelect
                      label={translation[siteLang].membership}
                      options={[
                        {
                          options: [
                            {
                              label: translation[siteLang].all,
                              value: "__default",
                            },
                            {
                              label: translation[siteLang].basic,
                              value: "BASIC",
                            },
                            {
                              label: translation[siteLang].silver,
                              value: "SILVER",
                            },
                            {
                              label: translation[siteLang].gold,
                              value: "GOLD",
                            },
                            {
                              label: translation[siteLang].plat,
                              value: "PLAT",
                            },
                          ],
                        },
                      ]}
                      props={{
                        ...register("membership="),
                        value: filterFieldsValues["membership="],
                      }}
                    />
                  </FilterWidgetSection>
                  <FilterWidgetSection>
                    <InputSelect
                      label={translation[siteLang].shads}
                      options={[
                        {
                          options: [
                            {
                              label: translation[siteLang].all,
                              value: "__default",
                            },
                            {
                              label: translation[siteLang].yes,
                              value: "1",
                            },
                            {
                              label: translation[siteLang].no,
                              value: "0",
                            },
                          ],
                        },
                      ]}
                      props={{
                        ...register("shads="),
                        value: filterFieldsValues["shads="],
                      }}
                    />
                  </FilterWidgetSection>
                </div>
              ),
            },
          }}
        >
          <Table
            isHeaderFixed={true}
            headings={[
              ...(data.length > 0 && userPermissions?.users?.delete
                ? [
                    {
                      label: (
                        <InputCheckbox
                          checkboxStyle="checkbox"
                          props={{
                            onChange: () => onSelectTableRowHandler(0, true),
                            checked: isAllRowsSelected,
                            style: {
                              marginTop: "7px",
                              width: "15px",
                              height: "15px",
                            },
                          }}
                        />
                      ),
                      style: {
                        width: "50px",
                      },
                    },
                  ]
                : []),
              {
                label: translation[siteLang].id,
                style: {
                  width: "70px",
                },
              },
              {
                label: translation[siteLang].name,
              },
              {
                label: translation[siteLang].membership,
                style: {
                  width: "110px",
                },
              },
              {
                label: translation[siteLang].shads,
                style: {
                  width: "70px",
                },
              },
              {
                label: translation[siteLang].phone,
              },
              {
                label: translation[siteLang].orders,
              },
              {
                label: translation[siteLang].email,
              },
              {
                label: translation[siteLang].birth_date,
                style: {
                  width: "120px",
                },
              },
              {
                label: translation[siteLang].registration_date,
              },
              ...(userPermissions?.users?.update ||
              userPermissions?.users?.delete
                ? [
                    {
                      label: translation[siteLang].actions,
                      style: {
                        width: "100px",
                      },
                    },
                  ]
                : []),
            ]}
            rows={data.map((el) => ({
              link: `/clients/view/${el.id}/details`,
              columns: [
                {
                  link_not_applied: true,
                  value: (
                    <InputCheckbox
                      checkboxStyle="checkbox"
                      props={{
                        onChange: () => onSelectTableRowHandler(el.id),
                        checked: el.table_selected,
                        style: {
                          marginTop: "7px",
                          width: "15px",
                          height: "15px",
                        },
                      }}
                    />
                  ),
                },
                {
                  value: el.id,
                },
                {
                  value: el.name,
                },
                {
                  value:
                    el.membership &&
                    translation[siteLang][el.membership.toLowerCase()],
                },
                {
                  value: translation[siteLang][el.shads ? "yes" : "no"],
                },
                {
                  value: el.phone,
                },
                {
                  value: el.orders?.length,
                },
                {
                  value: el.email,
                },
                {
                  value: el.birth_date,
                },
                {
                  value: formatDate(el.created_at, siteLang),
                },
                ...(userPermissions?.users?.update ||
                userPermissions?.users?.delete
                  ? [
                      {
                        link_not_applied: true,
                        value: (
                          <div className="page_listing_table_actions">
                            {userPermissions?.users?.update && (
                              <Button
                                icon={
                                  <Edit
                                    width="15"
                                    height="15"
                                    color="#101928"
                                  />
                                }
                                buttonType="link"
                                styleType="secondary"
                                url={`/clients/edit/${el.id}`}
                                size="md"
                              />
                            )}
                            {userPermissions?.users?.deletet && (
                              <Button
                                icon={
                                  <Delete
                                    width="15"
                                    height="15"
                                    color="#101928"
                                  />
                                }
                                buttonType="button"
                                styleType="secondary"
                                onClick={() => onRequestDeleteHandler([el.id])}
                                size="md"
                                className="delete"
                              />
                            )}
                          </div>
                        ),
                      },
                    ]
                  : []),
              ],
            }))}
          />
        </ViewListingPage>
      </MainContent>
    </>
  );
};

export default ListClients;
