import axios, { AxiosResponse } from "axios";
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import appAxios from "../utils/setupAxios";
import lodash from "lodash";
import { useSearchParams } from "react-router-dom";

const useListingData = ({
  moduleType,
  moduleName,
  queries = "",
  defaultSort = "id__desc",
  defaultFilter,
  extraFetchers,
  number = 10,
  isSubModuleListing = false,
  subModuleName = "",
  filterDefaultValues,
  localSearchFilter = false,
}: HookType) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [listingData, setListingData] = useState<{
    isFetching: boolean;
    data: any[];
    errorFetching: string;
    currentPage: number;
    lastPage: number;
    extraFetchedData: any;
    queryTotalResults: number;
  }>({
    isFetching: true,
    data: [],
    errorFetching: "",
    currentPage: +(searchParams.get("page") || 0) || 1,
    lastPage: 1,
    extraFetchedData: null,
    queryTotalResults: 0,
  });

  const [requestedPage, setRequestedPage] = useState<number>(
    +(searchParams.get("page") || 0) || 1
  );

  const isFirstMount = useRef<boolean>(true);

  const [sortData, setSortData] = useState<string>(
    searchParams.get("sort") || defaultSort
  );

  const defaultQueryFilter = useMemo(() => {
    let obj: { [name: string]: string } = { ...defaultFilter };
    Array.from(searchParams.entries()).forEach((val) => {
      if (val[0] !== "page" && val[0] !== "sort" && val[0] !== "search")
        obj = Object.assign(obj, { [val[0]]: val[1] });
    });
    return obj;
  }, [defaultFilter, searchParams]);
  const [filterData, setFilterData] = useState<{ [name: string]: any }>(
    defaultQueryFilter
  );
  const [filterState, setFilterState] = useState<{ active: boolean }>({
    active: Object.values(filterDefaultValues || {}).reduce(
      (acc, cur) => acc || cur !== "__default",
      false
    ),
  });

  const [search, setSearch] = useState<string | null>(
    searchParams.get("search") || null
  );
  const [searchValue, setSearchValue] = useState<string>(
    searchParams.get("search") || ""
  );

  const [idsToDelete, setIdsToDelete] = useState<{
    deleteError: string;
    deleteSuccessful: boolean;
    ids: number[];
    moduleToDeleteFrom?: string;
  }>({
    deleteError: "",
    deleteSuccessful: false,
    ids: [],
    moduleToDeleteFrom: "",
  });

  const updateSearchParams = useCallback(
    (
      values: { [name: string]: string },
      type?: "push" | "replaceAll" | "delete"
    ) => {
      if (type === "replaceAll") {
        setSearchParams(values);
      } else if (type === "delete") {
        Object.keys(values).forEach((val) => {
          searchParams.delete(val);
        });
        setSearchParams(
          Array.from(searchParams.entries()).reduce(
            (acc, cur) => Object.assign(acc, { [cur[0]]: cur[1].toString() }),
            {}
          )
        );
      } else {
        Object.entries(values).forEach((val) => {
          searchParams.set(val[0], val[1]);
        });
        setSearchParams(
          Array.from(searchParams.entries()).reduce(
            (acc, cur) => Object.assign(acc, { [cur[0]]: cur[1].toString() }),
            {}
          )
        );
      }
    },
    [searchParams, setSearchParams]
  );

  const persistPage = useCallback(
    (page: number) => {
      updateSearchParams({ page: page.toString() });
      setRequestedPage(page);
    },
    [updateSearchParams]
  );

  const onChangeSearchHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    },
    []
  );

  const onChangePageHandler = useCallback(
    (page: number) => {
      document.getElementById("page_content_wrapper")?.scrollTo(0, 0);
      persistPage(page);
    },
    [persistPage]
  );

  const onCancelSearchHandler = useCallback(() => {
    setSearch(null);
    if (!localSearchFilter) {
      updateSearchParams({ search: "" }, "delete");
      setSearchValue("");
    }
    if (listingData.currentPage > 1) persistPage(1);
  }, [
    listingData.currentPage,
    persistPage,
    updateSearchParams,
    localSearchFilter,
  ]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (search !== null && !localSearchFilter) {
        setSearchValue(search.trim());
        updateSearchParams({ search: search.trim() });
        persistPage(1);
      }
    }, 1000);
    return () => clearTimeout(timeOut);
  }, [search, persistPage, updateSearchParams, localSearchFilter]);

  const onChangeSortHandler = useCallback(
    (value: string) => {
      setSortData(value);
      updateSearchParams({ sort: value });
      persistPage(1);
    },
    [persistPage, updateSearchParams]
  );

  const onChangeFilterHandler = useCallback(
    (filter: { [name: string]: any }) => {
      setFilterData((current) => ({ ...current, ...filter }));
      Object.entries({ ...filterData, ...filter })
        .filter((val) => val[1] === "__default")
        .reduce((acc, cur) => Object.assign(acc, { [cur[0]]: cur[1] }), {});
      updateSearchParams(
        Object.entries({ ...filterData, ...filter })
          .filter((val) => val[1] === "__default")
          .reduce((acc, cur) => Object.assign(acc, { [cur[0]]: cur[1] }), {}),
        "delete"
      );
      updateSearchParams(
        Object.entries({ ...filterData, ...filter })
          .filter((val) => val[1] !== "__default")
          .reduce((acc, cur) => Object.assign(acc, { [cur[0]]: cur[1] }), {})
      );
      if (filterDefaultValues)
        setFilterState({
          active: !lodash.isEqual(filterDefaultValues, filter),
        });
      persistPage(1);
    },
    [filterDefaultValues, persistPage, filterData, updateSearchParams]
  );

  const onRequestDeleteHandler = useCallback(
    (
      ids: number[],
      selected: boolean = false,
      moduleToDeleteFrom: string = ""
    ) => {
      setIdsToDelete((current) => ({
        ...current,
        moduleToDeleteFrom,
        ids: selected
          ? listingData.data
              .filter((el: any) => el.table_selected)
              .map((el: any) => el.id)
          : ids,
      }));
    },
    [listingData.data]
  );

  const onCancelRequestDeleteHandler = useCallback(() => {
    setIdsToDelete({
      deleteError: "",
      deleteSuccessful: false,
      ids: [],
      moduleToDeleteFrom: "",
    });
  }, []);

  const onDeleteRowsHandler = useCallback(async () => {
    setListingData((current) => ({
      ...current,
      isFetching: true,
    }));

    let deleteError = "",
      deleteSuccessful = false;

    const deletingFunctions = idsToDelete.ids.map((id) =>
      appAxios.delete(
        `${moduleType === "branch" ? "branch-" : ""}module/${
          idsToDelete.moduleToDeleteFrom || moduleName
        }/delete/${id}`
      )
    );

    let extraFetchedData: any = {};
    extraFetchers?.forEach((fetching) => {
      if (fetching.afterDeletion) {
        deletingFunctions.push(fetching.function());
      }
    });

    await axios
      .all(deletingFunctions)
      .then((res) => {
        deleteSuccessful = true;
        if (deletingFunctions.length > idsToDelete.ids.length) {
          extraFetchers?.forEach((fetching, index) => {
            if (fetching.afterDeletion) {
              extraFetchedData[fetching.returnName] = fetching.isModule
                ? res[index + idsToDelete.ids.length].data.module.data
                : res[index + idsToDelete.ids.length].data[fetching.returnName];
            }
          });
        }
      })
      .catch((error) => {
        deleteError = error?.response?.data?.message || "__ERROR";
      });

    setIdsToDelete((current) => ({
      isDeleting: false,
      deleteError,
      deleteSuccessful,
      ids: deleteSuccessful ? [] : current.ids,
    }));

    if (
      listingData.data.length !== idsToDelete.ids.length ||
      listingData.currentPage === 1
    ) {
      setListingData((current) => ({
        ...current,
        isFetching: false,
        data: current.data.filter((el) => !idsToDelete.ids.includes(el.id)),
        extraFetchedData: {
          ...current.extraFetchedData,
          ...extraFetchedData,
        },
      }));
    } else {
      persistPage(listingData.currentPage - 1);
    }
  }, [
    idsToDelete,
    moduleName,
    moduleType,
    listingData,
    extraFetchers,
    persistPage,
  ]);

  const onSelectTableRowHandler = useCallback(
    (id: number, all: boolean = false) => {
      setListingData((current) => {
        let changeAllTo = false;
        if (all) {
          if (isSubModuleListing && subModuleName)
            current.data[0][subModuleName].forEach((el: any) => {
              if (!el.table_selected) {
                changeAllTo = true;
              }
            });
          else
            current.data.forEach((el: any) => {
              if (!el.table_selected) {
                changeAllTo = true;
              }
            });
        }
        return {
          ...current,
          data: current.data.map((el: any) => ({
            ...el,
            ...(!isSubModuleListing && !subModuleName
              ? {
                  table_selected: all
                    ? changeAllTo
                    : el.id === id
                    ? !el.table_selected
                    : el.table_selected,
                }
              : {
                  [subModuleName]: el?.[subModuleName]?.map((el: any) => ({
                    ...el,
                    table_selected: all
                      ? changeAllTo
                      : el.id === id
                      ? !el.table_selected
                      : el.table_selected,
                  })),
                }),
          })),
        };
      });
    },
    [isSubModuleListing, subModuleName]
  );

  const isAllRowsSelected = useMemo(() => {
    let isAll = true;
    if (isSubModuleListing && subModuleName)
      listingData.data?.[0]?.[subModuleName]?.forEach((el: any) => {
        if (!el.table_selected) {
          isAll = false;
        }
      });
    else
      listingData.data.forEach((el: any) => {
        if (!el.table_selected) {
          isAll = false;
        }
      });
    return isSubModuleListing && subModuleName
      ? listingData.data?.[0]?.[subModuleName].length > 0
        ? isAll
        : false
      : listingData.data.length > 0
      ? isAll
      : false;
  }, [listingData.data, isSubModuleListing, subModuleName]);

  const showMultipleDelete = useMemo(() => {
    let show = false;
    if (isSubModuleListing && subModuleName)
      listingData.data?.[0]?.[subModuleName]?.forEach((el: any) => {
        if (el.table_selected) {
          show = true;
        }
      });
    else
      listingData.data.forEach((el: any) => {
        if (el.table_selected) {
          show = true;
        }
      });
    return show;
  }, [listingData.data, isSubModuleListing, subModuleName]);

  useEffect(() => {
    const fetchController = new AbortController();
    const fetchControllerSignal = fetchController.signal;

    if (!isFirstMount.current)
      setListingData((current) => ({
        ...current,
        isFetching: true,
        errorFetching: "",
      }));

    let errorFetching = "",
      data: any[] = [],
      currentPage = requestedPage,
      lastPage = 1,
      extraFetchedData: any = {},
      queryTotalResults: number = 0;

    let filterQuery = "";

    filterQuery =
      Object.values(filterData).findIndex((el) => el !== "__default") !== -1
        ? Object.entries(filterData).reduce(
            (acc, cur) =>
              acc +
              (cur[1] !== "__default"
                ? `${acc ? "," : ""}${cur[0] + cur[1]}`
                : ""),
            ""
          )
        : "";

    const sortQuery =
      (sortData.split("__")[1] === "asc" ? "-" : "") + sortData.split("__")[0];

    let fetchingFunctions = [
      appAxios.get(
        `${
          moduleType === "branch" ? "branch-" : ""
        }module/${moduleName}/data?number=${number}&page=${requestedPage}${`&sort=${sortQuery}`}${
          searchValue ? `&search=${searchValue}` : ""
        }${queries ? `&${queries}` : ""}${
          filterQuery
            ? (queries && queries.includes("where") ? "," : "&where=") +
              filterQuery
            : ""
        }`,
        {
          signal: fetchControllerSignal,
        }
      ),
    ];

    if (isFirstMount.current) {
      extraFetchers?.forEach((fetching) => {
        fetchingFunctions.push(fetching.function(fetchControllerSignal));
      });
    }

    (async () => {
      await axios
        .all(fetchingFunctions)
        .then((res) => {
          data = res[0].data.module.data.map((el: any) => ({
            ...el,
            table_selected: false,
            ...(isSubModuleListing && subModuleName
              ? {
                  [subModuleName]: el?.[subModuleName]?.map((el: any) => ({
                    ...el,
                    table_selected: false,
                  })),
                }
              : {}),
          }));
          lastPage = res[0].data.module.last_page;
          queryTotalResults = res[0].data.module.total;
          if (isFirstMount.current) {
            extraFetchers?.forEach((fetching, index) => {
              extraFetchedData[fetching.returnName] = fetching.isModule
                ? res[index + 1].data.module.data
                : res[index + 1].data[fetching.returnName];
            });
          }
        })
        .catch((error) => {
          errorFetching =
            error.message === "canceled"
              ? "aborted"
              : error?.response?.data?.message || "ERROR";
        });
      if (errorFetching !== "aborted")
        setListingData((current) => ({
          ...current,
          isFetching: false,
          data,
          errorFetching,
          currentPage,
          lastPage,
          queryTotalResults,
          ...(isFirstMount.current && extraFetchers && extraFetchers.length > 0
            ? { extraFetchedData }
            : {}),
        }));
      isFirstMount.current = false;
    })();
    return () => {
      if (fetchController) fetchController.abort();
    };
  }, [
    moduleType,
    moduleName,
    requestedPage,
    number,
    queries,
    sortData,
    searchValue,
    filterData,
    extraFetchers,
    isSubModuleListing,
    subModuleName,
    searchParams,
  ]);

  return {
    isFetching: listingData.isFetching,
    data: listingData.data,
    sort: {
      sortParam: sortData,
      onChangeSortHandler,
    },
    filter: {
      filterData,
      onChangeFilterHandler,
      isActive: filterState.active,
    },
    search: {
      searchValue: search,
      onChangeSearchHandler,
      onCancelSearchHandler,
    },
    tablePagination: {
      currentPage: listingData.currentPage,
      lastPage: listingData.lastPage,
      queryTotalResults: listingData.queryTotalResults,
      onChangePageHandler,
    },
    extraFetchedData: listingData.extraFetchedData,
    deletingState: idsToDelete,
    onRequestDeleteHandler,
    onCancelRequestDeleteHandler,
    onDeleteRowsHandler,
    onSelectTableRowHandler,
    isAllRowsSelected,
    showMultipleDelete,
    setListingData,
  };
};

export default useListingData;

type HookType = {
  moduleType: "admin" | "branch";
  moduleName: string;
  queries?: string;
  number?: number | string;
  defaultSort?: string;
  defaultFilter?: { [name: string]: any };
  extraFetchers?: {
    function: (signal?: AbortSignal) => Promise<AxiosResponse<any, any>>;
    returnName: string;
    afterDeletion?: boolean;
    isModule?: boolean;
  }[];
  isSubModuleListing?: boolean;
  subModuleName?: string;
  filterDefaultValues?: {
    [name: string]: any;
  };
  localSearchFilter?: boolean;
};
