import axios, { AxiosRequestHeaders } from "axios";
import appAxios from "./setupAxios";

const resolveEmptyPromise = async () => {
  return await new Promise((resolve) => {
    resolve(true);
  });
};

export const getAllBranches = async (signal?: AbortSignal) => {
  return await appAxios.get("branch/all", signal ? { signal } : {});
};

export const getAllCategories = async (signal?: AbortSignal) => {
  return await appAxios.get("category/all", signal ? { signal } : {});
};

export const assignStocksToService = async (
  serviceID: number,
  stocks: { id: number; used_amount: number }[],
  signal?: AbortSignal
) => {
  if (stocks.length === 0) return await resolveEmptyPromise();
  else
    return await appAxios.post(`service/assign/stocks/to/${serviceID}`, {
      stocks,
      ...(signal ? { signal } : {}),
    });
};

export const removeStocksFromService = async (
  serviceID: number,
  stocks: { id: number }[],
  signal?: AbortSignal
) => {
  if (stocks.length === 0) return await resolveEmptyPromise();
  else {
    const deleteFns = stocks.map((el) =>
      appAxios.delete(`service/remove/stock/${el.id}/from/${serviceID}`, {
        data: {
          ...(signal ? { signal } : {}),
        },
      })
    );
    return await axios.all(deleteFns);
  }
};

export const getAllBrands = async (signal?: AbortSignal) => {
  return await appAxios.get("brand/all", signal ? { signal } : {});
};

export const getModuleData = async (
  moduleType: "branch" | "admin",
  moduleName: string,
  queries?: string,
  signal?: AbortSignal
) => {
  return await appAxios.get(
    `${
      moduleType === "branch" ? "branch-" : ""
    }module/${moduleName}/data?number=all${queries ? "&" + queries : ""}`,
    signal ? { signal } : {}
  );
};

export const getSingleModuleData = async (
  moduleType: "branch" | "admin",
  moduleName: string,
  moduleID: number,
  queries?: string,
  signal?: AbortSignal,
  headers?: AxiosRequestHeaders
) => {
  return await appAxios.get(
    `${
      moduleType === "branch" ? "branch-" : ""
    }module/${moduleName}/single/${moduleID}?number=all${
      queries ? "&" + queries : ""
    }`,
    {
      ...headers,
      ...(signal ? { signal } : {}),
    }
  );
};

export const assignProductsToOrder = async (
  orderID: number,
  products: { id: number; quantity: number }[],
  signal?: AbortSignal
) => {
  if (products.length === 0) return await resolveEmptyPromise();
  else
    return await appAxios.post(`order/worker/add/products/to/${orderID}`, {
      products,
      ...(signal ? { signal } : {}),
    });
};

export const removeProductsFromOrder = async (
  orderID: number,
  products: { id: number }[],
  signal?: AbortSignal
) => {
  if (products.length === 0) return await resolveEmptyPromise();
  else {
    const deleteFns = products.map((el) =>
      appAxios.delete(`order/worker/remove/product/${el.id}/from/${orderID}`, {
        data: {
          ...(signal ? { signal } : {}),
        },
      })
    );
    return await axios.all(deleteFns);
  }
};

export const removeServicesFromOrder = async (
  orderID: number,
  services: { id: number }[],
  signal?: AbortSignal
) => {
  if (services.length === 0) return await resolveEmptyPromise();
  else {
    const deleteFns = services.map((el) =>
      appAxios.delete(`order/worker/remove/service/${el.id}/from/${orderID}`, {
        data: {
          ...(signal ? { signal } : {}),
        },
      })
    );
    return await axios.all(deleteFns);
  }
};

export const assignServicesToOrder = async (
  orderID: number,
  services: { id: number; worker: number }[],
  signal?: AbortSignal
) => {
  if (services.length === 0) return await resolveEmptyPromise();
  else {
    const assignFns = services.map((el) =>
      appAxios.post(`order/worker/add/service/${el.id}/to/${orderID}`, {
        worker: el.worker,
        ...(signal ? { signal } : {}),
      })
    );
    return await axios.all(assignFns);
  }
};

export const assignWorkersToOrder = async (
  orderID: number,
  workers: { id: number; service: number }[],
  signal?: AbortSignal
) => {
  if (workers.length === 0) return await resolveEmptyPromise();
  else {
    const assignFns = workers.map((el) =>
      appAxios.post(`order/branch/assign/worker/${el.id}/to/${orderID}`, {
        services: [el.service],
        ...(signal ? { signal } : {}),
      })
    );
    return await axios.all(assignFns);
  }
};

export const removeWorkersFromOrder = async (
  orderID: number,
  workers: { id: number; service: number }[],
  signal?: AbortSignal
) => {
  if (workers.length === 0 && workers.filter((el: any) => el.id).length === 0)
    return await resolveEmptyPromise();
  else {
    const deleteFns = workers
      .filter((el: any) => el.id)
      .map((el) =>
        appAxios.delete(`order/branch/remove/worker/${el.id}/from/${orderID}`, {
          data: {
            services: [el.service],
            ...(signal ? { signal } : {}),
          },
        })
      );
    return await axios.all(deleteFns);
  }
};

export const assignServicesToWorker = async (
  workerID: number,
  services: { id: number }[],
  signal?: AbortSignal
) => {
  if (services.length === 0) return await resolveEmptyPromise();
  else {
    const assignFns = services.map((el) =>
      appAxios.post(`worker/assign/${workerID}/service/${el.id}`, {
        ...(signal ? { signal } : {}),
      })
    );
    return await axios.all(assignFns);
  }
};

export const removeServicesFromWorker = async (
  workerID: number,
  services: { id: number }[],
  signal?: AbortSignal
) => {
  if (services.length === 0) return await resolveEmptyPromise();
  else {
    const deleteFns = services.map((el) =>
      appAxios.delete(`worker/remove/${workerID}/service/${el.id}`, {
        data: {
          ...(signal ? { signal } : {}),
        },
      })
    );
    return await axios.all(deleteFns);
  }
};

export const assignWorkdays = async (
  type: "worker" | "branch",
  moduleID: number,
  days: { [name: string]: { on: boolean; from: string; to: string } },
  signal?: AbortSignal
) => {
  if (Object.keys(days).length === 0) return await resolveEmptyPromise();
  else {
    return appAxios.post(`work-day/create`, {
      for_branch: type === "branch" ? true : false,
      [type === "branch" ? "branch_id" : "worker_id"]: moduleID,
      ...days,
      ...(signal ? { signal } : {}),
    });
  }
};

export const updateWorkdays = async (
  days: { id: number; on: boolean; from: string; to: string }[],
  signal?: AbortSignal
) => {
  if (days.length === 0) return await resolveEmptyPromise();
  else {
    const assignFns = days.map(({ id, ...rest }) =>
      appAxios.patch(`work-day/update/${id}`, {
        ...rest,
        ...(signal ? { signal } : {}),
      })
    );
    return await axios.all(assignFns);
  }
};

export const addClientAddress = async (
  clientID: number,
  addresses: any[],
  signal?: AbortSignal
) => {
  if (addresses.length === 0) return await resolveEmptyPromise();
  else {
    const assignFns = addresses.map((el) =>
      appAxios.post(`address/admin/create`, {
        ...el,
        user_id: clientID,
        ...(signal ? { signal } : {}),
      })
    );
    return await axios.all(assignFns);
  }
};

export const updateClientAddresses = async (
  addresses: any[],
  signal?: AbortSignal
) => {
  if (addresses.length === 0) return await resolveEmptyPromise();
  else {
    const assignFns = addresses.map((el) =>
      appAxios.post(`address/admin/update/${el.id}`, {
        ...el,
        ...(signal ? { signal } : {}),
      })
    );
    return await axios.all(assignFns);
  }
};

export const getAllCities = async (signal?: AbortSignal) => {
  return await appAxios.get("city", signal ? { signal } : {});
};

export const assignServicesToBranch = async (
  branchID: number | string,
  services: { id: number }[],
  signal?: AbortSignal
) => {
  if (services.length === 0 || !branchID) return await resolveEmptyPromise();
  else {
    const assignFns = services.map((el) =>
      appAxios.post(`branch/assign/${el.id}/to/${branchID}`, {
        ...(signal ? { signal } : {}),
      })
    );
    return await axios.all(assignFns);
  }
};

export const removeServicesFromBranch = async (
  branchID: number | string,
  services: { id: number }[],
  signal?: AbortSignal
) => {
  if (services.length === 0 || !branchID) return await resolveEmptyPromise();
  else {
    const deleteFns = services.map((el) =>
      appAxios.delete(`branch/remove/${el.id}/from/${branchID}`, {
        data: {
          ...(signal ? { signal } : {}),
        },
      })
    );
    return await axios.all(deleteFns);
  }
};

export const assignBranchToRegion = async (
  branchID: number,
  regions: number[],
  signal?: AbortSignal
) => {
  if (regions.length === 0) return await resolveEmptyPromise();
  else {
    const assignFns = regions.map((el) =>
      appAxios.post(`branch/add/${branchID}/to/region/${el}`, {
        ...(signal ? { signal } : {}),
      })
    );
    return await axios.all(assignFns);
  }
};

export const removeBranchFromRegion = async (
  branchID: number,
  regions: number[],
  signal?: AbortSignal
) => {
  if (regions.length === 0) return await resolveEmptyPromise();
  else {
    const deleteFns = regions.map((el) =>
      appAxios.delete(`branch/remove/${branchID}/from/region/${el}`, {
        data: {
          ...(signal ? { signal } : {}),
        },
      })
    );
    return await axios.all(deleteFns);
  }
};

export const assignPermissionToRole = async (
  roleID: number,
  permissions: number[],
  signal?: AbortSignal
) => {
  if (permissions.length === 0) return await resolveEmptyPromise();
  else {
    return appAxios.post(`role/add/permissions/to/${roleID}`, {
      permissions,
      ...(signal ? { signal } : {}),
    });
  }
};

export const removePermissionFromRole = async (
  roleID: number,
  permissions: number[],
  signal?: AbortSignal
) => {
  if (permissions.length === 0) return await resolveEmptyPromise();
  else {
    const deleteFns = permissions.map((el) =>
      appAxios.delete(`role/remove/${el}/from/${roleID}`, {
        data: {
          ...(signal ? { signal } : {}),
        },
      })
    );
    return await axios.all(deleteFns);
  }
};

export const assignRoleToAdmin = async (
  adminID: number,
  roles: number[],
  signal?: AbortSignal
) => {
  if (roles.length === 0) return await resolveEmptyPromise();
  else {
    const assignFns = roles.map((el) =>
      appAxios.post(`role/user/assign/${el}/to/${adminID}`, {
        ...(signal ? { signal } : {}),
      })
    );
    return await axios.all(assignFns);
  }
};

export const removeRoleFromAdmin = async (
  adminID: number,
  roles: number[],
  signal?: AbortSignal
) => {
  if (roles.length === 0) return await resolveEmptyPromise();
  else {
    const deleteFns = roles.map((el) =>
      appAxios.delete(`role/user/remove/${el}/from/${adminID}`, {
        data: {
          ...(signal ? { signal } : {}),
        },
      })
    );
    return await axios.all(deleteFns);
  }
};

export const uploadProductImages = async (
  productID: number,
  images: File[],
  signal?: AbortSignal
) => {
  if (images.length === 0) return await resolveEmptyPromise();
  else {
    const assignFns = images.map((el) => {
      const formData = new FormData();
      formData.append("image", el);
      return appAxios.post(`product/upload/image/${productID}`, formData);
    });
    return await axios.all(assignFns);
  }
};

export const removeProductImages = async (
  productID: number,
  images: number[],
  signal?: AbortSignal
) => {
  if (images.length === 0) return await resolveEmptyPromise();
  else {
    const deleteFns = images.map((el) =>
      appAxios.delete(`product/remove/image/${el}/from/${productID}`)
    );
    return await axios.all(deleteFns);
  }
};

export const assignProductsToBranch = async (
  branchID: number,
  products: { id: number; quantity: number; commission: number }[],
  signal?: AbortSignal
) => {
  if (products.length === 0) return await resolveEmptyPromise();
  else
    return await appAxios.post(`product/assign/products`, {
      products,
      branch_id: branchID,
      ...(signal ? { signal } : {}),
    });
};

export const removeProductsFromBranch = async (
  products: number[],
  signal?: AbortSignal
) => {
  if (products.length === 0) return await resolveEmptyPromise();
  else {
    const deleteFns = products.map((el) =>
      appAxios.delete(`product/remove/${el}`, {
        data: {
          ...(signal ? { signal } : {}),
        },
      })
    );
    return await axios.all(deleteFns);
  }
};

export const payOrder = async (
  orderID: number,
  isCheckedOut: 0 | 1,
  methods: string[],
  signal?: AbortSignal
) => {
  if (isCheckedOut === 0 || methods.length === 0)
    return await resolveEmptyPromise();
  else {
    const assignFns = methods.map((el) => {
      const formData = new FormData();
      formData.append("payment_method", el);
      return appAxios.post(`order/branch/pay/${orderID}`, formData);
    });
    return await axios.all(assignFns);
  }
};

export const refundOrder = async (
  orderID: number,
  data: {
    refund_reason_en: string;
    refund_reason_ar: string;
    return_to_wallet: boolean;
  },
  signal?: AbortSignal
) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, val]) => {
    formData.append(key, typeof val === "boolean" ? (val ? "1" : "0") : val);
  });

  return await appAxios.post(`order/branch/refund/${orderID}`, formData);
};

export const refill = async (
  type: "stock" | "product",
  items: { [name: string]: number[] },
  signal?: AbortSignal
) => {
  if (Object.values(items)[0].length > 0)
    return await appAxios.post(`${type}/refill`, items);
  else return await resolveEmptyPromise();
};

export const uploadWorkerProfilePic = async (
  workerID: number,
  images: File[],
  signal?: AbortSignal
) => {
  if (images.length === 0) return await resolveEmptyPromise();
  else {
    const formData = new FormData();
    formData.append("profile_picture", images[0]);
    return appAxios.post(`worker/upload/profile-picture/${workerID}`, formData);
  }
};

export const deleteMilestone = async (
  milestoneID: number,
  signal?: AbortSignal
) => {
  return await appAxios.delete(`milestone/delete/${milestoneID}`);
};

export const createMilestone = async (
  data: {
    user_id: number;
    reason_en: string;
    reason_ar: string;
    points: string | number;
  },
  signal?: AbortSignal
) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, val]) => {
    formData.append(key, val.toString());
  });

  return await appAxios.post(`milestone/create`, formData);
};
