import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import InputCheckbox from "../../components/ui/Inputs/InputCheckbox";
import Table from "../../components/ui/Table";
import MainContent from "../../containers/MainContent";
import ViewListingPage from "../../containers/ViewListingPage";
import useListingData from "../../hooks/useListingData";
import { selectSettings, selectUser } from "../../store/selectors";
import { Delete, Edit } from "../../utils/icons";
import translation from "../../utils/translation";
import MainHeader from "../../containers/MainHeader";
import MainSidebar from "../../containers/MainSidebar";

const ListRoles = () => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { permissions: userPermissions },
  } = useSelector(selectUser);

  const {
    data,
    extraFetchedData,
    onRequestDeleteHandler,
    onSelectTableRowHandler,
    isAllRowsSelected,
    showMultipleDelete,
    ...rest
  } = useListingData({
    moduleName: "roles",
    moduleType: "admin",
  });

  return (
    <>
      <MainHeader />
      <MainSidebar />
      <MainContent
        {...(userPermissions?.admins?.view
          ? {
              hasContentSidebar: true,
              contentSidebarSections: [
                {
                  title: translation[siteLang].admins,
                  links: [
                    {
                      label: translation[siteLang].admins,
                      url: "/admins",
                    },
                    {
                      label: translation[siteLang].roles,
                      url: "/admins/roles",
                    },
                  ],
                },
              ],
            }
          : {
              hasContentSidebar: false,
            })}
      >
        <PageTitle
          firstSection={
            <h1>
              {[
                translation[siteLang][siteLang === "ar" ? "list" : "roles"],
                " ",
                translation[siteLang][
                  siteLang === "ar" ? "roles" : "list"
                ].toLowerCase(),
              ]}
            </h1>
          }
          {...(userPermissions?.roles?.create
            ? {
                secondSection: (
                  <Button
                    styleType="primary"
                    size="lg"
                    label={[
                      translation[siteLang][siteLang === "ar" ? "role" : "new"],
                      translation[siteLang][
                        siteLang === "ar" ? "new" : "role"
                      ].toLowerCase(),
                    ].join(" ")}
                    buttonType="link"
                    url="/admins/roles/new"
                  />
                ),
              }
            : {})}
        />
        <ViewListingPage
          {...rest}
          hasNoResults={data.length === 0}
          tableFilter={{
            showMultipleDelete,
            onRequestDeleteHandler,
            hasSearch: true,
            search: {
              ...rest.search,
              placeholder:
                translation[siteLang].search_all +
                " " +
                translation[siteLang].roles.toLowerCase(),
            },
          }}
        >
          <Table
            isHeaderFixed={true}
            headings={[
              ...(data.length > 0 && userPermissions?.roles?.delete
                ? [
                    {
                      label: (
                        <InputCheckbox
                          checkboxStyle="checkbox"
                          props={{
                            onChange: () => onSelectTableRowHandler(0, true),
                            checked: isAllRowsSelected,
                            style: {
                              marginTop: "7px",
                              width: "15px",
                              height: "15px",
                            },
                          }}
                        />
                      ),
                      style: {
                        width: "50px",
                      },
                    },
                  ]
                : []),
              {
                label: translation[siteLang].role,
              },
              ...(userPermissions?.roles?.update ||
              userPermissions?.roles?.delete
                ? [
                    {
                      label: translation[siteLang].actions,
                      style: {
                        width: "100px",
                      },
                    },
                  ]
                : []),
            ]}
            rows={data.map((el) => ({
              columns: [
                ...(userPermissions?.roles?.delete
                  ? [
                      {
                        link_not_applied: true,
                        value: (
                          <InputCheckbox
                            checkboxStyle="checkbox"
                            props={{
                              onChange: () => onSelectTableRowHandler(el.id),
                              checked: el.table_selected,
                              style: {
                                marginTop: "7px",
                                width: "15px",
                                height: "15px",
                              },
                            }}
                          />
                        ),
                      },
                    ]
                  : []),
                {
                  value: el.name,
                },
                ...(userPermissions?.roles?.update ||
                userPermissions?.roles?.delete
                  ? [
                      {
                        link_not_applied: true,
                        value: (
                          <div className="page_listing_table_actions">
                            {userPermissions?.roles?.update && (
                              <Button
                                icon={
                                  <Edit
                                    width="15"
                                    height="15"
                                    color="#101928"
                                  />
                                }
                                buttonType="link"
                                styleType="secondary"
                                url={`/admins/roles/edit/${el.id}`}
                                size="md"
                              />
                            )}
                            {userPermissions?.roles?.delete && (
                              <Button
                                icon={
                                  <Delete
                                    width="15"
                                    height="15"
                                    color="#101928"
                                  />
                                }
                                buttonType="button"
                                styleType="secondary"
                                onClick={() => onRequestDeleteHandler([el.id])}
                                size="md"
                                className="delete"
                              />
                            )}
                          </div>
                        ),
                      },
                    ]
                  : []),
              ],
            }))}
          />
        </ViewListingPage>
      </MainContent>
    </>
  );
};

export default ListRoles;
