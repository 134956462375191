import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import InputCheckbox from "../../components/ui/Inputs/InputCheckbox";
import InputField from "../../components/ui/Inputs/InputField";
import InputFile from "../../components/ui/Inputs/InputFile";
import InputSelect from "../../components/ui/Inputs/InputSelect";
import {
  BoxWrapper,
  SectionsWrapper,
} from "../../components/updatingPages/components";
import MainContent from "../../containers/MainContent";
import useUpdatingData from "../../hooks/useUpdatingData";
import { selectSettings, selectUser } from "../../store/selectors";
import { getAllBranches } from "../../utils/fetchers";
import { Arrow } from "../../utils/icons";
import translation from "../../utils/translation";
import { useMemo } from "react";
import UpdateModulePage from "../../containers/UpdateModulePage";
import { validateInputField } from "../../containers/UpdateModulePage/utils";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { useParams } from "react-router-dom";

const moduleBaseName = "/stocks";

const UpdateStocks = ({ type }: { type: "create" | "edit" }) => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { branch_id: userBranchID },
  } = useSelector(selectUser);
  const { stockID } = useParams();

  const extraFetchers = useMemo(
    () => [
      ...(userBranchID
        ? []
        : [
            {
              function: getAllBranches,
              returnName: "branches",
            },
          ]),
    ],
    [userBranchID]
  );

  const defaultValues = useMemo(
    () => ({
      name_en: "",
      name_ar: "",
      stock_availability: false,
      branch_id: userBranchID ? userBranchID : "no_option",
      quantity: "",
      multi_use: false,
      description_en: "",
      descritption_ar: "",
      use_times: "",
      price: "",
      min_quantity: "",
      max_quantity: "",
      sku: "",
      image: [],
    }),
    [userBranchID]
  );

  const resetModuleDefaults = useMemo(
    () => (clonedDefaults: any) => ({
      image: [],
      branch_id: clonedDefaults?.branch_id?.toString() || "no_option",
    }),
    []
  );

  const {
    isFetching,
    extraFetchedData,
    register,
    watchedInputs,
    setValue,
    moduleDefaultData,
    ...updatingModule
  } = useUpdatingData({
    moduleType: userBranchID ? "branch" : "admin",
    moduleState: type,
    moduleID: +(stockID || 0),
    ...(type === "edit" ? { resetModuleDefaults } : {}),
    moduleName: "stocks",
    modulePostEndpoint:
      type === "edit" ? `stock/update/${stockID}` : "stock/create",
    modulePostType: "formData",
    watchInputFields: true,
    defaultValues,
    navigateAfterSubmissionTo: moduleBaseName,
    extraFetchers,
  });

  const formState = updatingModule.formState;

  const updatedDataToSubmit = useMemo(() => {
    return (data: any) => ({
      image: data.image?.[0]?.file || "",
    });
  }, []);

  return (
    <MainContent onlyInPage={true} hasFixedWidthHeader={true}>
      <UpdateModulePage
        moduleBaseName={moduleBaseName}
        updatedDataToSubmit={updatedDataToSubmit}
        {...updatingModule}
      >
        <PageTitle
          firstSection={
            <div className="page_header_title_update">
              <Button
                buttonType="button"
                size="md"
                styleType="icon"
                onClick={() => updatingModule.onNavigateBack(moduleBaseName)}
                disabled={updatingModule.submissionState.isSubmitting}
                icon={
                  <Arrow
                    height="27"
                    width="27"
                    color="#101928"
                    extraStyle={{
                      transform: `rotate(${
                        siteLang === "ar" ? "180" : "0"
                      }deg)`,
                    }}
                  />
                }
              />
              <h1>
                {type === "edit"
                  ? [
                      translation[siteLang].edit,
                      " ",
                      translation[siteLang].stock.toLowerCase(),
                    ]
                  : [
                      translation[siteLang].add,
                      " ",
                      translation[siteLang][
                        siteLang === "ar" ? "stock" : "new"
                      ].toLowerCase(),
                      " ",
                      translation[siteLang][
                        siteLang === "ar" ? "new" : "stock"
                      ].toLowerCase(),
                    ]}
              </h1>
            </div>
          }
          secondSection={
            <Button
              label={translation[siteLang].save}
              buttonType="submit"
              size="lg"
              styleType="primary"
              disabled={
                isFetching || updatingModule.submissionState.isSubmitting
              }
              isLoading={updatingModule.submissionState.isSubmitting}
            />
          }
        />
        <SectionsWrapper isSeparated={true} fixedWidth={true}>
          {isFetching ? (
            <div style={{ width: "fit-content", margin: "100px auto 0" }}>
              <LoadingSpinner type="circle" />
            </div>
          ) : (
            <div className="section_wrapper_row">
              <div style={{ width: "70%" }}>
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].basic_info}</h3>
                  </div>
                  <div className="box_content">
                    <div className="box_row">
                      <div>
                        <div className="box_section">
                          <InputField
                            label={`${translation[siteLang].name} ${translation[siteLang].en}`}
                            type="text"
                            props={register(
                              "name_en",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.name_en?.message}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={`${translation[siteLang].name} ${translation[siteLang].ar}`}
                            type="text"
                            props={register(
                              "name_ar",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.name_ar?.message}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={`${translation[siteLang].description} ${translation[siteLang].en}`}
                            type="textarea"
                            props={register(
                              "description_en",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.description_en?.message}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={`${translation[siteLang].description} ${translation[siteLang].ar}`}
                            type="textarea"
                            props={register(
                              "description_ar",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.description_ar?.message}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </BoxWrapper>
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].inventory}</h3>
                  </div>
                  <div className="box_content">
                    <div className="box_row">
                      <div>
                        <div className="box_section">
                          <InputField
                            label={translation[siteLang].quantity}
                            labelNote={`(${translation[siteLang].available})`}
                            type="number"
                            props={register(
                              "quantity",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.quantity?.message}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={translation[siteLang].price}
                            type="number"
                            icon={<>{siteLang === "ar" ? "جنيه" : "EGP"}</>}
                            iconBorder={true}
                            props={register(
                              "price",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.price?.message}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={translation[siteLang].quantity}
                            labelNote={`(${translation[siteLang].minimum})`}
                            type="number"
                            props={register("min_quantity")}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={translation[siteLang].quantity}
                            labelNote={`(${translation[siteLang].maximum})`}
                            type="number"
                            props={register("max_quantity")}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={translation[siteLang].sku}
                            type="number"
                            props={register("sku")}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputCheckbox
                            topLabel={translation[siteLang].available}
                            label={translation[siteLang].available_in_stock}
                            checkboxStyle="switcher"
                            props={register(
                              "stock_availability",
                              validateInputField(siteLang, true)
                            )}
                            error={
                              formState.errors?.stock_availability?.message
                            }
                          />
                        </div>
                      </div>
                      <div style={{ width: "100%" }}>
                        <div className="box_separator"></div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputCheckbox
                            topLabel={translation[siteLang].multi_use}
                            label={translation[siteLang].used_multiple}
                            checkboxStyle="switcher"
                            props={register(
                              "multi_use",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.multi_use?.message}
                          />
                        </div>
                      </div>
                      {(watchedInputs.multi_use === true ||
                        watchedInputs.multi_use === 1) && (
                        <div>
                          <div className="box_section">
                            <InputField
                              label={translation[siteLang].use_times}
                              type="number"
                              props={register(
                                "use_times",
                                validateInputField(siteLang, true)
                              )}
                              error={formState.errors?.use_times?.message}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </BoxWrapper>
              </div>
              <div style={{ width: "30%" }}>
                {!userBranchID && (
                  <BoxWrapper hasBorder={true}>
                    <div className="box_content">
                      <InputSelect
                        label={translation[siteLang].branch}
                        options={[
                          {
                            options:
                              extraFetchedData?.branches?.map((el: any) => ({
                                label: el.name,
                                value: el.id.toString(),
                              })) || [],
                          },
                        ]}
                        props={{
                          ...register(
                            "branch_id",
                            validateInputField(siteLang, true, true)
                          ),
                          value: watchedInputs.branch_id,
                        }}
                        error={formState.errors?.branch_id?.message}
                      />
                    </div>
                  </BoxWrapper>
                )}
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].product_image}</h3>
                  </div>
                  <div className="box_content">
                    <InputFile
                      defaultFiles={
                        moduleDefaultData?.image
                          ? [{ id: 1, src: moduleDefaultData.image }]
                          : []
                      }
                      watchedFiles={watchedInputs.image}
                      onChangeFiles={(files) => {
                        setValue("image", files);
                      }}
                      props={{
                        accept: "image",
                      }}
                    />
                  </div>
                </BoxWrapper>
              </div>
            </div>
          )}
        </SectionsWrapper>
      </UpdateModulePage>
    </MainContent>
  );
};

export default UpdateStocks;
