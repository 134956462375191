import { Route, Routes } from "react-router-dom";
import UpdateWorkers from "./UpdateWorkers";
import ListWorkers from "./ListWorkers";
import ListAdditives from "./ListAdditives";
import UpdateAdditives from "./UpdateAdditive";
import UpdatePaycuts from "./UpdatePaycuts";
import ListPaycuts from "./ListPaycuts";
import ListDaysoff from "./ListDaysoff";
import UpdateDaysoff from "./UpdateDaysoff";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/selectors";
import ListWorkRates from "./ListWorkRates";
import ListOrders from "../Orders/ListOrders";
import UpdateSalary from "./UpdateSalary";
import ListSalaries from "./ListSalaries";

const Workers = () => {
  const {
    user: { permissions: userPermissions },
  } = useSelector(selectUser);

  return (
    <Routes>
      {(userPermissions?.worker_salaries?.create ||
        userPermissions?.worker_salaries?.view ||
        userPermissions?.worker_salaries?.update) && (
        <Route path="salaries/:workerName/:workerID">
          {userPermissions?.worker_salaries?.create && (
            <Route path="new" element={<UpdateSalary type="create" />} />
          )}
          {userPermissions?.worker_salaries?.view && (
            <Route index element={<ListSalaries />} />
          )}
        </Route>
      )}

      {(userPermissions?.day_offs?.create ||
        userPermissions?.day_offs?.view ||
        userPermissions?.day_offs?.update) && (
        <Route path="daysoff/:workerName/:workerID">
          {userPermissions?.day_offs?.update && (
            <Route
              path="edit/:dayoffID"
              element={<UpdateDaysoff type="edit" />}
            />
          )}
          {userPermissions?.day_offs?.create && (
            <Route path="new" element={<UpdateDaysoff type="create" />} />
          )}
          {userPermissions?.day_offs?.view && (
            <Route index element={<ListDaysoff />} />
          )}
        </Route>
      )}

      {(userPermissions?.paycuts?.create ||
        userPermissions?.paycuts?.view ||
        userPermissions?.paycuts?.update) && (
        <Route path="paycuts/:workerName/:workerID">
          {userPermissions?.paycuts?.update && (
            <Route
              path="edit/:paycutID"
              element={<UpdatePaycuts type="edit" />}
            />
          )}
          {userPermissions?.paycuts?.create && (
            <Route path="new" element={<UpdatePaycuts type="create" />} />
          )}
          {userPermissions?.paycuts?.view && (
            <Route index element={<ListPaycuts />} />
          )}
        </Route>
      )}

      {(userPermissions?.additives?.create ||
        userPermissions?.additives?.view ||
        userPermissions?.additives?.update) && (
        <Route path="additives/:workerName/:workerID">
          {userPermissions?.additives?.update && (
            <Route
              path="edit/:additiveID"
              element={<UpdateAdditives type="edit" />}
            />
          )}
          {userPermissions?.additives?.create && (
            <Route path="new" element={<UpdateAdditives type="create" />} />
          )}
          {userPermissions?.additives?.view && (
            <Route index element={<ListAdditives />} />
          )}
        </Route>
      )}

      {userPermissions?.workers?.view && (
        <Route
          path="orders/:workerName/:workerID"
          element={<ListOrders isFromWorker={true} />}
        />
      )}

      {userPermissions?.workers?.view && (
        <Route
          path="work-rates/:workerName/:workerID"
          element={<ListWorkRates />}
        />
      )}
      {userPermissions?.workers?.update && (
        <Route path="edit/:workerID" element={<UpdateWorkers type="edit" />} />
      )}
      {userPermissions?.workers?.create && (
        <Route path="new" element={<UpdateWorkers type="create" />} />
      )}
      {userPermissions?.workers?.view && (
        <Route index element={<ListWorkers />} />
      )}
    </Routes>
  );
};

export default Workers;
