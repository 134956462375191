import styled from "styled-components";

const Wrapper = styled.section<{ expanded: boolean; onlyInPage: boolean }>`
  position: fixed;
  z-index: 2;
  ${(props) =>
    !props.onlyInPage
      ? `
    top: var(--headerHeight);
    width: calc(
      100% -
        var(
          --${props.expanded ? "mainSidebarCollapsedWidth" : "mainSidebarWidth"}
        )
    );
    height: calc(100vh - var(--headerHeight));
    transition: ${props.theme.lang === "ar" ? "right" : "left"} 0.1s
        ease-in-out,
      width 0.1s ease-in-out;
      ${
        props.theme.lang === "ar"
          ? `right: var(--${
              props.expanded ? "mainSidebarCollapsedWidth" : "mainSidebarWidth"
            })`
          : `left: var(--${
              props.expanded ? "mainSidebarCollapsedWidth" : "mainSidebarWidth"
            })`
      }
  `
      : `
      top: 0;
      width: 100%;
      height: 100%;
      `}
`;

export default Wrapper;
