import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import InputCheckbox from "../../components/ui/Inputs/InputCheckbox";
import InputField from "../../components/ui/Inputs/InputField";
import {
  BoxWrapper,
  SectionsWrapper,
} from "../../components/updatingPages/components";
import MainContent from "../../containers/MainContent";
import useUpdatingData from "../../hooks/useUpdatingData";
import { selectSettings, selectUser } from "../../store/selectors";
import { Arrow, Page } from "../../utils/icons";
import translation from "../../utils/translation";
import { useMemo } from "react";
import UpdateModulePage from "../../containers/UpdateModulePage";
import { validateInputField } from "../../containers/UpdateModulePage/utils";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { useParams } from "react-router-dom";
import {
  assignServicesToBranch,
  assignStocksToService,
  getAllBranches,
  getModuleData,
  removeServicesFromBranch,
  removeStocksFromService,
} from "../../utils/fetchers";
import Table from "../../components/ui/Table";
import { Product } from "../../components/listingPages/ListingComponents";
import { formatMoney } from "../../utils/formatter";
import InputSelect from "../../components/ui/Inputs/InputSelect";

const moduleBaseName = "/services";

const UpdateServices = ({ type }: { type: "create" | "edit" }) => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { branch_id: userBranchID },
  } = useSelector(selectUser);
  const { categoryID, serviceID } = useParams();

  const defaultValues = useMemo(
    () => ({
      name_en: "",
      name_ar: "",
      price: "",
      ...(type === "create" ? { category_id: categoryID } : {}),
      branch_id: userBranchID ? userBranchID : "no_option",
      estimated_time: "",
      commission_type: "percentage",
      default_commission: "",
      commission: "",
      sort_order: "",
      home: false,
      stocks: [],
    }),
    [categoryID, type, userBranchID]
  );

  const extraFetchers = useMemo(
    () => [
      ...(userBranchID
        ? []
        : [
            {
              function: getAllBranches,
              returnName: "branches",
            },
          ]),
      {
        function: (signal: AbortSignal) =>
          getModuleData(
            userBranchID ? "branch" : "admin",
            "stocks",
            "with=branch",
            signal
          ),
        returnName: "stocks",
        isModule: true,
      },
    ],
    [userBranchID]
  );

  const extraSubmissions = useMemo(
    () => [
      {
        function: (id: number, refinedData: any) => {
          return assignStocksToService(
            id,
            refinedData.stocks
              .filter(
                (el: any) => !el.isDefault && el.isSelected && el.used_amount
              )
              .map((el: any) => ({ id: el.id, used_amount: el.used_amount }))
          );
        },
        responseOfID: "service",
      },
      {
        function: (id: number, refinedData: any) =>
          removeStocksFromService(
            id,
            refinedData.stocks
              .filter((el: any) => el.isDefault && !el.isSelected)
              .map((el: any) => ({ id: el.id }))
          ),
        responseOfID: "service",
      },
      {
        function: (id: number, refinedData: any) => {
          return assignServicesToBranch(refinedData.addedBranch, [{ id }]);
        },
        responseOfID: "service",
      },
      {
        function: (id: number, refinedData: any) =>
          removeServicesFromBranch(refinedData.removedBranch, [{ id }]),
        responseOfID: "service",
      },
    ],
    []
  );

  const resetModuleDefaults = useMemo(
    () => (clonedDefaults: any, extraFetchedData: any) => ({
      stocks:
        extraFetchedData?.stocks?.map((el: any) => ({
          ...el,
          used_amount:
            clonedDefaults?.service_stocks?.find(
              (stock: any) => stock.stock_id === el.id
            )?.used_amount || "",
          isSelected: clonedDefaults?.stocks?.find(
            (stock: any) => stock.id === el.id
          )
            ? true
            : false,
          isDefault: clonedDefaults?.stocks?.find(
            (stock: any) => stock.id === el.id
          )
            ? true
            : false,
        })) || [],
      branch_id: userBranchID
        ? userBranchID.toString()
        : clonedDefaults?.branches?.[0]?.id?.toString() || "no_option",
    }),
    [userBranchID]
  );

  const {
    isFetching,
    extraFetchedData,
    register,
    watchedInputs,
    setValue,
    moduleDefaultData,
    ...updatingModule
  } = useUpdatingData({
    moduleType: userBranchID ? "branch" : "admin",
    moduleState: type,
    moduleID: +(serviceID || 0),
    moduleName: "services",
    modulePostEndpoint:
      type === "edit" ? `service/update/${serviceID}` : "service/create",
    modulePostType: "object",
    modulePatch: type === "edit" ? true : false,
    watchInputFields: true,
    defaultValues,
    navigateAfterSubmissionTo: moduleBaseName,
    extraFetchers,
    extraSubmissions,
    resetModuleDefaults,
    queries: "with=stocks,branches,service_stocks",
  });

  const formState = updatingModule.formState;

  const updatedDataToSubmit = useMemo(() => {
    return (data: any) => ({
      stocks: data.stocks.map((el: any) => ({
        id: el.id,
        used_amount: el.used_amount,
        isDefault: el.isDefault,
        isSelected: el.isSelected,
      })),
      addedBranch:
        moduleDefaultData?.branches?.length > 0 &&
        moduleDefaultData?.branches?.findIndex(
          (el: any) => el.id.toString() === data.branch_id
        ) !== -1
          ? ""
          : data.branch_id,
      removedBranch:
        moduleDefaultData?.branches?.length > 0 &&
        moduleDefaultData?.branches?.findIndex(
          (el: any) => el.id.toString() === data.branch_id
        ) === -1
          ? moduleDefaultData?.branches?.find((el: any) => el.id).id
          : "",
      default_commission:
        data.commission_type === "fixed"
          ? +((+data.default_commission / +data.price) * 100).toFixed(2)
          : data.default_commission,
      commission:
        data.commission_type === "fixed"
          ? +((+data.commission / +data.price) * 100).toFixed(2)
          : data.commission,
    });
  }, [moduleDefaultData]);

  return (
    <MainContent onlyInPage={true} hasFixedWidthHeader={true}>
      <UpdateModulePage
        moduleBaseName={moduleBaseName}
        updatedDataToSubmit={updatedDataToSubmit}
        {...updatingModule}
      >
        <PageTitle
          isSticky={true}
          firstSection={
            <div className="page_header_title_update">
              <Button
                buttonType="button"
                size="md"
                styleType="icon"
                onClick={() => updatingModule.onNavigateBack(moduleBaseName)}
                disabled={updatingModule.submissionState.isSubmitting}
                icon={
                  <Arrow
                    height="27"
                    width="27"
                    color="#101928"
                    extraStyle={{
                      transform: `rotate(${
                        siteLang === "ar" ? "180" : "0"
                      }deg)`,
                    }}
                  />
                }
              />
              <h1>
                {type === "edit"
                  ? [
                      translation[siteLang].edit,
                      " ",
                      translation[siteLang].service.toLowerCase(),
                    ]
                  : [
                      translation[siteLang].add,
                      " ",
                      translation[siteLang][
                        siteLang === "ar" ? "service" : "new"
                      ].toLowerCase(),
                      " ",
                      translation[siteLang][
                        siteLang === "ar" ? "new" : "service"
                      ].toLowerCase(),
                    ]}
              </h1>
            </div>
          }
          secondSection={
            <Button
              label={translation[siteLang].save}
              buttonType="submit"
              size="lg"
              styleType="primary"
              disabled={
                isFetching || updatingModule.submissionState.isSubmitting
              }
              isLoading={updatingModule.submissionState.isSubmitting}
            />
          }
        />
        <SectionsWrapper isSeparated={true} fixedWidth={true}>
          {isFetching ? (
            <div style={{ width: "fit-content", margin: "100px auto 0" }}>
              <LoadingSpinner type="circle" />
            </div>
          ) : (
            <>
              <BoxWrapper hasBorder={true}>
                <div className="box_header">
                  <h3>{translation[siteLang].basic_info}</h3>
                </div>
                <div className="box_content">
                  <div className="box_row">
                    <div>
                      <InputField
                        label={`${translation[siteLang].name} ${translation[siteLang].en}`}
                        type="text"
                        props={register(
                          "name_en",
                          validateInputField(siteLang, true)
                        )}
                        error={formState.errors?.name_en?.message}
                      />
                    </div>
                    <div>
                      <InputField
                        label={`${translation[siteLang].name} ${translation[siteLang].ar}`}
                        type="text"
                        props={register(
                          "name_ar",
                          validateInputField(siteLang, true)
                        )}
                        error={formState.errors?.name_ar?.message}
                      />
                    </div>
                    <div>
                      <InputField
                        label={translation[siteLang].price}
                        type="number"
                        icon={<>{siteLang === "ar" ? "جنيه" : "EGP"}</>}
                        iconBorder={true}
                        props={register(
                          "price",
                          validateInputField(siteLang, true)
                        )}
                        error={formState.errors?.price?.message}
                      />
                    </div>
                    <div>
                      <InputField
                        label={translation[siteLang].estimated_time}
                        type="number"
                        icon={<>{siteLang === "ar" ? "دقيقة" : "Minutes"}</>}
                        iconBorder={true}
                        props={register(
                          "estimated_time",
                          validateInputField(siteLang, true)
                        )}
                        error={formState.errors?.estimated_time?.message}
                      />
                    </div>
                    <div>
                      <InputSelect
                        label={translation[siteLang].commission_type}
                        options={[
                          {
                            options: [
                              {
                                label: translation[siteLang].fixed,
                                value: "fixed",
                              },
                              {
                                label: translation[siteLang].percentage,
                                value: "percentage",
                              },
                            ],
                          },
                        ]}
                        props={{
                          ...register("commission_type"),
                          value: watchedInputs.commission_type,
                        }}
                      />
                    </div>
                    <div>
                      <InputField
                        label={translation[siteLang].commission}
                        labelNote={`(${translation[siteLang].default})`}
                        type="number"
                        icon={
                          <>
                            {watchedInputs.commission_type === "percentage"
                              ? "%"
                              : siteLang === "ar"
                              ? "جنيه"
                              : "EGP"}
                          </>
                        }
                        iconBorder={true}
                        props={{
                          ...register(
                            "default_commission",
                            validateInputField(siteLang, true)
                          ),
                          step: "any",
                        }}
                        error={formState.errors?.default_commission?.message}
                      />
                    </div>
                    <div>
                      <InputField
                        label={translation[siteLang].commission}
                        labelNote={`(${translation[siteLang].additive})`}
                        type="number"
                        icon={
                          <>
                            {watchedInputs.commission_type === "percentage"
                              ? "%"
                              : siteLang === "ar"
                              ? "جنيه"
                              : "EGP"}
                          </>
                        }
                        iconBorder={true}
                        props={{
                          ...register(
                            "commission",
                            validateInputField(siteLang, true)
                          ),
                          step: "any",
                        }}
                        error={formState.errors?.commission?.message}
                      />
                    </div>
                    <div>
                      <InputField
                        label={translation[siteLang].position}
                        type="number"
                        props={{
                          ...register("sort_order"),
                        }}
                      />
                    </div>
                    {!userBranchID && (
                      <div>
                        <InputSelect
                          label={translation[siteLang].branch}
                          options={[
                            {
                              options:
                                extraFetchedData?.branches?.map((el: any) => ({
                                  label: el.name,
                                  value: el.id.toString(),
                                })) || [],
                            },
                          ]}
                          props={{
                            ...register(
                              "branch_id",
                              validateInputField(siteLang, true, true)
                            ),
                            value: watchedInputs.branch_id,
                          }}
                          dependantConditions={[
                            {
                              name: "stocks",
                              value:
                                extraFetchedData?.branches?.reduce(
                                  (acc: any, cur: any) =>
                                    Object.assign(acc, {
                                      [cur.id]: watchedInputs.stocks.map(
                                        (el: any) => ({
                                          ...el,
                                          isSelected: false,
                                        })
                                      ),
                                    }),
                                  {}
                                ) || {},
                            },
                          ]}
                          setDependantValues={setValue}
                          error={formState.errors?.branch_id?.message}
                        />
                      </div>
                    )}
                    <div>
                      <InputCheckbox
                        topLabel={translation[siteLang].home}
                        label={translation[siteLang].home_service}
                        checkboxStyle="switcher"
                        props={register("home")}
                      />
                    </div>
                  </div>
                </div>
              </BoxWrapper>
              <BoxWrapper hasBorder={true}>
                <div className="box_header">
                  <h3>{translation[siteLang].service_stocks}</h3>
                </div>
                <div
                  className="box_content"
                  style={
                    watchedInputs.stocks.filter(
                      (el: any) =>
                        el.branch_id.toString() === watchedInputs.branch_id
                    ).length > 0
                      ? { padding: 0 }
                      : {}
                  }
                >
                  <div className="box_section">
                    {watchedInputs.stocks.filter(
                      (el: any) =>
                        el.branch_id.toString() === watchedInputs.branch_id
                    ).length === 0 ? (
                      <div className="page_listing_no_results">
                        <Page width="70" height="70" color="#878c93" />
                        <p>{translation[siteLang].no_stocks_for_branch}</p>
                      </div>
                    ) : (
                      <Table
                        headings={[
                          {
                            label: "",
                            style: {
                              width: "50px",
                            },
                          },
                          {
                            label: translation[siteLang].stock,
                            style: {
                              width: "40%",
                            },
                          },
                          {
                            label: translation[siteLang].quantity,
                          },
                          ...(userBranchID
                            ? []
                            : [
                                {
                                  label: translation[siteLang].branch,
                                  style: {
                                    width: "25%",
                                  },
                                },
                              ]),
                          {
                            label: translation[siteLang].usage_amount,
                            style: {
                              width: "20%",
                            },
                          },
                        ]}
                        rows={watchedInputs.stocks
                          .filter(
                            (el: any) =>
                              el.branch_id.toString() ===
                              watchedInputs.branch_id
                          )
                          .map((el: any, index: number) => ({
                            columns: [
                              {
                                value: (
                                  <InputCheckbox
                                    checkboxStyle="checkbox"
                                    props={{
                                      ...register(`stocks.${index}.isSelected`),
                                      style: {
                                        marginTop: "5px",
                                        width: "15px",
                                        height: "15px",
                                      },
                                    }}
                                  />
                                ),
                              },
                              {
                                value: (
                                  <Product
                                    image={el.image}
                                    name={el.name}
                                    info={
                                      el.description
                                        ? el.description?.substring(0, 45) +
                                          (el.description?.length > 45
                                            ? "..."
                                            : "")
                                        : ""
                                    }
                                    price={
                                      el.price
                                        ? formatMoney(+el.price, siteLang)
                                        : ""
                                    }
                                  />
                                ),
                              },
                              {
                                value: el.quantity,
                              },
                              ...(userBranchID
                                ? []
                                : [
                                    {
                                      value: el.branch.name,
                                    },
                                  ]),
                              {
                                value: el.isSelected && (
                                  <InputField
                                    type="number"
                                    props={{
                                      ...register(
                                        `stocks.${index}.used_amount`,
                                        validateInputField(
                                          siteLang,
                                          true,
                                          false,
                                          {
                                            min: (val: any) =>
                                              +val >= 1 ||
                                              translation[siteLang]
                                                .min_amount_1,
                                          }
                                        )
                                      ),
                                      min: 1,
                                    }}
                                    error={
                                      formState.errors?.stocks?.[index]
                                        ?.used_amount?.message
                                    }
                                  />
                                ),
                              },
                            ],
                          }))}
                      />
                    )}
                  </div>
                </div>
              </BoxWrapper>
            </>
          )}
        </SectionsWrapper>
      </UpdateModulePage>
    </MainContent>
  );
};

export default UpdateServices;
