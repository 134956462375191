import axios, { AxiosPromise, AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import appAxios from "../utils/setupAxios";
import useWatchFormInputs from "./useWatchFormInputs";
import lodash from "lodash";
import { UpdatedDataToSubmitType } from "../containers/UpdateModulePage/utils";

export const removeEmptyFields = (data: any) => {
  const refinedData = lodash.cloneDeep(data);
  Object.entries(refinedData).forEach((el) => {
    if (
      el[1] === "" ||
      el[1] === "no_option" ||
      el[1] === undefined ||
      el[1] === null
    )
      delete refinedData[el[0]];
    else {
      if (el[1] === true) refinedData[el[0]] = 1;
      if (el[1] === false) refinedData[el[0]] = 0;
      if (el[1] === "is_null") refinedData[el[0]] = null;
    }
  });
  return refinedData;
};

const useUpdatingData = ({
  moduleType,
  moduleState,
  moduleName,
  modulePostEndpoint,
  modulePostType,
  defaultValues,
  moduleID,
  queries = "",
  watchInputFields = false,
  extraFetchers,
  resetModuleDefaults,
  navigateAfterSubmissionTo,
  extraSubmissions,
  modulePatch = false,
  disallowDefaultNavigateBackOnSuccess = false,
  onSubmit,
}: HookType) => {
  const [moduleData, setModuleData] = useState<{
    isFetching: boolean;
    data: any;
    errorFetching: string;
    extraFetchedData: any;
  }>({
    isFetching:
      moduleState === "edit" || (extraFetchers && extraFetchers.length > 0)
        ? true
        : false,
    data: {},
    errorFetching: "",
    extraFetchedData: null,
  });

  const [navigationWarning, setNavigationWarning] = useState<boolean>(false);

  const [submissionState, setSubmissionState] = useState<{
    isSubmitting: boolean;
    submitSuccessful: boolean;
    submitError: string;
  }>({
    isSubmitting: false,
    submitSuccessful: false,
    submitError: "",
  });

  const navigate = useNavigate();

  const { watch, reset, ...form } = useForm({
    defaultValues,
  });

  const watchedInputs = useWatchFormInputs(
    defaultValues,
    watchInputFields,
    watch
  );

  const onNavigateBack = useCallback(
    (path: string, discard: boolean = false) => {
      if (!discard && Object.keys(form.formState.dirtyFields).length)
        setNavigationWarning(true);
      else navigate(-1);
    },
    [navigate, form.formState.dirtyFields]
  );

  const onResetSubmissionStateHandler = useCallback(
    (loading: boolean = false) => {
      if (
        loading !== true &&
        submissionState.submitSuccessful === true &&
        navigateAfterSubmissionTo
      ) {
        if (disallowDefaultNavigateBackOnSuccess)
          window.sessionStorage.setItem("disallowDefaultNavigation", "1");
        navigate(navigateAfterSubmissionTo);
      } else {
        setSubmissionState({
          isSubmitting: loading,
          submitSuccessful: false,
          submitError: "",
        });
      }
    },
    [
      navigate,
      submissionState.submitSuccessful,
      navigateAfterSubmissionTo,
      disallowDefaultNavigateBackOnSuccess,
    ]
  );

  const onSubmitModuleHandler = useCallback(
    async (
      submittedData: any,
      callback?: UpdatedDataToSubmitType,
      urlParamToReplace?: string
    ) => {
      onResetSubmissionStateHandler(true);
      let submitSuccessful = false,
        submitError = "";
      try {
        onSubmit?.(submittedData, { watch, reset, ...form });
        let refinedData = { ...lodash.cloneDeep(submittedData) };
        if (callback) {
          refinedData = {
            ...refinedData,
            ...lodash.cloneDeep(callback(submittedData)),
          };
        }
        refinedData = removeEmptyFields(refinedData);
        let submission: any = {};
        if (modulePostType === "formData") {
          submission = new FormData();
          Object.entries(refinedData).forEach((item) => {
            if (Array.isArray(item[1])) {
              item[1].forEach((el, index) => {
                submission.append(`${item[0]}[${index}]`, el);
              });
            } else submission.append(item[0], item[1]);
          });
        } else {
          submission = { ...refinedData };
        }
        const submitFunction = modulePatch ? appAxios.patch : appAxios.post;
        await submitFunction(
          modulePostEndpoint.replace("%PARAM%", urlParamToReplace || ""),
          submission
        )
          .then(async (res) => {
            if (extraSubmissions && extraSubmissions.length > 0) {
              let extraSubmissionsFns = extraSubmissions
                .filter((el) => !el.submitAlone)
                .map((el) =>
                  el.function(
                    res.data?.[el.responseOfID]?.id || moduleID,
                    refinedData
                  )
                );
              let extraSubmissionsAloneFns = extraSubmissions
                .filter((el) => el.submitAlone)
                .map((el) =>
                  el.function(
                    res.data?.[el.responseOfID]?.id || moduleID,
                    refinedData
                  )
                );
              if (extraSubmissionsFns.length)
                await axios
                  .all(extraSubmissionsFns)
                  .then(() => {
                    if (extraSubmissionsAloneFns.length === 0)
                      submitSuccessful = true;
                  })
                  .catch((error) => {
                    throw new Error(
                      Object.values(error?.response?.data?.errors || {})?.join(
                        " "
                      ) ||
                        error?.response?.data?.message ||
                        "__ERROR"
                    );
                  });
              if (extraSubmissionsAloneFns.length)
                await axios
                  .all(extraSubmissionsAloneFns)
                  .then(() => {
                    submitSuccessful = true;
                  })
                  .catch((error) => {
                    throw new Error(
                      Object.values(error?.response?.data?.errors || {})?.join(
                        " "
                      ) ||
                        error?.response?.data?.message ||
                        "__ERROR"
                    );
                  });
            } else {
              submitSuccessful = true;
            }
          })
          .catch((error) => {
            throw new Error(
              Object.values(error?.response?.data?.errors || {})?.join(" ") ||
                error?.response?.data?.message ||
                "__ERROR"
            );
          });
      } catch (error: any) {
        submitError = error?.message || error;
      }
      setSubmissionState({
        isSubmitting: false,
        submitSuccessful,
        submitError,
      });
    },
    [
      moduleID,
      modulePostEndpoint,
      modulePostType,
      onResetSubmissionStateHandler,
      extraSubmissions,
      modulePatch,
      onSubmit,
      watch,
      reset,
      form,
    ]
  );

  useEffect(() => {
    const fetchController = new AbortController();
    const fetchControllerSignal = fetchController.signal;
    if (moduleState === "edit" || (extraFetchers && extraFetchers.length > 0)) {
      let data = {},
        errorFetching = "",
        extraFetchedData: any = {};

      let fetchingFunctions: AxiosPromise[] = [];

      if (moduleState === "edit")
        fetchingFunctions.push(
          appAxios.get(
            `${
              moduleType === "branch" ? "branch-" : ""
            }module/${moduleName}/single/${moduleID}${
              queries ? `?${queries}` : ""
            }`,
            { signal: fetchControllerSignal }
          )
        );

      extraFetchers?.forEach((fetching) => {
        fetchingFunctions.push(fetching.function(fetchControllerSignal));
      });

      (async () => {
        await axios
          .all(fetchingFunctions)
          .then((res) => {
            if (moduleState === "edit") data = res[0].data.data;
            extraFetchers?.forEach((fetching, index) => {
              extraFetchedData[fetching.returnName] = fetching.isModule
                ? res[index + (moduleState === "edit" ? 1 : 0)].data.module.data
                : res[index + (moduleState === "edit" ? 1 : 0)].data[
                    fetching.returnName
                  ];
            });
          })
          .catch((error) => {
            errorFetching =
              error.message === "canceled"
                ? "aborted"
                : error?.response?.data?.message || "ERROR";
          });
        //check if the error not from the signal cut
        //if from the signal cut do nothing
        if (errorFetching !== "aborted")
          setModuleData((current) => ({
            ...current,
            isFetching: false,
            data,
            errorFetching,
            ...(extraFetchers && extraFetchers.length > 0
              ? { extraFetchedData }
              : {}),
          }));

        //reset module defaults
        if (!errorFetching && (resetModuleDefaults || moduleState === "edit")) {
          const clonedDefaults: any = lodash.cloneDeep(
            Object.keys(data).length > 0 ? data : defaultValues
          );
          let overwritten: any = {};
          if (resetModuleDefaults)
            overwritten = resetModuleDefaults(clonedDefaults, extraFetchedData);
          const clonedOverwritten: any = lodash.cloneDeep(overwritten);
          reset({
            ...Object.keys(defaultValues).reduce(
              (acc, cur) =>
                Object.assign(acc, {
                  [cur]:
                    clonedDefaults?.[cur] === undefined
                      ? defaultValues?.[cur] || ""
                      : clonedDefaults[cur],
                }),
              {}
            ),
            ...clonedOverwritten,
          });
        }
      })();
    }

    return () => {
      if (fetchController) fetchController.abort();
    };
  }, [
    moduleState,
    moduleName,
    extraFetchers,
    moduleID,
    moduleType,
    queries,
    reset,
    resetModuleDefaults,
    defaultValues,
  ]);

  return {
    isFetching: moduleData.isFetching,
    moduleDefaultData: moduleData.data,
    extraFetchedData: moduleData.extraFetchedData,
    reset,
    ...form,
    watchedInputs,
    navigationWarning,
    onNavigateBack,
    onCloseNavigationWarningHandler: () => setNavigationWarning(false),
    onSubmitModuleHandler,
    submissionState,
    onResetSubmissionStateHandler,
  };
};

export default useUpdatingData;

type HookType = {
  moduleType: "admin" | "branch";
  moduleState: "create" | "edit";
  moduleName: string;
  modulePostEndpoint: string;
  modulePostType: "object" | "formData";
  defaultValues: any;
  modulePatch?: boolean;
  queries?: string;
  watchInputFields?: boolean;
  moduleID?: number;
  resetModuleDefaults?: (clonedDefaults: any, extraFetchedData: any) => any;
  navigateAfterSubmissionTo?: string;
  // to add session storage boolean that makes the back button goes to a link
  // rather that default go back
  disallowDefaultNavigateBackOnSuccess?: boolean;
  extraFetchers?: {
    function: (signal: AbortSignal) => Promise<AxiosResponse<any, any>>;
    returnName: string;
    isModule?: boolean;
  }[];
  extraSubmissions?: {
    function: (id: number, refinedData: any) => Promise<any>;
    responseOfID: string;
    submitAlone?: boolean;
  }[];
  onSubmit?: (data: any, form: UseFormReturn) => void;
};
