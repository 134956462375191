import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSettings, selectUser } from "../../store/selectors";
import { setIsMainSidebarCollapsed } from "../../store/settingsSlice";
import {
  Admin,
  Branch,
  CalendarIcon,
  Chevron,
  Client,
  CogIcon,
  Dashboard,
  Invoice,
  Location,
  ServiceIcon,
  Shampoo,
  StockIcon,
  Wokrer,
} from "../../utils/icons";
import translation from "../../utils/translation";
import Wrapper, { Collapse, MenuNavLink } from "./styles";

const MainSidebar = () => {
  const { siteLang, isMainSidebarCollapsed } = useSelector(selectSettings);
  const {
    user: { permissions, branch_id: userBranchID },
  } = useSelector(selectUser);

  const dispatch = useDispatch();

  return (
    <Wrapper collapsed={isMainSidebarCollapsed}>
      <MenuNavLink to="/">
        <span>
          <Dashboard width="20" height="20" color="#fff" />
        </span>
        <span>{translation[siteLang].dashboard}</span>
      </MenuNavLink>
      {permissions?.orders?.view && (
        <MenuNavLink to="/orders">
          <span>
            <CalendarIcon width="20" height="20" color="#fff" />
          </span>
          <span>{translation[siteLang].orders}</span>
        </MenuNavLink>
      )}
      {permissions?.stocks?.view && (
        <MenuNavLink to="/stocks">
          <span>
            <StockIcon width="20" height="20" color="#fff" />
          </span>
          <span>{translation[siteLang].stocks}</span>
        </MenuNavLink>
      )}
      {permissions?.services?.view && (
        <MenuNavLink to="/services">
          <span>
            <ServiceIcon width="20" height="20" color="#fff" />
          </span>
          <span>{translation[siteLang].services}</span>
        </MenuNavLink>
      )}
      {permissions?.product?.view && !userBranchID && (
        <MenuNavLink to="/products">
          <span>
            <Shampoo width="20" height="20" color="#fff" />
          </span>
          <span>{translation[siteLang].products}</span>
        </MenuNavLink>
      )}
      {userBranchID && (
        <MenuNavLink to="/branches/products">
          <span>
            <Shampoo width="20" height="20" color="#fff" />
          </span>
          <span>{translation[siteLang].products}</span>
        </MenuNavLink>
      )}
      {permissions?.workers?.view && (
        <MenuNavLink to="/workers">
          <span>
            <Wokrer width="20" height="20" color="#fff" />
          </span>
          <span>{translation[siteLang].workers}</span>
        </MenuNavLink>
      )}
      {permissions?.users?.view && (
        <MenuNavLink to="/clients">
          <span>
            <Client width="20" height="20" color="#fff" />
          </span>
          <span>{translation[siteLang].clients}</span>
        </MenuNavLink>
      )}
      {permissions?.branches?.view && !userBranchID && (
        <MenuNavLink to="/branches">
          <span>
            <Branch width="20" height="20" color="#fff" />
          </span>
          <span>{translation[siteLang].branches}</span>
        </MenuNavLink>
      )}
      {permissions?.branches?.update && userBranchID && (
        <MenuNavLink to="/branches/my-branch">
          <span>
            <Branch width="20" height="20" color="#fff" />
          </span>
          <span>{translation[siteLang].branch}</span>
        </MenuNavLink>
      )}
      {permissions?.cities?.view && (
        <MenuNavLink to="/cities">
          <span>
            <Location width="20" height="20" color="#fff" />
          </span>
          <span>{translation[siteLang].locations}</span>
        </MenuNavLink>
      )}
      {permissions?.expenses?.view && (
        <MenuNavLink to="/expenses">
          <span>
            <Invoice width="20" height="20" color="#fff" />
          </span>
          <span>{translation[siteLang].expenses}</span>
        </MenuNavLink>
      )}
      {permissions?.admins?.view && (
        <MenuNavLink to="/admins">
          <span>
            <Admin width="20" height="20" color="#fff" />
          </span>
          <span>{translation[siteLang].admins}</span>
        </MenuNavLink>
      )}
      {permissions?.app_settings?.view && (
        <MenuNavLink to="/settings">
          <span>
            <CogIcon width="20" height="20" color="#fff" />
          </span>
          <span>{translation[siteLang].settings}</span>
        </MenuNavLink>
      )}
      <Collapse
        onClick={() =>
          dispatch(setIsMainSidebarCollapsed(!isMainSidebarCollapsed))
        }
      >
        <Chevron width="20" height="25" color="#fff" />
      </Collapse>
    </Wrapper>
  );
};

export default memo(MainSidebar);
