import * as dateFns from "date-fns";

export const formateOrderDate = (date: Date): string => {
  // const date = new Date(ISODate);
  // return (
  //   date.getFullYear().toString() +
  //   "-" +
  //   ((date.getMonth() + 1).toString().length === 2
  //     ? (date.getMonth() + 1).toString()
  //     : "0" + (date.getMonth() + 1).toString()) +
  //   "-" +
  //   (date.getDate().toString().length === 2
  //     ? date.getDate().toString()
  //     : "0" + date.getDate().toString()) +
  //   " " +
  //   (date.getHours().toString().length === 2
  //     ? date.getHours().toString()
  //     : "0" + date.getHours().toString()) +
  //   ":" +
  //   (((date.getMinutes() / 5) * 5).toString().length === 2
  //     ? ((date.getMinutes() / 5) * 5).toString()
  //     : "0" + ((date.getMinutes() / 5) * 5).toString()) +
  //   ":00"
  // );
  return dateFns.format(date, "yyyy-MM-dd HH:mm:00");
};

export const formatDate = (
  date: string,
  siteLang: "en" | "ar",
  showTime: boolean = false,
  formatOptions?: any,
  showDate: boolean = true
) => {
  return new Intl.DateTimeFormat(siteLang === "ar" ? "ar-EG" : "en-US", {
    ...(showDate
      ? {
          year: "numeric",
          month: "short",
          day: "numeric",
          weekday: "short",
        }
      : {}),
    ...(showTime
      ? {
          hour: "numeric",
          minute: "numeric",
        }
      : {}),
    ...formatOptions,
  }).format(new Date(date));
};

export const formatNumber = (
  number: number,
  siteLang: "en" | "ar",
  formatOptions?: any
) => {
  return new Intl.NumberFormat(
    siteLang === "ar" ? "ar-EG" : "en-US",
    formatOptions ? formatOptions : {}
  ).format(number);
};

export const formatMoney = (number: number, siteLang: "en" | "ar") => {
  return (
    (siteLang === "en" ? "EGP " : "") +
    formatNumber(number, siteLang) +
    (siteLang === "ar" ? " جنيه" : "")
  );
};
