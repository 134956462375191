import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import ClientBookings from "../../components/clients/ClientBookings";
import ClientDetails from "../../components/clients/ClientDetails";
import ClientMilestones from "../../components/clients/ClientMilestones";
import ClientRedeems from "../../components/clients/ClientRedeems";
import { selectUser } from "../../store/selectors";
import ListClients from "./ListClients";
import UpdateClients from "./UpdateClients";
import ViewClient from "./ViewClient";

const Clients = () => {
  const {
    user: { permissions: userPermissions },
  } = useSelector(selectUser);

  return (
    <Routes>
      {userPermissions?.users?.view && (
        <Route path="view/:clientID" element={<ViewClient />}>
          <Route path="redeems" element={<ClientRedeems />} />
          <Route path="milestones" element={<ClientMilestones />} />
          <Route path="bookings" element={<ClientBookings />} />
          <Route path="details" element={<ClientDetails />} />
        </Route>
      )}

      {userPermissions?.users?.update && (
        <Route path="edit/:clientID" element={<UpdateClients type="edit" />} />
      )}
      {userPermissions?.users?.create && (
        <Route path="new" element={<UpdateClients type="create" />} />
      )}
      {userPermissions?.users?.view && (
        <Route index element={<ListClients />} />
      )}
    </Routes>
  );
};

export default Clients;
