import styled from "styled-components";
import FieldWrapper from "../InputField/styles";

const Wrapper = styled(FieldWrapper)`
  input {
    padding-${(props) => (props.theme.lang === "ar" ? "left" : "right")}: 45px;
  }
  .search_delete {
    position: absolute; 
    ${(props) => (props.theme.lang === "ar" ? "left" : "right")}: 0;
    cursor: pointer;
    &:hover svg {
      stroke: var(--darkBlue);
    }
  }
`;

export default Wrapper;
