import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectSettings } from "../../../store/selectors";
import translation from "../../../utils/translation";
import InputField from "../../ui/Inputs/InputField";
import Modal from "../../ui/Modal";

export type CreateMilestoneStatusType = {
  isOpen: boolean;
  isSubmitting: boolean;
  success: boolean;
  error: string;
};

type ComponentPropsType = {
  createState: CreateMilestoneStatusType;
  onResetCreate: () => void;
  onCreateMilestone: (data: {
    points: string | number;
    reason_en: string;
    reason_ar: string;
  }) => Promise<void>;
};

const CreateMilestoneModal = ({
  createState,
  onCreateMilestone,
  onResetCreate,
}: ComponentPropsType) => {
  const { siteLang } = useSelector(selectSettings);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      points: "",
      reason_en: "",
      reason_ar: "",
    },
  });

  return (
    <Modal
      as="form"
      onSubmit={handleSubmit(onCreateMilestone)}
      showHeader={true}
      headerTitle={[
        translation[siteLang].add,
        " ",
        translation[siteLang].milestone.toLowerCase(),
      ].join(" ")}
      maxWidth="400px"
      showFooter={true}
      showCloseIcon={true}
      onCloseHandler={onResetCreate}
      footerButtonsDirection="center"
      footerButtons={[
        {
          label: translation[siteLang].cancel,
          styleType: "secondary",
          size: "lg",
          buttonType: "button",
          onClick: onResetCreate,
          disabled: createState.isSubmitting,
        },
        {
          label: translation[siteLang].add,
          styleType: "danger",
          size: "lg",
          buttonType: "submit",
          disabled: createState.isSubmitting,
          isLoading: createState.isSubmitting,
        },
      ]}
    >
      {createState.error && (
        <p
          style={{
            margin: "0 0 20px",
            textAlign: "center",
            fontSize: "15px",
            color: "#f23636",
            fontWeight: 500,
          }}
        >
          {createState.error}
        </p>
      )}
      <div style={{ marginBottom: "15px" }}>
        <InputField
          type="number"
          label={translation[siteLang].points}
          props={register("points", {
            required: translation[siteLang].required_field,
          })}
          error={errors?.points?.message}
        />
      </div>
      <div style={{ marginBottom: "15px" }}>
        <InputField
          type="text"
          label={`${translation[siteLang].reason} ${translation[siteLang].en}`}
          props={register("reason_en", {
            required: translation[siteLang].required_field,
          })}
          error={errors?.reason_en?.message}
        />
      </div>
      <InputField
        type="text"
        label={`${translation[siteLang].reason} ${translation[siteLang].ar}`}
        props={register("reason_ar", {
          required: translation[siteLang].required_field,
        })}
        error={errors?.reason_ar?.message}
      />
    </Modal>
  );
};

export default CreateMilestoneModal;
