import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { selectSettings } from "../../store/selectors";
import Wrapper from "./styles";

const PageWrapper = ({
  children,
  hasContentSidebar,
  onlyInPage = false,
  hasFixedWidthHeader = false,
}: {
  hasContentSidebar: boolean;
  children: ReactNode;
  onlyInPage?: boolean;
  hasFixedWidthHeader?: boolean;
  hasFixedTopHeader?: boolean;
}) => {
  const { isContentSidebarCollapsed } = useSelector(selectSettings);

  return (
    <Wrapper
      hasContentSidebar={hasContentSidebar}
      expanded={isContentSidebarCollapsed}
      onlyInPage={onlyInPage}
      hasFixedWidthHeader={hasFixedWidthHeader}
      id="page_content_wrapper"
    >
      {children}
    </Wrapper>
  );
};

export default PageWrapper;
