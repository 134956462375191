import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Card from "../../components/stats/Card";
import Chart from "../../components/stats/Chart";
import DropdownCalendar from "../../components/ui/Dropdown/DropdownCalendar";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import {
  BoxWrapper,
  SectionsWrapper,
} from "../../components/updatingPages/components";
import MainContent from "../../containers/MainContent";
import MainHeader from "../../containers/MainHeader";
import MainSidebar from "../../containers/MainSidebar";
import { selectSettings, selectUser } from "../../store/selectors";
import { CalendarIcon } from "../../utils/icons";
import appAxios from "../../utils/setupAxios";
import translation from "../../utils/translation";
import * as dateFns from "date-fns";
import { formatDate } from "../../utils/formatter";

const defaultValues = {
  from: dateFns.sub(new Date(), { weeks: 1 }).toISOString(),
  to: new Date().toISOString(),
};

const HomeContent = () => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { branch_id: userBranchID },
  } = useSelector(selectUser);

  const [stats, setStats] = useState<{ data: any; isFetching: boolean }>({
    data: null,
    isFetching: !userBranchID,
  });

  const { watch, setValue } = useForm({
    defaultValues,
  });

  const watchedInputs = watch();

  const timeFrameFrom = useMemo(() => {
    return watchedInputs.from;
  }, [watchedInputs.from]);

  const timeFrameTo = useMemo(() => {
    return watchedInputs.to;
  }, [watchedInputs.to]);

  useEffect(() => {
    const fetchController = new AbortController();
    const signal = fetchController.signal;

    if (!userBranchID) {
      const defaultFilter = `from=${dateFns.format(
        new Date(timeFrameFrom),
        "yyyy-MM-dd"
      )}&to=${dateFns.format(new Date(timeFrameTo), "yyyy-MM-dd")}`;

      axios
        .all([
          appAxios.get(`analytic/orders/count`, {
            signal,
          }),
          appAxios.get(
            `analytic/orders/count?${defaultFilter}&where=status=completed`,
            {
              signal,
            }
          ),
          appAxios.get(
            `analytic/orders/count?${defaultFilter}&where=status=scheduled`,
            {
              signal,
            }
          ),
          appAxios.get(
            `analytic/orders/count?${defaultFilter}&where=status=canceled`,
            {
              signal,
            }
          ),
          appAxios.get(
            `analytic/orders/count?${defaultFilter}&where=status=pending`,
            {
              signal,
            }
          ),
          appAxios.get(
            `analytic/orders/count?${defaultFilter}&where=status=in_progress`,
            {
              signal,
            }
          ),
          appAxios.get(
            `analytic/orders/count?${defaultFilter}&where=status=refunded`,
            {
              signal,
            }
          ),
          appAxios.get(`analytic/users/count`, {
            signal,
          }),
          appAxios.get(
            `analytic/users/count?${defaultFilter}&where=membership=BASIC`,
            {
              signal,
            }
          ),
          appAxios.get(
            `analytic/users/count?${defaultFilter}&where=membership=SILVER`,
            {
              signal,
            }
          ),
          appAxios.get(
            `analytic/users/count?${defaultFilter}&where=membership=GOLD`,
            {
              signal,
            }
          ),
          appAxios.get(
            `analytic/users/count?${defaultFilter}&where=membership=PLAT`,
            {
              signal,
            }
          ),
          appAxios.get(`analytic/users/count?${defaultFilter}&where=shads=1`, {
            signal,
          }),
          appAxios.get(`analytic/users/graph?${defaultFilter}`, {
            signal,
          }),
          appAxios.get(`analytic/workers/count?${defaultFilter}`, {
            signal,
          }),
          ...(!userBranchID
            ? [
                appAxios.get(`analytic/branches/count?${defaultFilter}`, {
                  signal,
                }),
              ]
            : []),
        ])
        .then((res) => {
          setStats({
            data: {
              ordersCard: {
                total: res[0].data.total,
                completed: res[1].data.total,
                scheduled: res[2].data.total,
                canceled: res[3].data.total,
                pending: res[4].data.total,
                in_progress: res[5].data.total,
                refunded: res[6].data.total,
              },
              clientsCard: {
                total: res[7].data.total,
                basic: res[8].data.total,
                silver: res[9].data.total,
                gold: res[10].data.total,
                plat: res[11].data.total,
                shads: res[12].data.total,
              },
              ordersChart: {
                total: res[13].data.total,
              },
              workersCard: {
                total: res[14].data.total,
              },
              ...(!userBranchID
                ? {
                    branchesCard: {
                      total: res[15].data.total,
                    },
                  }
                : {}),
            },
            isFetching: false,
          });
        })
        .catch(() => {});
    }
    return () => {
      if (fetchController) fetchController.abort();
    };
  }, [timeFrameFrom, timeFrameTo, userBranchID]);

  return (
    <>
      <MainHeader />
      <MainSidebar />
      <MainContent>
        <PageTitle
          firstSection={<h1>{[translation[siteLang].dashboard]}</h1>}
          {...(!userBranchID
            ? {
                secondSection: (
                  <>
                    <label style={{ marginBottom: 0, fontWeight: "600" }}>
                      {translation[siteLang].from}:
                    </label>
                    <DropdownCalendar
                      button={{
                        size: "lg",
                        styleType: "secondary",
                        label: formatDate(timeFrameFrom, siteLang),
                        icon: (
                          <CalendarIcon
                            width="20"
                            height="20"
                            color="#a2a6ab"
                          />
                        ),
                        iconPosition: "start",
                      }}
                      onChange={(date) =>
                        setValue("from", (date as Date).toISOString())
                      }
                      defaultValue={new Date(timeFrameFrom)}
                      openDirection="end"
                      noAutoCollapse={false}
                    />
                    <label style={{ marginBottom: 0, fontWeight: "600" }}>
                      {translation[siteLang].to}:
                    </label>
                    <DropdownCalendar
                      button={{
                        size: "lg",
                        styleType: "secondary",
                        label: formatDate(timeFrameTo, siteLang),
                        icon: (
                          <CalendarIcon
                            width="20"
                            height="20"
                            color="#a2a6ab"
                          />
                        ),
                        iconPosition: "start",
                      }}
                      onChange={(date) =>
                        setValue("to", (date as Date).toISOString())
                      }
                      defaultValue={new Date(timeFrameTo)}
                      openDirection="end"
                      noAutoCollapse={false}
                    />
                  </>
                ),
              }
            : {})}
        />
        {!userBranchID && (
          <SectionsWrapper>
            {stats.isFetching ? (
              <div style={{ width: "fit-content", margin: "100px auto 0" }}>
                <LoadingSpinner type="circle" />
              </div>
            ) : (
              <>
                <div className="section_wrapper_row">
                  <div style={{ width: "50%" }}>
                    <BoxWrapper hasBorder={true} style={{ height: "100%" }}>
                      <div className="box_header">
                        <h3>{translation[siteLang].orders}</h3>
                      </div>
                      <div className="box_content">
                        <Card
                          count={stats.data.ordersCard.total}
                          divisions={[
                            {
                              label: translation[siteLang].completed,
                              value:
                                stats.data.ordersCard.completed +
                                ` (${(
                                  (stats.data.ordersCard.completed * 100) /
                                  (stats.data.ordersCard.total || 1)
                                ).toFixed(2)}%)`,
                            },
                            {
                              label: translation[siteLang].scheduled,
                              value:
                                stats.data.ordersCard.scheduled +
                                ` (${(
                                  (stats.data.ordersCard.scheduled * 100) /
                                  (stats.data.ordersCard.total || 1)
                                ).toFixed(2)}%)`,
                            },
                            {
                              label: translation[siteLang].canceled,
                              value:
                                stats.data.ordersCard.canceled +
                                ` (${(
                                  (stats.data.ordersCard.canceled * 100) /
                                  (stats.data.ordersCard.total || 1)
                                ).toFixed(2)}%)`,
                            },
                            {
                              label: translation[siteLang].pending,
                              value:
                                stats.data.ordersCard.pending +
                                ` (${(
                                  (stats.data.ordersCard.pending * 100) /
                                  (stats.data.ordersCard.total || 1)
                                ).toFixed(2)}%)`,
                            },
                            {
                              label: translation[siteLang].in_progress,
                              value:
                                stats.data.ordersCard.in_progress +
                                ` (${(
                                  (stats.data.ordersCard.in_progress * 100) /
                                  (stats.data.ordersCard.total || 1)
                                ).toFixed(2)}%)`,
                            },
                            {
                              label: translation[siteLang].refunded,
                              value:
                                stats.data.ordersCard.refunded +
                                ` (${(
                                  (stats.data.ordersCard.refunded * 100) /
                                  (stats.data.ordersCard.total || 1)
                                ).toFixed(2)}%)`,
                            },
                          ]}
                        />
                      </div>
                    </BoxWrapper>
                  </div>
                  <div style={{ width: "50%" }}>
                    <BoxWrapper hasBorder={true} style={{ height: "100%" }}>
                      <div className="box_header">
                        <h3>{translation[siteLang].clients}</h3>
                      </div>
                      <div className="box_content">
                        <Card
                          count={stats.data.clientsCard.total}
                          divisions={[
                            {
                              label: translation[siteLang].basic,
                              value:
                                stats.data.clientsCard.basic +
                                ` (${(
                                  (stats.data.clientsCard.basic * 100) /
                                  (stats.data.clientsCard.total || 1)
                                ).toFixed(2)}%)`,
                            },
                            {
                              label: translation[siteLang].silver,
                              value:
                                stats.data.clientsCard.silver +
                                ` (${(
                                  (stats.data.clientsCard.silver * 100) /
                                  (stats.data.clientsCard.total || 1)
                                ).toFixed(2)}%)`,
                            },
                            {
                              label: translation[siteLang].gold,
                              value:
                                stats.data.clientsCard.gold +
                                ` (${(
                                  (stats.data.clientsCard.gold * 100) /
                                  (stats.data.clientsCard.total || 1)
                                ).toFixed(2)}%)`,
                            },
                            {
                              label: translation[siteLang].plat,
                              value:
                                stats.data.clientsCard.plat +
                                ` (${(
                                  (stats.data.clientsCard.plat * 100) /
                                  (stats.data.clientsCard.total || 1)
                                ).toFixed(2)}%)`,
                            },
                            {
                              label: translation[siteLang].shads,
                              value:
                                stats.data.clientsCard.shads +
                                ` (${(
                                  (stats.data.clientsCard.shads * 100) /
                                  (stats.data.clientsCard.total || 1)
                                ).toFixed(2)}%)`,
                            },
                          ]}
                        />
                      </div>
                    </BoxWrapper>
                  </div>
                  <div style={{ width: "50%" }}>
                    <BoxWrapper hasBorder={true} style={{ height: "100%" }}>
                      <div className="box_header">
                        <h3>{translation[siteLang].workers}</h3>
                      </div>
                      <div className="box_content">
                        <Card
                          count={stats.data.workersCard.total}
                          divisions={[]}
                        />
                      </div>
                    </BoxWrapper>
                  </div>
                  {!userBranchID && (
                    <div style={{ width: "50%" }}>
                      <BoxWrapper hasBorder={true} style={{ height: "100%" }}>
                        <div className="box_header">
                          <h3>{translation[siteLang].branches}</h3>
                        </div>
                        <div className="box_content">
                          <Card
                            count={stats.data.branchesCard.total}
                            divisions={[]}
                          />
                        </div>
                      </BoxWrapper>
                    </div>
                  )}
                  <div style={{ width: "100%" }}>
                    <BoxWrapper hasBorder={true} style={{ height: "100%" }}>
                      <div className="box_header">
                        <h3>{translation[siteLang].orders}</h3>
                      </div>
                      <div className="box_content">
                        <Chart
                          chartDataSet={[
                            {
                              title: translation[siteLang].total,
                              data: stats.data.ordersChart.total,
                            },
                          ]}
                          timeFrame="all"
                        />
                      </div>
                    </BoxWrapper>
                  </div>
                </div>
              </>
            )}
          </SectionsWrapper>
        )}
      </MainContent>
    </>
  );
};

export default HomeContent;
