import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import InputField from "../../components/ui/Inputs/InputField";
import {
  BoxWrapper,
  SectionsWrapper,
} from "../../components/updatingPages/components";
import MainContent from "../../containers/MainContent";
import useUpdatingData from "../../hooks/useUpdatingData";
import { selectSettings } from "../../store/selectors";
import { Arrow } from "../../utils/icons";
import translation from "../../utils/translation";
import { useMemo } from "react";
import UpdateModulePage from "../../containers/UpdateModulePage";
import { validateInputField } from "../../containers/UpdateModulePage/utils";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { useParams } from "react-router-dom";

const UpdateCategories = ({
  type,
  topBaseName,
}: {
  type: "create" | "edit";
  topBaseName?: string;
}) => {
  const { siteLang } = useSelector(selectSettings);
  const { categoryID } = useParams();

  const moduleBaseName = useMemo(
    () => topBaseName || "/services",
    [topBaseName]
  );

  const defaultValues = useMemo(
    () => ({
      name_en: "",
      name_ar: "",
      sort_order: "",
    }),
    []
  );

  const {
    isFetching,
    extraFetchedData,
    register,
    watchedInputs,
    setValue,
    moduleDefaultData,
    ...updatingModule
  } = useUpdatingData({
    moduleType: "admin",
    moduleState: type,
    moduleID: +(categoryID || 0),
    moduleName: "categories",
    modulePatch: type === "edit" ? true : false,
    modulePostEndpoint:
      type === "edit" ? `category/update/${categoryID}` : "category/create",
    modulePostType: "object",
    watchInputFields: true,
    defaultValues,
    navigateAfterSubmissionTo: moduleBaseName,
  });

  const formState = updatingModule.formState;

  const updatedDataToSubmit = useMemo(() => {
    return (data: any) => ({
      ...(moduleDefaultData?.name_en !== data.name_en
        ? { name_en: data.name_en }
        : { name_en: "" }),
      ...(moduleDefaultData?.name_ar !== data.name_ar
        ? { name_ar: data.name_ar }
        : { name_ar: "" }),
    });
  }, [moduleDefaultData]);

  return (
    <MainContent onlyInPage={true} hasFixedWidthHeader={true}>
      <UpdateModulePage
        moduleBaseName={moduleBaseName}
        updatedDataToSubmit={updatedDataToSubmit}
        {...updatingModule}
      >
        <PageTitle
          firstSection={
            <div className="page_header_title_update">
              <Button
                buttonType="button"
                size="md"
                styleType="icon"
                onClick={() => updatingModule.onNavigateBack(moduleBaseName)}
                disabled={updatingModule.submissionState.isSubmitting}
                icon={
                  <Arrow
                    height="27"
                    width="27"
                    color="#101928"
                    extraStyle={{
                      transform: `rotate(${
                        siteLang === "ar" ? "180" : "0"
                      }deg)`,
                    }}
                  />
                }
              />
              <h1>
                {type === "edit"
                  ? [
                      translation[siteLang].edit,
                      " ",
                      translation[siteLang].category.toLowerCase(),
                    ]
                  : [
                      translation[siteLang].add,
                      " ",
                      translation[siteLang][
                        siteLang === "ar" ? "category" : "new"
                      ].toLowerCase(),
                      " ",
                      translation[siteLang][
                        siteLang === "ar" ? "new" : "category"
                      ].toLowerCase(),
                    ]}
              </h1>
            </div>
          }
          secondSection={
            <Button
              label={translation[siteLang].save}
              buttonType="submit"
              size="lg"
              styleType="primary"
              disabled={
                isFetching || updatingModule.submissionState.isSubmitting
              }
              isLoading={updatingModule.submissionState.isSubmitting}
            />
          }
        />
        <SectionsWrapper isSeparated={true} fixedWidth={true}>
          {isFetching ? (
            <div style={{ width: "fit-content", margin: "100px auto 0" }}>
              <LoadingSpinner type="circle" />
            </div>
          ) : (
            <BoxWrapper hasBorder={true}>
              <div className="box_header">
                <h3>{translation[siteLang].basic_info}</h3>
              </div>
              <div className="box_content">
                <div className="box_row">
                  <div>
                    <div className="box_section">
                      <InputField
                        label={`${translation[siteLang].name} ${translation[siteLang].en}`}
                        type="text"
                        props={register(
                          "name_en",
                          validateInputField(siteLang, true)
                        )}
                        error={formState.errors?.name_en?.message}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="box_section">
                      <InputField
                        label={`${translation[siteLang].name} ${translation[siteLang].ar}`}
                        type="text"
                        props={register(
                          "name_ar",
                          validateInputField(siteLang, true)
                        )}
                        error={formState.errors?.name_ar?.message}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="box_section">
                      <InputField
                        label={translation[siteLang].position}
                        type="number"
                        props={{
                          ...register("sort_order"),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </BoxWrapper>
          )}
        </SectionsWrapper>
      </UpdateModulePage>
    </MainContent>
  );
};

export default UpdateCategories;
