import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ClientDetailsRow } from "../../components/clients/ClientDetails/styles";
import PageTitle from "../../components/listingPages/PageTitle";
import { Option } from "../../components/orders/components";
import Invoice from "../../components/orders/Invoice";
import Button from "../../components/ui/Button";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import {
  SplittedPage,
  SplittedPageSidebar,
  SplittedPageSidebarButton,
} from "../../components/updatingPages/components";
import MainContent from "../../containers/MainContent";
import { selectSettings, selectUser } from "../../store/selectors";
import { getModuleData, getSingleModuleData } from "../../utils/fetchers";
import { formatDate, formatMoney } from "../../utils/formatter";
import { Arrow, CalendarIcon } from "../../utils/icons";
import translation from "../../utils/translation";

const ViewOrder = () => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { branch_id: userBranchID },
  } = useSelector(selectUser);
  const { orderID } = useParams();

  const navigate = useNavigate();

  const [orderDetails, setOrderDetails] = useState<{
    isFetching: boolean;
    data: any;
    extra: any;
  }>({ isFetching: true, data: null, extra: {} });

  useEffect(() => {
    const fetchController = new AbortController();
    const fetchControllerSignal = fetchController.signal;
    (async () => {
      const fetchingFns = [
        getSingleModuleData(
          userBranchID ? "branch" : "admin",
          "orders",
          +(orderID as string),
          "with=payment_methods,products,services,user,services.category,products.category,order_workers.service,order_workers.worker,order_products,coupon",
          fetchControllerSignal
        ),
        getModuleData(
          "admin",
          "app_settings",
          "where=name~tax",
          fetchControllerSignal
        ),
      ];
      await axios.all(fetchingFns).then((res) => {
        setOrderDetails({
          data: res?.[0]?.data?.data,
          extra: {
            taxesPercentage: +res?.[1]?.data?.module?.data?.[0]?.value || 0,
          },
          isFetching: false,
        });
      });
    })();
    return () => {
      if (fetchController) fetchController.abort();
    };
  }, [orderID, userBranchID]);

  const subtotalOrderAmout = useMemo(() => {
    if (orderDetails.data) {
      return (
        orderDetails.data.services.reduce(
          (acc: any, cur: any) => acc + cur.price,
          0
        ) +
        orderDetails.data.products.reduce(
          (acc: any, cur: any) =>
            acc +
            cur.price *
              (orderDetails.data.order_products.find(
                (el: any) => el.product_id === cur.id
              )?.quantity || 0),
          0
        )
      );
    } else return 0;
  }, [orderDetails.data]);

  return (
    <MainContent onlyInPage={true}>
      <PageTitle
        extraStyle={{
          margin: "0 -40px",
          padding: "15px 40px",
          borderBottom: "1px solid #dee3e7",
        }}
        firstSection={
          <div className="page_header_title_update">
            <Button
              buttonType="button"
              size="md"
              styleType="icon"
              onClick={() =>
                window.sessionStorage.getItem("disallowDefaultNavigation") ===
                "1"
                  ? navigate("/orders")
                  : navigate(-1)
              }
              icon={
                <Arrow
                  height="27"
                  width="27"
                  color="#101928"
                  extraStyle={{
                    transform: `rotate(${siteLang === "ar" ? "180" : "0"}deg)`,
                  }}
                />
              }
              disabled={orderDetails.isFetching}
            />
            <h1>
              {[
                translation[siteLang][siteLang === "ar" ? "order" : "view"],
                translation[siteLang][
                  siteLang === "ar" ? "view" : "order"
                ].toLowerCase(),
              ].join(" ")}
            </h1>
          </div>
        }
      />
      {orderDetails.isFetching ? (
        <div style={{ width: "fit-content", margin: "100px auto 0" }}>
          <LoadingSpinner type="circle" />
        </div>
      ) : (
        <>
          <SplittedPage
            pixelsToMinusFromWidth={500}
            style={{
              backgroundColor: "#f8f8fb",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "30px",
            }}
          >
            <Invoice
              title={`${translation[siteLang].order_for} ${orderDetails.data.user.name}`}
              date={orderDetails.data.reservation_time}
              products={[
                ...orderDetails.data.products.map((el: any) => ({
                  quantity: el.category.name,
                  name:
                    (orderDetails.data.order_products.find(
                      (prod: any) => prod.product_id === el.id
                    )?.quantity || 0) +
                    " x " +
                    el.name,
                  price: el.price,
                })),
                ...orderDetails.data.services.map((el: any) => ({
                  quantity: el.category.name,
                  name: el.name,
                  info:
                    translation[siteLang].with +
                    " " +
                    (orderDetails.data.order_workers.find(
                      (worker: any) => worker.service_id === el.id
                    )?.worker?.name || ""),
                  price: el.price,
                })),
              ]}
              extraFields={[
                {
                  name: translation[siteLang].subtotal,
                  amount: subtotalOrderAmout,
                },
                ...(orderDetails.data?.discounted_amount
                  ? [
                      {
                        name:
                          translation[siteLang].discount +
                          (orderDetails.data?.coupon?.code
                            ? ` (${orderDetails.data.coupon.code})`
                            : ""),
                        amount: orderDetails.data.discounted_amount || 0,
                      },
                    ]
                  : []),
                {
                  name:
                    translation[siteLang].taxes +
                    ` (${orderDetails?.extra?.taxesPercentage || "0"}%)`,
                  amount: orderDetails.data?.tax || 0,
                },
                {
                  name: translation[siteLang].total,
                  isTotal: true,
                  amount: orderDetails.data.total_amount,
                },
              ]}
            />
          </SplittedPage>
          <SplittedPageSidebar
            width={500}
            style={{
              padding: "30px 40px 60px",
            }}
          >
            <h3>{translation[siteLang].details}</h3>
            <ClientDetailsRow>
              <span>{translation[siteLang].id}</span>
              <span>{orderDetails.data.id}</span>
            </ClientDetailsRow>
            <ClientDetailsRow>
              <span>{translation[siteLang].reservation_time}</span>
              <span>
                {formatDate(orderDetails.data.reservation_time, siteLang, true)}
              </span>
            </ClientDetailsRow>
            <ClientDetailsRow>
              <span>{translation[siteLang].start_date}</span>
              <span>
                {orderDetails.data.started_at
                  ? formatDate(orderDetails.data.started_at, siteLang, true)
                  : translation[siteLang].not_yet}
              </span>
            </ClientDetailsRow>
            <ClientDetailsRow>
              <span>{translation[siteLang].end_date}</span>
              <span>
                {orderDetails.data.ended_at
                  ? formatDate(orderDetails.data.ended_at, siteLang, true)
                  : translation[siteLang].not_yet}
              </span>
            </ClientDetailsRow>
            {orderDetails.data.status === "refunded" && (
              <ClientDetailsRow>
                <span>{translation[siteLang].refund_reason}</span>
                <span>{orderDetails.data[`refund_reason_${siteLang}`]}</span>
              </ClientDetailsRow>
            )}
            <h3>{translation[siteLang].client}</h3>
            <ClientDetailsRow>
              <span>{translation[siteLang].name}</span>
              <span>{orderDetails.data.user.name}</span>
            </ClientDetailsRow>
            <ClientDetailsRow>
              <span>{translation[siteLang].shads}</span>
              <span>
                {
                  translation[siteLang][
                    orderDetails.data.user.shads ? "yes" : "no"
                  ]
                }
              </span>
            </ClientDetailsRow>
            <ClientDetailsRow>
              <span>{translation[siteLang].phone}</span>
              <span>{orderDetails.data.user.phone}</span>
            </ClientDetailsRow>
            <ClientDetailsRow>
              <span>{translation[siteLang].email}</span>
              <span>{orderDetails.data.user.email}</span>
            </ClientDetailsRow>
            <ClientDetailsRow>
              <span>{translation[siteLang].membership}</span>
              <span>
                {orderDetails.data.user.membership &&
                  translation[siteLang][
                    orderDetails.data.user.membership.toLowerCase()
                  ]}
              </span>
            </ClientDetailsRow>
            <ClientDetailsRow>
              <span>{translation[siteLang].wallet}</span>
              <span>
                {formatMoney(orderDetails.data.user.wallet, siteLang)}
              </span>
            </ClientDetailsRow>
            <h3>{translation[siteLang].payment_method}</h3>
            {orderDetails.data?.payment_methods?.map((method: any) => (
              <Option key={method.id}>
                <div className="icon">
                  <CalendarIcon width="20" height="20" color="#037aff" />
                </div>
                <p>
                  {translation[siteLang].paid_with}:{" "}
                  <b>
                    {translation[siteLang]?.[method.payment_method]} (
                    {formatMoney(method.paid_amount, siteLang)})
                  </b>
                </p>
              </Option>
            ))}
            <Option>
              <div className="icon">
                <CalendarIcon width="20" height="20" color="#037aff" />
              </div>
              <p>
                {translation[siteLang].total_paid}:{" "}
                <b>{formatMoney(orderDetails.data?.total_paid, siteLang)}</b>
              </p>
            </Option>
          </SplittedPageSidebar>
          <SplittedPageSidebarButton width={500}>
            <Button
              label={translation[siteLang].print_invoice}
              buttonType="button"
              onClick={() => {
                window.open(
                  `/orders/print/${orderID}`,
                  "targetWindow",
                  "resizable=no,scrollbars=yes,,width=600,height=700"
                );
              }}
              size="xl"
              styleType="primary"
              extraStyle={{ width: "100%" }}
              disabled={orderDetails.isFetching}
            />
          </SplittedPageSidebarButton>
        </>
      )}
    </MainContent>
  );
};

export default ViewOrder;
