import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import InputField from "../../components/ui/Inputs/InputField";
import {
  BoxWrapper,
  SectionsWrapper,
} from "../../components/updatingPages/components";
import MainContent from "../../containers/MainContent";
import useUpdatingData, {
  removeEmptyFields,
} from "../../hooks/useUpdatingData";
import { selectSettings } from "../../store/selectors";
import { Arrow, CalendarIcon, Edit, Page, Plus } from "../../utils/icons";
import translation from "../../utils/translation";
import { useCallback, useEffect, useMemo, useState } from "react";
import UpdateModulePage from "../../containers/UpdateModulePage";
import { validateInputField } from "../../containers/UpdateModulePage/utils";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { useParams } from "react-router-dom";
import InputFile from "../../components/ui/Inputs/InputFile";
import AddClientAddress from "../../components/clients/AddClientAddress";
import Table from "../../components/ui/Table";
import lodash from "lodash";
import {
  addClientAddress,
  getAllCities,
  updateClientAddresses,
} from "../../utils/fetchers";
import { rawPhoneNumber } from "./utils";
import InputCheckbox from "../../components/ui/Inputs/InputCheckbox";
import InputSelect from "../../components/ui/Inputs/InputSelect";

const moduleBaseName = "/clients";

export const defaultAddress = {
  name: "",
  city: "no_option",
  region_id: "no_option",
  street: "",
  lat: "",
  long: "",
  home: false,
  building: "",
  floor: "",
  appartment: "",
  isDefault: false,
  isAdded: false,
};

const UpdateClients = ({ type }: { type: "create" | "edit" }) => {
  const { siteLang } = useSelector(selectSettings);
  const { clientID } = useParams();

  const [clientAddresses, setClientAddresses] = useState<any[]>([]);
  const [activeAddress, setActiveAddress] = useState<number | null>(null);

  const defaultValues = useMemo(
    () => ({
      name: "",
      email: "",
      phone: "",
      password: "",
      membership: "no_option",
      shads: false,
      birth_date: "2000-01-01",
      profile_picture: [],
    }),
    []
  );

  const extraFetchers = useMemo(
    () => [
      {
        function: getAllCities,
        returnName: "cities",
      },
    ],
    []
  );

  const extraSubmissions = useMemo(
    () => [
      {
        function: (id: number, refinedData: any) => {
          return addClientAddress(id, refinedData.newAddresses);
        },
        responseOfID: "user",
      },
      {
        function: (id: number, refinedData: any) =>
          updateClientAddresses(refinedData.updatedAddresses),
        responseOfID: "user",
      },
    ],
    []
  );

  const resetModuleDefaults = useMemo(
    () => (clonedDefaults: any) => ({
      phone: +rawPhoneNumber(clonedDefaults?.phone || "") || "",
      profile_picture: [],
    }),
    []
  );

  const {
    isFetching,
    extraFetchedData,
    register,
    watchedInputs,
    setValue,
    moduleDefaultData,
    ...updatingModule
  } = useUpdatingData({
    moduleType: "admin",
    moduleState: type,
    moduleID: +(clientID || 0),
    resetModuleDefaults,
    moduleName: "users",
    modulePostEndpoint:
      type === "edit" ? `user/admin/update/${clientID}` : "user/admin/create",
    modulePostType: "formData",
    watchInputFields: true,
    defaultValues,
    queries: "with=addresses,addresses.region.city",
    navigateAfterSubmissionTo: moduleBaseName,
    extraSubmissions,
    extraFetchers,
  });

  const formState = updatingModule.formState;

  useEffect(() => {
    setClientAddresses(
      moduleDefaultData?.addresses?.map((el: any) => ({
        ...el,
        city: el?.region?.city_id?.toString(),
        region_id: el?.region_id?.toString(),
        home: el.home === 1 ? true : false,
        isDefault: true,
        isAdded: true,
        region: "",
      })) || []
    );
  }, [moduleDefaultData]);

  const updatedDataToSubmit = useMemo(() => {
    return (data: any) => ({
      ...(moduleDefaultData?.email !== data.email
        ? { email: data.email }
        : { email: "" }),
      ...(rawPhoneNumber(data.phone) !==
      rawPhoneNumber(moduleDefaultData.phone || "")
        ? {
            phone: "+20" + rawPhoneNumber(data.phone),
          }
        : { phone: "" }),
      profile_picture: data.profile_picture?.[0]?.file || "",
      newAddresses: clientAddresses
        .filter((address: any) => !address.isDefault)
        .map((address: any) => removeEmptyFields(address)),
      updatedAddresses: clientAddresses
        .filter((address: any) => address.isDefault)
        .map((address: any) => removeEmptyFields(address)),
      deletedAddresses:
        moduleDefaultData?.addresses?.filter(
          (def: any) =>
            clientAddresses.findIndex(
              (address: any) => address.id === def.id
            ) === -1
        ) || [],
    });
  }, [moduleDefaultData, clientAddresses]);

  const moduleSelectOptions = useMemo(() => {
    const cities: any[] = [],
      regions: any[] = [];
    let citiesRegionsDependats: any = {
      name: `region_id`,
      value: {
        no_option: "no_option",
      },
    };
    extraFetchedData?.cities?.forEach((city: any) => {
      cities.push({ label: city.name, value: city.id.toString() });
      citiesRegionsDependats.value = Object.assign(citiesRegionsDependats, {
        [city.id]: "no_option",
      });
      city.regions.forEach((region: any) => {
        regions.push({
          label: region.name,
          value: region.id.toString(),
          dependsOn: {
            or: [
              {
                name: `city`,
                value: city.id.toString(),
              },
            ],
          },
        });
      });
    });
    return {
      cities,
      regions,
      citiesDependats: [citiesRegionsDependats],
    };
  }, [extraFetchedData]);

  const onAppendNewAddress = useCallback(() => {
    setActiveAddress(clientAddresses.length);
    setClientAddresses((current) => [...current, { ...defaultAddress }]);
  }, [clientAddresses]);

  const onAddNewAddress = useCallback(
    (newAddress: any) => {
      setActiveAddress(null);
      setClientAddresses((current) => {
        const cloneCurrentAddresses = lodash.cloneDeep(current);
        cloneCurrentAddresses[activeAddress as number] = {
          ...newAddress,
          isAdded: true,
        };
        return cloneCurrentAddresses;
      });
    },
    [activeAddress]
  );

  const onRemoveAddress = useCallback(() => {
    setActiveAddress(null);
    setClientAddresses((current) =>
      current.filter((_, index) => index !== activeAddress)
    );
  }, [activeAddress]);

  // const onDeleteAddress = useCallback(
  //   (index: number) => {
  //    setClientAddresses(current => current.filter((_,index) => index !== activeAddress))
  //   },
  //   []
  // );

  return (
    <>
      {activeAddress !== null && (
        <AddClientAddress
          onAddNewAddress={onAddNewAddress}
          onCloseModal={() =>
            clientAddresses[activeAddress].isAdded
              ? setActiveAddress(null)
              : onRemoveAddress()
          }
          citiesOptions={moduleSelectOptions.cities}
          citiesDependats={moduleSelectOptions.citiesDependats}
          regionsOptions={moduleSelectOptions.regions}
          defaultAddressValues={clientAddresses[activeAddress]}
        />
      )}
      <MainContent onlyInPage={true} hasFixedWidthHeader={true}>
        <UpdateModulePage
          moduleBaseName={moduleBaseName}
          updatedDataToSubmit={updatedDataToSubmit}
          {...updatingModule}
        >
          <PageTitle
            isSticky={true}
            firstSection={
              <div className="page_header_title_update">
                <Button
                  buttonType="button"
                  size="md"
                  styleType="icon"
                  onClick={() => updatingModule.onNavigateBack(moduleBaseName)}
                  disabled={updatingModule.submissionState.isSubmitting}
                  icon={
                    <Arrow
                      height="27"
                      width="27"
                      color="#101928"
                      extraStyle={{
                        transform: `rotate(${
                          siteLang === "ar" ? "180" : "0"
                        }deg)`,
                      }}
                    />
                  }
                />
                <h1>
                  {type === "edit"
                    ? [
                        translation[siteLang].edit,
                        " ",
                        translation[siteLang].client.toLowerCase(),
                      ]
                    : [
                        translation[siteLang].add,
                        " ",
                        translation[siteLang][
                          siteLang === "ar" ? "client" : "new"
                        ].toLowerCase(),
                        " ",
                        translation[siteLang][
                          siteLang === "ar" ? "new" : "client"
                        ].toLowerCase(),
                      ]}
                </h1>
              </div>
            }
            secondSection={
              <Button
                label={translation[siteLang].save}
                buttonType="submit"
                size="lg"
                styleType="primary"
                disabled={
                  isFetching || updatingModule.submissionState.isSubmitting
                }
                isLoading={updatingModule.submissionState.isSubmitting}
              />
            }
          />
          <SectionsWrapper isSeparated={true} fixedWidth={true}>
            {isFetching ? (
              <div style={{ width: "fit-content", margin: "100px auto 0" }}>
                <LoadingSpinner type="circle" />
              </div>
            ) : (
              <div className="section_wrapper_row">
                <div style={{ width: "70%" }}>
                  <BoxWrapper hasBorder={true}>
                    <div className="box_header">
                      <h3>{translation[siteLang].basic_info}</h3>
                    </div>
                    <div className="box_content">
                      <div className="box_row">
                        <div>
                          <div className="box_section">
                            <InputField
                              label={translation[siteLang].name}
                              type="text"
                              props={register(
                                "name",
                                validateInputField(siteLang, true)
                              )}
                              error={formState.errors?.name?.message}
                            />
                          </div>
                        </div>
                        <div>
                          <div className="box_section">
                            <InputField
                              label={translation[siteLang].email}
                              type="text"
                              props={register(
                                "email",
                                validateInputField(siteLang, true)
                              )}
                              error={formState.errors?.email?.message}
                            />
                          </div>
                        </div>
                        <div>
                          <div className="box_section">
                            <InputField
                              label={translation[siteLang].password}
                              type="password"
                              props={register(
                                "password",
                                type === "create"
                                  ? validateInputField(siteLang, true)
                                  : {}
                              )}
                              error={
                                type === "create"
                                  ? formState.errors?.password?.message
                                  : ""
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <div className="box_section">
                            <InputField
                              label={translation[siteLang].phone}
                              type="number"
                              icon={<>+20</>}
                              iconBorder={true}
                              props={register(
                                "phone",
                                validateInputField(siteLang, true)
                              )}
                              error={formState.errors?.phone?.message}
                            />
                          </div>
                        </div>
                        <div>
                          <div className="box_section">
                            <InputField
                              label={translation[siteLang].birth_date}
                              type="date"
                              icon={
                                <CalendarIcon
                                  width="15"
                                  height="15"
                                  color="#878c93"
                                  extraStyle={{
                                    width: "18px",
                                    height: "18px",
                                  }}
                                />
                              }
                              iconBorder={true}
                              props={register("birth_date")}
                              error={formState.errors?.birth_date?.message}
                            />
                          </div>
                        </div>
                        <div>
                          <div className="box_section">
                            <InputSelect
                              label={translation[siteLang].membership}
                              options={[
                                {
                                  options: [
                                    {
                                      label:
                                        translation[siteLang].select_option,
                                      value: "no_option",
                                    },
                                    {
                                      label: translation[siteLang].basic,
                                      value: "BASIC",
                                    },
                                    {
                                      label: translation[siteLang].silver,
                                      value: "SILVER",
                                    },
                                    {
                                      label: translation[siteLang].gold,
                                      value: "GOLD",
                                    },
                                    {
                                      label: translation[siteLang].plat,
                                      value: "PLAT",
                                    },
                                  ],
                                },
                              ]}
                              props={{
                                ...register("membership"),
                                value: watchedInputs.membership,
                              }}
                            />
                          </div>
                        </div>
                        <div>
                          <div className="box_section">
                            <InputCheckbox
                              topLabel={translation[siteLang].shads}
                              checkboxStyle="switcher"
                              props={register("shads")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </BoxWrapper>
                  <BoxWrapper hasBorder={true}>
                    <div
                      className="box_header"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <h3>{translation[siteLang].addresses}</h3>
                      <Button
                        buttonType="button"
                        onClick={onAppendNewAddress}
                        size="md"
                        styleType="secondary"
                        icon={
                          <Plus width="20px" height="20px" color="#101928" />
                        }
                      />
                    </div>
                    <div
                      className="box_content"
                      style={
                        clientAddresses.filter((el: any) => el.isAdded).length >
                        0
                          ? { padding: 0 }
                          : {}
                      }
                    >
                      {clientAddresses.filter((el: any) => el.isAdded)
                        .length === 0 ? (
                        <div
                          className="page_listing_no_results"
                          style={{ marginTop: 0 }}
                        >
                          <Page width="70" height="70" color="#878c93" />
                          <p>{translation[siteLang].no_addresses}</p>
                        </div>
                      ) : (
                        <Table
                          headings={[
                            {
                              label: translation[siteLang].name,
                              style: {
                                width: "calc(100% - 105px)",
                              },
                            },
                            {
                              label: translation[siteLang].actions,
                              style: {
                                width: "105px",
                              },
                            },
                          ]}
                          rows={clientAddresses
                            .filter((el: any) => el.isAdded)
                            .map((el: any, index: number) => ({
                              columns: [
                                {
                                  value: el.name,
                                },
                                {
                                  value: (
                                    <div className="page_listing_table_actions">
                                      <Button
                                        icon={
                                          <Edit
                                            width="20"
                                            height="20"
                                            color="#101928"
                                          />
                                        }
                                        buttonType="button"
                                        styleType="secondary"
                                        onClick={() => setActiveAddress(index)}
                                        size="md"
                                      />
                                      {/* <Button
                                        icon={
                                          <Delete
                                            width="20"
                                            height="20"
                                            color="#101928"
                                          />
                                        }
                                        buttonType="button"
                                        styleType="secondary"
                                        onClick={() => {
                                          onDeleteAddress(index);
                                        }}
                                        size="md"
                                        className="delete"
                                      /> */}
                                    </div>
                                  ),
                                },
                              ],
                            }))}
                        />
                      )}
                    </div>
                  </BoxWrapper>
                </div>
                <div style={{ width: "30%" }}>
                  <BoxWrapper hasBorder={true}>
                    <div className="box_header">
                      <h3>{translation[siteLang].profile_pic}</h3>
                    </div>
                    <div className="box_content">
                      <InputFile
                        defaultFiles={
                          moduleDefaultData?.profile_picture
                            ? [
                                {
                                  id: 1,
                                  src: moduleDefaultData.profile_picture,
                                },
                              ]
                            : []
                        }
                        watchedFiles={watchedInputs.profile_picture}
                        onChangeFiles={(files) => {
                          setValue("profile_picture", files);
                        }}
                        props={{
                          accept: "image",
                        }}
                      />
                    </div>
                  </BoxWrapper>
                </div>
              </div>
            )}
          </SectionsWrapper>
        </UpdateModulePage>
      </MainContent>
    </>
  );
};

export default UpdateClients;
