import styled from "styled-components";

const Wrapper = styled.div`
  * {
    font: inherit;
  }
  .react-calendar {
    padding: 20px 20px 18px;
    border: none;
    width: 450px;
  }
  .react-calendar__navigation {
    height: 40px;
  }
  .react-calendar__navigation__next2-button,
  .react-calendar__navigation__prev2-button {
    display: none;
  }
  .react-calendar__navigation__label {
    font-size: 18px;
    font-weight: 700;
    background-color: transparent !important;
    color: var(--darkBlue);
    cursor: default;
  }
  .react-calendar__navigation__arrow {
    outline: none;
    border-radius: 3px;
    min-width: 40px;
    padding: 0;
    &:hover,
    &:focus {
      background-color: var(--lightGrey2) !important;
    }
  }
  .react-calendar__month-view {
    font-size: 15px;
  }
  .react-calendar__month-view__weekdays {
    color: var(--darkBlue);
    font-weight: 700;
  }
  .react-calendar__month-view__weekdays__weekday {
    text-transform: none;
    abbr {
      text-decoration: none;
      cursor: default;
    }
  }
  .react-calendar__month-view__days__day {
    outline: none;
    margin: 2px 0;
    padding: 0;
    font-weight: 600;
    color: var(--darkBlue);
    &,
    &:hover {
      background-color: transparent !important;
    }
    abbr {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 40px;
      max-width: 40px;
      margin: auto;
      border-radius: 25px;
      &:hover {
        background-color: var(--lightGrey2);
      }
    }
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: var(--lightGrey5);
  }
  .react-calendar__tile--now abbr {
    background-color: var(--lightYellow);
  }
  .react-calendar__tile--active:not(.react-calendar__tile--hover):not(.react-calendar__tile:disabled):not(.react-calendar__tile--hoverEnd)
    abbr {
    background-color: var(--blue) !important;
    color: var(--white) !important;
  }
  .react-calendar__tile--hover abbr,
  .react-calendar__tile--hoverEnd abbr {
    background-color: var(--lightGrey2);
  }
  .react-calendar__tile:disabled {
    cursor: not-allowed;
    abbr {
      background-color: var(--lightGrey4) !important;
      color: var(--lightGrey6);
      cursor: not-allowed !important;
    }
  }
`;

export default Wrapper;
