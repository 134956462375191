import styled from "styled-components";

const Wrapper = styled.div<{
  footerButtonsDirection: "start" | "end" | "center";
}>`
  .overlay {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .modal {
    position: fixed;
    z-index: 999;
    background-color: var(--white);
    max-width: 640px;
    width: 95%;
    height: fit-content;
    max-height: calc(100vh - 80px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% + 100px));
    opacity: 0;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgb(16 25 40 / 10%);
    display: flex;
    flex-direction: column;
  }
  .modal_header {
    border-bottom: 1px solid var(--lightGrey3);
    padding: 15px 20px;
    display: flex;
    align-items: flex-start;
    height: fit-content;
  }
  .modal_header__title {
    flex: 1;
    padding-${(props) => (props.theme.lang === "ar" ? "left" : "right")}: 15px;
    display: flex;
    align-items: center;
    button {
      margin-${(props) => (props.theme.lang === "ar" ? "left" : "right")}: 10px;
      svg {
        transform: rotate(${(props) =>
          props.theme.lang === "ar" ? "180" : "0"}deg);
      }
    }
    p {
      flex: 1;
      margin: 0;
      font-size: 20px;
      font-weight: 700;
      color: var(--darkBlue);
    }
  }
  .modal_footer {
    display: flex;
    align-items: center;
    justify-content: ${(props) =>
      props.footerButtonsDirection === "center"
        ? "center"
        : `flex-${props.footerButtonsDirection}`};
    padding: 15px 20px;
    border-top: 1px solid var(--lightGrey3);
    height: fit-content;
    button:not(:last-child) {
      margin-${(props) => (props.theme.lang === "ar" ? "left" : "right")}: 10px;
    }
  }
  .modal_body {
    padding: 20px;
    overflow-y: auto;
    flex: 1;
    .modal_section {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
    .modal_row {
      margin: -7px;
      display: flex;
    }
    .modal_col {
      padding: 7px;
      width: 50%;
    }
    .field_label {
      font-size: 16px;
      font-weight: 700;
      color: var(--darkBlue);
    }
  }
`;

export default Wrapper;
