import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { selectUser } from "../../store/selectors";
import ListServices from "./ListServices";
import UpdateCategories from "./UpdateCategories";
import UpdateServices from "./UpdateServices";

const Categories = () => {
  const {
    user: { permissions: userPermissions },
  } = useSelector(selectUser);

  return (
    <Routes>
      {(userPermissions?.categories?.create ||
        userPermissions?.categories?.update) && (
        <Route path="categories">
          {userPermissions?.categories?.update && (
            <Route
              path="edit/:categoryID"
              element={<UpdateCategories type="edit" topBaseName="/services" />}
            />
          )}
          {userPermissions?.categories?.create && (
            <Route
              path="new"
              element={
                <UpdateCategories type="create" topBaseName="/services" />
              }
            />
          )}
        </Route>
      )}

      {userPermissions?.services?.update && (
        <Route
          path="edit/:serviceID"
          element={<UpdateServices type="edit" />}
        />
      )}
      {userPermissions?.services?.create && (
        <Route
          path="new/:categoryID"
          element={<UpdateServices type="create" />}
        />
      )}
      {userPermissions?.services?.view && (
        <Route index element={<ListServices />} />
      )}
    </Routes>
  );
};

export default Categories;
