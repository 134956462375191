import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import InputField from "../../components/ui/Inputs/InputField";
import {
  BoxWrapper,
  SectionsWrapper,
} from "../../components/updatingPages/components";
import MainContent from "../../containers/MainContent";
import useUpdatingData from "../../hooks/useUpdatingData";
import { selectSettings } from "../../store/selectors";
import { Arrow } from "../../utils/icons";
import translation from "../../utils/translation";
import { useMemo } from "react";
import UpdateModulePage from "../../containers/UpdateModulePage";
import { validateInputField } from "../../containers/UpdateModulePage/utils";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { useParams } from "react-router-dom";
import InputCheckbox from "../../components/ui/Inputs/InputCheckbox";
import InputSelect from "../../components/ui/Inputs/InputSelect";
import Calendar from "../../components/ui/Calendar";

const moduleBaseName = "/settings/coupons";

const todaysDate = new Date();

const UpdateCoupons = ({ type }: { type: "create" | "edit" }) => {
  const { siteLang } = useSelector(selectSettings);
  const { couponID } = useParams();

  const defaultValues = useMemo(
    () => ({
      code: "",
      starts_from: todaysDate,
      expires_at: new Date(
        todaysDate.getFullYear(),
        todaysDate.getMonth(),
        todaysDate.getDate() + 7
      ),
      category: "all",
      type: "fixed",
      active: true,
      usage_limit: "",
      value: "",
      special: true,
      membership: "no_option",
      usages_per_user: "",
    }),
    []
  );

  const resetModuleDefaults = useMemo(
    () => (clonedDefaults: any) => ({
      starts_from: new Date(clonedDefaults?.starts_from),
      expires_at: new Date(clonedDefaults?.expires_at),
    }),
    []
  );

  const {
    isFetching,
    extraFetchedData,
    register,
    watchedInputs,
    setValue,
    moduleDefaultData,
    ...updatingModule
  } = useUpdatingData({
    moduleType: "admin",
    moduleState: type,
    moduleID: +(couponID || 0),
    moduleName: "coupons",
    modulePostEndpoint:
      type === "edit" ? `coupon/update/${couponID}` : "coupon/create",
    modulePostType: "formData",
    watchInputFields: true,
    defaultValues,
    ...(type === "edit" ? { resetModuleDefaults } : {}),
    navigateAfterSubmissionTo: moduleBaseName,
  });

  const formState = updatingModule.formState;

  const updatedDataToSubmit = useMemo(() => {
    return (data: any) => ({
      starts_from: `${data.starts_from.getFullYear()}-${
        (`${data.starts_from.getMonth() + 1}`.length === 1 ? "0" : "") +
        (data.starts_from.getMonth() + 1)
      }-${
        (`${data.starts_from.getDate()}`.length === 1 ? "0" : "") +
        data.starts_from.getDate()
      }`,
      expires_at: `${data.expires_at.getFullYear()}-${
        (`${data.expires_at.getMonth() + 1}`.length === 1 ? "0" : "") +
        (data.expires_at.getMonth() + 1)
      }-${
        (`${data.expires_at.getDate()}`.length === 1 ? "0" : "") +
        data.expires_at.getDate()
      }`,
      ...(type === "edit" && moduleDefaultData
        ? {
            code: moduleDefaultData.code === data.code ? "" : data.code,
          }
        : {}),
    });
  }, [type, moduleDefaultData]);

  return (
    <MainContent onlyInPage={true} hasFixedWidthHeader={true}>
      <UpdateModulePage
        moduleBaseName={moduleBaseName}
        updatedDataToSubmit={updatedDataToSubmit}
        {...updatingModule}
      >
        <PageTitle
          firstSection={
            <div className="page_header_title_update">
              <Button
                buttonType="button"
                size="md"
                styleType="icon"
                onClick={() => updatingModule.onNavigateBack(moduleBaseName)}
                disabled={updatingModule.submissionState.isSubmitting}
                icon={
                  <Arrow
                    height="27"
                    width="27"
                    color="#101928"
                    extraStyle={{
                      transform: `rotate(${
                        siteLang === "ar" ? "180" : "0"
                      }deg)`,
                    }}
                  />
                }
              />
              <h1>
                {type === "edit"
                  ? [
                      translation[siteLang].edit,
                      " ",
                      translation[siteLang].coupon.toLowerCase(),
                    ]
                  : [
                      translation[siteLang].add,
                      " ",
                      translation[siteLang][
                        siteLang === "ar" ? "coupon" : "new"
                      ].toLowerCase(),
                      " ",
                      translation[siteLang][
                        siteLang === "ar" ? "new" : "coupon"
                      ].toLowerCase(),
                    ]}
              </h1>
            </div>
          }
          secondSection={
            <Button
              label={translation[siteLang].save}
              buttonType="submit"
              size="lg"
              styleType="primary"
              disabled={
                isFetching || updatingModule.submissionState.isSubmitting
              }
              isLoading={updatingModule.submissionState.isSubmitting}
            />
          }
        />
        <SectionsWrapper isSeparated={true} fixedWidth={true}>
          {isFetching ? (
            <div style={{ width: "fit-content", margin: "100px auto 0" }}>
              <LoadingSpinner type="circle" />
            </div>
          ) : (
            <div className="section_wrapper_row">
              <div style={{ width: "60%" }}>
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].basic_info}</h3>
                  </div>
                  <div className="box_content">
                    <div className="box_section">
                      <div className="box_row">
                        <div>
                          <InputField
                            label={translation[siteLang].code}
                            type="text"
                            props={register(
                              "code",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.code?.message}
                          />
                        </div>
                        <div>
                          <InputSelect
                            label={translation[siteLang].type}
                            options={[
                              {
                                options: [
                                  {
                                    label: translation[siteLang].fixed,
                                    value: "fixed",
                                  },
                                  {
                                    label: translation[siteLang].percentage,
                                    value: "percentage",
                                  },
                                ],
                              },
                            ]}
                            props={{
                              ...register(
                                "type",
                                validateInputField(siteLang, true, true)
                              ),
                              value: watchedInputs.type,
                            }}
                            error={formState.errors?.type?.message}
                          />
                        </div>
                        <div>
                          <InputField
                            label={translation[siteLang].value}
                            type="number"
                            icon={
                              <>
                                {watchedInputs.type === "percentage"
                                  ? "%"
                                  : siteLang === "ar"
                                  ? "جنيه"
                                  : "EGP"}
                              </>
                            }
                            iconBorder={true}
                            props={register(
                              "value",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.value?.message}
                          />
                        </div>
                        <div>
                          <InputSelect
                            label={translation[siteLang].category}
                            options={[
                              {
                                options: [
                                  {
                                    label: translation[siteLang].all,
                                    value: "all",
                                  },
                                  {
                                    label: translation[siteLang].home,
                                    value: "home",
                                  },
                                  {
                                    label: translation[siteLang].indoor,
                                    value: "indoor",
                                  },
                                  {
                                    label: translation[siteLang].outdoor,
                                    value: "outdoor",
                                  },
                                ],
                              },
                            ]}
                            props={{
                              ...register(
                                "category",
                                validateInputField(siteLang, true, true)
                              ),
                              value: watchedInputs.category,
                            }}
                            error={formState.errors?.category?.message}
                          />
                        </div>
                        <div>
                          <InputSelect
                            label={translation[siteLang].membership}
                            options={[
                              {
                                options: [
                                  {
                                    label: translation[siteLang].select_option,
                                    value: "no_option",
                                  },
                                  {
                                    label: translation[siteLang].all,
                                    value: "all",
                                  },
                                  {
                                    label: translation[siteLang].basic,
                                    value: "BASIC",
                                  },
                                  {
                                    label: translation[siteLang].silver,
                                    value: "SILVER",
                                  },
                                  {
                                    label: translation[siteLang].gold,
                                    value: "GOLD",
                                  },
                                  {
                                    label: translation[siteLang].plat,
                                    value: "PLAT",
                                  },
                                ],
                              },
                            ]}
                            props={{
                              ...register("membership"),
                              value: watchedInputs.membership,
                            }}
                          />
                        </div>
                        <div>
                          <InputField
                            label={translation[siteLang].usage_limit}
                            type="number"
                            props={register(
                              "usage_limit",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.usage_limit?.message}
                          />
                        </div>
                        <div>
                          <InputField
                            label={translation[siteLang].usages_per_customer}
                            type="number"
                            props={register(
                              "usages_per_user",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.usages_per_user?.message}
                          />
                        </div>
                        <div>
                          <InputCheckbox
                            checkboxStyle="switcher"
                            topLabel={translation[siteLang].active}
                            props={register("active")}
                          />
                        </div>
                        <div>
                          <InputCheckbox
                            checkboxStyle="switcher"
                            topLabel={translation[siteLang].special}
                            props={register("special")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </BoxWrapper>
              </div>
              <div style={{ width: "40%" }}>
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].start_date}</h3>
                  </div>
                  <div className="box_content" style={{ padding: "2px" }}>
                    <Calendar
                      defaultValue={watchedInputs.starts_from}
                      value={watchedInputs.starts_from}
                      onChange={(date) => setValue("starts_from", date)}
                    />
                  </div>
                </BoxWrapper>
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].end_date}</h3>
                  </div>
                  <div className="box_content" style={{ padding: "2px" }}>
                    <Calendar
                      defaultValue={watchedInputs.expires_at}
                      value={watchedInputs.expires_at}
                      onChange={(date) => setValue("expires_at", date)}
                    />
                  </div>
                </BoxWrapper>
              </div>
            </div>
          )}
        </SectionsWrapper>
      </UpdateModulePage>
    </MainContent>
  );
};

export default UpdateCoupons;
