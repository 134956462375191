import styled from "styled-components";

const Wrapper = styled.div<{ hasFiles: boolean; fileType: "image" | "pdf" }>`
  label {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: var(--lightBlue);
    transition: background-color 0.2s ease-in-out;
    border-radius: 7px;
    height: ${(props) => (props.hasFiles ? "130px" : "250px")};
    margin: 0;
    &:hover {
      background-color: var(--lightBlue2);
    }
    input {
      cursor: pointer;
      position: absolute;
      top: 0;
      ${(props) => (props.theme.lang === "ar" ? "right" : "left")}: -100%;
      width: 200%;
      height: 100%;
      opacity: 0;
      z-index: 2;
    }
  }
  .upload_label {
    margin-top: 10px;
    color: var(--blue);
    font-size: 15px;
    font-weight: 500;
    word-break: break-all;
    text-align: center;
    padding: 0 10px;
  }
  .uploaded_file {
    position: relative;
    overflow: hidden;
    border-radius: 7px;
    width: 100%;
    border: 1px solid var(--lightGrey4);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--${(props) =>
      props.fileType === "pdf" ? "lightBlue" : "white"});
    &:not(.uploaded_file--main) {
      height: 130px;
      img {
        height: 130px;
        object-fit: contain;
      }
    }
    &:hover {
      .uploaded_file_control {
        opacity: 1;
      }
    }
  }
  .uploaded_file--main {
    height: 250px;
    img {
      height: 250px;
    }
  }
  img {
    width: 100%;
    object-fit: cover;
  }
  .uploaded_file_control {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    background-color: rgba(60, 60, 60, 0.8);
    transition: opacity 0.2s ease-in-out;
    padding: 10px;
    opacity: 0;
    button {
      padding: 0;
      outline: none;
      background: none;
      border: none;
      &:not(:last-child) {
        margin-${(props) =>
          props.theme.lang === "ar" ? "left" : "right"}: 10px;
      }
    }
  }
  .files_sections {
    display: flex;
    flex-wrap: wrap;
    margin: ${(props) => (props.hasFiles ? "8px -8px -8px" : "-8px")};
  }
  .files_sections_single {
    padding: 8px;
    width: ${(props) => (props.hasFiles ? "50%;" : "100%")};
  }
`;

export default Wrapper;
