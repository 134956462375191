import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const MenuNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 7px 10px;
  border-radius: 7px;
  color: var(--white);
  transition: background-color 0.1s ease-in-out;
  line-height: 25px;
  width: 100%;
  height: 40px;
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
  &:hover {
    background-color: var(--darkGrey);
    text-decoration: none;
    color: var(--white);
  }
  &.active {
    background-color: var(--blue);
  }
  span {
    display: flex;
    line-height: 25px;
    align-items: center;
    &:last-child {
      margin-${(props) => (props.theme.lang === "ar" ? "right" : "left")}: 10px;
      padding-top: 1px;
      font-size: 15px;
      font-weight: 500;
    }
  }
`;

export const Collapse = styled.button.attrs({ type: "button" })`
  position: absolute;
  bottom: 12px;
  padding: 7px 10px;
  background: none;
  border: none;
  ${(props) => (props.theme.lang === "ar" ? "left" : "right")}: 12px;
  &,
  &:focus {
    outline: none;
  }
  svg {
    transition: transform 0.1s ease-in-out;
  }
`;

const Wrapper = styled.aside<{ collapsed: boolean }>`
  position: fixed;
  top: var(--headerHeight);
  height: calc(100vh - var(--headerHeight));
  width: var(
    --${(props) => (props.collapsed ? "mainSidebarCollapsedWidth" : "mainSidebarWidth")}
  );
  transition: width 0.1s ease-in-out;
  padding: 12px;
  background-color: var(--darkBlue);
  ${(props) => (props.theme.lang === "ar" ? "right" : "left")}: 0;
  ${MenuNavLink} span:last-child {
    display: ${(props) => (props.collapsed ? "none" : "block")};
  }
  ${Collapse} svg {
    transform: ${(props) =>
      props.theme.lang === "ar"
        ? props.collapsed
          ? "rotate(180deg)"
          : "rotate(0)"
        : props.collapsed
        ? "rotate(0)"
        : "rotate(180deg)"};
  }
`;

export default Wrapper;
