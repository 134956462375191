import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import Table from "../../components/ui/Table";
import MainContent from "../../containers/MainContent";
import ViewListingPage from "../../containers/ViewListingPage";
import useListingData from "../../hooks/useListingData";
import { selectSettings, selectUser } from "../../store/selectors";
import translation from "../../utils/translation";
import MainHeader from "../../containers/MainHeader";
import MainSidebar from "../../containers/MainSidebar";
import { useParams } from "react-router-dom";
import { formatDate, formatMoney } from "../../utils/formatter";

const ListSalaries = () => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { branch_id: userBranchID, permissions: userPermissions },
  } = useSelector(selectUser);
  const { workerName, workerID } = useParams();

  const {
    data,
    extraFetchedData,
    onRequestDeleteHandler,
    onSelectTableRowHandler,
    isAllRowsSelected,
    showMultipleDelete,
    ...rest
  } = useListingData({
    moduleName: "worker_salaries",
    moduleType: userBranchID ? "branch" : "admin",
    queries: `where=worker_id=${workerID}`,
  });

  return (
    <>
      <MainHeader />
      <MainSidebar />
      <MainContent>
        <PageTitle
          firstSection={
            <>
              <span style={{ color: "#878c93", fontWeight: "500" }}>
                {workerName}
              </span>
              <h1>
                {[
                  translation[siteLang][
                    siteLang === "ar" ? "list" : "monthly_salary"
                  ],
                  " ",
                  translation[siteLang][
                    siteLang === "ar" ? "monthly_salary" : "list"
                  ].toLowerCase(),
                ]}
              </h1>
            </>
          }
          secondSection={
            <>
              <Button
                styleType="secondary"
                size="lg"
                label={[
                  translation[siteLang].back_to,
                  translation[siteLang].workers.toLowerCase(),
                ].join(" ")}
                buttonType="link"
                url={`/workers`}
              />
              {userPermissions?.worker_salaries?.create && (
                <Button
                  styleType="primary"
                  size="lg"
                  label={[
                    translation[siteLang][
                      siteLang === "ar" ? "monthly_salary" : "new"
                    ],
                    translation[siteLang][
                      siteLang === "ar" ? "new" : "monthly_salary"
                    ].toLowerCase(),
                  ].join(" ")}
                  buttonType="link"
                  url={`/workers/salaries/${workerName}/${workerID}/new`}
                />
              )}
            </>
          }
        />
        <ViewListingPage
          {...rest}
          hasNoResults={data.length === 0}
          tableFilter={{
            showMultipleDelete,
            onRequestDeleteHandler,
            hasSearch: true,
            search: {
              ...rest.search,
              placeholder:
                translation[siteLang].search_all +
                " " +
                translation[siteLang].monthly_salary.toLowerCase(),
            },
          }}
        >
          <Table
            isHeaderFixed={true}
            headings={[
              {
                label: translation[siteLang].date,
              },
              {
                label: translation[siteLang].expected_salary,
              },
              {
                label: translation[siteLang].actual_salary,
              },
              {
                label: translation[siteLang].total_paycuts,
              },
              {
                label: translation[siteLang].total_additives,
              },
              {
                label: translation[siteLang].note,
              },
            ]}
            rows={data.map((el) => ({
              columns: [
                {
                  value: formatDate(el.salary_date, siteLang),
                },
                {
                  value: formatMoney(el.expected_salary, siteLang),
                },
                {
                  value: formatMoney(el.actual_salary, siteLang),
                },
                {
                  value: formatMoney(el.total_paycuts, siteLang),
                },
                {
                  value: formatMoney(el.total_additives, siteLang),
                },
                {
                  value: el[`notes_${siteLang}`],
                },
              ],
            }))}
          />
        </ViewListingPage>
      </MainContent>
    </>
  );
};

export default ListSalaries;
