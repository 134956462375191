import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { selectUser } from "../../store/selectors";
import ListStocks from "../Stocks/ListStocks";
import ListBranches from "./ListBranches";
import ListProductsRefill from "./ListProductsRefill";
import UpdateBranches from "./UpdateBranches";

const Branches = () => {
  const {
    user: { permissions: userPermissions, branch_id: userBranchID },
  } = useSelector(selectUser);

  return (
    <Routes>
      {/* My branch */}
      {userBranchID && (
        <Route path="products" element={<ListProductsRefill />} />
      )}
      {userBranchID && userPermissions?.stocks?.update && (
        <Route path="refill-stocks" element={<ListStocks type="refill" />} />
      )}
      {userPermissions?.branches?.update && userBranchID && (
        <Route
          path="my-branch"
          element={<UpdateBranches type="edit" isMyCurrent={true} />}
        />
      )}

      {userPermissions?.stocks?.update && (
        <Route
          path="refill-stocks/:branchName/:branchID"
          element={<ListStocks type="refill" />}
        />
      )}
      {userPermissions?.branches?.update && (
        <Route
          path="refill-products/:branchName/:branchID"
          element={<ListProductsRefill />}
        />
      )}
      {userPermissions?.branches?.update && !userBranchID && (
        <Route path="edit/:branchID" element={<UpdateBranches type="edit" />} />
      )}
      {userPermissions?.branches?.create && (
        <Route path="new" element={<UpdateBranches type="create" />} />
      )}
      {userPermissions?.branches?.view && (
        <Route index element={<ListBranches />} />
      )}
    </Routes>
  );
};

export default Branches;
