import { Route, Routes } from "react-router-dom";
import Services from "./Services";
import Home from "./Home";
import Orders from "./Orders";
import Stocks from "./Stocks";
import Products from "./Products";
import Workers from "./Workers";
import Clients from "./Clients";
import Branches from "./Branches";
import Cities from "./Cities";
import Expenses from "./Expenses";
import Settings from "./Settings";
import Admins from "./Admins";
import { useSelector } from "react-redux";
import { selectUser } from "../store/selectors";
import ListAdminNotifications from "./AdminNotifications/ListAdminNotifications";

const AppRoutes = () => {
  const {
    user: { permissions: userPermissions },
  } = useSelector(selectUser);

  return (
    <Routes>
      <Route path="/notifications" element={<ListAdminNotifications />} />
      {userPermissions?.app_settings?.view && (
        <Route path="/settings/*" element={<Settings />} />
      )}
      {userPermissions?.admins?.view && (
        <Route path="/admins/*" element={<Admins />} />
      )}
      {userPermissions?.expenses?.view && (
        <Route path="/expenses/*" element={<Expenses />} />
      )}
      {userPermissions?.cities?.view && (
        <Route path="/cities/*" element={<Cities />} />
      )}
      {userPermissions?.branches?.view && (
        <Route path="/branches/*" element={<Branches />} />
      )}
      {userPermissions?.users?.view && (
        <Route path="/clients/*" element={<Clients />} />
      )}
      {userPermissions?.workers?.view && (
        <Route path="/workers/*" element={<Workers />} />
      )}
      {userPermissions?.product?.view && (
        <Route path="/products/*" element={<Products />} />
      )}
      {userPermissions?.services?.view && (
        <Route path="/services/*" element={<Services />} />
      )}
      {userPermissions?.stocks?.view && (
        <Route path="/stocks/*" element={<Stocks />} />
      )}
      {userPermissions?.orders?.view && (
        <Route path="/orders/*" element={<Orders />} />
      )}
      <Route path="/" element={<Home />} />
    </Routes>
  );
};

export default AppRoutes;
