import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .login_box_logo {
    display: block;
    margin: 0 auto 20px;
    text-align: center;
    height: 60px;
    width: fit-content;
  }
  .login_box_error {
    margin: -13px 0 20px;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    color: var(--red);
  }
  .login_box {
    border-radius: 7px;
    border: 1px solid var(--lightGrey3);
    width: 460px;
    padding: 25px;
  }
  .box_section {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;
