import { SelectOption } from "../../components/ui/Inputs/utils";
import { formatDate, formatNumber } from "../../utils/formatter";
import translation from "../../utils/translation";

export const calendarLabel = (
  date1: string,
  date2: string,
  siteLang: "en" | "ar"
): string => {
  const first_data = new Date(date1),
    second_date = new Date(date2);
  if (date1 && date2) {
    if (first_data.toDateString() !== second_date.toDateString()) {
      if (
        first_data.getFullYear() === second_date.getFullYear() &&
        first_data.getMonth() === second_date.getMonth()
      )
        return (
          `${formatNumber(first_data.getDate(), siteLang)} - ${formatNumber(
            second_date.getDate(),
            siteLang
          )} ` +
          formatDate(date1, siteLang, false, {
            year: "numeric",
            month: "short",
            day: undefined,
            weekday: undefined,
          })
        );
      else
        return (
          formatDate(first_data.toISOString(), siteLang, false, {
            year: "numeric",
            month: "short",
            day: "numeric",
          }) +
          " - " +
          formatDate(second_date.toISOString(), siteLang, false, {
            year: "numeric",
            month: "short",
            day: "numeric",
          })
        );
    } else return formatDate(date1, siteLang);
  } else {
    return formatDate(date1, siteLang);
  }
};

export const orderTimeOptions = (siteLang: "en" | "ar"): SelectOption[] => {
  // const options = [];
  // const start = 10;
  // const end = 10;
  // for(let n = start; n <= end; n+=0.5) {
  //   options.push({
  //     label: n/1
  //   })
  // }
  return [
    {
      label: `10:00 ${siteLang === "ar" ? "صباحا" : "AM"}`,
      value: "10:00",
    },
    {
      label: `10:30 ${siteLang === "ar" ? "صباحا" : "AM"}`,
      value: "10:30",
    },
    {
      label: `11:00 ${siteLang === "ar" ? "صباحا" : "AM"}`,
      value: "11:00",
    },
    {
      label: `11:30 ${siteLang === "ar" ? "صباحا" : "AM"}`,
      value: "11:30",
    },
    {
      label: `12:00 ${siteLang === "ar" ? "مساءا" : "PM"}`,
      value: "12:00",
    },
    {
      label: `12:30 ${siteLang === "ar" ? "مساءا" : "PM"}`,
      value: "12:30",
    },
    {
      label: `1:00 ${siteLang === "ar" ? "مساءا" : "PM"}`,
      value: "13:00",
    },
    {
      label: `1:30 ${siteLang === "ar" ? "مساءا" : "PM"}`,
      value: "13:30",
    },
    {
      label: `2:00 ${siteLang === "ar" ? "مساءا" : "PM"}`,
      value: "14:00",
    },
    {
      label: `2:30 ${siteLang === "ar" ? "مساءا" : "PM"}`,
      value: "14:30",
    },
    {
      label: `3:00 ${siteLang === "ar" ? "مساءا" : "PM"}`,
      value: "15:00",
    },
    {
      label: `3:30 ${siteLang === "ar" ? "مساءا" : "PM"}`,
      value: "15:30",
    },
    {
      label: `4:00 ${siteLang === "ar" ? "مساءا" : "PM"}`,
      value: "16:00",
    },
    {
      label: `4:30 ${siteLang === "ar" ? "مساءا" : "PM"}`,
      value: "16:30",
    },
    {
      label: `5:00 ${siteLang === "ar" ? "مساءا" : "PM"}`,
      value: "17:00",
    },
    {
      label: `5:30 ${siteLang === "ar" ? "مساءا" : "PM"}`,
      value: "17:30",
    },
    {
      label: `6:00 ${siteLang === "ar" ? "مساءا" : "PM"}`,
      value: "18:00",
    },
    {
      label: `6:30 ${siteLang === "ar" ? "مساءا" : "PM"}`,
      value: "18:30",
    },
    {
      label: `7:00 ${siteLang === "ar" ? "مساءا" : "PM"}`,
      value: "19:00",
    },
    {
      label: `7:30 ${siteLang === "ar" ? "مساءا" : "PM"}`,
      value: "19:30",
    },
    {
      label: `8:00 ${siteLang === "ar" ? "مساءا" : "PM"}`,
      value: "20:00",
    },
    {
      label: `8:30 ${siteLang === "ar" ? "مساءا" : "PM"}`,
      value: "20:30",
    },
    {
      label: `9:00 ${siteLang === "ar" ? "مساءا" : "PM"}`,
      value: "21:00",
    },
    {
      label: `9:30 ${siteLang === "ar" ? "مساءا" : "PM"}`,
      value: "21:30",
    },
    {
      label: `10:00 ${siteLang === "ar" ? "مساءا" : "PM"}`,
      value: "22:00",
    },
  ];
};

export const workersSelectOptions = (
  workers: any[],
  serviceFieldIndex: number
) => {
  return (
    workers.map((el: any) => ({
      label: el.name,
      value: el.id.toString(),
      dependsOn: {
        and: [
          {
            name: "branch_id",
            value: el.branch_id.toString(),
          },
        ],
        or: el.services.map((serv: any) => ({
          name: `services.${serviceFieldIndex}.service`,
          value: serv.id.toString(),
        })),
      },
    })) || []
  );
};

export const branchesWorkersDependatConditions = (
  branches: any[],
  serviceFieldsLength: number
) => {
  return new Array(serviceFieldsLength).fill(1).map((_, index) => ({
    name: `services.${index}.worker`,
    value: branches.reduce(
      (acc: any, cur: any) =>
        Object.assign(acc, {
          [cur.id]: "no_option",
        }),
      {}
    ),
  }));
};

export const timeWorkersDependatConditions = (serviceFieldsLength: number) => {
  return new Array(serviceFieldsLength).fill(1).map((_, index) => ({
    name: `services.${index}.worker`,
    value: {
      any: "no_option",
    },
  }));
};

export const orderStatusOptions = (siteLang: "en" | "ar"): SelectOption[] => {
  return [
    {
      label: translation[siteLang].pending,
      value: "pending",
    },
    {
      label: translation[siteLang].scheduled,
      value: "scheduled",
    },
    {
      label: translation[siteLang].in_progress,
      value: "in_progress",
    },
    {
      label: translation[siteLang].canceled,
      value: "canceled",
    },
    {
      label: translation[siteLang].completed,
      value: "completed",
    },
  ];
};

export const orderServicesWithCategorized = ({
  fetchedServices,
  siteLang,
  includePrice = false,
}: {
  fetchedServices: any;
  siteLang: "en" | "ar";
  includePrice?: boolean;
}) => {
  const servicesCategories: any = [];
  fetchedServices?.forEach((el: any) => {
    if (
      servicesCategories.findIndex((cat: any) => cat.id === el.category_id) ===
      -1
    )
      servicesCategories.push({
        label: el.category?.name,
        id: el.category?.id,
        sort_order: el.category?.sort_order,
        options: [],
      });
  });
  servicesCategories.sort((a: any, b: any) => a.sort_order - b.sort_order);
  fetchedServices?.forEach((el: any) => {
    servicesCategories[
      servicesCategories.findIndex((cat: any) => cat.id === el.category_id)
    ]?.options?.push({
      label: el.name + (el.home ? ` (${translation[siteLang].home})` : ""),
      value: el.id.toString(),
      ...(includePrice ? { price: el.price } : {}),
    });
  });

  return servicesCategories;
};

export const orderProductsWithCategorized = ({
  fetchedProducts,
}: {
  fetchedProducts: any[];
}) => {
  const productsCategories: any = [];
  fetchedProducts.forEach((el: any) => {
    productsCategories.push({
      label: el.category?.name,
      id: el.category?.id,
      options: [],
    });
  });
  fetchedProducts.forEach((el: any) => {
    productsCategories[
      productsCategories.findIndex((cat: any) => cat.id === el.category_id)
    ]?.options?.push({
      label: el.name,
      value: el.id.toString(),
      price: el.price,
    });
  });

  return productsCategories;
};
