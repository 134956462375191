import styled from "styled-components";

export const Widget = styled.div<{
  expanded: boolean;
  openDirection: "start" | "end";
  isTop?: boolean;
  isFitContent?: boolean;
}>`
  position: absolute;
  z-index: 999;
  ${(props) => (props.isTop ? "bottom" : "top")}: calc(100% + 7px);
  background: var(--white);
  box-shadow: 0 2px 7px rgb(164 173 186 / 20%);
  border: 1px solid var(--lightGrey3);
  border-radius: 3px;
  color: var(--darkBlue);
  transition: transform 0.1s ease-in-out;
  ${(props) => (props.isFitContent ? "" : `max-height: 250px;`)}
  overflow-y: auto;
  overscroll-behavior: contain;
  ${(props) =>
    props.theme.lang === "ar"
      ? props.openDirection === "end"
        ? "left"
        : "right"
      : props.openDirection === "end"
      ? "right"
      : "left"}: 0;
  min-width: 220px;
  // max-width: 100%;
  ${(props) =>
    props.expanded
      ? `
  & {
    transform: translateY(0);
  }
  `
      : `
  & {
    visibility: hidden;
    transform: translateY(20px);
  }
  `}
  .option_container {
    transition: background-color 0.1s;
    padding: 8px 15px;
    &,
    &:hover {
      color: var(--darkBlue);
    }
    &:hover {
      text-decoration: none;
      background-color: var(--lightGrey);
    }
    &,
    &:focus {
      outline: none;
    }
    &:first-child {
      border-radius: 3px 3px 0 0;
    }
    &:last-child {
      border-radius: 0 0 3px 3px;
    }
  }
  .all_radio_options {
    display: flex;
    flex-direction: column;
    .option {
      margin: 0;
    }
  }
  h4 {
    font-size: 18px;
    font-weight: 700;
    color: var(--darkBlue);
    margin: 0;
    padding: 8px 15px;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 998;
`;

export const WidgetButton = styled.button<{
  $hasLabelIcon: "both" | "single";
  $iconOnly: boolean;
}>`
  margin: 0;
  border: none;
  display: flex;
  align-items: center;
  background-color: transparent;
  width: 100%;
  text-align: ${(props) => (props.theme.lang === "ar" ? "right" : "left")};
  &,
  &:hover {
    color: var(--darkBlue);
    text-decoration: none;
  }
  i {
    font-style: normal;
    flex: 1;
    ${(props) =>
      props.$hasLabelIcon === "both"
        ? `
        margin-${props.theme.lang === "ar" ? "right" : "left"}: 7px;
    `
        : ""}
  }
  svg {
    width: ${(props) => (props.$iconOnly ? "22px" : "18px")};
    height: ${(props) => (props.$iconOnly ? "22px" : "18px")};
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

export default Wrapper;
