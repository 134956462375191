import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PrintedInvoice from "../../components/orders/PrintedInvoice";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { selectUser } from "../../store/selectors";
import { getModuleData, getSingleModuleData } from "../../utils/fetchers";

const PrintOrder = () => {
  const [orderDetails, setOrderDetails] = useState<{
    isFetching: boolean;
    data: any;
    extra: any;
  }>({ isFetching: true, data: {}, extra: {} });

  const {
    user: { branch_id: userBranchID },
  } = useSelector(selectUser);

  const { orderID } = useParams();

  useEffect(() => {
    let printTimer: any = null;
    (async () => {
      const fetchController = new AbortController();
      const fetchControllerSignal = fetchController.signal;
      const fetchingFns = [
        getSingleModuleData(
          userBranchID ? "branch" : "admin",
          "orders",
          +(orderID as string),
          "with=products,services,user,services.category,products.category,branch,order_products,order_workers.service,order_workers.worker,coupon,payment_methods",
          fetchControllerSignal,
          { "Accept-Language": "en" }
        ),
        getModuleData(
          "admin",
          "app_settings",
          "where=name~tax",
          fetchControllerSignal
        ),
      ];
      await axios.all(fetchingFns).then((res) => {
        setOrderDetails({
          data: res?.[0]?.data?.data,
          extra: {
            taxesPercentage: +res?.[1]?.data?.module?.data?.[0]?.value || 0,
          },
          isFetching: false,
        });

        printTimer = setTimeout(() => {
          window.print();
        }, 500);
      });
    })();
    return () => {
      if (printTimer) clearTimeout(printTimer);
    };
  }, [orderID, userBranchID]);

  return orderDetails.isFetching ? (
    <div style={{ width: "fit-content", margin: "100px auto 0" }}>
      <LoadingSpinner type="circle" />
    </div>
  ) : (
    <PrintedInvoice order={orderDetails.data} extraData={orderDetails.extra} />
  );
};

export default PrintOrder;
