import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import InputCheckbox from "../../components/ui/Inputs/InputCheckbox";
import Table from "../../components/ui/Table";
import MainContent from "../../containers/MainContent";
import ViewListingPage from "../../containers/ViewListingPage";
import useListingData from "../../hooks/useListingData";
import { selectSettings, selectUser } from "../../store/selectors";
import { Delete } from "../../utils/icons";
import translation from "../../utils/translation";
import MainHeader from "../../containers/MainHeader";
import MainSidebar from "../../containers/MainSidebar";
import { useParams } from "react-router-dom";
import { formatDate, formatMoney } from "../../utils/formatter";
import { getAllBranches } from "../../utils/fetchers";
import { useMemo } from "react";
import InputSelect from "../../components/ui/Inputs/InputSelect";

const ListExpenses = () => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { permissions: userPermissions, branch_id: userBranchID },
  } = useSelector(selectUser);

  const { categoryName, categoryID } = useParams();

  const extraFetchers = useMemo(
    () =>
      userBranchID
        ? []
        : [
            {
              function: getAllBranches,
              returnName: "branches",
            },
          ],
    [userBranchID]
  );

  const {
    data,
    extraFetchedData,
    onRequestDeleteHandler,
    onSelectTableRowHandler,
    isAllRowsSelected,
    showMultipleDelete,
    ...rest
  } = useListingData({
    moduleName: "expenses",
    moduleType: userBranchID ? "branch" : "admin",
    extraFetchers,
    queries: `${
      userBranchID ? "" : "with=branch&"
    }where=expense_category_id=${categoryID}`,
  });

  return (
    <>
      <MainHeader />
      <MainSidebar />
      <MainContent>
        <PageTitle
          firstSection={
            <>
              <span style={{ color: "#878c93", fontWeight: "500" }}>
                {categoryName}
              </span>
              <h1>
                {[
                  translation[siteLang][
                    siteLang === "ar" ? "list" : "expenses"
                  ],
                  " ",
                  translation[siteLang][
                    siteLang === "ar" ? "expenses" : "list"
                  ].toLowerCase(),
                ]}
              </h1>
            </>
          }
          secondSection={
            <>
              <Button
                styleType="secondary"
                size="lg"
                label={[
                  translation[siteLang].back_to,
                  translation[siteLang].categories.toLowerCase(),
                ].join(" ")}
                buttonType="link"
                url={`/expenses`}
              />
              {userPermissions?.expenses?.create && (
                <Button
                  styleType="primary"
                  size="lg"
                  label={[
                    translation[siteLang][
                      siteLang === "ar" ? "expense" : "new"
                    ],
                    translation[siteLang][
                      siteLang === "ar" ? "new" : "expense"
                    ].toLowerCase(),
                  ].join(" ")}
                  buttonType="link"
                  url={`/expenses/list/${categoryName}/${categoryID}/new`}
                />
              )}
            </>
          }
        />
        <ViewListingPage
          {...rest}
          hasNoResults={data.length === 0}
          tableFilter={{
            showMultipleDelete,
            onRequestDeleteHandler,
            hasSearch: true,
            search: {
              ...rest.search,
              placeholder:
                translation[siteLang].search_all +
                " " +
                translation[siteLang].expenses.toLowerCase(),
            },
            extraFilters: (
              <>
                {!userBranchID && (
                  <InputSelect
                    options={[
                      {
                        options: [
                          {
                            label: translation[siteLang].all_branches,
                            value: "__default",
                          },
                          ...(extraFetchedData?.branches?.map((el: any) => ({
                            label: el.name,
                            value: el.id.toString(),
                          })) || []),
                        ],
                      },
                    ]}
                    props={{
                      value:
                        rest.filter.filterData?.["branch_id="] || "__default",
                      style: {
                        minWidth: "200px",
                      },
                      onChange: (e) =>
                        rest.filter.onChangeFilterHandler({
                          "branch_id=": e.target.value,
                        }),
                    }}
                  />
                )}
              </>
            ),
          }}
        >
          <Table
            isHeaderFixed={true}
            headings={[
              ...(data.length > 0 && userPermissions?.expenses?.delete
                ? [
                    {
                      label: (
                        <InputCheckbox
                          checkboxStyle="checkbox"
                          props={{
                            onChange: () => onSelectTableRowHandler(0, true),
                            checked: isAllRowsSelected,
                            style: {
                              marginTop: "7px",
                              width: "15px",
                              height: "15px",
                            },
                          }}
                        />
                      ),
                      style: {
                        width: "50px",
                      },
                    },
                  ]
                : []),
              {
                label: translation[siteLang].expense,
              },
              {
                label: translation[siteLang].date,
              },
              {
                label: translation[siteLang].value,
              },
              {
                label: translation[siteLang].branch,
              },
              ...(userPermissions?.expenses?.delete
                ? [
                    {
                      label: translation[siteLang].actions,
                      style: {
                        width: "85px",
                      },
                    },
                  ]
                : []),
            ]}
            rows={data.map((el) => ({
              link: userPermissions?.expenses?.update
                ? `/expenses/list/${categoryName}/${categoryID}/edit/${el.id}`
                : "",
              columns: [
                ...(userPermissions?.expenses?.delete
                  ? [
                      {
                        link_not_applied: true,
                        value: (
                          <InputCheckbox
                            checkboxStyle="checkbox"
                            props={{
                              onChange: () => onSelectTableRowHandler(el.id),
                              checked: el.table_selected,
                              style: {
                                marginTop: "7px",
                                width: "15px",
                                height: "15px",
                              },
                            }}
                          />
                        ),
                      },
                    ]
                  : []),
                {
                  value: el.name,
                },
                {
                  value: formatDate(el.expense_date, siteLang),
                },
                {
                  value: formatMoney(el.amount, siteLang),
                },
                {
                  value: el?.branch?.name,
                },
                ...(userPermissions?.expenses?.delete
                  ? [
                      {
                        link_not_applied: true,
                        value: (
                          <div className="page_listing_table_actions">
                            <Button
                              icon={
                                <Delete
                                  width="15"
                                  height="15"
                                  color="#101928"
                                />
                              }
                              buttonType="button"
                              styleType="secondary"
                              onClick={() => onRequestDeleteHandler([el.id])}
                              size="md"
                              className="delete"
                            />
                          </div>
                        ),
                      },
                    ]
                  : []),
              ],
            }))}
          />
        </ViewListingPage>
      </MainContent>
    </>
  );
};

export default ListExpenses;
