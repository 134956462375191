import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectSettings, selectUser } from "../../../store/selectors";
import { getSingleModuleData } from "../../../utils/fetchers";
import {
  formatDate,
  formatMoney,
  formatNumber,
} from "../../../utils/formatter";
import { Page } from "../../../utils/icons";
import translation from "../../../utils/translation";
import LoadingSpinner from "../../ui/LoadingSpinner";
import Table from "../../ui/Table";
import { BoxWrapper } from "../../updatingPages/components";

const ClientRedeems = () => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { branch_id: userBranchID },
  } = useSelector(selectUser);
  const { clientID } = useParams();

  const [clientDetails, setClientDetails] = useState<{
    isFetching: boolean;
    data: any;
  }>({ isFetching: true, data: {} });

  useEffect(() => {
    const fetchController = new AbortController();
    const fetchControllerSignal = fetchController.signal;
    getSingleModuleData(
      "admin",
      "users",
      +(clientID as string),
      "with=redeem_history",
      fetchControllerSignal
    ).then((res) => {
      setClientDetails({ isFetching: false, data: res.data.data });
    });
  }, [clientID, userBranchID]);

  return clientDetails.isFetching ? (
    <div style={{ width: "fit-content", margin: "100px auto 0" }}>
      <LoadingSpinner type="circle" />
    </div>
  ) : (
    <>
      <BoxWrapper hasBorder={true} style={{ backgroundColor: "#fff" }}>
        <div className="box_header">
          <h3>{translation[siteLang].redeem_history}</h3>
        </div>
        <div
          className="box_content"
          style={
            clientDetails.data.redeem_history.length > 0 ? { padding: 0 } : {}
          }
        >
          {clientDetails.data.redeem_history.length === 0 ? (
            <div className="page_listing_no_results" style={{ marginTop: 0 }}>
              <Page width="70" height="70" color="#878c93" />
              <p>{translation[siteLang].no_redeem}</p>
            </div>
          ) : (
            <Table
              isHeaderFixed={true}
              headings={[
                {
                  label: translation[siteLang].time,
                  style: {
                    width: "40%",
                  },
                },
                {
                  label: translation[siteLang].points,
                },
                {
                  label: translation[siteLang].wallet,
                },
                // {
                //   label: translation[siteLang].actions,
                //   style: {
                //     width: "105px",
                //   },
                // },
              ]}
              rows={clientDetails.data.redeem_history.map((el: any) => ({
                columns: [
                  {
                    value: formatDate(el.created_at, siteLang, true),
                  },
                  {
                    value: formatNumber(el.points, siteLang),
                  },
                  {
                    value: formatMoney(el.wallet_money, siteLang),
                  },
                  // {
                  //   value: (
                  //     <div className="page_listing_table_actions">
                  //       <Button
                  //         icon={
                  //           <Edit
                  //             width="20"
                  //             height="20"
                  //             color="#101928"
                  //           />
                  //         }
                  //         buttonType="link"
                  //         styleType="secondary"
                  //         url={`/services/edit/${el.id}`}
                  //         size="md"
                  //       />
                  //       <Button
                  //         icon={
                  //           <Delete
                  //             width="20"
                  //             height="20"
                  //             color="#101928"
                  //           />
                  //         }
                  //         buttonType="button"
                  //         styleType="secondary"
                  //         onClick={() =>
                  //           onRequestDeleteHandler(
                  //             [el.id],
                  //             false,
                  //             "services"
                  //           )
                  //         }
                  //         size="md"
                  //         className="delete"
                  //       />
                  //     </div>
                  //   ),
                  // },
                ],
              }))}
            />
          )}
        </div>
      </BoxWrapper>
    </>
  );
};

export default ClientRedeems;
