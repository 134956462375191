import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import InputField from "../../components/ui/Inputs/InputField";
import {
  BoxWrapper,
  SectionsWrapper,
} from "../../components/updatingPages/components";
import MainContent from "../../containers/MainContent";
import useUpdatingData from "../../hooks/useUpdatingData";
import { selectSettings, selectUser } from "../../store/selectors";
import { Arrow } from "../../utils/icons";
import translation from "../../utils/translation";
import { useMemo } from "react";
import UpdateModulePage from "../../containers/UpdateModulePage";
import { validateInputField } from "../../containers/UpdateModulePage/utils";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { useParams } from "react-router-dom";
import {
  assignRoleToAdmin,
  getAllBranches,
  getModuleData,
  removeRoleFromAdmin,
} from "../../utils/fetchers";
import InputSelect from "../../components/ui/Inputs/InputSelect";
import { rawPhoneNumber } from "../Clients/utils";

const moduleBaseName = "/admins";

const UpdateAdmins = ({ type }: { type: "create" | "edit" }) => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { branch_id: userBranchID },
  } = useSelector(selectUser);

  const { adminID } = useParams();

  const extraFetchers = useMemo(
    () => [
      ...(userBranchID
        ? []
        : [
            {
              function: getAllBranches,
              returnName: "branches",
            },
          ]),
      {
        function: (signal: AbortSignal) =>
          getModuleData("admin", "roles", "", signal),
        returnName: "roles",
        isModule: true,
      },
    ],
    [userBranchID]
  );

  const extraSubmissions = useMemo(
    () => [
      {
        function: (id: number, refinedData: any) => {
          return assignRoleToAdmin(id, refinedData.rolesToAssign);
        },
        responseOfID: "admin",
      },
      {
        function: (id: number, refinedData: any) =>
          removeRoleFromAdmin(id, refinedData.removedRoles),
        responseOfID: "admin",
      },
    ],
    []
  );

  const defaultValues = useMemo(
    () => ({
      name: "",
      email: "",
      phone: "",
      role: "normal",
      monthly_salary: "",
      branch_id: userBranchID ? userBranchID : "no_option",
      assigned_role: "no_option",
    }),
    [userBranchID]
  );

  const resetModuleDefaults = useMemo(
    () => (clonedDefaults: any) => ({
      branch_id: clonedDefaults?.branch_id?.toString() || "no_option",
      assigned_role: clonedDefaults?.roles[0]?.id?.toString() || "no_option",
      phone: +rawPhoneNumber(clonedDefaults?.phone || "") || "",
    }),
    []
  );

  const {
    isFetching,
    extraFetchedData,
    register,
    watchedInputs,
    setValue,
    moduleDefaultData,
    ...updatingModule
  } = useUpdatingData({
    moduleType: userBranchID ? "branch" : "admin",
    moduleState: type,
    moduleID: +(adminID || 0),
    moduleName: "admins",
    modulePostEndpoint:
      type === "edit" ? `admin/update/${adminID}` : "admin/create",
    ...(type === "edit" ? { modulePatch: true, resetModuleDefaults } : {}),
    modulePostType: "object",
    watchInputFields: true,
    defaultValues,
    navigateAfterSubmissionTo: moduleBaseName,
    extraFetchers,
    queries: "with=roles",
    extraSubmissions,
  });

  const formState = updatingModule.formState;

  const updatedDataToSubmit = useMemo(() => {
    return (data: any) => ({
      rolesToAssign:
        (moduleDefaultData?.roles?.length > 0 &&
          moduleDefaultData?.roles?.findIndex(
            (el: any) => el.id.toString() === data.assigned_role
          ) !== -1) ||
        data.assigned_role === "no_option"
          ? []
          : [data.assigned_role],
      removedRoles:
        type === "edit"
          ? moduleDefaultData?.roles?.length > 0 &&
            moduleDefaultData?.roles?.findIndex(
              (el: any) => el.id.toString() === data.assigned_role
            ) !== -1
            ? []
            : moduleDefaultData?.roles?.map((el: any) => el.id)
          : [],
      ...(rawPhoneNumber(data.phone) !==
      rawPhoneNumber(moduleDefaultData.phone || "")
        ? {
            phone: "+20" + rawPhoneNumber(data.phone),
          }
        : { phone: "" }),
      email: moduleDefaultData.email === data.email ? "" : data.email,
      ...(userBranchID || data.role === "normal"
        ? {
            branch_id: userBranchID ? userBranchID : data.branch_id,
          }
        : {}),
    });
  }, [moduleDefaultData, userBranchID, type]);

  return (
    <MainContent onlyInPage={true} hasFixedWidthHeader={true}>
      <UpdateModulePage
        moduleBaseName={moduleBaseName}
        updatedDataToSubmit={updatedDataToSubmit}
        {...updatingModule}
      >
        <PageTitle
          firstSection={
            <div className="page_header_title_update">
              <Button
                buttonType="button"
                size="md"
                styleType="icon"
                onClick={() => updatingModule.onNavigateBack(moduleBaseName)}
                disabled={updatingModule.submissionState.isSubmitting}
                icon={
                  <Arrow
                    height="27"
                    width="27"
                    color="#101928"
                    extraStyle={{
                      transform: `rotate(${
                        siteLang === "ar" ? "180" : "0"
                      }deg)`,
                    }}
                  />
                }
              />
              <h1>
                {type === "edit"
                  ? [
                      translation[siteLang].edit,
                      " ",
                      translation[siteLang].admin.toLowerCase(),
                    ]
                  : [
                      translation[siteLang].add,
                      " ",
                      translation[siteLang][
                        siteLang === "ar" ? "admin" : "new"
                      ].toLowerCase(),
                      " ",
                      translation[siteLang][
                        siteLang === "ar" ? "new" : "admin"
                      ].toLowerCase(),
                    ]}
              </h1>
            </div>
          }
          secondSection={
            <Button
              label={translation[siteLang].save}
              buttonType="submit"
              size="lg"
              styleType="primary"
              disabled={
                isFetching || updatingModule.submissionState.isSubmitting
              }
              isLoading={updatingModule.submissionState.isSubmitting}
            />
          }
        />
        <SectionsWrapper isSeparated={true} fixedWidth={true}>
          {isFetching ? (
            <div style={{ width: "fit-content", margin: "100px auto 0" }}>
              <LoadingSpinner type="circle" />
            </div>
          ) : (
            <BoxWrapper hasBorder={true}>
              <div className="box_header">
                <h3>{translation[siteLang].basic_info}</h3>
              </div>
              <div className="box_content">
                <div className="box_row">
                  <div>
                    <InputField
                      label={translation[siteLang].name}
                      type="text"
                      props={register(
                        "name",
                        validateInputField(siteLang, true)
                      )}
                      error={formState.errors?.name?.message}
                    />
                  </div>
                  <div>
                    <InputField
                      label={translation[siteLang].email}
                      type="text"
                      props={register(
                        "email",
                        validateInputField(siteLang, true)
                      )}
                      error={formState.errors?.email?.message}
                    />
                  </div>
                  <div>
                    <InputField
                      label={translation[siteLang].password}
                      type="password"
                      props={register(
                        "password",
                        type === "create"
                          ? validateInputField(siteLang, true)
                          : {}
                      )}
                      error={formState.errors?.password?.message}
                    />
                  </div>
                  <div>
                    <InputField
                      label={translation[siteLang].phone}
                      type="number"
                      icon={<>+20</>}
                      iconBorder={true}
                      props={register(
                        "phone",
                        validateInputField(siteLang, true)
                      )}
                      error={formState.errors?.phone?.message}
                    />
                  </div>
                  <div>
                    <InputField
                      label={translation[siteLang].monthly_salary}
                      type="number"
                      icon={<>{siteLang === "ar" ? "جنيه" : "EGP"}</>}
                      iconBorder={true}
                      props={register(
                        "monthly_salary",
                        validateInputField(siteLang, true)
                      )}
                      error={formState.errors?.monthly_salary?.message}
                    />
                  </div>
                  <div>
                    <InputSelect
                      label={translation[siteLang].role}
                      options={[
                        {
                          options:
                            extraFetchedData?.roles?.map((el: any) => ({
                              label: el.name,
                              value: el.id.toString(),
                            })) || [],
                        },
                      ]}
                      props={{
                        ...register("assigned_role"),
                        value: watchedInputs.assigned_role,
                      }}
                    />
                  </div>
                  <div>
                    <InputSelect
                      label={translation[siteLang].type}
                      options={[
                        {
                          options: [
                            {
                              label: translation[siteLang].normal_admin,
                              value: "normal",
                            },
                            {
                              label: translation[siteLang].super_admin,
                              value: "super",
                            },
                          ],
                        },
                      ]}
                      dependantConditions={[
                        {
                          name: "branch_id",
                          value: {
                            normal: "no_option",
                            super: "no_option",
                          },
                        },
                      ]}
                      setDependantValues={setValue}
                      props={{
                        ...register(
                          "role",
                          validateInputField(siteLang, true, true)
                        ),
                        value: watchedInputs.role,
                      }}
                      error={formState.errors?.role?.message}
                    />
                  </div>
                  {watchedInputs.role === "normal" && !userBranchID && (
                    <div>
                      <InputSelect
                        label={translation[siteLang].branch}
                        options={[
                          {
                            options:
                              extraFetchedData?.branches?.map((el: any) => ({
                                label: el.name,
                                value: el.id.toString(),
                              })) || [],
                          },
                        ]}
                        props={{
                          ...register("branch_id"),
                          value: watchedInputs.branch_id,
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </BoxWrapper>
          )}
        </SectionsWrapper>
      </UpdateModulePage>
    </MainContent>
  );
};

export default UpdateAdmins;
