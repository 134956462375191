import styled from "styled-components";

export const SectionsWrapper = styled.div<{
  isSeparated?: boolean;
  fixedWidth?: boolean;
}>`
  ${(props) =>
    props.fixedWidth
      ? `
      max-width: 1100px;
      margin: auto;
      `
      : ""}
  .section_wrapper_row {
    margin: -15px;
    display: flex;
    flex-wrap: wrap;
    > * {
      padding: 15px;
    }
  }
  .invoice_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    &:not(:last-child) {
      border-bottom: 1px solid var(--lightGrey3);
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
  }
  .invoice_balance {
    font-weight: 700;
    font-size: 18px;
  }
`;

export const BoxWrapper = styled.div<{ hasBorder?: true }>`
  ${(props) =>
    props.hasBorder
      ? `
      border: 1px solid var(--lightGrey4);
      border-radius: 7px;
      .box_header {
        padding: 15px 25px;
      }
      .box_content {
        padding: 20px 25px 25px;
      }
      table tr:last-child td {
        border-bottom : 0;
      }
      table th:first-child, table td:first-child {
        padding-${props.theme.lang === "ar" ? "right" : "left"}: 25px;
      }
      table th:last-child, table td:last-child {
        padding-${props.theme.lang === "ar" ? "left" : "right"}: 25px;
      }
    `
      : `
      .box_header {
        padding-bottom: 15px;
      }
      .box_content {
        padding-top: 20px;
      }
      `}
  .box_header {
    border-bottom: 1px solid var(--lightGrey4);
    h3 {
      font-size: 20px;
      font-weight: 800;
      color: var(--black);
      margin: 0;
    }
    p {
      font-size: 15px;
      font-weight: 500;
      color: var(--darkGrey);
      margin: 0;
    }
  }
  .box_content {
    position: relative;
  }
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  .box_section:not(:last-child) {
    margin-bottom: 15px;
  }
  .box_row {
    margin: -10px;
    display: flex;
    flex-wrap: wrap;
    > * {
      width: 50%;
      padding: 10px;
    }
  }
  .box_separator {
    margin: 0 -25px;
    background-color: var(--lightGrey4);
    height: 1px;
  }
  .box_content_full_width {
    > div > button {
      width: 100%;
    }
  }
  .order_service_remove {
    position: absolute;
    ${(props) => (props.theme.lang === "ar" ? "left" : "right")}: 15px;
    top: 10px;
    padding: 0;
    border: none;
    outline: none;
    background: none;
  }
  .page_listing_no_results {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    p {
      margin: 10px 0 0;
      font-weight: 600;
      color: var(--lightGrey6);
    }
  }
  .page_listing_table_actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    > *:not(:last-child) {
      margin-${(props) => (props.theme.lang === "ar" ? "left" : "right")}: 7px;
    }
    svg {
      transition: fill .1s ease-in-out;
      width: 18px;
      height: 18px;
    }
    .delete {
      &:hover, &:focus {
        border-color: var(--red);
        background-color: var(--red);
        svg {
          fill: var(--white);
        }
      }
    }
  }
`;

export const SplittedPage = styled.div<{
  pixelsToMinusFromWidth?: number;
}>`
  position: fixed;
  width: calc(100% - ${(props) => props.pixelsToMinusFromWidth || 0}px);
  overflow-y: auto;
  padding: 30px 40px 80px;
  ${(props) => (props.lang === "ar" ? "right" : "left")}: 0;
  height: calc(100vh - 73px);
  top: 73px;
`;

export const SplittedPageSidebar = styled.div<{
  width?: number;
}>`
  position: fixed;
  width: ${(props) => props.width}px;
  overflow-y: auto;
  ${(props) => (props.lang === "ar" ? "left" : "right")}: 0;
  border-${(props) =>
    props.lang === "ar" ? "right" : "left"}: 1px solid var(--lightGrey3);
  height: calc(100vh - 73px - 93px);
  top: 73px;
  h3 {
    width: 100%;
    margin: 40px 0 15px;
    font-weight: 800;
    &:first-child {
      margin-top : 0;
    }
  }
`;

export const SplittedPageSidebarButton = styled.div<{
  width?: number;
}>`
  position: fixed;
  width: ${(props) => props.width}px;
  border-top: 1px solid var(--lightGrey3);
  border-${(props) =>
    props.lang === "ar" ? "right" : "left"}: 1px solid var(--lightGrey3);
  bottom: 0;
  ${(props) => (props.lang === "ar" ? "left" : "right")}: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  button {
    width: calc(50% - 5px);
    &:first-child:not(:last-child) {
      margin-${(props) => (props.lang === "ar" ? "left" : "right")}: 10px;
    }
  }
`;
