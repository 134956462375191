import { formatDate, formatMoney } from "../../../utils/formatter";
import Logo from "../../../utils/logo.png";
import translation from "../../../utils/translation";
import QRCode from "./QRCode.jpeg";
import { Wrapper } from "./styles";

const PrintedInvoice = ({
  order,
  extraData,
}: {
  order: any;
  extraData: any;
}) => {
  return (
    <Wrapper>
      <div className="invoice_row" style={{ borderBottom: 0 }}>
        <div className="invoice_col full">
          <p>
            <img height="35px" src={Logo} alt="" />
          </p>
        </div>
      </div>
      <div className="invoice_row">
        <div className="invoice_col">
          <p>
            <b>Branch: {order.branch.name}</b>
          </p>
          <p>
            <b>Branch ID: {order.branch.id}</b>
          </p>
          <p>{formatDate(order.reservation_time, "en")}</p>
          <p>{formatDate(order.reservation_time, "en", true, {}, false)}</p>
        </div>
        {/* <div className="invoice_col">
          <p>{formatDate(order.reservation_time, "en")}</p>
        </div> */}
      </div>
      <div className="invoice_row">
        <div className="invoice_col">
          <p>
            <b> Order ID: {order.id}</b>
          </p>
        </div>
      </div>
      {order.services.map((serv: any) => (
        <div className="invoice_row">
          <div className="invoice_col">
            <p>{serv.category.name}</p>
            <p>
              <b>{serv.name}</b>
            </p>
            <p>
              {order?.order_workers.find(
                (worker: any) => worker.service_id === serv.id
              )?.worker?.name
                ? ` With ${
                    order?.order_workers.find(
                      (worker: any) => worker.service_id === serv.id
                    )?.worker?.name || ""
                  }`
                : ""}
            </p>
          </div>
          <div className="invoice_col">
            <p>{formatMoney(serv.price, "en")}</p>
          </div>
        </div>
      ))}
      {order.products.map((prod: any) => (
        <div className="invoice_row">
          <div className="invoice_col">
            <p>{prod.category.name}</p>
            <p>
              <b>
                {order.order_products.find(
                  (el: any) => el.product_id === prod.id
                )?.quantity || 0}{" "}
                x {prod.name}
              </b>
            </p>
          </div>
          <div className="invoice_col">
            <p>{formatMoney(prod.price, "en")}</p>
          </div>
        </div>
      ))}
      <div className="invoice_row">
        <div className="invoice_col full">
          <p>
            Total items:{" "}
            {order.services.length +
              order.order_products.reduce(
                (acc: any, cur: any) => acc + cur.quantity,
                0
              )}
          </p>
        </div>
      </div>
      {order?.payment_methods?.length > 0 && (
        <div className="invoice_row">
          <div className="invoice_col">
            <p>Paid with</p>
          </div>
          <div className="invoice_col">
            <p>
              {order?.payment_methods
                ?.map((el: any) => translation.en[el.payment_method])
                ?.join(", ")}
            </p>
          </div>
        </div>
      )}
      <div className="invoice_row">
        <div className="invoice_col">
          <p>Subtotal</p>
        </div>
        <div className="invoice_col">
          <p>
            {formatMoney(
              order.services.reduce(
                (acc: any, cur: any) => acc + cur.price,
                0
              ) +
                order.products.reduce(
                  (acc: any, cur: any) =>
                    acc +
                    cur.price *
                      (order.order_products.find(
                        (el: any) => el.product_id === cur.id
                      )?.quantity || 0),
                  0
                ),
              "en"
            )}
          </p>
        </div>
      </div>
      <div className="invoice_row">
        <div className="invoice_col">
          <p>Taxes ({extraData?.taxesPercentage || "0"}%)</p>
        </div>
        <div className="invoice_col">
          <p>{formatMoney(order.tax, "en")}</p>
        </div>
      </div>
      {order.discounted_amount > 0 && (
        <div className="invoice_row">
          <div className="invoice_col">
            <p>
              Discount{order?.coupon?.code ? ` (${order.coupon.code})` : ""}
            </p>
          </div>
          <div className="invoice_col">
            <p>{formatMoney(order.discounted_amount, "en")}</p>
          </div>
        </div>
      )}
      <div className="invoice_row" style={{ marginBottom: 0 }}>
        <div className="invoice_col">
          <p style={{ fontWeight: "800" }}>Total</p>
        </div>
        <div className="invoice_col">
          <p style={{ fontWeight: "800" }}>
            {formatMoney(order.total_amount, "en")}
          </p>
        </div>
      </div>
      <div
        className="invoice_row"
        style={{
          backgroundColor: "var(--black)",
          color: "var(--white)",
          paddingTop: "10px",
        }}
      >
        <div className="invoice_col full">
          <p style={{ fontWeight: "400" }}>
            It was a pleasure to serve you.
            <br />
            <b>01129978411</b>
          </p>
        </div>
      </div>
      <div
        className="invoice_row"
        style={{
          border: 0,
          padding: 0,
        }}
      >
        <div className="invoice_col full">
          <p>
            <img src={QRCode} alt="" style={{ width: "100px" }} />
          </p>
        </div>
      </div>
    </Wrapper>
  );
};

export default PrintedInvoice;
