import { HTMLAttributes, Ref, useCallback, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { selectSettings } from "../../../store/selectors";
import { Close } from "../../../utils/icons";
import Button from "../Button";
import { Overlay } from "../Dropdown/styles";
import Wrapper from "./styles";
import { SidebarWidgetType } from "./utils";

const ContainerElement = ({
  type,
  children,
  childRef,
  ...props
}: {
  type: "div" | "form";
  childRef: Ref<HTMLFormElement | HTMLDivElement>;
} & HTMLAttributes<HTMLFormElement | HTMLDivElement>) => {
  if (type === "form")
    return (
      <form
        {...(props as HTMLAttributes<HTMLFormElement>)}
        ref={childRef as Ref<HTMLFormElement>}
      >
        {children}
      </form>
    );
  else
    return (
      <div
        {...(props as HTMLAttributes<HTMLDivElement>)}
        ref={childRef as Ref<HTMLDivElement>}
      >
        {children}
      </div>
    );
};

const SidebarWidget = ({
  children,
  headerTitle,
  onCloseHandler,
  showFooter,
  footerButtons,
  containerType = "div",
  formSubmission,
}: SidebarWidgetType) => {
  const sidebar_widget = useRef<HTMLDivElement | HTMLFormElement>(null);
  const { siteLang } = useSelector(selectSettings);

  useEffect(() => {
    if (sidebar_widget.current) {
      sidebar_widget.current.animate(
        [
          {
            transform: `translateX(${siteLang === "ar" ? "-" : ""}100%)`,
          },
          {
            transform: "translateX(0)",
          },
        ],
        {
          duration: 400,
          easing: "cubic-bezier(.23,1,.32,1)",
          fill: "forwards",
        }
      );
    }
  }, [siteLang]);

  const onAnimateCloseHandler = useCallback(
    (e) => {
      if (sidebar_widget.current) {
        sidebar_widget.current.animate(
          [
            {
              transform: "translateX(0)",
            },
            {
              transform: `translateX(${siteLang === "ar" ? "-" : ""}100%)`,
            },
          ],
          {
            duration: 200,
            easing: "cubic-bezier(.23,1,.32,1)",
            fill: "forwards",
          }
        );
        setTimeout(() => {
          onCloseHandler(e);
        }, 200);
      }
    },
    [onCloseHandler, siteLang]
  );

  return createPortal(
    <Wrapper>
      <ContainerElement
        type={containerType}
        className="sidebar_widget"
        childRef={sidebar_widget}
        {...(containerType === "form"
          ? ({ onSubmit: formSubmission } as HTMLAttributes<HTMLFormElement>)
          : {})}
      >
        <div className="sidebar_widget_header">
          <p>{headerTitle}</p>
          <Button
            buttonType="button"
            size="md"
            styleType="icon"
            onClick={onAnimateCloseHandler}
            icon={
              <Close
                height="27"
                width="27"
                color="#101928"
                extraStyle={{ strokeWidth: 2 }}
              />
            }
          />
        </div>
        <div className="sidebar_widget_body">{children}</div>
        {showFooter && (
          <div className="sidebar_widget_footer">
            <div className="sidebar_widget_footer__buttons">
              {footerButtons?.map((button, index) => (
                <div key={index}>
                  <Button {...button} />
                </div>
              ))}
            </div>
          </div>
        )}
      </ContainerElement>
      <Overlay className="overlay" onClick={onAnimateCloseHandler} />
    </Wrapper>,
    document.getElementById("portalRoot") as HTMLElement
  );
};

export default SidebarWidget;
