import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { selectSettings } from "../../../store/selectors";
import { formatMoney } from "../../../utils/formatter";
import { Close } from "../../../utils/icons";
import { Wrapper } from "./styles";

const OrderServiceWidget = ({
  children,
  onRemoveHandler,
  price,
  quantity,
  title,
  info,
}: {
  quantity: number;
  price: number;
  title: string;
  info: string;
  children: ReactNode;
  onRemoveHandler?: CallableFunction | null;
}) => {
  const { siteLang } = useSelector(selectSettings);

  return (
    <Wrapper>
      <div className="widget_quantity">{quantity}</div>
      <div className="widget_price">
        <span>{formatMoney(price, siteLang)}</span>
        {onRemoveHandler && (
          <button type="button" onClick={() => onRemoveHandler()}>
            <Close width="18" height="18" color="#f23636" />
          </button>
        )}
      </div>
      <div className="widget_body">
        <div className="widget_title">
          <h3>{title}</h3>
          <span>{info}</span>
        </div>
        {children}
      </div>
    </Wrapper>
  );
};

export default OrderServiceWidget;
