import styled from "styled-components";

export const Wrapper = styled.div`
  .count {
    font-size: 40px;
    font-weight: 800;
    color: var(--darkBlue);
    margin: 0;
    line-height: 25px;
  }
  .divisions {
    color: var(--darkBlue);
    margin-top: 20px;
    font-weight: 500;
    span:first-child:not(:last-child) {
      color: var(--lightGrey6);
      margin-${(props) => (props.theme.lang === "ar" ? "left" : "right")}: 10px;
    }
    span:last-child:not(:first-child) {
      font-weight: 700;
    }
  }
`;
