import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Table from "../../components/ui/Table";
import MainContent from "../../containers/MainContent";
import ViewListingPage from "../../containers/ViewListingPage";
import useListingData from "../../hooks/useListingData";
import { selectSettings, selectUser } from "../../store/selectors";
import translation from "../../utils/translation";
import MainHeader from "../../containers/MainHeader";
import MainSidebar from "../../containers/MainSidebar";
import { formatDate } from "../../utils/formatter";
import Button from "../../components/ui/Button";
import { Delete } from "../../utils/icons";
import InputCheckbox from "../../components/ui/Inputs/InputCheckbox";

const ListSupportTickets = () => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { permissions: userPermissions },
  } = useSelector(selectUser);

  const {
    data,
    extraFetchedData,
    onRequestDeleteHandler,
    showMultipleDelete,
    onSelectTableRowHandler,
    isAllRowsSelected,
    ...rest
  } = useListingData({
    moduleName: "support_forms",
    moduleType: "admin",
    queries: "with=user,support_reason",
  });

  return (
    <>
      <MainHeader />
      <MainSidebar />
      <MainContent
        {...(userPermissions?.app_settings?.view ||
        userPermissions?.notifications?.view ||
        userPermissions?.coupons?.view ||
        userPermissions?.banners?.view ||
        userPermissions?.support_reasons?.view ||
        userPermissions?.referal_codes?.view ||
        userPermissions?.cancellation_reasons?.view
          ? {
              hasContentSidebar: true,
              contentSidebarSections: [
                {
                  title: translation[siteLang].settings,
                  links: [
                    ...(userPermissions?.app_settings?.view
                      ? [
                          {
                            label: translation[siteLang].app_settings,
                            url: "/settings",
                          },
                        ]
                      : []),
                    {
                      label: translation[siteLang].support_tickets,
                      url: "/settings/tickets",
                    },
                    ...(userPermissions?.notifications?.view
                      ? [
                          {
                            label: translation[siteLang].notifications,
                            url: "/settings/notifications",
                          },
                        ]
                      : []),
                    ...(userPermissions?.coupons?.view
                      ? [
                          {
                            label: translation[siteLang].coupons,
                            url: "/settings/coupons",
                          },
                        ]
                      : []),
                    ...(userPermissions?.banners?.view
                      ? [
                          {
                            label: translation[siteLang].banners,
                            url: "/settings/banners",
                          },
                        ]
                      : []),
                    ...(userPermissions?.referal_codes?.view
                      ? [
                          {
                            label: translation[siteLang].referrals,
                            url: "/settings/referrals",
                          },
                        ]
                      : []),
                    ...(userPermissions?.support_reasons?.view
                      ? [
                          {
                            label: translation[siteLang].support_reasons,
                            url: "/settings/support-reasons",
                          },
                        ]
                      : []),
                    ...(userPermissions?.cancellation_reasons?.view
                      ? [
                          {
                            label: translation[siteLang].cancel_reasons,
                            url: "/settings/cancel-reasons",
                          },
                        ]
                      : []),
                  ],
                },
              ],
            }
          : {
              hasContentSidebar: false,
            })}
      >
        <PageTitle
          firstSection={
            <h1>
              {[
                translation[siteLang][siteLang === "ar" ? "list" : "tickets"],
                " ",
                translation[siteLang][
                  siteLang === "ar" ? "tickets" : "list"
                ].toLowerCase(),
              ]}
            </h1>
          }
        />
        <ViewListingPage
          {...rest}
          hasNoResults={data.length === 0}
          tableFilter={{
            showMultipleDelete,
            onRequestDeleteHandler,
            hasSearch: true,
            search: {
              ...rest.search,
              placeholder:
                translation[siteLang].search_all +
                " " +
                translation[siteLang].tickets.toLowerCase(),
            },
          }}
        >
          <Table
            isHeaderFixed={true}
            headings={[
              ...(data.length > 0
                ? [
                    {
                      label: (
                        <InputCheckbox
                          checkboxStyle="checkbox"
                          props={{
                            onChange: () => onSelectTableRowHandler(0, true),
                            checked: isAllRowsSelected,
                            style: {
                              marginTop: "7px",
                              width: "15px",
                              height: "15px",
                            },
                          }}
                        />
                      ),
                      style: {
                        width: "50px",
                      },
                    },
                  ]
                : []),
              {
                label: translation[siteLang].reason,
              },
              {
                label: translation[siteLang].status,
              },
              {
                label: translation[siteLang].user_id,
              },
              {
                label: translation[siteLang].user,
              },
              {
                label: translation[siteLang].message,
              },
              {
                label: translation[siteLang].time,
              },
              {
                label: translation[siteLang].actions,
                style: {
                  width: "85px",
                },
              },
            ]}
            rows={data.map((el) => ({
              link: `/settings/tickets/edit/${el.id}`,
              columns: [
                {
                  link_not_applied: true,
                  value: (
                    <InputCheckbox
                      checkboxStyle="checkbox"
                      props={{
                        onChange: () => onSelectTableRowHandler(el.id),
                        checked: el.table_selected,
                        style: {
                          marginTop: "7px",
                          width: "15px",
                          height: "15px",
                        },
                      }}
                    />
                  ),
                },
                {
                  value: el.support_reason?.reason,
                },
                {
                  value: translation[siteLang][el.status],
                },
                {
                  value: el.user_id,
                },
                {
                  value: el.user?.name,
                },
                {
                  value:
                    el.message.length > 20
                      ? el.message.substring(0, 20) + "..."
                      : el.message,
                },
                {
                  value: formatDate(el.created_at, siteLang),
                },
                {
                  link_not_applied: true,
                  value: (
                    <div className="page_listing_table_actions">
                      <Button
                        icon={<Delete width="20" height="20" color="#101928" />}
                        buttonType="button"
                        styleType="secondary"
                        onClick={() => onRequestDeleteHandler([el.id])}
                        size="md"
                        className="delete"
                      />
                    </div>
                  ),
                },
              ],
            }))}
          />
        </ViewListingPage>
      </MainContent>
    </>
  );
};

export default ListSupportTickets;
