import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import InputCheckbox from "../../components/ui/Inputs/InputCheckbox";
import Table from "../../components/ui/Table";
import MainContent from "../../containers/MainContent";
import ViewListingPage from "../../containers/ViewListingPage";
import useListingData from "../../hooks/useListingData";
import { selectSettings, selectUser } from "../../store/selectors";
import { Delete } from "../../utils/icons";
import translation from "../../utils/translation";
import MainHeader from "../../containers/MainHeader";
import MainSidebar from "../../containers/MainSidebar";
import { useNavigate } from "react-router-dom";

const ListCities = () => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { permissions: userPermissions },
  } = useSelector(selectUser);

  const navigate = useNavigate();

  const {
    data,
    extraFetchedData,
    onRequestDeleteHandler,
    onSelectTableRowHandler,
    isAllRowsSelected,
    showMultipleDelete,
    ...rest
  } = useListingData({
    moduleName: "cities",
    moduleType: "admin",
  });

  return (
    <>
      <MainHeader />
      <MainSidebar />
      <MainContent>
        <PageTitle
          firstSection={
            <h1>
              {[
                translation[siteLang][siteLang === "ar" ? "list" : "cities"],
                " ",
                translation[siteLang][
                  siteLang === "ar" ? "cities" : "list"
                ].toLowerCase(),
              ]}
            </h1>
          }
          {...(userPermissions?.cities?.create
            ? {
                secondSection: (
                  <Button
                    styleType="primary"
                    size="lg"
                    label={[
                      translation[siteLang][siteLang === "ar" ? "city" : "new"],
                      translation[siteLang][
                        siteLang === "ar" ? "new" : "city"
                      ].toLowerCase(),
                    ].join(" ")}
                    buttonType="link"
                    url="/cities/new"
                  />
                ),
              }
            : {})}
        />
        <ViewListingPage
          {...rest}
          hasNoResults={data.length === 0}
          tableFilter={{
            showMultipleDelete,
            onRequestDeleteHandler,
            hasSearch: true,
            search: {
              ...rest.search,
              placeholder:
                translation[siteLang].search_all +
                " " +
                translation[siteLang].cities.toLowerCase(),
            },
          }}
        >
          <Table
            isHeaderFixed={true}
            headings={[
              ...(data.length > 0 && userPermissions?.cities?.delete
                ? [
                    {
                      label: (
                        <InputCheckbox
                          checkboxStyle="checkbox"
                          props={{
                            onChange: () => onSelectTableRowHandler(0, true),
                            checked: isAllRowsSelected,
                            style: {
                              marginTop: "7px",
                              width: "15px",
                              height: "15px",
                            },
                          }}
                        />
                      ),
                      style: {
                        width: "50px",
                      },
                    },
                  ]
                : []),
              {
                label: translation[siteLang].city,
              },
              ...(userPermissions?.cities?.delete ||
              userPermissions?.regions?.view
                ? [
                    {
                      label: translation[siteLang].actions,
                      style: {
                        width: "200px",
                      },
                    },
                  ]
                : []),
            ]}
            rows={data.map((el) => ({
              link: userPermissions?.cities?.update
                ? `/cities/edit/${el.id}`
                : "",
              columns: [
                ...(userPermissions?.cities?.delete
                  ? [
                      {
                        link_not_applied: true,
                        value: (
                          <InputCheckbox
                            checkboxStyle="checkbox"
                            props={{
                              onChange: () => onSelectTableRowHandler(el.id),
                              checked: el.table_selected,
                              style: {
                                marginTop: "7px",
                                width: "15px",
                                height: "15px",
                              },
                            }}
                          />
                        ),
                      },
                    ]
                  : []),
                {
                  value: el.name,
                },
                ...(userPermissions?.cities?.delete ||
                userPermissions?.regions?.view
                  ? [
                      {
                        link_not_applied: true,
                        value: (
                          <div className="page_listing_table_actions">
                            {userPermissions?.regions?.view && (
                              <Button
                                label={translation[siteLang].regions}
                                buttonType="button"
                                styleType="secondary"
                                onClick={() =>
                                  navigate(
                                    `/cities/regions/${el.name}/${el.id}`
                                  )
                                }
                                size="md"
                              />
                            )}
                            {userPermissions?.cities?.delete && (
                              <Button
                                icon={
                                  <Delete
                                    width="15"
                                    height="15"
                                    color="#101928"
                                  />
                                }
                                buttonType="button"
                                styleType="secondary"
                                onClick={() => onRequestDeleteHandler([el.id])}
                                size="md"
                                className="delete"
                                extraStyle={{ height: "40px", width: "40px" }}
                              />
                            )}
                          </div>
                        ),
                      },
                    ]
                  : []),
              ],
            }))}
          />
        </ViewListingPage>
      </MainContent>
    </>
  );
};

export default ListCities;
