type TranslationType = {
  en: {
    [name: string]: string;
  };
  ar: {
    [name: string]: string;
  };
};

const translation: TranslationType = {
  en: {
    en: "in English",
    ar: "in Arabic",
    sort_by: "Sort by",
    filters: "Filters",
    save: "Save",
    clear: "Clear",
    default: "Default",
    reservation_time: "Reservation time",
    search: "Search",
    listing_no_results: "This page does not have results.",
    login: "Login",
    logout: "Logout",
    my_profile: "My profile",
    notification: "Notification",
    notifications: "Notifications",
    dashboard: "Dashboard",
    orders: "Orders",
    order: "Order",
    list: "List",
    id: "ID",
    user: "User",
    service: "Service",
    services: "Services",
    categories: "Categories",
    category: "Category",
    all_categories: "All categories",
    type: "Type",
    date: "Date",
    time: "Time",
    day: "Day",
    days: "Days",
    actions: "Actions",
    branches: "Branches",
    branch: "Branch",
    all_branches: "All branches",
    cancel: "Cancel",
    new: "New",
    delete: "Delete",
    delete_selected: "Delete selected",
    sure_to_refund: "Are you sure you want to refund this order?",
    sure_to_delete: "Are you sure you want to delete?",
    sure_to_block: "Are you sure you want to block this user?",
    sure_to_unblock: "Are you sure you want to unblock this user?",
    product: "Product",
    products: "Products",
    stocks: "Stocks",
    stock: "Stock",
    featured: "Featured",
    quantity: "Quantity",
    left_over: "Left over",
    usage: "Usage",
    available: "Available",
    not_available: "Not available",
    multi_use: "Multi use",
    price: "Price",
    search_all: "Search all",
    add_photo: "Add a photo",
    add_pdf: "Add a PDF file",
    add: "Add",
    edit: "Edit",
    basic_info: "Basic info",
    inventory: "Inventory",
    product_image: "Product image",
    name: "Name",
    description: "Description",
    available_in_stock: "Available in stock?",
    used_multiple: "Used multiple times?",
    use_times: "Use times",
    usage_amount: "Usage amount per time",
    warning: "Warning",
    sure_cancel_changes:
      "Are you sure you want to leave? The changes you made won't be saved.",
    discard: "Discard",
    submission_error: "Submission error",
    submission_error_msg:
      "Error occured while submitting data. Please try again.",
    submission_successful: "Submission successful",
    submission_successful_msg: "Data is submitted successfuly.",
    required_field: "This field is required",
    estimated_time: "Estimated time",
    commission: "Commission",
    online: "Online",
    category_no_services: "This category has no services.",
    category_no_products: "This category has no products.",
    home: "Home",
    van: "Van",
    home_service: "Home service?",
    service_stocks: "Service stocks",
    select_branch_for_stocks: "Select a branch to view its stocks",
    no_stocks_at_branch: "No stocks available in branch",
    phone: "Phone",
    password: "Password",
    sku: "SKU",
    barcode: "Barcode",
    brand: "Brand",
    brands: "Brands",
    indoor: "Indoor",
    outdoor: "Outdoor",
    start_time: "Start time",
    end_time: "End time",
    not_yet: "Not yet",
    service_duration: "Service duration",
    worker: "Worker",
    workers: "Workers",
    age: "Age",
    gender: "Gender",
    male: "Male",
    female: "Female",
    email: "Email",
    job_title: "Job title",
    monthly_salary: "Monthly salary",
    flag: "Flag",
    select_timing: "Select time",
    payment_method: "Payment method",
    cash: "Cash",
    card: "Card",
    card_on_arrival: "Card on arrival",
    client: "Client",
    clients: "Clients",
    addresses: "Addresses",
    address: "Address",
    profile_pic: "Profile picture",
    birth_date: "Birth date",
    invoice: "Invoice",
    total: "Total",
    taxes: "Taxes",
    balance: "Balance",
    checkout: "Checkout",
    start_date: "Start date",
    end_date: "End date",
    additives: "Additives",
    additive: "Additive",
    paycuts: "Paycuts",
    paycut: "Paycut",
    value: "Value",
    back_to: "Back to",
    view: "View",
    details: "Details",
    subtotal: "Subtotal",
    order_for: "Order for",
    with: "With",
    print_invoice: "Print invoice",
    rejected: "Rejected",
    accepted: "Accepted",
    pending: "Pending",
    scheduled: "Scheduled",
    in_progress: "In progress",
    canceled: "Canceled",
    completed: "Completed",
    refunded: "Refunded",
    refund: "Refund",
    refund_order: "Refund order",
    status: "Status",
    street: "Street",
    building: "Building",
    floor: "Floor",
    appartment: "Appartment",
    location: "Location",
    locations: "Locations",
    city: "City",
    cities: "Cities",
    region: "Region",
    regions: "Regions",
    no_addresses: "No addresses for this client",
    apply: "Apply",
    points: "Points",
    wallet: "Wallet",
    mobile_wallet: "Mobile wallet",
    source: "Source",
    past: "Past",
    future: "Future",
    expenses_categories: "Expense categories",
    expense: "Expense",
    expenses: "Expenses",
    setting: "Setting",
    settings: "Settings",
    cancel_reasons: "Cancel reasons",
    support_reasons: "Support reasons",
    reason: "Reason",
    reasons: "Reasons",
    banner: "Banner",
    banners: "Banners",
    link: "Link",
    image: "Image",
    banner_image: "Banner image",
    coupon: "Coupon",
    coupons: "Coupons",
    percentage: "Percentage",
    fixed: "Fixed",
    usage_limit: "Usage limit",
    code: "Code",
    all: "All",
    active: "Active",
    blocked: "Blocked",
    block: "Block",
    unblock: "Unblock",
    referral: "Referral",
    referrals: "Referrals",
    used: "Used",
    app_settings: "App settings",
    main: "Main",
    private: "Private",
    data_type: "Values Type",
    numeric: "Numbers",
    numeric_arr: "Multiple Numbers",
    string: "Words/Characters",
    string_arr: "Multiple Words/Characters",
    boolean: "Yes/No",
    multiple: "Multiple",
    admin: "Admin",
    admins: "Admins",
    role: "Role",
    roles: "Roles",
    permission: "Permission",
    permissions: "Permissions",
    create: "Create",
    update: "Update",
    normal_admin: "Branch admin",
    super_admin: "Super admin",
    no_stocks_for_branch: "No stocks for the selected branch.",
    no_products: "No products available.",
    minimum: "Minimum",
    maximum: "Maximum",
    branch_products: "Branch products",
    bookings: "Bookings",
    milestone: "Milestone",
    milestones: "Milestones",
    no_milestones: "This client has no milestones yet.",
    no_redeem: "This client has no redeems yet.",
    daysoff: "Daysoff",
    dayoff: "Dayoff",
    redeem: "Redeem",
    working_days: "Working days",
    on_off: "Off/On",
    sun: "Sunday",
    mon: "Monday",
    tue: "Tuesday",
    wed: "Wednesday",
    thu: "Thursday",
    fri: "Friday",
    sat: "Saturday",
    from: "From",
    to: "To",
    support_tickets: "Support tickets",
    tickets: "Tickets",
    ticket: "Ticket",
    open: "Open",
    closed: "Closed",
    spam: "Spam",
    message: "Message",
    page: "Page",
    of: "Of",
    select_all: "Select all",
    select_option: "Select option",
    deselect_all: "Deselect all",
    total_paid: "Total paid",
    use_coupon: "Use coupon",
    use_wallet: "Use wallet",
    use_wallet_money: "Pay with wallet money",
    usages_per_coupon: "Usages per coupon",
    usages_per_customer: "Usages per customer",
    amount: "Amount",
    min_amount: "Minimum amount",
    min_amount_1: "Minimum amount is 1",
    paid_with: "Paid with",
    discount: "Discount",
    select_branch: "Select branch",
    refill_selected: "Refill selected to max",
    refill: "Refill",
    refill_note:
      "Refill to the maximum amount. Edit separately to refill to specific amount.",
    work_rates: "Work rates",
    rate: "Rate",
    shads: "Shads",
    waffarha: "Waffarha",
    lucky: "Lucky",
    special: "Special",
    membership: "Membership",
    basic: "Basic",
    silver: "Silver",
    gold: "Gold",
    plat: "Platinum",
    yes: "Yes",
    no: "No",
    commission_type: "Commission type",
    comment: "Comment",
    registration_date: "Registration date",
    applied_on_checkout_only: "Applied only on checkout",
    position: "Position",
    user_id: "User ID",
    order_id: "Order ID",
    min_payment_methods: "There must be at least one payment method",
    total_sales: "Total sales",
    employment_date: "Employment date",
    mobile: "Mobile",
    dashboard_source: "Dashboard",
    redeem_history: "Redeem history",
    return_to_wallet: "Return to wallet",
    duplicate: "Duplicate",
    duplicate_notification:
      "This a will be new duplicate notification with the content of the notification id of",
    refund_reason: "Refund reason",
    expected_salary: "Expected salary",
    actual_salary: "Actual salary",
    total_additives: "Total additives",
    total_paycuts: "Total paycuts",
    note: "Note",
    salaries: "Salaries",
    admin_notifications: "Admin Notifications",
    selected_count: "Selected Count",
    cash_sales: "Cash Sales",
    card_sales: "Card Sales",
    select_client: "Select a client",
    no_clients_search: "No clients for this search",
  },
  ar: {
    en: "بالانجليزية",
    ar: "بالعربية",
    sort_by: "رتب حسب",
    filters: "فلتر",
    save: "حفظ",
    clear: "الغاء",
    default: "الافتراضى",
    reservation_time: "وقت الحجز",
    search: "بحث",
    listing_no_results: "هذه الصفحة لا تحتوى على نتائج.",
    login: "تسجيل الدخول",
    logout: "تسجيل الخروج",
    my_profile: "الملف الشخصى",
    notification: "اشعار",
    notifications: "الاشعارات",
    dashboard: "الرئيسية",
    orders: "الطلبات",
    order: "طلب",
    list: "قائمة",
    id: "رقم",
    user: "عميل",
    service: "خدمة",
    services: "الخدمات",
    categories: "التصنيفات",
    category: "تصنيف",
    all_categories: "كل التصنيفات",
    type: "نوع",
    date: "تاريخ",
    time: "وقت",
    day: "يوم",
    days: "أيام",
    actions: "اجراءات",
    branches: "الفروع",
    branch: "فرع",
    all_branches: "كل الفروع",
    cancel: "اغلاق",
    new: "جديد",
    delete: "حذف",
    delete_selected: "حذف المختار",
    sure_to_refund: "هل أنت متأكد من استرجاع قيمة الطلب",
    sure_to_delete: "هل أنت متأكد من الحذف؟",
    sure_to_block: "هل أنت متأكد من حظر هذا المستخدم؟",
    sure_to_unblock: "هل أنت متأكد من حذف حظر هذا المستخدم؟",
    product: "منتج",
    products: "المنتجات",
    stocks: "المخزن",
    stock: "منتج",
    featured: "مميز",
    quantity: "كمية",
    left_over: "متبقى",
    usage: "مرات الاستخدام",
    available: "متاح",
    not_available: "غير متاح",
    multi_use: "متعدد الاستخدام",
    price: "سعر",
    search_all: "بحث كل",
    add_photo: "اضف صورة",
    add_pdf: "اضف ملف",
    add: "اضف",
    edit: "تعديل",
    basic_info: "بيانات اساسية",
    inventory: "جرد",
    product_image: "صورة المنتج",
    name: "اسم",
    description: "وصف",
    available_in_stock: "متاحة فى المخزن؟",
    used_multiple: "تستخدم اكتر من مرة؟",
    use_times: "مرات الاستخدام",
    usage_amount: "كمية الاستخدام فى المرة",
    warning: "تحذير",
    sure_cancel_changes:
      "هل أنت متأكد من الخروج؟ التغييرات التى قمت بها لن تُحفظ.",
    discard: "تخطى",
    submission_error: "خطأ اثناء التأكيد",
    submission_error_msg:
      "لقد حدث خطأ اثناء تأكيد البيانات. برجاء المحاولة مرة اخرى.",
    submission_successful: "تم التأكيد",
    submission_successful_msg: "تم تأكيد البيانات بنجاح.",
    required_field: "هذا الحقل مطلوب",
    estimated_time: "الوقت المقدر",
    commission: "عمولة",
    online: "اونلاين",
    category_no_services: "هذا التصنيف لا يحتوى على خدمات.",
    category_no_products: "هذا التصنيف لا يحتوى على منتجات.",
    home: "منزل",
    van: "متنقل",
    home_service: "خدمة منزلية؟",
    service_stocks: "منتجات الخدمة",
    select_branch_for_stocks: "اختر فرع لعرض منتجاته",
    no_stocks_at_branch: "لا يوجد منتجات فى الفرع",
    phone: "رقم الهاتف",
    password: "كلمة المرور",
    sku: "SKU",
    barcode: "باركود",
    brand: "ماركة",
    brands: "الماركات",
    indoor: "داخلى",
    outdoor: "خارجى",
    start_time: "وقت البدأ",
    end_time: "وقت الانهاء",
    not_yet: "لم يحن",
    service_duration: "مدة الخدمة",
    worker: "موظف",
    workers: "الموظفين",
    age: "السن",
    gender: "جنس",
    male: "ذكر",
    female: "انثى",
    email: "بريد الكترونى",
    job_title: "مسمى وظيفى",
    monthly_salary: "المرتب الشهرى",
    flag: "وسام",
    select_timing: "اختر وقت",
    payment_method: "طريقة الدفع",
    cash: "نقدى",
    card: "فيزا",
    card_on_arrival: "فيزا عند الوصول",
    client: "عميل",
    clients: "العملاء",
    addresses: "العنواين",
    address: "عنوان",
    profile_pic: "الصورة الشخصية",
    birth_date: "تاريخ الميلاد",
    invoice: "فاتورة",
    total: "مجموع",
    taxes: "ضريبة",
    balance: "النهائى",
    checkout: "دفع",
    start_date: "تاريخ البداية",
    end_date: "تاريخ الانتهاء",
    additives: "الاضافات",
    additive: "اضافة",
    paycuts: "الخصومات",
    paycut: "خصم",
    value: "قيمة",
    back_to: "الرجوع إلى",
    view: "عرض",
    details: "تفاصيل",
    subtotal: "المجموع النهائى",
    order_for: "طلب خاص بـ",
    with: "مع",
    print_invoice: "طباعة الفاتورة",
    rejected: "مرفوض",
    accepted: "مقبول",
    pending: "متعلق",
    scheduled: "تم التحديد",
    in_progress: "جارى العمل",
    canceled: "ملغى",
    completed: "اكتمل",
    refunded: "تم استرجاعه",
    refund: "استرجاع",
    refund_order: "استرجاع الطلب",
    status: "الحالة",
    street: "شارع",
    building: "مبنى",
    floor: "دور",
    appartment: "شقة",
    location: "موقع",
    locations: "الأماكن",
    city: "مدينة",
    cities: "المدن",
    region: "منطقة",
    regions: "المناطق",
    no_addresses: "لا يوجد عنواين لهذا العميل",
    apply: "تأكيد",
    points: "النقاط",
    wallet: "المحفظة",
    mobile_wallet: "المحفظة الالكترونية",
    source: "المصدر",
    past: "ماضى",
    future: "قادم",
    expenses_categories: "تصنيفات المصروفات",
    expense: "مصروف",
    expenses: "المصروفات",
    setting: "اعداد",
    settings: "الاعدادات",
    cancel_reasons: "اسباب الالغاء",
    support_reasons: "اسباب الدعم",
    reason: "سبب",
    reasons: "الاسباب",
    banner: "اعلان",
    banners: "الاعلانات",
    link: "رابط",
    image: "صورة",
    coupon: "كوبون",
    coupons: "الكوبونات",
    percentage: "نسبة",
    fixed: "ثابت",
    usage_limit: "حد الاستخدام",
    code: "كود",
    all: "كل",
    active: "نشط",
    blocked: "محظور",
    block: "حظر",
    unblock: "حذف الحظر",
    referral: "كود احالة",
    referrals: "اكواد الاحالة",
    used: "تم استعماله",
    app_settings: "اعدادات النظام",
    main: "رئيسى",
    private: "خاص",
    data_type: "Values Type",
    numeric: "أرقام",
    numeric_arr: "أرقام متعددة",
    string: "كلمات/حروف",
    string_arr: "كلمات/حروف متعددة",
    boolean: "نعم/لا",
    multiple: "متعدد",
    admin: "مدير",
    admins: "المدراء",
    role: "دور",
    roles: "الأدوار",
    permission: "تصريح",
    permissions: "التصريحات",
    create: "انشاء",
    update: "تعديل",
    normal_admin: "مدير فرع",
    super_admin: "مدير عام",
    no_stocks_for_branch: "لا يوجد منتجات مخزن لهذا الفرع.",
    no_products: "لا يوجد منتجات متاحة.",
    minimum: "الحد الأدنى",
    maximum: "الحد الأعلى",
    branch_products: "منتجات الفرع",
    bookings: "الحجوزات",
    milestone: "انجاز",
    milestones: "الانجازات",
    no_milestones: "هذا العميل ليس لديه انجازات حتى الآن.",
    no_redeem: "هذا العميل ليس لديه استردادات حتى الآن.",
    daysoff: "الاجازات",
    dayoff: "اجازة",
    redeem: "استرداد",
    working_days: "ايام العمل",
    on_off: "اجازة/يعمل",
    sun: "الأحد",
    mon: "الأثنين",
    tue: "الثلاثاء",
    wed: "الأربعاء",
    thu: "الخميس",
    fri: "الجمعة",
    sat: "السبت",
    from: "من",
    to: "إلى",
    support_tickets: "تذاكر الدعم",
    tickets: "التذاكر",
    ticket: "تذكرة",
    open: "مفتوحة",
    closed: "مغلقة",
    spam: "غير صحيحة",
    message: "رسالة",
    page: "الصفحة",
    of: "من",
    select_all: "اختر الكل",
    select_option: "اختر",
    deselect_all: "الغاء الكل",
    total_paid: "المدفوع",
    use_coupon: "استخدام كوبون",
    use_wallet: "استخدام المحفظة",
    use_wallet_money: "ادفع عن طريق المحفظة",
    usages_per_coupon: "مرات الاستخدام للكوبون",
    usages_per_customer: "مرات الاستخدام للعميل الواحد",
    amount: "كمية",
    min_amount: "الحد الأدنى للعدد",
    min_amount_1: "الحد الأدنى للعدد 1",
    paid_with: "تم الدفع بواسطة",
    discount: "خصم",
    select_branch: "اخر فرع",
    refill_selected: "تعبئة المختار للحد الأعلى",
    refill: "تعبئة",
    refill_note:
      "اعادة تعبئة للحد الأعلى للكمية. قم بتعديل منفصل لكل منتج لتعبئة كمية محددة.",
    work_rates: "معدل العمل",
    rate: "معدل",
    shads: "شادز",
    waffarha: "وفرها",
    lucky: "لاكى",
    special: "خاص",
    membership: "عضوية",
    basic: "عادى",
    silver: "فضية",
    gold: "ذهبية",
    plat: "بلاتينية",
    yes: "نعم",
    no: "لا",
    commission_type: "نوع العمولة",
    comment: "تعليق",
    registration_date: "تاريخ التسجيل",
    applied_on_checkout_only: "تطبق عند الدفع فقط",
    position: "المكان",
    user_id: "رقم العميل",
    order_id: "رقم طلب",
    min_payment_methods: "يجب وجود وسيلة دفع واحدة على الأقل",
    total_sales: "مجموع المبيعات",
    employment_date: "تاريخ التعيين",
    mobile: "الموبايل",
    dashboard_source: "الداشبورد",
    redeem_history: "تاريخ الاستردادات",
    return_to_wallet: "استرجاع قيمة المحفظة",
    duplicate: "تكرار",
    duplicate_notification: "هذا الاشعار سيكون تكرار لمحتويات الاشعار رقم",
    refund_reason: "سبب الاسترداد",
    expected_salary: "المرتب المتوقع",
    actual_salary: "المرتب الحالى",
    total_additives: "اجمالى الاضافات",
    total_paycuts: "اجمالى الخصومات",
    note: "ملاحظة",
    salaries: "المرتبات",
    admin_notifications: "اشعارات الادارة",
    selected_count: "العدد المختار",
    cash_sales: "مبيعات نقدية",
    card_sales: "مبيبعات البطاقات",
    select_client: "اضف عميل",
    no_clients_search: "لا يوجد عملاء لهذا البحث",
  },
};

export default translation;
