import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
import ClientSidebar from "../../components/clients/ClientSidebar";
import { ClientSidebarLinks } from "../../components/clients/ClientSidebar/styles";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import {
  SplittedPage,
  SplittedPageSidebar,
} from "../../components/updatingPages/components";
import MainContent from "../../containers/MainContent";
import { selectSettings, selectUser } from "../../store/selectors";
import { getSingleModuleData } from "../../utils/fetchers";
import { Arrow } from "../../utils/icons";
import translation from "../../utils/translation";

const ViewClient = () => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { branch_id: userBranchID },
  } = useSelector(selectUser);
  const { clientID } = useParams();

  const navigate = useNavigate();

  const [clientDetails, setClientDetails] = useState<{
    isFetching: boolean;
    data: any;
  }>({ isFetching: true, data: {} });

  useEffect(() => {
    const fetchController = new AbortController();
    const fetchControllerSignal = fetchController.signal;
    getSingleModuleData(
      "admin",
      "users",
      +(clientID as string),
      "",
      fetchControllerSignal
    ).then((res) => {
      setClientDetails({ isFetching: false, data: res.data.data });
    });
  }, [clientID, userBranchID]);

  return (
    <MainContent onlyInPage={true}>
      <PageTitle
        extraStyle={{
          margin: "0 -40px",
          padding: "15px 40px",
          borderBottom: "1px solid #dee3e7",
        }}
        firstSection={
          <div className="page_header_title_update">
            <Button
              buttonType="button"
              size="md"
              styleType="icon"
              onClick={() => navigate(-1)}
              icon={
                <Arrow
                  height="27"
                  width="27"
                  color="#101928"
                  extraStyle={{
                    transform: `rotate(${siteLang === "ar" ? "180" : "0"}deg)`,
                  }}
                />
              }
              disabled={clientDetails.isFetching}
            />
            <h1>
              {[
                translation[siteLang][siteLang === "ar" ? "client" : "view"],
                translation[siteLang][
                  siteLang === "ar" ? "view" : "client"
                ].toLowerCase(),
              ].join(" ")}
            </h1>
          </div>
        }
      />
      {clientDetails.isFetching ? (
        <div style={{ width: "fit-content", margin: "100px auto 0" }}>
          <LoadingSpinner type="circle" />
        </div>
      ) : (
        <>
          <SplittedPage style={{ width: "400px", padding: 0 }}>
            <ClientSidebar
              clientID={clientID as string}
              details={{ ...clientDetails.data }}
            />
            <ClientSidebarLinks>
              <NavLink to={`/clients/view/${clientID}/details`}>
                {translation[siteLang].details}
              </NavLink>
              <NavLink to={`/clients/view/${clientID}/bookings`}>
                {translation[siteLang].bookings}
              </NavLink>
              <NavLink to={`/clients/view/${clientID}/milestones`}>
                {translation[siteLang].milestones}
              </NavLink>
              <NavLink to={`/clients/view/${clientID}/redeems`}>
                {translation[siteLang].redeem_history}
              </NavLink>
            </ClientSidebarLinks>
          </SplittedPage>
          <SplittedPageSidebar
            style={{
              padding: "40px 40px 80px",
              height: "calc(100% - 73px)",
              width: "calc(100% - 400px)",
              backgroundColor: "#f8f8fb",
            }}
          >
            <Outlet />
          </SplittedPageSidebar>
        </>
      )}
    </MainContent>
  );
};

export default ViewClient;
