import styled from "styled-components";

const Wrapper = styled.section<{
  isMainSidebarCollapsed: boolean;
  collapsed: boolean;
}>`
  width: var(
    --${(props) =>
      props.collapsed ? "contentSidebarCollapsedWidth" : "contentSidebarWidth"}
  );
  position: fixed;
  z-index: 1;
  top: var(--headerHeight);
  transition: ${(props) =>
    props.theme.lang === "ar" ? "right" : "left"} 0.1s ease-in-out;
  border-${(props) =>
    props.theme.lang === "ar" ? "left" : "right"}: 1px solid var(--lightGrey3);
  ${(props) =>
    `${props.theme.lang === "ar" ? "right" : "left"}: var(--${
      props.isMainSidebarCollapsed
        ? "mainSidebarCollapsedWidth"
        : "mainSidebarWidth"
    })`};
  height: calc(100% - var(--headerHeight));
  padding: 20px 15px;
  .collapse {
    position: absolute;
    top: 20px;
    ${(props) => (props.theme.lang === "ar" ? "right" : "left")}: ${(props) =>
  props.collapsed ? "17px" : "calc(100% - 17px)"};
    svg {
      transition: transform 0.1s ease-in-out;
      transform: ${(props) =>
        props.theme.lang === "ar"
          ? props.collapsed
            ? "rotate(180deg) translateX(1px)"
            : "rotate(0) translateX(1px)"
          : props.collapsed
          ? "rotate(0) translateX(1px)"
          : "rotate(180deg) translateX(1px)"};
    }
  }
  .content {
    display: ${(props) => (props.collapsed ? "none" : "block")};
    h3 {
      margin: 0 0 7px;
      font-size: 18px;
      font-weight: 700;
      color: var(--black);
      padding: 0 12px;
    }
    a {
      border-radius: 8px;
      color: var(--darkBlue);
      display: block;
      font-size: 16px;
      font-weight: 600;
      padding: 10px 12px;
      text-decoration: none;
      transition: background-color .1s ease-in-out;
      &:not(:last-child) {
        margin-bottom: 5px;
      }
      &:hover {
        background-color: var(--lightGrey);
      }
      &.active {
        background-color: var(--lightBlue);
      }
    }
  }
  .content_section:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export default Wrapper;
