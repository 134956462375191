import ReactCalendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useSelector } from "react-redux";
import { selectSettings } from "../../../store/selectors";
import { Chevron } from "../../../utils/icons";
import Wrapper from "./styles";
import { CalendarGeneralType } from "./utils";

const Calendar = ({
  onChange,
  defaultValue,
  value,
  selectRange = false,
  ...props
}: CalendarGeneralType & {
  defaultValue: Date | Date[];
  value?: Date | Date[];
}) => {
  const { siteLang } = useSelector(selectSettings);
  return (
    <Wrapper>
      <ReactCalendar
        locale={siteLang === "ar" ? "ar-EG" : "en-US"}
        calendarType="Arabic"
        minDetail="month"
        maxDetail="month"
        selectRange={selectRange}
        nextLabel={
          <Chevron
            width="15"
            height="15"
            color="#101928"
            extraStyle={{
              transform: siteLang === "ar" ? "rotate(180deg)" : "rotate(0deg)",
              strokeWidth: 3,
            }}
          />
        }
        prevLabel={
          <Chevron
            width="15"
            height="15"
            color="#101928"
            extraStyle={{
              transform: siteLang === "ar" ? "rotate(0deg)" : "rotate(180deg)",
              strokeWidth: 3,
            }}
          />
        }
        defaultValue={defaultValue}
        {...(value ? { value: value } : {})}
        onChange={onChange}
        {...props}
      />
    </Wrapper>
  );
};

export default Calendar;
