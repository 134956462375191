import { Link } from "react-router-dom";
import styled from "styled-components";

export const ClientOrderDate = styled.div`
  border: 1px solid var(--lightGrey3);
  padding: 5px;
  min-width: 64px;
  border-radius: 15px;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  span:first-child {
    font-weight: 800;
    color: var(--darkBlue);
    font-size: 18px;
  }
  span:nth-child(2) {
    color: var(--lightGrey6);
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
  }
  span:last-child {
    color: var(--lightGrey5);
    font-weight: 400;
    font-size: 12px;
  }
`;

export const ClientOrder = styled(Link)`
  display: flex;
  align-items: flex-start;
  margin: -10px;
  border-radius: 10px;
  padding: 10px;
  &:hover {
    text-decoration: none;
    background-color: var(--lightGrey);
  }
  .order_body {
    flex: 1;
    margin-${(props) => (props.theme.lang === "ar" ? "right" : "left")}: 20px;
  }
  .title {
    font-weight: 800;
    color: var(--darkBlue);
    font-size: 18px;
    margin: 0;
  }
  .time {
    margin: 0;
    color: var(--lightGrey6);
  }
  .price {
    margin: 10px 0 0;
    color: var(--darkBlue);
    font-weight: 800;
  }
  .status {
    margin: 0;
    color: var(--blue);
  }
`;
