import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import InputCheckbox from "../../components/ui/Inputs/InputCheckbox";
import Table from "../../components/ui/Table";
import MainContent from "../../containers/MainContent";
import ViewListingPage from "../../containers/ViewListingPage";
import useListingData from "../../hooks/useListingData";
import { selectSettings, selectUser } from "../../store/selectors";
import { Delete } from "../../utils/icons";
import translation from "../../utils/translation";
import MainHeader from "../../containers/MainHeader";
import MainSidebar from "../../containers/MainSidebar";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/formatter";

const ListWorkers = () => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { branch_id: userBranchID, permissions: userPermissions },
  } = useSelector(selectUser);

  const navigate = useNavigate();

  const {
    data,
    extraFetchedData,
    onRequestDeleteHandler,
    onSelectTableRowHandler,
    isAllRowsSelected,
    showMultipleDelete,
    ...rest
  } = useListingData({
    moduleName: "workers",
    moduleType: userBranchID ? "branch" : "admin",
    queries: userBranchID ? `where=branch_id=${userBranchID}` : "with=branch",
    number: "all",
  });

  return (
    <>
      <MainHeader />
      <MainSidebar />
      <MainContent>
        <PageTitle
          firstSection={
            <h1>
              {[
                translation[siteLang][siteLang === "ar" ? "list" : "workers"],
                " ",
                translation[siteLang][
                  siteLang === "ar" ? "workers" : "list"
                ].toLowerCase(),
              ]}
            </h1>
          }
          {...(userPermissions?.workers?.create
            ? {
                secondSection: (
                  <Button
                    styleType="primary"
                    size="lg"
                    label={[
                      translation[siteLang][
                        siteLang === "ar" ? "worker" : "new"
                      ],
                      translation[siteLang][
                        siteLang === "ar" ? "new" : "worker"
                      ].toLowerCase(),
                    ].join(" ")}
                    buttonType="link"
                    url="/workers/new"
                  />
                ),
              }
            : {})}
        />
        <ViewListingPage
          {...rest}
          hasNoResults={data.length === 0}
          tableFilter={{
            showMultipleDelete,
            onRequestDeleteHandler,
            hasSearch: true,
            search: {
              ...rest.search,
              placeholder:
                translation[siteLang].search_all +
                " " +
                translation[siteLang].workers.toLowerCase(),
            },
          }}
        >
          <Table
            isHeaderFixed={true}
            headings={[
              ...(data.length > 0 && userPermissions?.workers?.delete
                ? [
                    {
                      label: (
                        <InputCheckbox
                          checkboxStyle="checkbox"
                          props={{
                            onChange: () => onSelectTableRowHandler(0, true),
                            checked: isAllRowsSelected,
                            style: {
                              marginTop: "7px",
                              width: "15px",
                              height: "15px",
                            },
                          }}
                        />
                      ),
                      style: {
                        width: "50px",
                      },
                    },
                  ]
                : []),
              {
                label: translation[siteLang].id,
                style: {
                  width: "70px",
                },
              },
              {
                label: translation[siteLang].name,
              },
              {
                label: translation[siteLang].phone,
              },
              {
                label: translation[siteLang].job_title,
              },
              {
                label: translation[siteLang].employment_date,
              },
              ...(userBranchID
                ? []
                : [
                    {
                      label: translation[siteLang].branch,
                    },
                  ]),
              {
                label: translation[siteLang].actions,
                style: {
                  width: "620px",
                },
              },
            ]}
            rows={data.map((el) => ({
              link: userPermissions?.workers?.update
                ? `/workers/edit/${el.id}`
                : "",
              columns: [
                ...(userPermissions?.workers?.delete
                  ? [
                      {
                        link_not_applied: true,
                        value: (
                          <InputCheckbox
                            checkboxStyle="checkbox"
                            props={{
                              onChange: () => onSelectTableRowHandler(el.id),
                              checked: el.table_selected,
                              style: {
                                marginTop: "7px",
                                width: "15px",
                                height: "15px",
                              },
                            }}
                          />
                        ),
                      },
                    ]
                  : []),
                {
                  value: el.id,
                },
                {
                  value: el.name,
                },
                // {
                //   value:
                //     el.email,
                // },
                {
                  value: el.phone,
                },
                {
                  value: el.job_title,
                },
                {
                  value: formatDate(el.created_at, siteLang),
                },
                ...(userBranchID
                  ? []
                  : [
                      {
                        value: el.branch.name,
                      },
                    ]),
                {
                  link_not_applied: true,
                  value: (
                    <div className="page_listing_table_actions">
                      <Button
                        label={translation[siteLang].salaries}
                        buttonType="button"
                        styleType="secondary"
                        onClick={() =>
                          navigate(`/workers/salaries/${el.name}/${el.id}`)
                        }
                        size="md"
                        extraStyle={{
                          padding: "0 10px",
                          minWidth: "auto",
                        }}
                      />
                      <Button
                        label={translation[siteLang].work_rates}
                        buttonType="button"
                        styleType="secondary"
                        onClick={() =>
                          navigate(`/workers/work-rates/${el.name}/${el.id}`)
                        }
                        size="md"
                        extraStyle={{
                          padding: "0 10px",
                          minWidth: "auto",
                        }}
                      />
                      <Button
                        label={translation[siteLang].orders}
                        buttonType="button"
                        styleType="secondary"
                        onClick={() =>
                          navigate(`/workers/orders/${el.name}/${el.id}`)
                        }
                        size="md"
                        extraStyle={{
                          padding: "0 10px",
                          minWidth: "auto",
                        }}
                      />
                      {userPermissions?.day_offs?.view && (
                        <Button
                          label={translation[siteLang].daysoff}
                          buttonType="button"
                          styleType="secondary"
                          onClick={() =>
                            navigate(`/workers/daysoff/${el.name}/${el.id}`)
                          }
                          size="md"
                          extraStyle={{
                            padding: "0 10px",
                            minWidth: "auto",
                          }}
                        />
                      )}
                      {userPermissions?.additives?.view && (
                        <Button
                          label={translation[siteLang].additives}
                          buttonType="button"
                          styleType="secondary"
                          onClick={() =>
                            navigate(`/workers/additives/${el.name}/${el.id}`)
                          }
                          size="md"
                          extraStyle={{
                            padding: "0 10px",
                            minWidth: "auto",
                          }}
                        />
                      )}
                      {userPermissions?.paycuts?.view && (
                        <Button
                          label={translation[siteLang].paycuts}
                          buttonType="button"
                          styleType="secondary"
                          onClick={() =>
                            navigate(`/workers/paycuts/${el.name}/${el.id}`)
                          }
                          size="md"
                          extraStyle={{
                            padding: "0 10px",
                            minWidth: "auto",
                          }}
                        />
                      )}
                      {userPermissions?.workers?.delete && (
                        <Button
                          icon={
                            <Delete width="15" height="15" color="#101928" />
                          }
                          buttonType="button"
                          styleType="secondary"
                          onClick={() => onRequestDeleteHandler([el.id])}
                          size="md"
                          className="delete"
                          extraStyle={{ height: "40px", width: "40px" }}
                        />
                      )}
                    </div>
                  ),
                },
              ],
            }))}
          />
        </ViewListingPage>
      </MainContent>
    </>
  );
};

export default ListWorkers;
