import styled from "styled-components";

const Wrapper = styled.div<{ isSwitch: boolean }>`
  label {
    width: fit-content;
    line-height: 22px;
    display: inline-flex;
    align-items: center;
    color: var(--darkBlue);
    margin: 0;
    cursor: pointer;
    input {
      margin: ${(props) => (props.isSwitch ? "3px 0 0" : "0")};
      width: ${(props) => (props.isSwitch ? "50px" : "22px")};
      height: ${(props) => (props.isSwitch ? "26px" : "22px")};
      cursor: pointer;
      ${(props) =>
        props.isSwitch
          ? `
      opacity: 0;
      &:checked + span {
        background-color: var(--green);
        ::before {
          transform: translateX(${props.theme.lang === "ar" ? "" : "-"}100%);
        ${props.theme.lang === "ar" ? "right" : "left"}: calc(100% - 3px);
        }
      }
      `
          : ""}
    }
    > span {
      margin-${(props) => (props.theme.lang === "ar" ? "right" : "left")}: 10px;
      flex: 1;
    }
    .checkbox_switcher {
      width: 50px;
      position: relative;
      top: 1px;
      span {
        position: absolute;
        width: 100%;
        height: 26px;
        background-color: var(--lightGrey4);
        transition: .15s background-color ease-in-out;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 25px;
        ::before {
          content: "";
          position: absolute;
          transition: .15s ${(props) =>
            props.theme.lang === "ar"
              ? "right"
              : "left"} ease-in-out, .15s transform ease-in-out;
          ${(props) => (props.theme.lang === "ar" ? "right" : "left")}: 3px;
          width: 20px;
          height: 20px;
          background: var(--white);
          border-radius: 50%;
          top: 3px;
        }
      }
    }
  }
`;

export default Wrapper;
