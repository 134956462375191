import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { selectSettings } from "../../../store/selectors";
import { Check, Delete, Edit } from "../../../utils/icons";
import appAxios from "../../../utils/setupAxios";
import translation from "../../../utils/translation";
import Button from "../../ui/Button";
import Modal from "../../ui/Modal";
import ShadsyFlag from "../../../utils/Shad'sy Flag.png";
import { Wrapper } from "./styles";
import { useForm } from "react-hook-form";
import InputField from "../../ui/Inputs/InputField";
import { validateInputField } from "../../../containers/UpdateModulePage/utils";

const ClientSidebar = ({
  details,
  clientID,
}: {
  details: any;
  clientID: string;
}) => {
  const { siteLang } = useSelector(selectSettings);

  const [requestBlock, setRequestBlock] = useState<boolean>(false);
  const [requestUpdate, setRequestUpdate] = useState<
    "wallet" | "points" | "redeem" | ""
  >("");
  const [submitStatus, setSubmitStatus] = useState<{
    isSubmitting: boolean;
    successful: boolean;
    error: string;
  }>({ isSubmitting: false, successful: false, error: "" });

  const { register, formState, handleSubmit, reset } = useForm({
    defaultValues: {
      redeemedPoints: "",
      addedPoints: details.points,
      wallet: details.wallet,
    },
  });

  const onBlockClientHandler = useCallback(async () => {
    setSubmitStatus({ isSubmitting: true, successful: false, error: "" });
    let successful = false,
      error = "";
    await appAxios
      .post(`user/admin/update/${clientID}`, {
        status: details.status === "blocked" ? "active" : "blocked",
      })
      .then(() => {
        successful = true;
      })
      .catch(() => {
        error = translation[siteLang].submission_error_msg;
      });
    setSubmitStatus({ isSubmitting: false, successful, error });
    if (successful) {
      window.location.reload();
    }
  }, [siteLang, clientID, details]);

  const onUpdatePoints = useCallback(
    async (data: any, type: "wallet" | "points" | "redeem") => {
      setSubmitStatus({ isSubmitting: true, successful: false, error: "" });
      let successful = false,
        error = "";
      await appAxios
        .post(
          `${
            type === "redeem" ? "redeem/user-points" : "user/admin/update"
          }/${clientID}`,
          {
            ...(type === "redeem"
              ? { points: data.redeemedPoints }
              : { [type]: type === "points" ? data.addedPoints : data.wallet }),
          }
        )
        .then(() => {
          successful = true;
        })
        .catch((err) => {
          error =
            err?.response?.data?.message ||
            translation[siteLang].submission_error_msg;
        });
      setSubmitStatus({ isSubmitting: false, successful, error });
      if (successful) {
        window.location.reload();
      }
    },
    [siteLang, clientID]
  );

  const onCloseRedeemModal = useCallback(() => {
    reset();
    setRequestUpdate("");
  }, [reset]);

  return (
    <>
      {requestBlock && (
        <Modal
          maxWidth="400px"
          showFooter={true}
          showCloseIcon={true}
          onCloseHandler={() => setRequestBlock(false)}
          footerButtonsDirection="center"
          footerButtons={[
            {
              label: translation[siteLang].cancel,
              styleType: "secondary",
              size: "lg",
              buttonType: "button",
              onClick: () => setRequestBlock(false),
              disabled: submitStatus.isSubmitting,
            },
            {
              label:
                translation[siteLang][
                  details.status === "blocked" ? "unblock" : "block"
                ],
              styleType: details.status !== "blocked" ? "danger" : "primary",
              size: "lg",
              buttonType: "button",
              onClick: onBlockClientHandler,
              disabled: submitStatus.isSubmitting,
              isLoading: submitStatus.isSubmitting,
            },
          ]}
        >
          <p
            style={{
              margin: "20px 0",
              textAlign: "center",
              fontSize: "18px",
              fontWeight: 700,
            }}
          >
            {
              translation[siteLang][
                details.status === "blocked"
                  ? "sure_to_unblock"
                  : "sure_to_block"
              ]
            }
          </p>
        </Modal>
      )}
      {requestUpdate && (
        <Modal
          as="form"
          onSubmit={handleSubmit((data) => onUpdatePoints(data, requestUpdate))}
          maxWidth="400px"
          showHeader={true}
          headerTitle={`${
            translation[siteLang][
              requestUpdate === "redeem" ? "redeem" : "update"
            ]
          } ${
            translation[siteLang][
              requestUpdate !== "wallet" ? "points" : "wallet"
            ]
          }`}
          showFooter={true}
          showCloseIcon={true}
          onCloseHandler={onCloseRedeemModal}
          footerButtonsDirection="center"
          footerButtons={[
            {
              label: translation[siteLang].cancel,
              styleType: "secondary",
              size: "lg",
              buttonType: "button",
              onClick: onCloseRedeemModal,
              disabled: submitStatus.isSubmitting,
            },
            {
              label:
                translation[siteLang][
                  requestUpdate === "redeem" ? "redeem" : "update"
                ],
              styleType: "primary",
              size: "lg",
              buttonType: "submit",
              disabled: submitStatus.isSubmitting,
              isLoading: submitStatus.isSubmitting,
            },
          ]}
        >
          {submitStatus.error && (
            <div className="modal_section">
              <p style={{ color: "#f23636" }}>{submitStatus.error}</p>
            </div>
          )}
          <div className="modal_section">
            <InputField
              label={translation[siteLang].points}
              type="number"
              props={register(
                requestUpdate === "redeem"
                  ? "redeemedPoints"
                  : requestUpdate === "points"
                  ? "addedPoints"
                  : "wallet",
                validateInputField(siteLang, true)
              )}
              error={
                formState.errors?.[
                  requestUpdate === "redeem"
                    ? "redeemedPoints"
                    : requestUpdate === "points"
                    ? "addedPoints"
                    : "wallet"
                ]?.message
              }
            />
          </div>
        </Modal>
      )}
      <Wrapper>
        <div className="client_header">
          <div className="client_image">
            {details.shads ? (
              <span className="client_image__flag">
                <img src={ShadsyFlag} alt="" />
              </span>
            ) : null}
            {details.profile_picture ? (
              <img alt="" src={details.profile_picture} />
            ) : (
              details.name.substring(0, 1).toUpperCase()
            )}
          </div>
          <h2>{details.name}</h2>
          <span>{details.email}</span>
        </div>
        <div className="client_actions">
          <div>
            <Button
              styleType="secondary"
              buttonType="link"
              url={`/clients/edit/${clientID}`}
              size="lg"
              icon={<Edit width="15" height="15" color="#000" />}
            />
            <p>{translation[siteLang].edit}</p>
          </div>
          <div>
            <Button
              styleType="secondary"
              buttonType="button"
              onClick={() => setRequestUpdate("redeem")}
              size="lg"
              icon={<Edit width="15" height="15" color="#000" />}
            />
            <p>
              {translation[siteLang].redeem}
              <br />
              {translation[siteLang].points}
            </p>
          </div>
          <div>
            <Button
              styleType="secondary"
              buttonType="button"
              onClick={() => setRequestUpdate("wallet")}
              size="lg"
              icon={<Edit width="15" height="15" color="#000" />}
            />
            <p>
              {translation[siteLang].update}
              <br />
              {translation[siteLang].wallet}
            </p>
          </div>
          <div>
            <Button
              styleType="secondary"
              buttonType="button"
              onClick={() => setRequestUpdate("points")}
              size="lg"
              icon={<Edit width="15" height="15" color="#000" />}
            />
            <p>
              {translation[siteLang].update}
              <br />
              {translation[siteLang].points}
            </p>
          </div>
          <div>
            <Button
              styleType="secondary"
              buttonType="button"
              onClick={() => {
                setRequestBlock(true);
              }}
              size="lg"
              icon={
                details.status === "blocked" ? (
                  <Check width="15" height="15" color="#000" />
                ) : (
                  <Delete width="15" height="15" color="#000" />
                )
              }
            />
            <p>
              {
                translation[siteLang][
                  details.status === "blocked" ? "unblock" : "block"
                ]
              }
            </p>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default ClientSidebar;
