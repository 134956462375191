import { InputGeneralType } from "../utils";
import InputContainer from "../InputContainer";
import Wrapper from "./styles";
import { Close, Search } from "../../../../utils/icons";
import { MouseEventHandler, useState } from "react";

const InputSearch = ({
  label,
  props,
  onClearSearch,
}: InputGeneralType & {
  onClearSearch: MouseEventHandler<HTMLSpanElement>;
}) => {
  const [inputOnFocus, setInputOnFocus] = useState<boolean>(false);

  return (
    <InputContainer label={label}>
      <Wrapper hasIcon={true} iconPosition="start" inputOnFocus={inputOnFocus}>
        <span>
          <Search width="17" height="17" color="#101928" />
        </span>
        <input
          type="text"
          {...props}
          onFocus={() => setInputOnFocus(true)}
          onBlur={() => setInputOnFocus(false)}
        />
        {props?.value && (props.value as string).trim() && (
          <span className="search_delete" onClick={onClearSearch}>
            <Close width="17" height="17" color="#878c93" />
          </span>
        )}
      </Wrapper>
    </InputContainer>
  );
};

export default InputSearch;
