import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import InputCheckbox from "../../components/ui/Inputs/InputCheckbox";
import InputField from "../../components/ui/Inputs/InputField";
import {
  BoxWrapper,
  SectionsWrapper,
} from "../../components/updatingPages/components";
import MainContent from "../../containers/MainContent";
import useUpdatingData from "../../hooks/useUpdatingData";
import { selectSettings, selectUser } from "../../store/selectors";
import { Arrow } from "../../utils/icons";
import translation from "../../utils/translation";
import { useMemo } from "react";
import UpdateModulePage from "../../containers/UpdateModulePage";
import { validateInputField } from "../../containers/UpdateModulePage/utils";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { useParams } from "react-router-dom";
import { getAllBrands, uploadProductImages } from "../../utils/fetchers";
import InputSelect from "../../components/ui/Inputs/InputSelect";
import InputFile from "../../components/ui/Inputs/InputFile";

const moduleBaseName = "/products";

const UpdateProducts = ({ type }: { type: "create" | "edit" }) => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { branch_id: userBranchID },
  } = useSelector(selectUser);
  const { categoryID, productID } = useParams();

  const defaultValues = useMemo(
    () => ({
      ...(type === "create" ? { category_id: categoryID } : {}),
      name_en: "",
      name_ar: "",
      info_en: "",
      info_ar: "",
      price: "",
      featured: false,
      sku: "",
      barcode: "",
      brand_id: "no_option",
      quantity: 10,
      image: [],
    }),
    [categoryID, type]
  );

  const extraFetchers = useMemo(
    () => [
      {
        function: getAllBrands,
        returnName: "brands",
      },
    ],
    []
  );

  const resetModuleDefaults = useMemo(
    () => (clonedDefaults: any, extraFetchedData: any) => ({
      brand_id: clonedDefaults?.brand_id?.toString() || "no_option",
      image: [],
    }),
    []
  );

  const extraSubmissions = useMemo(
    () => [
      {
        function: (id: number, refinedData: any) => {
          return uploadProductImages(id, [refinedData.image]);
        },
        responseOfID: "product",
      },
    ],
    []
  );

  const {
    isFetching,
    extraFetchedData,
    register,
    watchedInputs,
    setValue,
    moduleDefaultData,
    ...updatingModule
  } = useUpdatingData({
    moduleType: userBranchID ? "branch" : "admin",
    moduleState: type,
    moduleID: +(productID || 0),
    moduleName: "product",
    modulePostEndpoint:
      type === "edit" ? `product/update/${productID}` : "product/create",
    modulePostType: "object",
    modulePatch: type === "edit" ? true : false,
    watchInputFields: true,
    defaultValues,
    navigateAfterSubmissionTo: moduleBaseName,
    extraFetchers,
    ...(type === "edit" ? { resetModuleDefaults } : {}),
    extraSubmissions,
  });

  const formState = updatingModule.formState;

  const updatedDataToSubmit = useMemo(() => {
    return (data: any) => ({
      image: data.image?.[0]?.file || "",
    });
  }, []);

  return (
    <MainContent onlyInPage={true} hasFixedWidthHeader={true}>
      <UpdateModulePage
        moduleBaseName={moduleBaseName}
        updatedDataToSubmit={updatedDataToSubmit}
        {...updatingModule}
      >
        <PageTitle
          firstSection={
            <div className="page_header_title_update">
              <Button
                buttonType="button"
                size="md"
                styleType="icon"
                onClick={() => updatingModule.onNavigateBack(moduleBaseName)}
                disabled={updatingModule.submissionState.isSubmitting}
                icon={
                  <Arrow
                    height="27"
                    width="27"
                    color="#101928"
                    extraStyle={{
                      transform: `rotate(${
                        siteLang === "ar" ? "180" : "0"
                      }deg)`,
                    }}
                  />
                }
              />
              <h1>
                {type === "edit"
                  ? [
                      translation[siteLang].edit,
                      " ",
                      translation[siteLang].product.toLowerCase(),
                    ]
                  : [
                      translation[siteLang].add,
                      " ",
                      translation[siteLang][
                        siteLang === "ar" ? "product" : "new"
                      ].toLowerCase(),
                      " ",
                      translation[siteLang][
                        siteLang === "ar" ? "new" : "product"
                      ].toLowerCase(),
                    ]}
              </h1>
            </div>
          }
          secondSection={
            <Button
              label={translation[siteLang].save}
              buttonType="submit"
              size="lg"
              styleType="primary"
              disabled={
                isFetching || updatingModule.submissionState.isSubmitting
              }
              isLoading={updatingModule.submissionState.isSubmitting}
            />
          }
        />
        <SectionsWrapper isSeparated={true} fixedWidth={true}>
          {isFetching ? (
            <div style={{ width: "fit-content", margin: "100px auto 0" }}>
              <LoadingSpinner type="circle" />
            </div>
          ) : (
            <div className="section_wrapper_row">
              <div style={{ width: "70%" }}>
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].basic_info}</h3>
                  </div>
                  <div className="box_content">
                    <div className="box_row">
                      <div>
                        <div className="box_section">
                          <InputField
                            label={`${translation[siteLang].name} ${translation[siteLang].en}`}
                            type="text"
                            props={register(
                              "name_en",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.name_en?.message}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={`${translation[siteLang].name} ${translation[siteLang].ar}`}
                            type="text"
                            props={register(
                              "name_ar",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.name_ar?.message}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={`${translation[siteLang].description} ${translation[siteLang].en}`}
                            type="textarea"
                            props={register(
                              "info_en",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.info_en?.message}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={`${translation[siteLang].description} ${translation[siteLang].ar}`}
                            type="textarea"
                            props={register(
                              "info_ar",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.info_ar?.message}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={translation[siteLang].price}
                            type="number"
                            icon={<>{siteLang === "ar" ? "جنيه" : "EGP"}</>}
                            iconBorder={true}
                            props={register(
                              "price",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.price?.message}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={translation[siteLang].sku}
                            type="text"
                            props={register(
                              "sku",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.sku?.message}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={translation[siteLang].barcode}
                            type="text"
                            props={register(
                              "barcode",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.barcode?.message}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputCheckbox
                            topLabel={translation[siteLang].featured}
                            checkboxStyle="switcher"
                            props={register(
                              "featured",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.featured?.message}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </BoxWrapper>
              </div>
              <div style={{ width: "30%" }}>
                <BoxWrapper hasBorder={true}>
                  <div className="box_content">
                    <InputSelect
                      label={translation[siteLang].brand}
                      options={[
                        {
                          options:
                            extraFetchedData?.brands?.map((el: any) => ({
                              label: el.name,
                              value: el.id.toString(),
                            })) || [],
                        },
                      ]}
                      props={{
                        ...register(
                          "brand_id",
                          validateInputField(siteLang, true, true)
                        ),
                        value: watchedInputs.brand_id,
                      }}
                      error={formState.errors?.brand_id?.message}
                    />
                  </div>
                </BoxWrapper>
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].product_image}</h3>
                  </div>
                  <div className="box_content">
                    <InputFile
                      defaultFiles={
                        moduleDefaultData?.image
                          ? [{ id: 1, src: moduleDefaultData.image }]
                          : []
                      }
                      watchedFiles={watchedInputs.image}
                      onChangeFiles={(files) => {
                        setValue("image", files);
                      }}
                      props={{
                        accept: "image",
                      }}
                      // error={
                      //   watchedInputs.image.length === 0 &&
                      //   defaultValues.image.length !== 0 &&
                      //   formState.isSubmitted
                      //     ? translation[siteLang].required_field
                      //     : ""
                      // }
                    />
                  </div>
                </BoxWrapper>
              </div>
            </div>
          )}
        </SectionsWrapper>
      </UpdateModulePage>
    </MainContent>
  );
};

export default UpdateProducts;
