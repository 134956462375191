import axios from "axios";

const appAxios = axios.create({
  baseURL: "https://shadscut.com/api/",
});

appAxios.interceptors.request.use((config) => {
  const credentials = JSON.parse(
    localStorage.getItem("shads_credentials") || '""'
  );
  const lang = localStorage.getItem("shads_lang") || "en";
  config.headers = {
    Authorization: `Bearer ${credentials.token}`,
    ...(config?.headers?.["Accept-Language"]
      ? { "Accept-Language": config.headers["Accept-Language"] }
      : { "Accept-Language": lang }),
  };
  return config;
});

export default appAxios;
