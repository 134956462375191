import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import InputField from "../../components/ui/Inputs/InputField";
import {
  BoxWrapper,
  SectionsWrapper,
} from "../../components/updatingPages/components";
import MainContent from "../../containers/MainContent";
import useUpdatingData from "../../hooks/useUpdatingData";
import { selectSettings } from "../../store/selectors";
import {
  assignPermissionToRole,
  getModuleData,
  removePermissionFromRole,
} from "../../utils/fetchers";
import { Arrow, DotsIcon } from "../../utils/icons";
import translation from "../../utils/translation";
import { useCallback, useMemo } from "react";
import UpdateModulePage from "../../containers/UpdateModulePage";
import { validateInputField } from "../../containers/UpdateModulePage/utils";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { useParams } from "react-router-dom";
import Table from "../../components/ui/Table";
import InputCheckbox from "../../components/ui/Inputs/InputCheckbox";
import lodash from "lodash";
import DropdownOptions from "../../components/ui/Dropdown/DropdownOptions";

const moduleBaseName = "/admins/roles";

const UpdateRoles = ({ type }: { type: "create" | "edit" }) => {
  const { siteLang } = useSelector(selectSettings);
  const { roleID } = useParams();

  const extraFetchers = useMemo(
    () => [
      {
        function: (signal: AbortSignal) =>
          getModuleData("admin", "permissions", "", signal),
        returnName: "permissions",
        isModule: true,
      },
    ],
    []
  );

  const extraSubmissions = useMemo(
    () => [
      {
        function: (id: number, refinedData: any) => {
          return assignPermissionToRole(
            id,
            refinedData.permissions
              .filter((el: any) => !el.isDefault && el.isSelected)
              .map((el: any) => el.id)
          );
        },
        responseOfID: "role",
      },
      {
        function: (id: number, refinedData: any) =>
          removePermissionFromRole(
            id,
            refinedData.permissions
              .filter((el: any) => el.isDefault && !el.isSelected)
              .map((el: any) => el.id)
          ),
        responseOfID: "role",
      },
    ],
    []
  );

  const defaultValues = useMemo(
    () => ({
      name: "",
      permissions: [],
    }),
    []
  );

  const resetModuleDefaults = useMemo(
    () => (clonedDefaults: any, extraFetchedData: any) => {
      const permissionsRows: {
        name: string;
        permissions: {
          [name: string]: {
            id: number;
            isDefault: boolean;
            isSelected: boolean;
          };
        };
      }[] = [];
      extraFetchedData.permissions.forEach((permission: any) => {
        const foundIndex = permissionsRows.findIndex((el) =>
          permission.name.includes(el.name)
        );
        if (foundIndex !== -1) {
          permissionsRows[foundIndex].permissions[
            permission.name.split(" ")[0]
          ] = {
            id: permission.id,
            isDefault: clonedDefaults?.permissions?.find(
              (el: any) => el.id === permission.id
            )
              ? true
              : false,
            isSelected: clonedDefaults?.permissions?.find(
              (el: any) => el.id === permission.id
            )
              ? true
              : false,
          };
        } else {
          permissionsRows.push({
            name: permission.name.split(" ")[1],
            permissions: {},
          });
          permissionsRows[permissionsRows.length - 1].permissions[
            permission.name.split(" ")[0]
          ] = {
            id: permission.id,
            isDefault: clonedDefaults?.permissions?.find(
              (el: any) => el.id === permission.id
            )
              ? true
              : false,
            isSelected: clonedDefaults?.permissions?.find(
              (el: any) => el.id === permission.id
            )
              ? true
              : false,
          };
        }
      });
      return {
        permissions: permissionsRows,
      };
    },
    []
  );

  const {
    isFetching,
    extraFetchedData,
    register,
    watchedInputs,
    setValue,
    moduleDefaultData,
    ...updatingModule
  } = useUpdatingData({
    moduleType: "admin",
    moduleState: type,
    moduleID: +(roleID || 0),
    resetModuleDefaults,
    moduleName: "roles",
    modulePostEndpoint:
      type === "edit" ? `role/update/${roleID}` : "role/create",
    modulePostType: "object",
    watchInputFields: true,
    defaultValues,
    navigateAfterSubmissionTo: moduleBaseName,
    extraFetchers,
    extraSubmissions,
    queries: "with=permissions",
  });

  const formState = updatingModule.formState;

  const updatedDataToSubmit = useMemo(() => {
    return (data: any) => {
      const permissions: {
        id: number;
        isDefault: boolean;
        isSelected: boolean;
      }[] = [];
      data.permissions.forEach((permission: any) => {
        Object.values(permission.permissions).forEach((el: any) => {
          permissions.push({ ...el });
        });
      });
      return {
        permissions,
        ...(type === "edit" && moduleDefaultData?.name
          ? {
              name: moduleDefaultData.name === data.name ? "" : data.name,
            }
          : {}),
      };
    };
  }, [moduleDefaultData, type]);

  const onSelectAllPermissions = useCallback(
    (type: "select" | "deselect") => {
      let clonedPermissions = lodash.cloneDeep(watchedInputs.permissions);
      clonedPermissions = clonedPermissions.map((group: any) => ({
        ...group,
        permissions: Object.keys(group.permissions).reduce(
          (acc, cur) =>
            Object.assign(acc, {
              [cur]: {
                ...group.permissions[cur],
                isSelected: type === "select" ? true : false,
              },
            }),
          {}
        ),
      }));
      setValue("permissions", clonedPermissions);
    },
    [watchedInputs.permissions, setValue]
  );

  return (
    <MainContent onlyInPage={true} hasFixedWidthHeader={true}>
      <UpdateModulePage
        moduleBaseName={moduleBaseName}
        updatedDataToSubmit={updatedDataToSubmit}
        {...updatingModule}
      >
        <PageTitle
          isSticky={true}
          firstSection={
            <div className="page_header_title_update">
              <Button
                buttonType="button"
                size="md"
                styleType="icon"
                onClick={() => updatingModule.onNavigateBack(moduleBaseName)}
                disabled={updatingModule.submissionState.isSubmitting}
                icon={
                  <Arrow
                    height="27"
                    width="27"
                    color="#101928"
                    extraStyle={{
                      transform: `rotate(${
                        siteLang === "ar" ? "180" : "0"
                      }deg)`,
                    }}
                  />
                }
              />
              <h1>
                {type === "edit"
                  ? [
                      translation[siteLang].edit,
                      " ",
                      translation[siteLang].role.toLowerCase(),
                    ]
                  : [
                      translation[siteLang].add,
                      " ",
                      translation[siteLang][
                        siteLang === "ar" ? "role" : "new"
                      ].toLowerCase(),
                      " ",
                      translation[siteLang][
                        siteLang === "ar" ? "new" : "role"
                      ].toLowerCase(),
                    ]}
              </h1>
            </div>
          }
          secondSection={
            <Button
              label={translation[siteLang].save}
              buttonType="submit"
              size="lg"
              styleType="primary"
              disabled={
                isFetching || updatingModule.submissionState.isSubmitting
              }
              isLoading={updatingModule.submissionState.isSubmitting}
            />
          }
        />
        <SectionsWrapper isSeparated={true} fixedWidth={true}>
          {isFetching ? (
            <div style={{ width: "fit-content", margin: "100px auto 0" }}>
              <LoadingSpinner type="circle" />
            </div>
          ) : (
            <>
              <BoxWrapper hasBorder={true}>
                <div className="box_header">
                  <h3>{translation[siteLang].basic_info}</h3>
                </div>
                <div className="box_content">
                  <div className="box_section">
                    <InputField
                      label={translation[siteLang].name}
                      type="text"
                      props={register(
                        "name",
                        validateInputField(siteLang, true)
                      )}
                      error={formState.errors?.name?.message}
                    />
                  </div>
                </div>
              </BoxWrapper>
              <BoxWrapper hasBorder={true}>
                <div
                  className="box_header"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h3>{translation[siteLang].permissions}</h3>
                  <DropdownOptions
                    button={{
                      size: "md",
                      styleType: "secondary",
                      icon: (
                        <DotsIcon width="20px" height="20px" color="#101928" />
                      ),
                    }}
                    openDirection="end"
                    options={[
                      {
                        buttons: [
                          {
                            buttonType: "button",
                            onClick: () => onSelectAllPermissions("select"),
                            label: translation[siteLang].select_all,
                          },
                          {
                            buttonType: "button",
                            onClick: () => onSelectAllPermissions("deselect"),
                            label: translation[siteLang].deselect_all,
                          },
                        ],
                      },
                    ]}
                    widgetStyle={{ minWidth: "150px" }}
                  />
                </div>
                <div
                  className="box_content"
                  style={
                    watchedInputs.permissions.length > 0 ? { padding: 0 } : {}
                  }
                >
                  <div className="box_section">
                    <Table
                      isHeaderFixed={true}
                      stickyTopOffsetValue="66px"
                      headings={[
                        {
                          label: translation[siteLang].permission,
                        },
                        {
                          label: translation[siteLang].view,
                          style: {
                            width: "200px",
                          },
                        },
                        {
                          label: translation[siteLang].create,
                          style: {
                            width: "200px",
                          },
                        },
                        {
                          label: translation[siteLang].update,
                          style: {
                            width: "200px",
                          },
                        },
                        {
                          label: translation[siteLang].delete,
                          style: {
                            width: "200px",
                          },
                        },
                      ]}
                      rows={watchedInputs.permissions.map(
                        (el: any, rowIndex: number) => ({
                          columns: [
                            {
                              value: el.name,
                            },
                            {
                              value: extraFetchedData?.permissions?.find(
                                (perm: any) => perm.name === `view ${el.name}`
                              ) && (
                                <InputCheckbox
                                  checkboxStyle="checkbox"
                                  props={{
                                    ...register(
                                      `permissions.${rowIndex}.permissions.view.isSelected`
                                    ),
                                    style: {
                                      marginTop: "5px",
                                      width: "15px",
                                      height: "15px",
                                    },
                                  }}
                                />
                              ),
                            },
                            {
                              value: extraFetchedData?.permissions?.find(
                                (perm: any) => perm.name === `create ${el.name}`
                              ) && (
                                <InputCheckbox
                                  checkboxStyle="checkbox"
                                  props={{
                                    ...register(
                                      `permissions.${rowIndex}.permissions.create.isSelected`
                                    ),
                                    style: {
                                      marginTop: "5px",
                                      width: "15px",
                                      height: "15px",
                                    },
                                  }}
                                />
                              ),
                            },
                            {
                              value: extraFetchedData?.permissions?.find(
                                (perm: any) => perm.name === `update ${el.name}`
                              ) && (
                                <InputCheckbox
                                  checkboxStyle="checkbox"
                                  props={{
                                    ...register(
                                      `permissions.${rowIndex}.permissions.update.isSelected`
                                    ),
                                    style: {
                                      marginTop: "5px",
                                      width: "15px",
                                      height: "15px",
                                    },
                                  }}
                                />
                              ),
                            },
                            {
                              value: extraFetchedData?.permissions?.find(
                                (perm: any) => perm.name === `delete ${el.name}`
                              ) && (
                                <InputCheckbox
                                  checkboxStyle="checkbox"
                                  props={{
                                    ...register(
                                      `permissions.${rowIndex}.permissions.delete.isSelected`
                                    ),
                                    style: {
                                      marginTop: "5px",
                                      width: "15px",
                                      height: "15px",
                                    },
                                  }}
                                />
                              ),
                            },
                          ],
                        })
                      )}
                    />
                  </div>
                </div>
              </BoxWrapper>
            </>
          )}
        </SectionsWrapper>
      </UpdateModulePage>
    </MainContent>
  );
};

export default UpdateRoles;
