import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import InputField from "../../components/ui/Inputs/InputField";
import {
  BoxWrapper,
  SectionsWrapper,
} from "../../components/updatingPages/components";
import MainContent from "../../containers/MainContent";
import useUpdatingData from "../../hooks/useUpdatingData";
import { selectSettings } from "../../store/selectors";
import { Arrow, Delete, Plus } from "../../utils/icons";
import translation from "../../utils/translation";
import { useCallback, useMemo } from "react";
import UpdateModulePage from "../../containers/UpdateModulePage";
import { validateInputField } from "../../containers/UpdateModulePage/utils";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { useParams } from "react-router-dom";
import InputCheckbox from "../../components/ui/Inputs/InputCheckbox";
import InputSelect from "../../components/ui/Inputs/InputSelect";

const moduleBaseName = "/settings";

const UpdateSettings = ({ type }: { type: "create" | "edit" }) => {
  const { siteLang } = useSelector(selectSettings);
  const { settingID } = useParams();

  const defaultValues = useMemo(
    () => ({
      name: "",
      value: "",
      main: false,
      private: false,
      data_type: "string",
    }),
    []
  );

  const resetModuleDefaults = useMemo(
    () => (clonedDefaults: any) => ({
      value:
        (clonedDefaults?.data_type === "boolean"
          ? ""
          : clonedDefaults?.data_type.includes("arr")
          ? clonedDefaults?.value?.map((el: any) => ({ value: el }))
          : clonedDefaults?.value) || "",
    }),
    []
  );

  const {
    isFetching,
    extraFetchedData,
    register,
    watchedInputs,
    setValue,
    moduleDefaultData,
    ...updatingModule
  } = useUpdatingData({
    moduleType: "admin",
    moduleState: type,
    moduleID: +(settingID || 0),
    moduleName: "app_settings",
    modulePostEndpoint:
      type === "edit"
        ? `app-setting/update/${settingID}`
        : "app-setting/create",
    modulePostType: "formData",
    watchInputFields: true,
    defaultValues,
    ...(type === "edit" ? { resetModuleDefaults } : {}),
    navigateAfterSubmissionTo: moduleBaseName,
  });

  const formState = updatingModule.formState;

  const removeValueField = useCallback(
    (index: number) => {
      setValue(
        "value",
        watchedInputs.value.filter((_: any, indx: number) => indx !== index)
      );
    },
    [setValue, watchedInputs.value]
  );

  const appendValueField = useCallback(() => {
    setValue("value", [...watchedInputs.value, { value: "" }]);
  }, [setValue, watchedInputs.value]);

  const updatedDataToSubmit = useMemo(() => {
    return (data: any) => ({
      value:
        data.data_type === "boolean"
          ? "0,1"
          : data.data_type.includes("arr")
          ? data.value.map((el: any) => el.value).join(",")
          : data.value,
    });
  }, []);

  return (
    <MainContent onlyInPage={true} hasFixedWidthHeader={true}>
      <UpdateModulePage
        moduleBaseName={moduleBaseName}
        updatedDataToSubmit={updatedDataToSubmit}
        {...updatingModule}
      >
        <PageTitle
          firstSection={
            <div className="page_header_title_update">
              <Button
                buttonType="button"
                size="md"
                styleType="icon"
                onClick={() => updatingModule.onNavigateBack(moduleBaseName)}
                disabled={updatingModule.submissionState.isSubmitting}
                icon={
                  <Arrow
                    height="27"
                    width="27"
                    color="#101928"
                    extraStyle={{
                      transform: `rotate(${
                        siteLang === "ar" ? "180" : "0"
                      }deg)`,
                    }}
                  />
                }
              />
              <h1>
                {type === "edit"
                  ? [
                      translation[siteLang].edit,
                      " ",
                      translation[siteLang].setting.toLowerCase(),
                    ]
                  : [
                      translation[siteLang].add,
                      " ",
                      translation[siteLang][
                        siteLang === "ar" ? "setting" : "new"
                      ].toLowerCase(),
                      " ",
                      translation[siteLang][
                        siteLang === "ar" ? "new" : "setting"
                      ].toLowerCase(),
                    ]}
              </h1>
            </div>
          }
          secondSection={
            <Button
              label={translation[siteLang].save}
              buttonType="submit"
              size="lg"
              styleType="primary"
              disabled={
                isFetching || updatingModule.submissionState.isSubmitting
              }
              isLoading={updatingModule.submissionState.isSubmitting}
            />
          }
        />
        <SectionsWrapper isSeparated={true} fixedWidth={true}>
          {isFetching ? (
            <div style={{ width: "fit-content", margin: "100px auto 0" }}>
              <LoadingSpinner type="circle" />
            </div>
          ) : (
            <div className="section_wrapper_row">
              <div style={{ width: "60%" }}>
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].basic_info}</h3>
                  </div>
                  <div className="box_content">
                    <div className="box_section">
                      <div className="box_row">
                        <div style={{ width: "100%" }}>
                          <InputField
                            label={translation[siteLang].name}
                            type="text"
                            props={register(
                              "name",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.name?.message}
                          />
                        </div>
                        <div>
                          <InputCheckbox
                            checkboxStyle="switcher"
                            topLabel={translation[siteLang].main}
                            props={register("main")}
                          />
                        </div>
                        <div>
                          <InputCheckbox
                            checkboxStyle="switcher"
                            topLabel={translation[siteLang].private}
                            props={register("private")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </BoxWrapper>
                {watchedInputs.data_type !== "boolean" && (
                  <BoxWrapper hasBorder={true}>
                    <div
                      className="box_header"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <h3>{translation[siteLang].value}</h3>
                      {watchedInputs.data_type.includes("arr") && (
                        <Button
                          buttonType="button"
                          onClick={() => appendValueField()}
                          size="md"
                          styleType="secondary"
                          icon={
                            <Plus width="20px" height="20px" color="#101928" />
                          }
                        />
                      )}
                    </div>
                    <div className="box_content">
                      {watchedInputs.data_type !== "numeric_arr" &&
                        watchedInputs.data_type !== "string_arr" && (
                          <div className="box_section">
                            <InputField
                              label={
                                translation[siteLang][watchedInputs.data_type]
                              }
                              type={
                                watchedInputs.data_type === "numeric"
                                  ? "number"
                                  : "text"
                              }
                              props={register(
                                "value",
                                validateInputField(siteLang, true)
                              )}
                              error={formState.errors?.value?.message}
                            />
                          </div>
                        )}
                      {watchedInputs.data_type.includes("arr") &&
                        watchedInputs.value.map((_: any, fieldIndex: any) => (
                          <div className="box_section" key={fieldIndex}>
                            <div className="box_row">
                              <div style={{ flex: 1 }}>
                                <InputField
                                  type={
                                    watchedInputs.data_type === "numeric_arr"
                                      ? "number"
                                      : "text"
                                  }
                                  props={register(
                                    `value.${fieldIndex}.value`,
                                    validateInputField(siteLang, true)
                                  )}
                                  error={
                                    formState.errors?.value?.[fieldIndex]?.value
                                      ?.message
                                  }
                                />
                              </div>
                              {watchedInputs.value.length > 1 && (
                                <div style={{ width: "60px" }}>
                                  <Button
                                    icon={
                                      <Delete
                                        width="15"
                                        height="15"
                                        color="#101928"
                                        extraStyle={{
                                          width: "21",
                                          height: "21",
                                        }}
                                      />
                                    }
                                    buttonType="button"
                                    styleType="secondary"
                                    onClick={() => removeValueField(fieldIndex)}
                                    size="md"
                                    className="delete"
                                    extraStyle={{
                                      height: "47px",
                                      width: "47px",
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  </BoxWrapper>
                )}
              </div>
              <div style={{ width: "40%" }}>
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].data_type}</h3>
                  </div>
                  <div className="box_content">
                    <InputSelect
                      options={[
                        {
                          options: [
                            {
                              label: `${translation[siteLang].string}`,
                              value: "string",
                            },
                            {
                              label: `${translation[siteLang].numeric}`,
                              value: "numeric",
                            },
                            {
                              label: `${translation[siteLang].boolean}`,
                              value: "boolean",
                            },
                            {
                              label: `${translation[siteLang].string_arr}`,
                              value: "string_arr",
                            },
                            {
                              label: `${translation[siteLang].numeric_arr}`,
                              value: "numeric_arr",
                            },
                          ],
                        },
                      ]}
                      props={{
                        ...register(
                          "data_type",
                          validateInputField(siteLang, true, true)
                        ),
                        value: watchedInputs.data_type,
                      }}
                      error={formState.errors?.data_type?.message}
                      dependantConditions={[
                        {
                          name: "value",
                          value: {
                            string: "",
                            numeric: "",
                            boolean: "",
                            string_arr: [{ value: "" }],
                            numeric_arr: [{ value: "" }],
                          },
                        },
                      ]}
                      setDependantValues={setValue}
                    />
                  </div>
                </BoxWrapper>
              </div>
            </div>
          )}
        </SectionsWrapper>
      </UpdateModulePage>
    </MainContent>
  );
};

export default UpdateSettings;
