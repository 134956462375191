import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { FilterWidgetSection } from "../../components/listingPages/ListingComponents";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import DropdownCalendar from "../../components/ui/Dropdown/DropdownCalendar";
import InputCheckbox from "../../components/ui/Inputs/InputCheckbox";
import InputSelect from "../../components/ui/Inputs/InputSelect";
import Table from "../../components/ui/Table";
import MainContent from "../../containers/MainContent";
import MainHeader from "../../containers/MainHeader";
import MainSidebar from "../../containers/MainSidebar";
import ViewListingPage from "../../containers/ViewListingPage";
import useFilterListing from "../../hooks/useFilterListing";
import useListingData from "../../hooks/useListingData";
import { selectSettings, selectUser } from "../../store/selectors";
import {
  getAllBranches,
  getModuleData,
  refundOrder,
} from "../../utils/fetchers";
import {
  formatDate,
  formateOrderDate,
  formatMoney,
} from "../../utils/formatter";
import { CalendarIcon, Delete } from "../../utils/icons";
import translation from "../../utils/translation";
import { calendarLabel, orderServicesWithCategorized } from "./utils";
import { orderStatusOptions } from "../Orders/utils";
import { useParams, useSearchParams } from "react-router-dom";
import RefundOrderModal, {
  RefundStateType,
} from "../../components/orders/RefundOrderModal";
import * as dateFns from "date-fns";

const ListOrders = ({ isFromWorker = false }: { isFromWorker?: boolean }) => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { branch_id: userBranchID, permissions: userPermissions },
  } = useSelector(selectUser);

  const [searchParams] = useSearchParams();

  const defaultReservationTimeFilter = useMemo(() => {
    let date: Date[] | Date = dateFns.startOfDay(new Date());
    if (
      searchParams.get("reservation_time>=") ||
      searchParams.get("reservation_time<=")
    ) {
      date = [];
      if (searchParams.get("reservation_time>="))
        date.push(
          dateFns.startOfDay(
            new Date(searchParams.get("reservation_time>=") as string)
          )
        );
      if (searchParams.get("reservation_time<="))
        date.push(
          dateFns.endOfDay(
            new Date(searchParams.get("reservation_time<=") as string)
          )
        );
    }
    return date;
  }, [searchParams]);

  const { workerName, workerID } = useParams();

  const [refundState, setRefundState] = useState<RefundStateType>({
    isSetToSubmit: -1,
    isSubmitting: false,
    success: false,
    error: "",
  });

  const extraFetchers = useMemo(
    () => [
      {
        function: (signal?: AbortSignal) =>
          getModuleData(
            userBranchID ? "branch" : "admin",
            "workers",
            "",
            signal
          ),
        returnName: "workers",
        isModule: true,
      },
      {
        function: (signal?: AbortSignal) =>
          getModuleData(
            userBranchID ? "branch" : "admin",
            "services",
            "with=category",
            signal
          ),
        returnName: "services",
        isModule: true,
      },
      ...(userBranchID
        ? []
        : [
            {
              function: getAllBranches,
              returnName: "branches",
            },
          ]),
    ],
    [userBranchID]
  );

  const filterDefaultValues = useMemo(() => {
    return {
      "branch_id=": searchParams.get("branch_id=") || "__default",
      "status=": searchParams.get("status=") || "__default",
      "source=": searchParams.get("source=") || "__default",
      "payment_method=":
        searchParams.get("payment_method=")?.split(",") || "__default",
      "worker_id=": searchParams.get("worker_id=")?.split(",") || "__default",
      "user_membership=":
        searchParams.get("user_membership=")?.split(",") || "__default",
      "service_ids=":
        searchParams.get("service_ids=")?.split(",") || "__default",
      ...(isFromWorker
        ? {
            "worker_status=": "__default",
            "worker_reservation_time>=": formateOrderDate(
              dateFns.startOfDay(new Date())
            ),
            "worker_reservation_time<=": formateOrderDate(
              dateFns.endOfDay(new Date())
            ),
          }
        : {}),
    };
  }, [searchParams, isFromWorker]);

  const {
    handleSubmit,
    register,
    reset,
    filterFieldsValues,
    setValue: setFilterValue,
  } = useFilterListing(filterDefaultValues);

  const defaultFilter = useMemo(() => {
    return {
      "reservation_time>=": formateOrderDate(dateFns.startOfDay(new Date())),
      "reservation_time<=": formateOrderDate(dateFns.endOfDay(new Date())),
    };
  }, []);

  const {
    data,
    extraFetchedData,
    onRequestDeleteHandler,
    onSelectTableRowHandler,
    isAllRowsSelected,
    showMultipleDelete,
    setListingData,
    ...rest
  } = useListingData({
    moduleName: isFromWorker ? "workers" : "orders",
    moduleType: userBranchID ? "branch" : "admin",
    queries: isFromWorker
      ? `with=orders,orders.services,orders.user,orders.branch,orders.payment_methods,orders.order_workers,workers,coupon&number=all&where=id=${workerID}`
      : `with=order_workers,workers,payment_methods,user,services,branch,coupon&number=all`,
    defaultFilter: isFromWorker ? undefined : defaultFilter,
    extraFetchers,
    isSubModuleListing: isFromWorker,
    subModuleName: isFromWorker ? "orders" : "",
    filterDefaultValues,
    localSearchFilter: true,
  });

  const onSetRefundOrderToSubmit = useCallback((id: number) => {
    setRefundState({
      isSetToSubmit: id,
      isSubmitting: false,
      success: false,
      error: "",
    });
  }, []);

  const onResetRefundOrderToSubmit = useCallback(() => {
    setRefundState({
      isSetToSubmit: -1,
      isSubmitting: false,
      success: false,
      error: "",
    });
  }, []);

  const onRefundOrder = useCallback(
    async (data: {
      refund_reason_en: string;
      refund_reason_ar: string;
      return_to_wallet: boolean;
    }) => {
      setRefundState((current) => ({
        ...current,
        isSubmitting: true,
        success: false,
        error: "",
      }));
      let success = false,
        error = "";
      await refundOrder(refundState.isSetToSubmit, data)
        .then(() => {
          success = true;
        })
        .catch(() => {
          error = translation[siteLang].submission_error_msg;
        });
      if (success)
        setListingData((current) => ({
          ...current,
          data: current.data.map((el) => ({
            ...el,
            status:
              el.id === refundState.isSetToSubmit ? "refunded" : el.status,
          })),
        }));
      setRefundState((current) => ({
        isSetToSubmit: success ? -1 : current.isSetToSubmit,
        isSubmitting: false,
        success,
        error,
      }));
    },
    [refundState.isSetToSubmit, siteLang, setListingData]
  );

  const ordersList = useMemo<any[]>(() => {
    if (!isFromWorker && (rest.filter.isActive || rest.search.searchValue)) {
      return data
        .filter((order) =>
          rest.search.searchValue
            ? order?.user?.name
                ?.toLowerCase()
                ?.includes(rest.search.searchValue.toLowerCase()) ||
              order?.user?.phone?.includes(
                rest.search.searchValue.toLowerCase()
              ) ||
              order.id
                .toString()
                .includes(rest.search.searchValue.toLowerCase())
            : true
        )
        .filter((order) =>
          filterFieldsValues["payment_method="] !== "__default" &&
          filterFieldsValues["payment_method="]?.length
            ? (order?.payment_methods || []).findIndex((el: any) =>
                filterFieldsValues["payment_method="].includes(
                  el.payment_method
                )
              ) !== -1
            : true
        )
        .filter((order) =>
          filterFieldsValues["worker_id="] !== "__default" &&
          filterFieldsValues["worker_id="]?.length
            ? (order?.order_workers || []).findIndex((el: any) =>
                filterFieldsValues["worker_id="].includes(
                  el.worker_id.toString()
                )
              ) !== -1
            : true
        )
        .filter((order) =>
          filterFieldsValues["user_membership="] !== "__default" &&
          filterFieldsValues["user_membership="]?.length
            ? filterFieldsValues["user_membership="].includes(
                order?.user?.membership || ""
              )
            : true
        )
        .filter((order) =>
          filterFieldsValues["service_ids="] !== "__default" &&
          filterFieldsValues["service_ids="]?.length
            ? (order?.services || []).findIndex((el: any) =>
                filterFieldsValues["service_ids="].includes(el.id.toString())
              ) !== -1
            : true
        );
    } else if (isFromWorker) {
      return (data?.[0]?.orders || [])
        .filter((el: any) =>
          rest.search.searchValue
            ? el?.user?.name
                ?.toLowerCase()
                ?.includes(rest.search.searchValue.toLowerCase()) ||
              el?.user?.phone?.includes(
                rest.search.searchValue.toLowerCase()
              ) ||
              el?.id.toString() === rest.search.searchValue.toLowerCase()
            : true
        )
        .filter(
          (el: any) =>
            el.reservation_time >=
              filterFieldsValues["worker_reservation_time>="] &&
            el.reservation_time <=
              filterFieldsValues["worker_reservation_time<="]
        )
        .filter((el: any) =>
          filterFieldsValues["worker_status="] !== "__default"
            ? el.status === filterFieldsValues["worker_status="]
            : true
        );
    } else return data;
  }, [
    data,
    isFromWorker,
    filterFieldsValues,
    rest.filter.isActive,
    rest.search.searchValue,
  ]);

  return (
    <>
      {refundState.isSetToSubmit !== -1 && (
        <RefundOrderModal
          onRefundOrder={onRefundOrder}
          onResetRefundOrderToSubmit={onResetRefundOrderToSubmit}
          refundState={refundState}
        />
      )}
      <MainHeader />
      <MainSidebar />
      <MainContent>
        <PageTitle
          firstSection={
            <>
              {workerName && (
                <span style={{ color: "#878c93", fontWeight: "500" }}>
                  {workerName}
                </span>
              )}
              <h1>
                {[
                  translation[siteLang][siteLang === "ar" ? "list" : "orders"],
                  " ",
                  translation[siteLang][
                    siteLang === "ar" ? "orders" : "list"
                  ].toLocaleLowerCase(),
                ]}
              </h1>
              {!workerName && (
                <>
                  <span style={{ color: "#878c93", fontWeight: "500" }}>
                    {translation[siteLang].total}: {ordersList.length}
                  </span>
                  <br />
                  <span style={{ color: "#878c93", fontWeight: "500" }}>
                    {translation[siteLang].total_sales}:{" "}
                    {formatMoney(
                      ordersList.reduce(
                        (acc, cur) => acc + (cur.total_amount || 0),
                        0
                      ),
                      siteLang
                    )}
                  </span>
                  <br />
                  <span style={{ color: "#878c93", fontWeight: "500" }}>
                    {translation[siteLang].cash_sales}:{" "}
                    {formatMoney(
                      ordersList.reduce(
                        (acc, cur) =>
                          acc +
                          (cur?.payment_methods?.reduce(
                            (acc: any, cur: any) =>
                              acc +
                              (cur.payment_method === "cash"
                                ? cur.paid_amount
                                : 0),
                            0
                          ) || 0),
                        0
                      ),
                      siteLang
                    )}
                  </span>
                  <br />
                  <span style={{ color: "#878c93", fontWeight: "500" }}>
                    {translation[siteLang].card_sales}:{" "}
                    {formatMoney(
                      ordersList.reduce(
                        (acc, cur) =>
                          acc +
                          (cur?.payment_methods?.reduce(
                            (acc: any, cur: any) =>
                              acc +
                              (cur.payment_method === "card"
                                ? cur.paid_amount
                                : 0),
                            0
                          ) || 0),
                        0
                      ),
                      siteLang
                    )}
                  </span>
                </>
              )}
            </>
          }
          secondSection={
            <>
              {userPermissions?.orders?.create && !isFromWorker ? (
                <Button
                  styleType="primary"
                  size="lg"
                  label={[
                    translation[siteLang][siteLang === "ar" ? "order" : "new"],
                    translation[siteLang][
                      siteLang === "ar" ? "new" : "order"
                    ].toLowerCase(),
                  ].join(" ")}
                  buttonType="link"
                  url="/orders/new"
                />
              ) : null}
              {isFromWorker ? (
                <Button
                  styleType="secondary"
                  size="lg"
                  label={[
                    translation[siteLang].back_to,
                    translation[siteLang].workers.toLowerCase(),
                  ].join(" ")}
                  buttonType="link"
                  url={`/workers`}
                />
              ) : null}
            </>
          }
        />
        <ViewListingPage
          {...rest}
          hasNoResults={ordersList.length === 0}
          tableFilter={{
            showMultipleDelete,
            onRequestDeleteHandler,
            ...(!isFromWorker
              ? {
                  hasSearch: true,
                  search: {
                    ...rest.search,
                    placeholder:
                      translation[siteLang].search_all +
                      " " +
                      translation[siteLang].orders.toLowerCase(),
                  },
                  hasSorting: true,
                  sorting: {
                    ...rest.sort,
                    options: [
                      {
                        label: `${translation[siteLang].default}`,
                        value: "id__desc",
                      },
                      {
                        label: `${translation[siteLang].reservation_time} ↑`,
                        value: "reservation_time__asc",
                      },
                      {
                        label: `${translation[siteLang].reservation_time} ↓`,
                        value: "reservation_time__desc",
                      },
                    ],
                  },
                }
              : {}),
            excludedFilterApplied: [
              "reservation_time>=",
              "reservation_time<=",
              ...(isFromWorker
                ? [
                    "worker_status=",
                    "worker_reservation_time>=",
                    "worker_reservation_time<=",
                  ]
                : []),
            ],
            hasFilter: true,
            ...(!isFromWorker
              ? {
                  filter: {
                    ...rest.filter,
                    formSubmission: handleSubmit,
                    reset,
                    filterDefaultValues,
                    filterContent: (
                      <div>
                        {!userBranchID && (
                          <FilterWidgetSection>
                            <InputSelect
                              label={translation[siteLang].branch}
                              options={[
                                {
                                  options: [
                                    {
                                      label: translation[siteLang].all_branches,
                                      value: "__default",
                                    },
                                    ...(extraFetchedData?.branches?.map(
                                      (el: any) => ({
                                        label: el.name,
                                        value: el.id.toString(),
                                      })
                                    ) || []),
                                  ],
                                },
                              ]}
                              props={{
                                ...register("branch_id="),
                                value: filterFieldsValues["branch_id="],
                              }}
                            />
                          </FilterWidgetSection>
                        )}
                        <FilterWidgetSection>
                          <InputSelect
                            label={translation[siteLang].status}
                            options={[
                              {
                                options: [
                                  {
                                    label: translation[siteLang].default,
                                    value: "__default",
                                  },
                                  ...orderStatusOptions(siteLang),
                                  {
                                    label: translation[siteLang].refunded,
                                    value: "refunded",
                                  },
                                ],
                              },
                            ]}
                            props={{
                              ...register("status="),
                              value: filterFieldsValues["status="],
                            }}
                          />
                        </FilterWidgetSection>
                        <FilterWidgetSection>
                          <InputSelect
                            label={translation[siteLang].source}
                            options={[
                              {
                                options: [
                                  {
                                    label: translation[siteLang].default,
                                    value: "__default",
                                  },
                                  {
                                    label: translation[siteLang].mobile,
                                    value: "mobile",
                                  },
                                  {
                                    label:
                                      translation[siteLang].dashboard_source,
                                    value: "dashboard",
                                  },
                                ],
                              },
                            ]}
                            props={{
                              ...register("source="),
                              value: filterFieldsValues["source="],
                            }}
                          />
                        </FilterWidgetSection>
                        <FilterWidgetSection>
                          <InputSelect
                            label={translation[siteLang].services}
                            isMulti={true}
                            options={
                              orderServicesWithCategorized({
                                fetchedServices:
                                  extraFetchedData?.services || [],
                                siteLang,
                              }) || []
                            }
                            props={{
                              ...register("service_ids="),
                              value: filterFieldsValues["service_ids="],
                            }}
                          />
                        </FilterWidgetSection>
                        <FilterWidgetSection>
                          <InputSelect
                            label={translation[siteLang].payment_method}
                            isMulti={true}
                            options={[
                              {
                                options: [
                                  {
                                    label: translation[siteLang].cash,
                                    value: "cash",
                                  },
                                  {
                                    label: translation[siteLang].card,
                                    value: "card",
                                  },
                                  {
                                    label: translation[siteLang].lucky,
                                    value: "lucky",
                                  },
                                  {
                                    label: translation[siteLang].waffarha,
                                    value: "waffarha",
                                  },
                                ],
                              },
                            ]}
                            props={{
                              ...register("payment_method="),
                              value: filterFieldsValues["payment_method="],
                            }}
                          />
                        </FilterWidgetSection>
                        <FilterWidgetSection>
                          <InputSelect
                            label={translation[siteLang].worker}
                            isMulti={true}
                            options={[
                              {
                                options:
                                  extraFetchedData?.workers?.map((el: any) => ({
                                    label: el.name,
                                    value: el.id.toString(),
                                  })) || [],
                              },
                            ]}
                            props={{
                              ...register("worker_id="),
                              value: filterFieldsValues["worker_id="],
                            }}
                          />
                        </FilterWidgetSection>
                        <FilterWidgetSection>
                          <InputSelect
                            label={translation[siteLang].membership}
                            isMulti={true}
                            options={[
                              {
                                options: [
                                  {
                                    label: translation[siteLang].basic,
                                    value: "BASIC",
                                  },
                                  {
                                    label: translation[siteLang].silver,
                                    value: "SILVER",
                                  },
                                  {
                                    label: translation[siteLang].gold,
                                    value: "GOLD",
                                  },
                                  {
                                    label: translation[siteLang].plat,
                                    value: "PLAT",
                                  },
                                ],
                              },
                            ]}
                            props={{
                              ...register("user_membership="),
                              value: filterFieldsValues["user_membership="],
                            }}
                          />
                        </FilterWidgetSection>
                      </div>
                    ),
                  },
                }
              : {}),
            extraFilters: (
              <>
                <DropdownCalendar
                  button={{
                    size: "lg",
                    styleType: "secondary",
                    label: calendarLabel(
                      isFromWorker
                        ? filterFieldsValues["worker_reservation_time>="]
                        : rest.filter.filterData["reservation_time>="],
                      isFromWorker
                        ? filterFieldsValues["worker_reservation_time<="]
                        : rest.filter.filterData["reservation_time<="],
                      siteLang
                    ),
                    icon: (
                      <CalendarIcon width="20" height="20" color="#a2a6ab" />
                    ),
                    iconPosition: "start",
                  }}
                  onChange={(value) => {
                    if (isFromWorker) {
                      setFilterValue(
                        "worker_reservation_time>=",
                        formateOrderDate((value as [Date, Date])[0])
                      );
                      setFilterValue(
                        "worker_reservation_time<=",
                        formateOrderDate((value as [Date, Date])[1])
                      );
                    } else
                      rest.filter.onChangeFilterHandler({
                        "reservation_time>=": formateOrderDate(
                          (value as [Date, Date])[0]
                        ),
                        "reservation_time<=": formateOrderDate(
                          (value as [Date, Date])[1]
                        ),
                      });
                  }}
                  defaultValue={defaultReservationTimeFilter}
                  selectRange={true}
                />
                {isFromWorker && (
                  <>
                    <span style={{ marginLeft: "10px" }}>
                      {translation[siteLang].status}:
                    </span>
                    <InputSelect
                      options={[
                        {
                          options: [
                            {
                              label: translation[siteLang].default,
                              value: "__default",
                            },
                            ...orderStatusOptions(siteLang),
                            {
                              label: translation[siteLang].refunded,
                              value: "refunded",
                            },
                          ],
                        },
                      ]}
                      props={{
                        ...register("worker_status="),
                        value: filterFieldsValues["worker_status="],
                        style: {
                          minWidth: "150px",
                        },
                      }}
                    />
                  </>
                )}
              </>
            ),
          }}
        >
          <Table
            isHeaderFixed={true}
            headings={[
              ...(ordersList.length > 0 &&
              userPermissions?.orders?.delete &&
              !isFromWorker
                ? [
                    {
                      label: (
                        <InputCheckbox
                          checkboxStyle="checkbox"
                          props={{
                            onChange: () => onSelectTableRowHandler(0, true),
                            checked: isAllRowsSelected,
                            style: {
                              marginTop: "7px",
                              width: "15px",
                              height: "15px",
                            },
                          }}
                        />
                      ),
                      style: {
                        width: "50px",
                      },
                    },
                  ]
                : []),
              {
                label: translation[siteLang].id,
                style: {
                  width: "70px",
                },
              },
              {
                label: translation[siteLang].user,
              },
              {
                label: translation[siteLang].phone,
              },
              {
                label: translation[siteLang].status,
                style: {
                  width: "110px",
                },
              },
              {
                label: translation[siteLang].service,
              },
              {
                label: translation[siteLang].total,
              },
              {
                label: translation[siteLang].coupon,
              },
              {
                label: translation[siteLang].payment_method,
              },
              {
                label: translation[siteLang].workers,
              },
              {
                label: translation[siteLang].date,
              },
              {
                label: translation[siteLang].source,
              },
              ...(!userBranchID
                ? [
                    {
                      label: translation[siteLang].branch,
                    },
                  ]
                : []),
              ...((userPermissions?.orders?.delete ||
                userPermissions?.orders?.update) &&
              !isFromWorker
                ? [
                    {
                      label: translation[siteLang].actions,
                      style: {
                        width: "150px",
                      },
                    },
                  ]
                : []),
            ]}
            rows={ordersList.map((el) => ({
              link:
                (el.status === "completed" && el.total_paid > 0) ||
                el.status === "refunded"
                  ? `/orders/view/${el.id}`
                  : userPermissions?.orders?.update
                  ? `/orders/edit/${el.id}`
                  : "",
              columns: [
                ...(userPermissions?.orders?.delete && !isFromWorker
                  ? [
                      {
                        link_not_applied: true,
                        value: (
                          <InputCheckbox
                            checkboxStyle="checkbox"
                            props={{
                              onChange: () => onSelectTableRowHandler(el.id),
                              checked: el.table_selected,
                              style: {
                                marginTop: "7px",
                                width: "15px",
                                height: "15px",
                              },
                            }}
                          />
                        ),
                      },
                    ]
                  : []),
                {
                  value: el.id,
                },
                {
                  value:
                    el.user.name +
                    (el.user.membership
                      ? ` (${
                          translation[siteLang][
                            el.user.membership.toLowerCase()
                          ]
                        })`
                      : ""),
                },
                {
                  value: el.user.phone,
                },
                {
                  value: translation[siteLang][el.status],
                },
                {
                  value: (
                    <ul>
                      {el.services.map((serv: any) => (
                        <li key={serv.id}>{serv.name}</li>
                      ))}
                    </ul>
                  ),
                },
                {
                  value: formatMoney(el.total_amount || 0, siteLang),
                },
                {
                  value: el?.coupon?.code,
                },
                {
                  value: (
                    <ul>
                      {el.payment_methods.map((method: any) => (
                        <li key={method.id}>
                          {translation[siteLang][method.payment_method]}:{" "}
                          {formatMoney(method.paid_amount || 0, siteLang)}
                        </li>
                      ))}
                    </ul>
                  ),
                },
                {
                  value: el?.workers
                    ?.map((worker: any) => worker.name)
                    ?.join(", "),
                },
                {
                  value: formatDate(el.reservation_time, siteLang, true),
                },
                {
                  value: translation[siteLang][el.source],
                },
                ...(!userBranchID
                  ? [
                      {
                        value: el.branch.name,
                      },
                    ]
                  : []),
                ...((userPermissions?.orders?.delete ||
                  userPermissions?.orders?.update) &&
                !isFromWorker
                  ? [
                      {
                        link_not_applied: true,
                        value: (
                          <div className="page_listing_table_actions">
                            {userPermissions?.orders?.delete && (
                              <Button
                                icon={
                                  <Delete
                                    width="20"
                                    height="20"
                                    color="#101928"
                                  />
                                }
                                buttonType="button"
                                styleType="secondary"
                                onClick={() => onRequestDeleteHandler([el.id])}
                                size="md"
                                className="delete"
                                extraStyle={{ height: "40px", width: "40px" }}
                              />
                            )}
                            {userPermissions?.refund_order?.create &&
                              el.status !== "refunded" &&
                              el.status === "completed" && (
                                <Button
                                  label={translation[siteLang].refund}
                                  buttonType="button"
                                  onClick={() =>
                                    onSetRefundOrderToSubmit(el.id)
                                  }
                                  styleType="secondary"
                                  size="md"
                                  extraStyle={{
                                    padding: "0 10px",
                                    minWidth: "auto",
                                  }}
                                />
                              )}
                          </div>
                        ),
                      },
                    ]
                  : []),
              ],
            }))}
          />
        </ViewListingPage>
      </MainContent>
    </>
  );
};

export default ListOrders;
