import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Button from "../../components/ui/Button";
import InputField from "../../components/ui/Inputs/InputField";
import InputSelect from "../../components/ui/Inputs/InputSelect";
import {
  BoxWrapper,
  SectionsWrapper,
} from "../../components/updatingPages/components";
import MainContent from "../../containers/MainContent";
import useUpdatingData from "../../hooks/useUpdatingData";
import { selectSettings, selectUser } from "../../store/selectors";
import {
  assignServicesToWorker,
  assignWorkdays,
  getAllBranches,
  getModuleData,
  removeServicesFromWorker,
  updateWorkdays,
  uploadWorkerProfilePic,
} from "../../utils/fetchers";
import { Arrow, CalendarIcon } from "../../utils/icons";
import translation from "../../utils/translation";
import { useMemo } from "react";
import UpdateModulePage from "../../containers/UpdateModulePage";
import { validateInputField } from "../../containers/UpdateModulePage/utils";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { useParams } from "react-router-dom";
import InputRadio from "../../components/ui/Inputs/InputRadio";
import Table from "../../components/ui/Table";
import InputCheckbox from "../../components/ui/Inputs/InputCheckbox";
import { formatDate, formatMoney } from "../../utils/formatter";
import DropdownCalendar from "../../components/ui/Dropdown/DropdownCalendar";
import { rawPhoneNumber } from "../Clients/utils";
import InputFile from "../../components/ui/Inputs/InputFile";
import { ClientDetailsRow } from "../../components/clients/ClientDetails/styles";
import { work_days } from "./utils";

const moduleBaseName = "/workers";

const UpdateWorkers = ({ type }: { type: "create" | "edit" }) => {
  const { siteLang } = useSelector(selectSettings);
  const {
    user: { branch_id: userBranchID },
  } = useSelector(selectUser);
  const { workerID } = useParams();

  const extraFetchers = useMemo(
    () => [
      ...(userBranchID
        ? []
        : [
            {
              function: getAllBranches,
              returnName: "branches",
            },
          ]),
      {
        function: (signal: AbortSignal) =>
          getModuleData(
            userBranchID ? "branch" : "admin",
            "services",
            "with=category",
            signal
          ),
        returnName: "services",
        isModule: true,
      },
    ],
    [userBranchID]
  );

  const extraSubmissions = useMemo(
    () => [
      {
        function: (id: number, refinedData: any) => {
          return uploadWorkerProfilePic(
            id,
            refinedData.profile_picture ? [refinedData.profile_picture] : []
          );
        },
        responseOfID: "worker",
      },
      {
        function: (id: number, refinedData: any) => {
          return assignServicesToWorker(
            id,
            refinedData.services
              .filter((el: any) => !el.isDefault && el.isSelected)
              .map((el: any) => ({ id: el.id }))
          );
        },
        responseOfID: "worker",
      },
      {
        function: (id: number, refinedData: any) =>
          removeServicesFromWorker(
            id,
            refinedData.services
              .filter((el: any) => el.isDefault && !el.isSelected)
              .map((el: any) => ({ id: el.id }))
          ),
        responseOfID: "worker",
      },
      {
        function: (id: number, refinedData: any) => {
          return assignWorkdays("worker", id, refinedData.newWorkingDays);
        },
        responseOfID: "worker",
      },
      {
        function: (id: number, refinedData: any) => {
          return updateWorkdays(refinedData.updatedWorkingDays);
        },
        responseOfID: "worker",
      },
    ],
    []
  );

  const defaultValues = useMemo(
    () => ({
      name: "",
      age: "",
      gender: "male",
      email: "",
      phone: "",
      password: "",
      job_title: "",
      monthly_salary: "",
      flag: "",
      profile_picture: [],
      branch_id: userBranchID ? userBranchID : "no_option",
      ...(type === "create" ? { started_at: new Date() } : {}),
      services: [],
      working_days: work_days.reduce(
        (acc, day) =>
          Object.assign(acc, {
            [day]: { on: false, from: "09:00", to: "17:00", id: null },
          }),
        {}
      ),
    }),
    [type, userBranchID]
  );

  const resetModuleDefaults = useMemo(
    () => (clonedDefaults: any, extraFetchedData: any) => ({
      services:
        extraFetchedData?.services?.map((el: any) => ({
          ...el,
          isSelected: clonedDefaults?.services?.find(
            (service: any) => service.id === el.id
          )
            ? true
            : false,
          isDefault: clonedDefaults?.services?.find(
            (service: any) => service.id === el.id
          )
            ? true
            : false,
        })) || [],
      branch_id: clonedDefaults?.branch_id?.toString() || "no_option",
      phone: +rawPhoneNumber(clonedDefaults?.phone || "") || "",
      ...(clonedDefaults?.work_days && clonedDefaults.work_days.length > 0
        ? {
            working_days: clonedDefaults.work_days.reduce(
              (acc: any, cur: any) =>
                Object.assign(acc, {
                  [cur.day]: {
                    on: cur.on === 1 ? true : false,
                    from: cur?.from?.substring(0, 5) || "09:00",
                    to: cur?.to?.substring(0, 5) || "17:00",
                    id: cur.id || null,
                  },
                }),
              {}
            ),
          }
        : {}),
      profile_picture: [],
    }),
    []
  );

  const {
    isFetching,
    extraFetchedData,
    register,
    watchedInputs,
    setValue,
    moduleDefaultData,
    ...updatingModule
  } = useUpdatingData({
    moduleType: userBranchID ? "branch" : "admin",
    moduleState: type,
    moduleID: +(workerID || 0),
    resetModuleDefaults,
    moduleName: "workers",
    modulePostEndpoint:
      type === "edit" ? `worker/update/${workerID}` : "worker/create",
    modulePostType: "object",
    watchInputFields: true,
    defaultValues,
    navigateAfterSubmissionTo: moduleBaseName,
    extraFetchers,
    extraSubmissions,
    queries: "with=services,work_days,additives,paycuts",
  });

  const formState = updatingModule.formState;

  const updatedDataToSubmit = useMemo(() => {
    return (data: any) => ({
      ...(type === "create"
        ? { started_at: new Date(data.started_at).toISOString().split("T")[0] }
        : {}),
      ...(rawPhoneNumber(data.phone) !==
      rawPhoneNumber(moduleDefaultData.phone || "")
        ? {
            phone: "+20" + rawPhoneNumber(data.phone),
          }
        : { phone: "" }),
      services: data.services.map((el: any) => ({
        id: el.id,
        isDefault: el.isDefault,
        isSelected: el.isSelected,
      })),
      newWorkingDays: Object.keys(data.working_days)
        .filter((day) => data.working_days[day].id === null)
        .reduce(
          (acc, cur) =>
            Object.assign(acc, { [cur]: { ...data.working_days[cur] } }),
          {}
        ),
      updatedWorkingDays: Object.keys(data.working_days)
        .filter((day) => {
          const defaultDay = moduleDefaultData?.work_days?.find(
            (el: any) => el.day === day
          );
          let needsUpdate = false;
          if (
            defaultDay &&
            ((defaultDay.on === 0 ? false : true) !==
              data.working_days[day].on ||
              defaultDay.from !== data.working_days[day].from ||
              defaultDay.to !== data.working_days[day].to)
          ) {
            needsUpdate = true;
          }
          return needsUpdate;
        })
        .map((day) => ({
          ...data.working_days[day],
        })),
      profile_picture: data.profile_picture?.[0]?.file || "",
    });
  }, [type, moduleDefaultData]);

  const isAllServicesSelected = watchedInputs.services.reduce(
    (acc: any, cur: any) => acc && cur.isSelected,
    true
  );

  const onSelectAllServices = () => {
    setValue(
      "services",
      watchedInputs.services.map((el: any) => ({
        ...el,
        isSelected: isAllServicesSelected ? false : true,
      }))
    );
  };

  return (
    <MainContent onlyInPage={true} hasFixedWidthHeader={true}>
      <UpdateModulePage
        moduleBaseName={moduleBaseName}
        updatedDataToSubmit={updatedDataToSubmit}
        {...updatingModule}
      >
        <PageTitle
          isSticky={true}
          firstSection={
            <div className="page_header_title_update">
              <Button
                buttonType="button"
                size="md"
                styleType="icon"
                onClick={() => updatingModule.onNavigateBack(moduleBaseName)}
                disabled={updatingModule.submissionState.isSubmitting}
                icon={
                  <Arrow
                    height="27"
                    width="27"
                    color="#101928"
                    extraStyle={{
                      transform: `rotate(${
                        siteLang === "ar" ? "180" : "0"
                      }deg)`,
                    }}
                  />
                }
              />
              <h1>
                {type === "edit"
                  ? [
                      translation[siteLang].edit,
                      " ",
                      translation[siteLang].worker.toLowerCase(),
                    ]
                  : [
                      translation[siteLang].add,
                      " ",
                      translation[siteLang][
                        siteLang === "ar" ? "worker" : "new"
                      ].toLowerCase(),
                      " ",
                      translation[siteLang][
                        siteLang === "ar" ? "new" : "worker"
                      ].toLowerCase(),
                    ]}
              </h1>
            </div>
          }
          secondSection={
            <Button
              label={translation[siteLang].save}
              buttonType="submit"
              size="lg"
              styleType="primary"
              disabled={
                isFetching || updatingModule.submissionState.isSubmitting
              }
              isLoading={updatingModule.submissionState.isSubmitting}
            />
          }
        />
        <SectionsWrapper isSeparated={true} fixedWidth={true}>
          {isFetching ? (
            <div style={{ width: "fit-content", margin: "100px auto 0" }}>
              <LoadingSpinner type="circle" />
            </div>
          ) : (
            <div className="section_wrapper_row">
              <div
                style={
                  type === "create" || !userBranchID ? { width: "70%" } : {}
                }
              >
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].basic_info}</h3>
                  </div>
                  <div className="box_content">
                    <div className="box_row">
                      <div>
                        <div className="box_section">
                          <InputField
                            label={translation[siteLang].name}
                            type="text"
                            props={register(
                              "name",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.name?.message}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={translation[siteLang].age}
                            type="number"
                            props={register(
                              "age",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.age?.message}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputRadio
                            label={translation[siteLang].gender}
                            currentValue="male"
                            options={[
                              {
                                label: translation[siteLang].male,
                                value: "male",
                              },
                              {
                                label: translation[siteLang].female,
                                value: "female",
                              },
                            ]}
                            props={{
                              ...register("gender"),
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={translation[siteLang].email}
                            type="text"
                            props={register(
                              "email",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.email?.message}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={translation[siteLang].password}
                            type="password"
                            props={register(
                              "password",
                              type === "create"
                                ? validateInputField(siteLang, true)
                                : {}
                            )}
                            error={
                              type === "create"
                                ? formState.errors?.password?.message
                                : ""
                            }
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={translation[siteLang].phone}
                            type="number"
                            icon={<>+20</>}
                            iconBorder={true}
                            props={register(
                              "phone",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.phone?.message}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={translation[siteLang].job_title}
                            type="text"
                            props={register(
                              "job_title",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.job_title?.message}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={translation[siteLang].monthly_salary}
                            type="number"
                            icon={<>{siteLang === "ar" ? "جنيه" : "EGP"}</>}
                            iconBorder={true}
                            props={register(
                              "monthly_salary",
                              validateInputField(siteLang, true)
                            )}
                            error={formState.errors?.monthly_salary?.message}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="box_section">
                          <InputField
                            label={translation[siteLang].flag}
                            type="text"
                            props={register("flag")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </BoxWrapper>
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].working_days}</h3>
                  </div>
                  <div className="box_content" style={{ padding: 0 }}>
                    <div className="box_section">
                      <Table
                        headings={[
                          {
                            label: translation[siteLang].on_off,
                            style: {
                              width: "110px",
                            },
                          },
                          {
                            label: translation[siteLang].day,
                            style: {
                              width: "30%",
                            },
                          },
                          {
                            label: translation[siteLang].from,
                          },
                          {
                            label: translation[siteLang].to,
                          },
                        ]}
                        rows={work_days.map((el: any, index: number) => ({
                          columns: [
                            {
                              value: (
                                <InputCheckbox
                                  checkboxStyle="switcher"
                                  props={{
                                    ...register(`working_days.${el}.on`),
                                    style: {
                                      marginTop: "5px",
                                      width: "15px",
                                      height: "15px",
                                    },
                                  }}
                                />
                              ),
                            },
                            {
                              value: translation[siteLang][el],
                            },
                            {
                              value: (
                                <InputField
                                  type="time"
                                  props={register(`working_days.${el}.from`)}
                                  disabled={!watchedInputs.working_days[el].on}
                                />
                              ),
                            },
                            {
                              value: (
                                <InputField
                                  type="time"
                                  props={register(`working_days.${el}.to`)}
                                  disabled={!watchedInputs.working_days[el].on}
                                />
                              ),
                            },
                          ],
                        }))}
                      />
                    </div>
                  </div>
                </BoxWrapper>
                <BoxWrapper hasBorder={true}>
                  <div className="box_header">
                    <h3>{translation[siteLang].services}</h3>
                  </div>
                  <div
                    className="box_content"
                    style={
                      watchedInputs.services.length > 0 ? { padding: 0 } : {}
                    }
                  >
                    <div className="box_section">
                      <Table
                        headings={[
                          {
                            label: (
                              <InputCheckbox
                                checkboxStyle="checkbox"
                                props={{
                                  onChange: onSelectAllServices,
                                  checked: isAllServicesSelected,
                                  style: {
                                    marginTop: "5px",
                                    width: "15px",
                                    height: "15px",
                                  },
                                }}
                              />
                            ),
                            style: {
                              width: "50px",
                            },
                          },
                          {
                            label: translation[siteLang].service,
                            style: {
                              width: "30%",
                            },
                          },
                          {
                            label: translation[siteLang].home,
                          },
                          {
                            label: translation[siteLang].category,
                          },
                          {
                            label: translation[siteLang].estimated_time,
                          },
                          {
                            label: translation[siteLang].price,
                          },
                        ]}
                        rows={watchedInputs.services.map(
                          (el: any, index: number) => ({
                            columns: [
                              {
                                value: (
                                  <InputCheckbox
                                    checkboxStyle="checkbox"
                                    props={{
                                      ...register(
                                        `services.${index}.isSelected`
                                      ),
                                      style: {
                                        marginTop: "5px",
                                        width: "15px",
                                        height: "15px",
                                      },
                                    }}
                                  />
                                ),
                              },
                              {
                                value: el.name,
                              },
                              {
                                value:
                                  translation[siteLang][el.home ? "yes" : "no"],
                              },
                              {
                                value: el.category.name,
                              },
                              {
                                value: el.estimated_time,
                              },
                              {
                                value: formatMoney(el.price, siteLang),
                              },
                            ],
                          })
                        )}
                      />
                    </div>
                  </div>
                </BoxWrapper>
              </div>
              {(type === "create" || !userBranchID) && (
                <div style={{ width: "30%" }}>
                  {!userBranchID && (
                    <BoxWrapper hasBorder={true}>
                      <div className="box_header">
                        <h3>{translation[siteLang].branch}</h3>
                      </div>
                      <div className="box_content">
                        <InputSelect
                          options={[
                            {
                              options:
                                extraFetchedData?.branches?.map((el: any) => ({
                                  label: el.name,
                                  value: el.id.toString(),
                                })) || [],
                            },
                          ]}
                          props={{
                            ...register(
                              "branch_id",
                              validateInputField(siteLang, true, true)
                            ),
                            value: watchedInputs.branch_id,
                          }}
                          error={formState.errors?.branch_id?.message}
                        />
                      </div>
                    </BoxWrapper>
                  )}
                  {type === "create" && (
                    <BoxWrapper hasBorder={true}>
                      <div className="box_header">
                        <h3>{translation[siteLang].start_date}</h3>
                      </div>
                      <div className="box_content">
                        <div className="box_content_full_width">
                          <DropdownCalendar
                            openDirection="end"
                            button={{
                              styleType: "secondary",
                              size: "lg",
                              icon: (
                                <CalendarIcon
                                  width="15"
                                  height="15"
                                  color="#878c93"
                                  extraStyle={{ width: "18px", height: "18px" }}
                                />
                              ),
                              iconPosition: "start",
                              label: formatDate(
                                watchedInputs.started_at.toISOString(),
                                siteLang,
                                false,
                                { weekday: "long" }
                              ),
                            }}
                            defaultValue={watchedInputs.date}
                            onChange={(date) => setValue("started_at", date)}
                          />
                        </div>
                      </div>
                    </BoxWrapper>
                  )}
                  <BoxWrapper hasBorder={true}>
                    <div className="box_header">
                      <h3>{translation[siteLang].profile_pic}</h3>
                    </div>
                    <div className="box_content">
                      <InputFile
                        defaultFiles={
                          moduleDefaultData?.profile_picture
                            ? [
                                {
                                  id: 1,
                                  src: moduleDefaultData.profile_picture,
                                },
                              ]
                            : []
                        }
                        watchedFiles={watchedInputs.profile_picture}
                        onChangeFiles={(files) => {
                          setValue("profile_picture", files);
                        }}
                        props={{
                          accept: "image",
                        }}
                      />
                    </div>
                  </BoxWrapper>
                  {type === "edit" && (
                    <BoxWrapper hasBorder={true}>
                      <div className="box_header">
                        <h3>{translation[siteLang].details}</h3>
                      </div>
                      <div className="box_content">
                        <ClientDetailsRow>
                          <span>{translation[siteLang].additives}</span>
                          <span>
                            {(moduleDefaultData.additives || []).reduce(
                              (acc: number, cur: any) => acc + +cur.value,
                              0
                            )}
                          </span>
                        </ClientDetailsRow>
                        <ClientDetailsRow>
                          <span>{translation[siteLang].paycuts}</span>
                          <span>
                            {(moduleDefaultData.paycuts || []).reduce(
                              (acc: number, cur: any) => acc + +cur.value,
                              0
                            )}
                          </span>
                        </ClientDetailsRow>
                        <ClientDetailsRow>
                          <span>{translation[siteLang].balance}</span>
                          <span>
                            {moduleDefaultData.accumlative_salary +
                              (moduleDefaultData.additives || []).reduce(
                                (acc: number, cur: any) => acc + +cur.value,
                                0
                              ) -
                              (moduleDefaultData.paycuts || []).reduce(
                                (acc: number, cur: any) => acc + +cur.value,
                                0
                              )}
                          </span>
                        </ClientDetailsRow>
                      </div>
                    </BoxWrapper>
                  )}
                </div>
              )}
            </div>
          )}
        </SectionsWrapper>
      </UpdateModulePage>
    </MainContent>
  );
};

export default UpdateWorkers;
