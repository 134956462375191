import { useSelector } from "react-redux";
import PageTitle from "../../components/listingPages/PageTitle";
import Table from "../../components/ui/Table";
import MainContent from "../../containers/MainContent";
import ViewListingPage from "../../containers/ViewListingPage";
import useListingData from "../../hooks/useListingData";
import { selectSettings } from "../../store/selectors";
import translation from "../../utils/translation";
import MainHeader from "../../containers/MainHeader";
import MainSidebar from "../../containers/MainSidebar";

const ListAdminNotifications = () => {
  const { siteLang } = useSelector(selectSettings);

  const { data, extraFetchedData, ...rest } = useListingData({
    moduleName: "notification_messages",
    moduleType: "admin",
    queries: "where=internal=1",
  });

  return (
    <>
      <MainHeader />
      <MainSidebar />
      <MainContent>
        <PageTitle
          firstSection={<h1>{translation[siteLang].admin_notifications}</h1>}
        />
        <ViewListingPage {...rest} hasNoResults={data.length === 0}>
          <Table
            isHeaderFixed={true}
            headings={[
              {
                label: translation[siteLang].name,
              },
              {
                label: translation[siteLang].description,
              },
            ]}
            rows={data.map((el) => ({
              columns: [
                {
                  value: el.title,
                },
                {
                  value: el.body,
                },
              ],
            }))}
          />
        </ViewListingPage>
      </MainContent>
    </>
  );
};

export default ListAdminNotifications;
