import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 30px 40px 20px;
  .client_image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 125px;
    height: 125px;
    border-radius: 50%;
    background-color: var(--lightBlue2);
    font-weight: 700;
    font-size: 40px;
    color: var(--blue);
    > img {
      width: 125px;
      height: 125px;
      object-fit: cover;
      border-radius: 50%;
    }
    .client_image__flag {
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 120%;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
  .client_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    text-align: center;
    h2 {
      font-weight: 800;
      color: var(--black);
      margin: 15px 0 0;
    }
    span {
      color: var(--lightGrey6);
    }
  }
  .client_actions {
    display: flex;
    justify-content: center;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
        &:not(:last-child) {
        margin-${(props) =>
          props.theme.lang === "ar" ? "left" : "right"}: 17px;
      }
      > p {
        font-size: 14px;
        font-weight: 700;
        color: var(--darkBlue);
        margin: 5px 0;
        text-align: center;
      }
    }
    a, button {
      padding: 10px;
      width: 52px;
      height: 52px;
      border-radius: 16px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

export const ClientSidebarLinks = styled.div`
  padding: 20px 40px 80px;
  border-top: 1px solid var(--lightGrey3);
  a {
    border-radius: 8px;
    color: var(--darkBlue);
    display: block;
    font-size: 16px;
    font-weight: 600;
    padding: 12px 15px;
    transition: background-color 0.1s ease-in-out;
    &:not(:last-child) {
      margin-bottom: 7px;
    }
    &:hover {
      background-color: var(--lightGrey);
      text-decoration: none;
      color: var(--darkBlue);
    }
    &.active {
      background-color: var(--lightBlue);
    }
  }
`;
