import {
  InputGeneralType,
  InputRadioType,
  onChangeInputHandler,
} from "../utils";
import InputContainer from "../InputContainer";
import Wrapper from "./styles";
import { Check } from "../../../../utils/icons";

const InputRadio = ({
  label,
  props,
  options,
  currentValue,
  note,
  error,
  dependantConditions,
  setDependantValues,
  shape = "normal",
  asCheckbox = false,
}: InputRadioType & InputGeneralType) => {
  return (
    <InputContainer label={label} note={note} error={error}>
      <Wrapper className="all_radio_options" shape={shape}>
        {options.map((option, index) => (
          <label key={index} className="option">
            <div className="option_container">
              <input
                type={asCheckbox ? "checkbox" : "radio"}
                value={option.value}
                {...(currentValue && {
                  defaultChecked: option.value === currentValue,
                })}
                {...props}
                onChange={(e) =>
                  onChangeInputHandler(
                    e,
                    props?.onChange,
                    dependantConditions,
                    setDependantValues
                  )
                }
              />
              {shape === "with_icon" ? (
                <div className="radio_choice">
                  <span className="check_icon">
                    <Check width="12" height="12" color="#fff" />
                  </span>
                  {option.icon}
                  <span>{option.label}</span>
                </div>
              ) : (
                <p>{option.label}</p>
              )}
            </div>
          </label>
        ))}
      </Wrapper>
    </InputContainer>
  );
};

export default InputRadio;
