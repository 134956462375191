import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import LoadingSpinner from "./components/ui/LoadingSpinner";
import Auth from "./pages/Auth";
import AppRoutes from "./pages/Routes";
import { selectUser } from "./store/selectors";
import { persistLoggedUser } from "./store/userSlice";
import style from "./utils/globalStyle";

const GlobalStyle = createGlobalStyle`${style}`;

const AppContainer = styled.section`
  position: relative;
  min-height: 100vh;
  min-width: 100%;
  overflow: hidden;
`;

const App = () => {
  const { user, isValidating } = useSelector(selectUser);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    document.documentElement.lang = localStorage.getItem("shads_lang") || "en";
    dispatch(persistLoggedUser());
  }, [dispatch]);

  useEffect(() => {
    if (!isValidating && !user) navigate("login");
  }, [user, isValidating, navigate]);

  return (
    <ThemeProvider theme={{ lang: localStorage.getItem("shads_lang") || "en" }}>
      <GlobalStyle />
      <AppContainer>
        {isValidating ? (
          <LoadingSpinner type="circle" style={{ marginTop: "150px" }} />
        ) : (
          <>
            {user && <AppRoutes />}
            <Auth />
          </>
        )}
      </AppContainer>
    </ThemeProvider>
  );
};

export default App;
