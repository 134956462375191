import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { rawPhoneNumber } from "../pages/Clients/utils";
import { userPermissions } from "../utils/auth";
import appAxios from "../utils/setupAxios";

type userStateType = {
  isValidating: boolean;
  isSubmitting: boolean;
  errorSubmitting: string;
  submitSuccess: boolean;
  user: any;
};

type userLoginCredentials = {
  phone: string;
  password: string;
};

export const logUser = createAsyncThunk(
  "user/fetch",
  async (credentials: userLoginCredentials, { rejectWithValue }) => {
    return await appAxios
      .post("auth/admin/login", {
        phone: "+20" + rawPhoneNumber(credentials.phone),
        password: credentials.password,
      })
      .then(async (res) => {
        localStorage.setItem(
          "shads_credentials",
          JSON.stringify({
            token: res.data.access_token,
            expiresOn: Date.now() + res.data.expires_in,
            refresh_token: res.data.refresh_token,
          })
        );
        return await appAxios
          .get("admin/dashboard-info")
          .then((res) => {
            const { roles, ...info } = res.data.info;
            return {
              ...info,
              permissions: userPermissions(roles?.[0]),
            };
          })
          .catch((error) => {
            return error.response.data.message;
          });
      })
      .catch((error) => {
        return rejectWithValue(error.response.data.message);
      });
  }
);

export const persistLoggedUser = createAsyncThunk(
  "user/persist",
  async (_, { rejectWithValue }) => {
    const credentials = JSON.parse(
      localStorage.getItem("shads_credentials") || ""
    );
    if (credentials.token && credentials.refresh_token) {
      try {
        const token = await appAxios.post("auth/admin/refresh_token", {
          refresh_token: credentials.refresh_token,
        });
        localStorage.setItem(
          "shads_credentials",
          JSON.stringify({
            token: token.data.access_token,
            expiresOn: Date.now() + token.data.expires_in,
            refresh_token: token.data.refresh_token,
          })
        );
        const userInfo = await appAxios.get("admin/dashboard-info");
        const { roles, ...info } = userInfo.data.info;
        return {
          ...info,
          permissions: userPermissions(roles?.[0]),
        };
      } catch {
        return rejectWithValue("Invalid Token");
      }
    } else {
      return rejectWithValue("Invalid Token");
    }
  }
);

const initialState: userStateType = {
  isValidating: true,
  isSubmitting: false,
  errorSubmitting: "",
  submitSuccess: false,
  user: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logUserOut: (state) => {
      localStorage.removeItem("shads_credentials");
      return {
        ...state,
        isValidating: false,
        isSubmitting: false,
        errorSubmitting: "",
        submitSuccess: false,
        user: null,
      };
    },
    updateUserItem: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logUser.pending, (state) => {
      state.isSubmitting = true;
    });
    builder.addCase(logUser.fulfilled, (state, action) => {
      state.errorSubmitting = "";
      state.user = {
        ...state.user,
        ...action.payload,
      };
      state.isSubmitting = false;
    });
    builder.addCase(logUser.rejected, (state, action) => {
      state.user = null;
      state.isSubmitting = false;
      state.errorSubmitting = (action.payload as string) || "";
    });
    builder.addCase(persistLoggedUser.pending, (state) => {
      state.isValidating = true;
    });
    builder.addCase(persistLoggedUser.fulfilled, (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
      };
      state.isValidating = false;
    });
    builder.addCase(persistLoggedUser.rejected, (state, action) => {
      state.isValidating = false;
    });
  },
});

export const { updateUserItem, logUserOut } = userSlice.actions;

export default userSlice.reducer;
