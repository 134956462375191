import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { TableFilterType } from "../../containers/ViewListingPage/utils";
import { selectSettings } from "../../store/selectors";
import { Chevron, Filter } from "../../utils/icons";
import translation from "../../utils/translation";
import Button from "../ui/Button";
import DropdownInputRadio from "../ui/Dropdown/DropdownInputRadio";
import InputSearch from "../ui/Inputs/InputSearch";
import SidebarWidget from "../ui/SidebarWidget";

const TableFilter = ({
  hasSearch = false,
  search,
  hasSorting = false,
  sorting,
  hasFilter = false,
  filter,
  extraFilters,
  extraFiltersPosition = "start",
  showMultipleDelete = false,
  onRequestDeleteHandler,
  excludedFilterApplied,
  extraFiltersFullWidth = false,
}: TableFilterType) => {
  const { siteLang } = useSelector(selectSettings);

  const [isFilterWidgetVisible, setIsFilterWidgetVisible] =
    useState<boolean>(false);

  const onSubmitFilterHandler = useCallback(
    (data: any) => {
      if (filter?.onChangeFilterHandler)
        filter.onChangeFilterHandler({
          ...data,
        });
    },
    [filter]
  );

  const onResetFilterHandler = useCallback(() => {
    if (filter?.reset && filter?.filterDefaultValues) {
      filter.reset({
        ...filter.filterDefaultValues,
      });
      onSubmitFilterHandler({
        ...filter.filterDefaultValues,
      });
    }
  }, [filter, onSubmitFilterHandler]);

  return (
    <div className="page_listing_filter">
      <div
        className="page_listing_filter_sec"
        style={
          extraFilters &&
          extraFiltersPosition === "start" &&
          extraFiltersFullWidth
            ? { width: "100%" }
            : {}
        }
      >
        {hasSearch && search && (
          <InputSearch
            onClearSearch={search.onCancelSearchHandler}
            props={{
              placeholder: search.placeholder,
              onChange: search.onChangeSearchHandler,
              value: search.searchValue || "",
            }}
          />
        )}
        {hasFilter && filter && (
          <>
            {isFilterWidgetVisible && (
              <SidebarWidget
                headerTitle={translation[siteLang].filters}
                onCloseHandler={() => setIsFilterWidgetVisible(false)}
                showFooter={true}
                footerButtons={[
                  {
                    buttonType: "button",
                    styleType: "secondary",
                    size: "lg",
                    label: translation[siteLang].clear,
                    onClick: onResetFilterHandler,
                  },
                  {
                    buttonType: "submit",
                    styleType: "primary",
                    size: "lg",
                    label: translation[siteLang].save,
                  },
                ]}
                containerType="form"
                formSubmission={filter.formSubmission(onSubmitFilterHandler)}
              >
                {filter.filterContent}
              </SidebarWidget>
            )}
            <Button
              styleType={
                Object.entries(filter.filterData).findIndex(
                  (el) =>
                    el[1] !== "__default" &&
                    !(excludedFilterApplied || []).includes(el[0])
                ) !== -1
                  ? "blue"
                  : "secondary_blue"
              }
              label={translation[siteLang].filters}
              icon={
                <Filter
                  width="20"
                  height="20"
                  color={
                    Object.entries(filter.filterData).findIndex(
                      (el) =>
                        el[1] !== "__default" &&
                        !(excludedFilterApplied || []).includes(el[0])
                    ) !== -1
                      ? "#fff"
                      : "#037aff"
                  }
                  extraStyle={{ transform: "rotate(90deg)" }}
                />
              }
              iconPosition="start"
              extraStyle={{ height: "45px" }}
              buttonType="button"
              onClick={() => setIsFilterWidgetVisible(true)}
              size="md"
            />
          </>
        )}
        {extraFilters && extraFiltersPosition === "start" && extraFilters}
      </div>
      {((hasSorting && sorting) ||
        (extraFilters && extraFiltersPosition === "end") ||
        (showMultipleDelete && onRequestDeleteHandler)) && (
        <div
          className="page_listing_filter_sec"
          style={
            extraFilters &&
            extraFiltersPosition === "end" &&
            extraFiltersFullWidth
              ? { width: "100%" }
              : {}
          }
        >
          {extraFilters && extraFiltersPosition === "end" && extraFilters}
          {hasSorting && sorting && (
            <>
              <p>{translation[siteLang].sort_by}:</p>
              <DropdownInputRadio
                button={{
                  styleType: "secondary_blue",
                  label: sorting.options.find(
                    (option) => option.value === sorting.sortParam
                  )?.label,
                  fullRadius: true,
                  size: "md",
                  icon: (
                    <Chevron
                      width="15"
                      height="15"
                      color="#037aff"
                      extraStyle={{ transform: "rotate(90deg)" }}
                    />
                  ),
                }}
                options={sorting.options}
                currentValue={sorting.sortParam}
                openDirection="end"
                props={{
                  name: "listing_sort",
                  onChange: (e) => sorting.onChangeSortHandler(e.target.value),
                }}
              />
            </>
          )}
          {showMultipleDelete && onRequestDeleteHandler && (
            <Button
              styleType="danger"
              label={translation[siteLang].delete_selected}
              size="md"
              buttonType="button"
              onClick={() => onRequestDeleteHandler([0], true)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TableFilter;
